import React from "react";
import i18next from "../i18n";
import InputBase from "@mui/material/InputBase";
import {styled} from "@mui/material/styles";
const StyledInputBaseMobile = styled(InputBase)(({ theme }) => ({
    input:{
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(0.5)}px)`,
        transition: theme.transitions.create('width'),
        width: '60%',
        [theme.breakpoints.up('md')]: {
            width: '18ch',
        },
    },
    root:{
        //backgroundColor: "white",
        //border: "solid",
        width:"50%",
        left: 0
    }
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
    input:{
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
        left: "40px!important",
        position: "relative"
    },
    root:{
        //backgroundColor: "white",
        //border: "solid",
        width:"50%",
        left: 0
    }
}));
class SearchInput extends React.Component {
    constructor(props){
        super(props);
        this.timeoutOnKey =  0;
        this.timeoutOnChange =  0;
    }

    onKeySearch(e){
        e.persist();
        var searchText = e.target.value; // this is the search text
        if(this.timeoutOnKey) clearTimeout(this.timeoutOnKey);
        this.timeoutOnKey = setTimeout(() => {
            if (searchText.length >2) {
                console.log(e,e.key)
                if (e.key === 'Enter')
                    this.props.searchblurhandle(searchText,true)
                else
                    this.props.searchblurhandle(searchText,false)
            }
        }, 250);
    }
    onChangeSearch(e){
        var searchText = e.target.value; // this is the search text
        if(this.timeoutOnChange) clearTimeout(this.timeoutOnChange);
        this.timeoutOnChange = setTimeout(() => {
            if (searchText.length >2)
                this.props.searchinputhandle(searchText)
        }, 250);
    }

    render() {
        if (this.props.mobile) {
            return  (
                <StyledInputBaseMobile
                    id={'search-input'}
                    placeholder={i18next.t('Search')}
                    inputProps={{ 'aria-label': 'search' }}
                    onKeyDown={(e)=>this.onKeySearch(e)}
                    onChange={(e)=>this.onChangeSearch(e)}
                    onFocus={this.props.searchfocushandle}
                    //onBlur={props.searchblurhandle}
                />
            )
        }else
            return (
                <StyledInputBase
                    id={"search-input"}
                    placeholder={i18next.t('Search')}
                    inputProps={{ 'aria-label': 'search' }}
                    onKeyDown={(e)=>this.onKeySearch(e)}
                    onChange={(e)=>this.onChangeSearch(e)}
                    onFocus={this.props.searchfocushandle}
                    //onBlur={props.searchblurhandle}
                />
            );
    }
}
export default SearchInput;