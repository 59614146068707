import React,{useState} from 'react';
import '../Styles/OptionsSlider.css'

export default function OptionsSlider(props) {
    const [value,setValue] = useState(0.8)
    return (
        <div style={{display:'flex'}}>
            <input
                style={{accentColor: "#2ecc71"}}
                id={"input-slider"}
                min={0}
                max={1}
                name={props.title}
                onChange={(e) => {
                    e.persist()
                    console.log(e);
                    setValue(Number.parseFloat(e.target.value));
                    props.handler(Number.parseFloat(e.target.value));
                }}
                step={0.01}
                type='range'
                value={value}
            />
            <div style={{paddingTop: '16px',color: '#4c4a37', font: 'Arial, sans-serif', fontSize: '12px', margin: '0 0 12px'}}>{value*100 +"%"}</div>
        </div>
    );
}
