import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";

export default function LoadInstrumentOnStart(props) {
    const [loading,setLoading] = useState(false)
    let params = useParams();
    console.log(params)

    useEffect(() => {

        if (loading)
            return
        setLoading(true)
        let instrument = params.instrument
        let tags =""
        if (instrument !== undefined){
            switch (instrument.toLowerCase()){
                case "analog" :
                    props.analoghandle()
                    tags = "поиск месторождений аналогов по всему миру, месторождения по параметрам, эталон, машинное обучение, benchmark, field analogue search system, ml, find deposit oil gas filed by parameters, criteria"
                    break;
                case "3dmodel" :
                    props.model3dhandle()
                    tags = "3д онлайн визуализатор просмотр геологических поверхностей, геомодель, 3d online grid surface viewer irap, petrel, zmap, ascii, earth vision, xyz formats"
                    break;
                //case "kern" :
                //   props.kernhandle()
                //   break;
                case "georeference" :
                    props.georefhandle()
                    tags = "онлайн геопривязка картинок, растровых изображений, online geo reference jpg, png, tiff, геопривязанные карты"
                    break;
                case "library" :
                    props.libraryhandle()
                    tags = "геопривязаннная библиотек по регионам, бассейнам, месторождениям мира, georeferenced scientific literature library."
                    break;
                case "reserves" :
                    props.reserveshandle()
                    tags = "онлайн калькулятор подсчета запасов вероятностным способом методом Monte Carlo, выбор распределений, оценка запасов и ресурсов нефти и газа месторождений, залежей, структуры, проспектов, зон нефтегазонакопления, online Monte Carlo reserves and recourses calculation, apply geological risk, risked reserves of deposit, play, prospect, field, lead"
                    break;
                case "las" :
                    props.lashandle()
                    tags = "онлайн просмотр las файлов, ГИС, РИГИС, геофизических исследований скважин, well log viewer, online"
                    break;
                default :
                    props.handleAlert('error','No instrument with that name.')
                    break;
            }
            document.getElementsByTagName('meta')["keywords"].content = instrument + ', '  + tags;
            document.getElementsByTagName('meta')["description"].content = instrument + ', ' + tags;
            document.title = instrument;

        }
    }, [loading, params.instrument, props]);



}