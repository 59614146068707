import React, {Component} from "react";
import {Button} from "semantic-ui-react";

class FitLayer extends Component {
    constructor (props, context) {
        super(props, context);
        this.state = {
            value: 0.4,
            id:this.props.value,
        }
    }

    handleChange = (value) => {
        this.props.fitlayer(this.state.id)
    };

    render () {
        const { value } = this.state;
        return (
            <div>
                <Button size={"small"} basic icon={"zoom-in"} onClick={this.handleChange}/>
            </div>
        )
    }
}
export default FitLayer;