import React, { useEffect, useRef } from 'react';
import * as turf from '@turf/turf';

const MyGeoMapAreaMeansurement = ({ map, area_active }) => {
    const points = useRef([]);
    const drawnFeatures = useRef(null);

    useEffect(() => {
        if (!map) return;

        const handleClick = (e) => {
            if (!area_active) return;

            const point = [e.lngLat.lng, e.lngLat.lat];
            points.current.push(point);

            updateMap();
        };

        map.on('click', handleClick);

        function updateMap() {
            // Очистка предыдущих слоев
            console.log(drawnFeatures.current)
            if (drawnFeatures.current) {
                if (map.getLayer("point")) {
                    map.removeLayer("point");
                }
                if (map.getSource("point")) {
                    map.removeSource("point");
                }
                if (map.getLayer("line")) {
                    map.removeLayer("line");
                }
                if (map.getSource("line")) {
                    map.removeSource("line");
                }
                if (map.getLayer("polygon")) {
                    map.removeLayer("polygon");
                }
                if (map.getSource("polygon")) {
                    map.removeSource("polygon");
                }
                if (map.getLayer('labels')) {
                    map.removeLayer('labels');
                }
                if (map.getSource('labels')) {
                    map.removeSource('labels');
                }
            }
            console.log(points.current,points.current.length)
            if (points.current.length === 1) {
                const pointFeature = turf.point(points.current[0]);

                drawnFeatures.current = {
                    type: 'geojson',
                    data: pointFeature,
                };

                map.addSource('point', drawnFeatures.current);
                map.addLayer({
                    id: 'point',
                    type: 'circle',
                    source: 'point',
                    paint: {
                        'circle-radius': 6,
                        'circle-color': '#007cbf',
                    },
                });
            } else if (points.current.length === 2) {
                const line = turf.lineString(points.current);
                const length = turf.length(line, { units: 'kilometers' });
                const midpoint = turf.midpoint(...points.current);

                drawnFeatures.current = {
                    type: 'geojson',
                    data: line,
                };

                map.addSource('line', drawnFeatures.current);
                map.addLayer({
                    id: 'line',
                    type: 'line',
                    source: 'line',
                    paint: {
                        'line-color': '#888',
                        'line-width': 4,
                    },
                });

                const labelSource = {
                    type: 'geojson',
                    data: {
                        type: 'FeatureCollection',
                        features: [
                            {
                                type: 'Feature',
                                geometry: midpoint.geometry,
                                properties: { text: `Длина: ${length.toFixed(2)} км` },
                            },
                        ],
                    },
                };

                map.addSource('labels', labelSource);
                map.addLayer({
                    id: 'labels',
                    type: 'symbol',
                    source: 'labels',
                    layout: {
                        'text-field': ['get', 'text'],
                        'text-size': 12,
                        'text-offset': [0, 1.5],
                        'text-anchor': 'top',
                    },
                    paint: {
                        'text-color': '#000',
                    },
                });
            } else if (points.current.length >= 3) {
                const polygon = turf.polygon([points.current.concat([points.current[0]])]);
                const area = turf.area(polygon) / 1e6; // в квадратных километрах
                const center = turf.center(polygon);

                drawnFeatures.current = {
                    type: 'geojson',
                    data: polygon,
                };

                map.addSource('polygon', drawnFeatures.current);
                map.addLayer({
                    id: 'polygon',
                    type: 'fill',
                    source: 'polygon',
                    paint: {
                        'fill-color': '#088',
                        'fill-opacity': 0.4,
                    },
                });

                const labelSource = {
                    type: 'geojson',
                    data: {
                        type: 'FeatureCollection',
                        features: [
                            {
                                type: 'Feature',
                                geometry: center.geometry,
                                properties: { text: `Площадь: ${area.toFixed(2)} км²` },
                            },
                        ],
                    },
                };

                map.addSource('labels', labelSource);
                map.addLayer({
                    id: 'labels',
                    type: 'symbol',
                    source: 'labels',
                    layout: {
                        'text-field': ['get', 'text'],
                        'text-size': 12,
                        'text-offset': [0, 1.5],
                        'text-anchor': 'top',
                    },
                    paint: {
                        'text-color': '#000',
                    },
                });
            }
        }

        return () => {
            console.log("Когда?")
            map.off('click', handleClick);
        };
    }, [map, area_active]);

    useEffect(() => {
        if (!map) return;

        if (!area_active) {
            points.current = [];
            drawnFeatures.current = null;
            if (map.getLayer("point")) {
                map.removeLayer("point");
            }
            if (map.getSource("point")) {
                map.removeSource("point");
            }
            if (map.getLayer("line")) {
                map.removeLayer("line");
            }
            if (map.getSource("line")) {
                map.removeSource("line");
            }
            if (map.getLayer("polygon")) {
                map.removeLayer("polygon");
            }
            if (map.getSource("polygon")) {
                map.removeSource("polygon");
            }
            if (map.getLayer('labels')) {
                map.removeLayer('labels');
            }
            if (map.getSource('labels')) {
                map.removeSource('labels');
            }
        }
    }, [area_active, map]);

    return null;
};
export default MyGeoMapAreaMeansurement;