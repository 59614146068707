import React, { useEffect } from 'react';

const MyGeoMapGeoReferencePreview = ({ map, url, clear, bbox, opacity, onClearComplete }) => {
    useEffect(() => {
        if (!map.current) return;

        const layerId = 'raster-layer';
        const sourceId = 'raster-source';

        // Функция для добавления источника и слоя
        const addLayer = () => {
            if (map.current.getSource(sourceId)) {
                map.current.removeSource(sourceId);
            }
            if (map.current.getLayer(layerId)) {
                map.current.removeLayer(layerId);
            }

            map.current.addSource(sourceId, {
                'type': 'image',
                'url': url,
                'coordinates': bbox
            });

            map.current.addLayer({
                id: layerId,
                type: 'raster',
                source: sourceId,
                paint: {
                    'raster-opacity': opacity,
                },
            });
        };

        // Функция для удаления слоя и источника
        const removeLayer = () => {
            if (map.current.getLayer(layerId)) {
                map.current.removeLayer(layerId);
            }
            if (map.current.getSource(sourceId)) {
                map.current.removeSource(sourceId);
            }
            if (onClearComplete) {
                onClearComplete();
            }
        };

        // Добавление слоя при монтировании компонента
        addLayer();

        // Удаление слоя, если параметр clear равен true
        if (clear) {
            removeLayer();
        }

        // Удаление слоя при размонтировании компонента
        return () => {
            removeLayer();
        };
    }, [map, url, clear, bbox, opacity, onClearComplete]);

    return null;
};

export default MyGeoMapGeoReferencePreview;