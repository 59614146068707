import React from "react";
import {Button,Icon,Label} from "semantic-ui-react";
import Cookies from 'js-cookie';
import {BrowserView, MobileView} from "react-device-detect";
class WelcomeGuide extends React.Component {
    render() {
        /**/
        return(

            <div className="welcomeguide" style={{display:this.props.display}} >
                <BrowserView>
                    <div style={{width:"10%",position: "absolute",top: "0%", left: "32px",backgroundColor: "rgba(255, 255, 255, 1)!important"}}>
                        <Icon size={"large"} name="arrow circle up"/>
                        <br/>
                        <Label>{"Для начала работы включите интересующий вас слой"}</Label>
                    </div>
                    <div style={{width:"10%",position: "absolute",top: "0%", right: "237px",backgroundColor: "rgba(255, 255, 255, 1)!important"}}>
                        <div style={{marginLeft: "85%"}}><Icon corner={"top right"} size={"large"} name="arrow circle up"/></div>
                        <Label >{"Зарегистируйтесь для использования всех возможностей платформы"}</Label>
                    </div>
                    <div style={{width:"150px",position: "absolute",top: "0%", right: "0px",backgroundColor: "rgba(255, 255, 255, 1)!important"}}>
                        <Icon size={"large"} name="arrow circle up"/>
                        <br/>
                        <Label >{"Сброс всех фильтров, слоев и настроек."}</Label>
                    </div>
                    <div style={{width:"10%",position: "absolute",top: "0%", left: "575px",backgroundColor: "rgba(255, 255, 255, 1)!important"}}>
                        <Icon size={"large"} name="arrow circle up"/>
                        <br/>
                        <Label >{"Загрузите свои собственные слои в форматах geotiff,geojson,shape"}</Label>
                    </div>
                    <div style={{width:"10%",position: "absolute",top: "0%", left: "290px",backgroundColor: "rgba(255, 255, 255, 1)!important"}}>
                        <Icon size={"large"} name="arrow circle up"/>
                        <br/>
                        <Label >{"Используйте поиск для нахождения геологических объектов во включенных слоях"}</Label>
                    </div>
                    <div style={{width:"10%",position: "absolute",top: "18%", right: "50px",backgroundColor: "rgba(255, 255, 255, 1)!important"}}>
                        <div style={{marginLeft: "85%"}}><Icon corner={"top right"} size={"large"} name="arrow circle right"/></div>
                        <Label >{"C помощью панели инструментов вы можете создавать и удалять собственные объекты"}</Label>
                    </div>
                    <div style={{marginTop: "40%", marginLeft: "48%",backgroundColor: "rgba(255, 255, 255, 1)!important"}}>
                        <Button className="welcomebutton" onClick={()=>{Cookies.set("guide",true);this.props.update()}} icon>{" Ok  "}<Icon name="close icon"/></Button>
                    </div>
                </BrowserView>
                <MobileView>
                    <div style={{width:"30%",position: "absolute",top: "18%", right: "15%",backgroundColor: "rgba(255, 255, 255, 1)!important"}}>
                        <div style={{marginLeft: "82%"}}><Icon corner={"top right"} size={"large"} name="arrow circle right"/></div>
                        <Label >{"C помощью панели инструментов вы можете создавать и удалять собственные объекты"}</Label>
                    </div>
                    <div style={{width:"10%",position: "absolute",top: "0%", left: "40%",backgroundColor: "rgba(255, 255, 255, 1)!important"}}>
                        <Icon size={"large"} name="arrow circle up"/>
                        <br/>
                        <Label >{"Используйте поиск для нахождения геологических объектов во включенных слоях"}</Label>
                    </div>
                    <div style={{width:"10%",position: "absolute",top: "0%", left: "7%",backgroundColor: "rgba(255, 255, 255, 1)!important"}}>
                        <Icon size={"large"} name="arrow circle up"/>
                        <br/>
                        <Label>{"Для начала работы включите интересующий вас слой"}</Label>
                    </div>
                </MobileView>
                <MobileView>
                    <div style={{marginTop: "80%", marginLeft: "48%",backgroundColor: "rgba(255, 255, 255, 1)!important"}}>
                        <Button className="welcomebutton" onClick={()=>{Cookies.set("guide",true);this.props.update()}} icon>{" Ok  "}<Icon name="close icon"/></Button>
                    </div>
                </MobileView>

            </div>


        )}}
export default WelcomeGuide;
