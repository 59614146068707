import React, {Component} from "react";
import {Button} from "semantic-ui-react";

class LayerFeaturesTable extends Component {
    constructor (props, context) {
        super(props, context);
        this.state = {
            value: 0.4,
            id:this.props.value,
        }
    }

    handleChange = (value) => {
        let layerID = 'basins';
        for(let i = 0 ; i < this.props.result.length ; i++){
            if ( this.props.result[i].name.toLowerCase() === this.props.value.toLowerCase())
                this.props.onFeaturestable(this.props.result[i].id)
        }

    };

    render () {
        const { value } = this.state;
        return (
            <div>
                <Button size={"small"} basic icon={"table"} onClick={this.handleChange}/>
            </div>
        )
    }
}
export default LayerFeaturesTable;