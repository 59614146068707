import React from 'react';
import { Button,Label } from 'semantic-ui-react'
import point from "../help/PointIcon.png"
import pointInterface from "../help/PointInterface.png"
import pointInterfaceZoom from "../help/PointInterfaceZoom.png"
import pointGif from "../help/point.gif"
import pointInterfaceGif from "../help/pointInterface.gif"
import pointInterfaceZoomGif from "../help/pointInterfaceZoom.gif"
import { isMobile} from "react-device-detect";
import "../Styles/Help.css"
class Help extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            textGuide:false,
            begin:false,
            system:false,
            start:false,
            maptooltips:false,
            auth:false,
            elements:false,
            head:false,
            search:false,
            layers:false,
            video0:true,
            video1:true,
            video2:true,
            video3:true,
            video4:true,
            video5:true,
            video6:true,
            video7:true,
            video8:true,
            video9:true,
        }
    };

    render() {
        console.log("HELP")
        let width = "50%";
        if (isMobile) {
            console.log("IS MOBILE")
            width = "100%";
        }
        return(
            <div className={"help"} style={{display:this.props.display,width:width}}>

                <Button basic size={'mini'} onClick={this.props.closehelp} icon><i className="close icon"/></Button>
                <br/>
                <div style={{textAlign:"end"}}>
                    {"Почта для обратной связи:"}
                    <br/>
                    {"info@mygeomap.ru"}
                </div>
                <br/>
                <br/>
                <Label basic size={"medium"} >{" Video Guides :"}</Label>
                <br/>
                <Label className={"sz90"}  basic size={"small"} onClick={()=>{ this.setState({video0:!this.state.video0}) }} >{"» Filters"}</Label>
                <br/>
                {(this.state.video0)&&(
                    <div>
                        <iframe width="560" frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen
                                height="315" title="video0" src={"https://www.youtube.com/embed/kWLwlonztPk"}/>
                        <br/>
                    </div>
                )}
                <Label className={"sz90"}  basic size={"small"} onClick={()=>{ this.setState({video1:!this.state.video1}) }} >{"» Upload .tiff to DB"}</Label>
                <br/>
                {(this.state.video1)&&(
                    <div>
                        <iframe width="560" frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen
                                height="315" title="video1" src={"https://www.youtube.com/embed/xILEwDDvWjs"}/>
                        <br/>
                    </div>
                )}
                <Label className={"sz90"}  basic size={"small"} onClick={()=>{ this.setState({video2:!this.state.video2}) }} >{"» Upload features to DB"}</Label>
                <br/>
                {(this.state.video2)&&(
                    <div>
                        <iframe width="560" frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen
                                height="315" title="video2" src={"https://www.youtube.com/embed/mXpA0qJEV-Y"}/>
                        <br/>
                    </div>
                )}
                <Label className={"sz90"}  basic size={"small"} onClick={()=>{ this.setState({video3:!this.state.video3}) }} >{"» Add features"}</Label>
                <br/>
                {(this.state.video3)&&(
                    <div>
                        <iframe width="560" frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen
                                height="315" title="video3" src={"https://www.youtube.com/embed/gGqZtA5By2c"}/>
                        <br/>
                    </div>
                )}
                <Label className={"sz90"}  basic size={"small"} onClick={()=>{ this.setState({video4:!this.state.video4}) }} >{"» Render your file"}</Label>
                <br/>
                {(this.state.video4)&&(
                    <div>
                        <iframe width="560" frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen
                                height="315" title="video4" src={"https://www.youtube.com/embed/yeLe2OdOnu4"}/>
                        <br/>
                    </div>
                )}
                <Label className={"sz90"}  basic size={"small"} onClick={()=>{ this.setState({video5:!this.state.video5}) }} >{"» Feature Information"}</Label>
                <br/>
                {(this.state.video5)&&(
                    <div>
                        <iframe width="560" frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen
                                height="315" title="video5" src={"https://www.youtube.com/embed/0wFzH3vlMiQ"}/>
                        <br/>
                    </div>
                )}
                <Label className={"sz90"}  basic size={"small"} onClick={()=>{ this.setState({video6:!this.state.video6}) }} >{"» Search"}</Label>
                <br/>
                {(this.state.video6)&&(
                    <div>
                        <iframe width="560" frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen
                                height="315" title="video6" src={"https://www.youtube.com/embed/AzkubNI0EVY"}/>
                        <br/>
                    </div>
                )}
                <Label className={"sz90"}  basic size={"small"} onClick={()=>{ this.setState({video7:!this.state.video7}) }} >{"» Thematic layers"}</Label>
                <br/>
                {(this.state.video7)&&(
                    <div>
                        <iframe width="560" frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen
                                height="315" title="video7" src={"https://www.youtube.com/embed/_FAavNQguPU"}/>
                        <br/>
                    </div>
                )}
                <Label className={"sz90"}  basic size={"small"} onClick={()=>{ this.setState({video8:!this.state.video8}) }} >{"» Registration"}</Label>
                <br/>
                {(this.state.video8)&&(
                    <div>
                        <iframe width="560" frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen
                                height="315" title="video8" src={"https://www.youtube.com/embed/XguzDuCZTc8"}/>
                        <br/>
                    </div>
                )}
                <Label className={"sz90"}  basic size={"small"} onClick={()=>{ this.setState({video9:!this.state.video9}) }} >{"» Introductory"}</Label>
                <br/>
                {(this.state.video9)&&(
                    <div>
                        <iframe width="560" frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen
                                height="315" title="video9" src={"https://www.youtube.com/embed/RG2U6r0SQv0"}/>
                        <br/>
                    </div>
                )}

            </div>
        )}}
export default Help;
