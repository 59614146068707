import React from 'react';
import axios from "axios";
import Dropzone from "react-dropzone";
import {Button, Form} from "semantic-ui-react";
import Panzoom from '@panzoom/panzoom'
import {Helmet} from "react-helmet";
var pointSize = 6;
var config = require('../config');
const hostBackend = config.HOSTBackend;
const portBackend = config.PORTBackend;
const protocolBackend = config.ProtocolBackend;
const prefixBackend = config.PrefixBackend;
//const geoReferenceUrl = config.georef
const georefApi = config.georefApi;
var urlBackend;
if (portBackend === "" || portBackend === undefined){
    urlBackend=protocolBackend+'://'+hostBackend+prefixBackend;
}
else{
    urlBackend=protocolBackend+'://'+hostBackend+':'+portBackend+prefixBackend;
}
var startScale = 1;
var scale = startScale;
var prevScale ;
var imageCoef = 1;
var mouse = {
    x : 0,
    y : 0,
    w : 0,
    alt : false,
    shift : false,
    ctrl : false,
    buttonLastRaw : 0, // user modified value
    buttonRaw : 0,
    over : false,
    buttons : [1, 2, 4, 6, 5, 3], // masks for setting and clearing button raw bits;
};
var xOff = 0;
var yOff = 0;
let offsetX=0;
let offsetY=0;
var netPanningX=0;
var netPanningY=0;
const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
};



class GeoReference extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            PublicationFiles: null,
            publicationText: "",
            points: [],
            table: [],
            timeout: 300,
            blobURL: "",
            bounds: [],
            clicks: 0,
            selectedFile: null,
            radioValue: 'Bilinear',
            opacity: 0.75,
            fileType: null,
            fileName: "",
            image: null,
            image2:null,
            imageBbox: null,
            imageURL: null,
            imageURL2:null,
            inc: 0,
            isDragging: false,
        }
    };

    getPosition(evt) {

        var canvas = document.getElementById("georefcanvas");
        var rect = canvas.getBoundingClientRect();
        var ctx = canvas.getContext("2d");
        //let scaleX = canvas.width / rect.width
        //let scaleY = canvas.height / rect.height;
        const transform = ctx.getTransform();
        const matrix = ctx.getTransform();
        const canvasX = (evt.clientX - rect.left - transform.e) / transform.a;
        const canvasY = (evt.clientY - rect.top - transform.f) / transform.d;
        let newX = evt.clientX * matrix.a + evt.clientY * matrix.c + matrix.e;
        let newY = evt.clientX * matrix.b + evt.clientY * matrix.d + matrix.f;
        console.log(canvasX, canvasY, newX, newY);
        return {
            x: (evt.clientX - rect.left) / (rect.right - rect.left) * canvas.width,
            y: (evt.clientY - rect.top) / (rect.bottom - rect.top) * canvas.height
        };

        //first calculate normal mouse coordinates
        /*var canvas = document.getElementById("georefcanvas");
        var rect = canvas.getBoundingClientRect();
        var ctx = canvas.getContext("2d");
        const point = {x: 0, y: 0};
        const matrix = ctx.getTransform();
        return {
            x: matrix.a * point.x + matrix.c * point.y + matrix.e,
            y: matrix.b * point.x + matrix.d * point.y + matrix.f,
        };*/
    }

    drawCoordinates(point, r, width,index) {
        var canvas = document.getElementById("georefcanvas");
        if ( canvas !== null && canvas !== undefined){
            console.log(point,index)
            var ctx = canvas.getContext("2d");
            ctx.font = "12px serif";
            ctx.fillStyle = '#000000';
            ctx.fillText(index, point.x-r*2, point.y);
            ctx.beginPath();
            ctx.arc(point.x, point.y, r, 0, 2 * Math.PI, false);
            ctx.lineWidth = width;
            ctx.strokeStyle = '#ea0606';
            ctx.stroke();
            ctx.closePath();
        }
    }

    updatePoint(array) {
        console.log("update points",array)
        var canvas = document.getElementById("georefcanvas");
        var ctx = canvas.getContext("2d");
        var cw = (canvas.width = 700);
        var ch = (canvas.height = 600);
        ctx.clearRect(0, 0, cw, ch);
        let that = this;
        var img = new Image();
        img.onload = function () {
                console.log(img.width,img.height);
                ctx.drawImage(img, 0, 0);
                array.map((p,index) => {
                    console.log(p,index)
                    that.drawCoordinates(p, pointSize, 1, index+1);
                });
        }
        img.src = this.state.image2;
    }

    radioChange = (e, {value}) => {
        this.setState({radioValue: value})
    };
    rangeChange = (e, {name, value}) => {
        this.setState({opacity: Number.parseFloat(value)})
    }

    async imageToMap(toLayer) {
        console.log(this.state.table, this.state.image)
        const dataform = new FormData();
        dataform.append('my_file', this.state.selectedFile);
        let that = this;
        let arrayimageX = [];
        let arrayimageY = [];
        let arraymapX = [];
        let arraymapY = [];
        console.log(this.state.table.length)
        let points = this.state.points;
        let mapPoints = this.props.mapPoints;
        let length = points.length ;
        if ( length > mapPoints.length)
            length = mapPoints.length
        for (let i = 0 ; i < length;i++){
            arrayimageX.push(points[i].x * imageCoef.width)
            arrayimageY.push(points[i].y * imageCoef.height)
            arraymapX.push(mapPoints[i].x)
            arraymapY.push(mapPoints[i].y)

        }
        console.log(this.state.table,arrayimageX, arrayimageY, arraymapX, arraymapY)
        dataform.append('imgX', arrayimageX);
        dataform.append('imgY', arrayimageY);
        dataform.append('mapX', arraymapX);
        dataform.append('mapY', arraymapY);
        dataform.append('type', this.state.radioValue);
        console.log(dataform)
        this.props.loadingopen();
        axios.post(georefApi+'/georef', dataform).then((res)  => {
            console.log(res)
            if (res.data.Error !== undefined){
                that.props.alert("error","Georefering failed check input data.")
            }else{
                let reader = new FileReader();
                const bbox = res.data.bbox;
                const blob = b64toBlob(res.data.image, 'image/png');
                const blobUrl = URL.createObjectURL(blob);
                if (toLayer){
                    console.log(that.state.fileName,that.state.opacity)
                    that.props.toLayer(that.state.fileName,blobUrl,blob,bbox,that.state.opacity)
                }
                else{
                    that.props.preview(blobUrl,bbox,that.state.opacity)
                }
                that.setState({imageURL:blobUrl});
                this.props.loadingclose();
                reader.onerror = function () {
                    console.log(reader.error);
                };
            }

        })
    }
    onImageChange=event=> {
        console.log(event)
        var canvas = document.getElementById("georefcanvas");
        var ctx = canvas.getContext("2d");
        let file = event.target.files[0];
        console.log(file.name);
        if (file.type === "image/tiff") {
            let reader = new FileReader();
            var that = this;
            var link = URL.createObjectURL(file);
            (async function () {

                //var link2 = URL.createObjectURL(image);

                const dataform = new FormData();
                console.log(event.target)
                dataform.append('file', file);
                let name = file.name;
                axios.post(urlBackend+'/upload', dataform, {
                }).then(res => { // then print response status
                    const blob = b64toBlob(res.data, 'image/png');
                    const blobUrl = URL.createObjectURL(blob);
                    axios.post(urlBackend+'/uploadResize', dataform, {
                    }).then(res2 => { // then print response status
                        const blob2 = b64toBlob(res2.data, 'image/png');
                        const blobUrl2 = URL.createObjectURL(blob2);
                        var img = new Image();
                        var imgResolution = new Image();
                        var cw = (canvas.width = 700);
                        var ch = (canvas.height = 600);
                        ctx.clearRect(0, 0, cw, ch);
                        img.onload = function () {
                                ctx.drawImage(img, 0, 0)
                        }
                        img.src = blobUrl2;
                        imgResolution.onload = function () {
                            console.log(imgResolution.width,imgResolution.height);
                            var hRatio = canvas.width / imgResolution.width;
                            var vRatio = canvas.height / imgResolution.height;
                            var ratio = Math.min(hRatio, vRatio);
                            var newWidth = imgResolution.width * ratio;
                            var newHeight = imgResolution.height * ratio;
                            var newHRatio =imgResolution.height/canvas.height;
                            var newWRatio = imgResolution.width/canvas.width;
                            var newRatio = Math.max(newHRatio, newWRatio);
                            console.log(ratio,newWidth,newHeight,"separation",newWRatio,newHRatio,newRatio,newWidth*newRatio,newHeight*newRatio);
                            imageCoef = {width:newRatio,height:newRatio};
                        }
                        imgResolution.src = blobUrl;
                        that.setState({image: blobUrl, fileName: file.name,image2:blobUrl2});
                    })
                })

            })();
            reader.onerror = function () {
                console.log(reader.error);
            };

            this.setState({
                selectedFile: event.target.files[0],
                fileType:"tif",
                fileName:file.name,
                points : [],
                table:[],
            })
            this.props.reset();
        }
        else {
            var link = URL.createObjectURL(file);
            var img = new Image();
            var imgResolution = new Image();
            var that = this;
            const dataform = new FormData();
            console.log(event.target)
            dataform.append('file', file);
            let name = file.name;
            axios.post(urlBackend+'/uploadResize', dataform, {
            }).then(res => { // then print response status
                const blob = b64toBlob(res.data, 'image/png');
                const blobUrl = URL.createObjectURL(blob);
                var cw = (canvas.width = 700);
                var ch = (canvas.height = 600);
                ctx.clearRect(0, 0, cw, ch);
                img.onload = function() {
                    ctx.drawImage(img, 0, 0)
                }
                img.src = blobUrl;
                imgResolution.onload = function () {
                    console.log(imgResolution.width,imgResolution.height);
                    var hRatio = canvas.width / imgResolution.width;
                    var vRatio = canvas.height / imgResolution.height;
                    var ratio = Math.min(hRatio, vRatio);
                    var newWidth = imgResolution.width * ratio;
                    var newHeight = imgResolution.height * ratio;
                    var newHRatio =imgResolution.height/canvas.height;
                    var newWRatio = imgResolution.width/canvas.width;
                    var newRatio = Math.max(newHRatio, newWRatio);
                    console.log(ratio,newWidth,newHeight,"separation",newWRatio,newHRatio,newRatio,newWidth*newRatio,newHeight*newRatio);
                    imageCoef = {width:newRatio,height:newRatio};
                }
                imgResolution.src = link;
                this.setState({
                    selectedFile: file,
                    image: link,
                    image2: blobUrl,
                    fileType:"png",
                    fileName:file.name,
                    points : [],
                    table:[],
                    timeout : 300,
                    clicks : 0,
                })
                this.props.reset();
            })
        }

    }
    componentDidUpdate(prevProps) {
        // Популярный пример (не забудьте сравнить пропсы):
        console.log("Update",this.props.mapPoints,prevProps.mapPoints)
        if (this.props.mapPoints.length>=1 && this.state.table>=1){
            if (this.props.mapPoints.length !== prevProps.mapPoints.length || this.state.table[this.props.mapPoints.length-1].mapX===undefined) {

                //this.setState({points: points,table:array});
            }
        }

    }
    componentDidMount() {
        let that=this;
        //let points = this.state.points;
        let canvas = document.getElementById("georefcanvas")
        const elem = document.getElementById('georefcanvas')
        const panzoom = Panzoom(elem, {
            maxScale: 10
        })
        //panzoom.pan(10, 10)
        panzoom.zoom(1, { animate: true })

        // Panning and pinch zooming are bound automatically (unless disablePan is true).
        // There are several available methods for zooming
        // that can be bound on button clicks or mousewheel.
        //elem.parentElement.addEventListener('click', panzoom.zoomIn)
        elem.addEventListener('wheel', panzoom.zoomWithWheel)
        //var WIDTH = canvas.width;
        //var HEIGHT = canvas.height;
        const ctx = canvas.getContext("2d");
        //canvas.addEventListener("mousewheel", onmousewheel, false);
        //canvas.addEventListener("DOMMouseScroll", onmousewheel, false);
        var startX,startY,mouseX,mouseY;
        var isDown=false;
        //reOffset();
        function reOffset(){
            var BB=canvas.getBoundingClientRect();
            offsetX=BB.left;
            offsetY=BB.top;

        }
        function setupMouse(e) {
            e.addEventListener('mousemove', mouseMove);
            e.addEventListener('mousedown', mouseMove);
            e.addEventListener('mouseup', mouseMove);
            e.addEventListener('mouseout', mouseMove);
            e.addEventListener('mouseover', mouseMove);
            e.addEventListener('mousewheel', mouseMove);
            e.addEventListener('DOMMouseScroll', mouseMove);
            e.addEventListener("contextmenu", function (e) {
                e.preventDefault();
            }, false);
        }
        //setupMouse(canvas);
        function mouseMove(event) {
            mouse.x = event.offsetX;
            mouse.y = event.offsetY;
            if (mouse.x === undefined) {
                mouse.x = event.clientX;
                mouse.y = event.clientY;
            }
            mouse.alt = event.altKey;
            mouse.shift = event.shiftKey;
            mouse.ctrl = event.ctrlKey;
            if (event.type === "mousedown") {
                event.preventDefault()
                startX=parseInt(event.clientX-offsetX);
                startY=parseInt(event.clientY-offsetY);
                mouse.buttonRaw |= mouse.buttons[event.which-1];
                isDown=true;
            }
            if (event.type === "mousemove" && isDown===true) {

                event.preventDefault()
                event.stopPropagation();
                mouseX=parseInt(event.clientX-offsetX);
                mouseY=parseInt(event.clientY-offsetY);

                // dx & dy are the distance the mouse has moved since
                // the last mousemove event
                var dx=mouseX-startX;
                var dy=mouseY-startY;

                // reset the vars for next mousemove
                startX=mouseX;
                startY=mouseY;

                console.log(dx,dy)
                netPanningX+=dx;
                netPanningY+=dy;
                xOff = netPanningX * displayTransform.matrix[0];
                yOff = netPanningY * displayTransform.matrix[3];
            }else if (event.type === "mouseup") {
                console.log(displayTransform)
                isDown=false;
                mouse.buttonRaw &= mouse.buttons[event.which + 2];
            } else if (event.type === "mouseout") {
                isDown=false;
                mouse.buttonRaw = 0;
                mouse.over = false;
            } else if (event.type === "mouseover") {
                mouse.over = true;
            } else if (event.type === "mousewheel") {
                console.log("SET PREV SCALE1")
                prevScale= scale
                event.preventDefault()
                let direction = event.deltaY > 0 ? 1 : -1;
                scale += (0.03*8) * direction;
                mouse.w = event.wheelDelta;
                offsetX += ( event.offsetX / prevScale )  - (event.offsetX  / scale);
                offsetY += ( event.offsetY / prevScale ) - ( event.offsetY / scale);
                console.log("Calculate")
            } else if (event.type === "DOMMouseScroll") { // FF you pedantic doffus
                mouse.w = -event.detail;
            }

        }
        var displayTransform = {
            x:0,
            y:0,
            ox:0,
            oy:0,
            scale:1,
            rotate:0,
            cx:0,  // chase values Hold the actual display
            cy:0,
            cox:0,
            coy:0,
            cscale:1,
            crotate:0,
            dx:0,  // deltat values
            dy:0,
            dox:0,
            doy:0,
            dscale:1,
            drotate:0,
            drag:0.1,  // drag for movements
            accel:0.7, // acceleration
            matrix:[0,0,0,0,0,0], // main matrix
            invMatrix:[0,0,0,0,0,0], // invers matrix;
            mouseX:0,
            mouseY:0,
            ctx:ctx,
            setTransform:function(){
                var m = this.matrix;
                var i = 0;
                this.ctx.setTransform(m[i++],m[i++],m[i++],m[i++],m[i++],m[i++]);
            },
            setHome:function(){
                this.ctx.setTransform(1,0,0,1,0,0);

            },
            update:function(){
                // smooth all movement out. drag and accel control how this moves
                // acceleration
                this.dx += (this.x-this.cx)*this.accel;
                this.dy += (this.y-this.cy)*this.accel;
                this.dox += (this.ox-this.cox)*this.accel;
                this.doy += (this.oy-this.coy)*this.accel;
                this.dscale += (this.scale-this.cscale)*this.accel;
                this.drotate += (this.rotate-this.crotate)*this.accel;
                // drag
                this.dx *= this.drag;
                this.dy *= this.drag;
                this.dox *= this.drag;
                this.doy *= this.drag;
                this.dscale *= this.drag;
                this.drotate *= this.drag;
                // set the chase values. Chase chases the requiered values
                this.cx += this.dx;
                this.cy += this.dy;
                this.cox += this.dox;
                this.coy += this.doy;
                this.cscale += this.dscale;
                this.crotate += this.drotate;

                // create the display matrix
                this.matrix[0] = Math.cos(this.crotate)*this.cscale;
                this.matrix[1] = Math.sin(this.crotate)*this.cscale;
                this.matrix[2] =  - this.matrix[1];
                this.matrix[3] = this.matrix[0];

                // set the coords relative to the origin
                this.matrix[4] = -(this.cx * this.matrix[0] + this.cy * this.matrix[2])+this.cox;
                this.matrix[5] = -(this.cx * this.matrix[1] + this.cy * this.matrix[3])+this.coy;


                // create invers matrix
                var det = (this.matrix[0] * this.matrix[3] - this.matrix[1] * this.matrix[2]);
                this.invMatrix[0] = this.matrix[3] / det;
                this.invMatrix[1] =  - this.matrix[1] / det;
                this.invMatrix[2] =  - this.matrix[2] / det;
                this.invMatrix[3] = this.matrix[0] / det;

                // check for mouse. Do controls and get real position of mouse.
                if(mouse !== undefined){  // if there is a mouse get the real cavas coordinates of the mouse
                    if(mouse.oldX !== undefined && (mouse.buttonRaw & 1)===1){ // check if panning (middle button)
                        var mdx = mouse.x-mouse.oldX; // get the mouse movement
                        var mdy = mouse.y-mouse.oldY;
                        // get the movement in real space
                        var mrx = (mdx * this.invMatrix[0] + mdy * this.invMatrix[2]);
                        var mry = (mdx * this.invMatrix[1] + mdy * this.invMatrix[3]);
                        this.x -= mrx;
                        this.y -= mry;
                    }
                    // do the zoom with mouse wheel
                    if(mouse.w !== undefined && mouse.w !== 0){
                        this.ox = mouse.x;
                        this.oy = mouse.y;
                        this.x = this.mouseX;
                        this.y = this.mouseY;

                        // comment out the following is you change drag and accel
                        // and the zoom does not feel right (lagging and not
                        // zooming around the mouse

                        this.cox = mouse.x;
                        this.coy = mouse.y;
                        this.cx = this.mouseX;
                        this.cy = this.mouseY;
                        //console.log("SET PREV SCALE 2")
                        //prevScale= scale
                        if(mouse.w > 0){ // zoom in
                            this.scale += 0.03;
                            mouse.w -= 20;
                            if(mouse.w < 0){
                                mouse.w = 0;
                            }
                        }
                        if(mouse.w < 0){ // zoom out
                            if(this.scale >0.1){
                                this.scale -= 0.03;
                                mouse.w += 20;
                                if(mouse.w > 0){
                                    mouse.w = 0;
                                }
                            }

                        }
                        //console.log("SET SCALE",this.scale)
                        //scale=this.scale;
                    }
                    // get the real mouse position
                    var screenX = (mouse.x - this.cox);
                    var screenY = (mouse.y - this.coy);
                    this.mouseX = this.cx + (screenX * this.invMatrix[0] + screenY * this.invMatrix[2]);
                    this.mouseY = this.cy + (screenX * this.invMatrix[1] + screenY * this.invMatrix[3]);
                    mouse.rx = this.mouseX;  // add the coordinates to the mouse. r is for real
                    mouse.ry = this.mouseY;
                    // save old mouse position
                    mouse.oldX = mouse.x;
                    mouse.oldY = mouse.y;
                }

            }
        }
        let timer = 0;
        /*
        function update() {
            //xOff = displayTransform.matrix[4] - (displayTransform.matrix[4] / scale);
            //yOff = displayTransform.matrix[5] - (displayTransform.matrix[5] / scale);
            //xOff = displayTransform.cox-displayTransform.cx;
            //yOff = displayTransform.cox-displayTransform.cx;
            //console.log(that.state.points)
            displayTransform.update();
            // set home transform to clear the screem
            displayTransform.setHome();
            if (++timer % 15 === 0) {
                var img = new Image();
                img.onload = function () {
                    var width = (ctx.canvas.width = 500);
                    var height = (ctx.canvas.height = 500);
                    displayTransform.setTransform();
                    ctx.clearRect(0, 0, width, height);
                    ctx.drawImage(img, 0, 0)
                    that.state.points.map(p => {
                        that.drawCoordinates(p, pointSize / displayTransform.scale, 1 / displayTransform.scale);
                    });
                }
                img.src = that.state.image;
            }

            requestAnimationFrame(update)
        }
        update()*/
    }
    render() {
        let points = this.state.points;
        let mapPoints = this.props.mapPoints;
        let arrayTable = [];
        for (let i = 0 ; i < points.length;i++){
            if (mapPoints[i]!==undefined){
                console.log("has new point" + mapPoints[i])
                arrayTable.push({imageX:points[i].x,imageY:points[i].y,mapX:mapPoints[i].x,mapY:mapPoints[i].y});
            }

            else{
                console.log("has NO new point" + mapPoints[i])
                arrayTable.push({imageX:points[i].x,imageY:points[i].y});
            }

        }
        const { t } = this.props;
        return(
            <div className={"georef"}
                 style={{display:this.props.display}}
            >
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{"Геопривязка"}</title>
                    <meta name="description" content={"Привязка карт онлайн привязка изображения к карте georeferencing"} />
                </Helmet>
                <div style={{zIndex:99999,position: "relative", backgroundColor: 'white'}}>
                    <Button basic size={'mini'} onClick={this.props.close} icon><i className="close icon"/></Button>
                    <br/>
                    {this.state.publicationText}
                    <Dropzone onDrop={acceptedFiles => {
                        console.log(acceptedFiles[0].name)
                        let files = {}
                        files.target={}

                        if (acceptedFiles[0].name !== undefined){
                            files.target.files = []
                            files.target.files.push(acceptedFiles[0])
                            this.onImageChange(files)
                            this.setState({PublicationFiles:acceptedFiles,publicationText:"Files to Upload: "+ acceptedFiles[0].name,fileName:acceptedFiles[0].name});
                        }
                        else
                            this.setState({PublicationFiles:acceptedFiles,publicationText:"Files to Upload: "});
                    }}
                    >
                        {({getRootProps, getInputProps}) => (
                            <section>
                                <div style={{height:"35px",width:"300px",borderRadius: "15px",padding: "10px",border:"1px solid black"}} {...getRootProps()}>
                                    <input {...getInputProps()} type="file"  name="file" onChange={this.onImageChange}/>
                                    {t("Click or Drag'n'Drop file here")}
                                </div>
                            </section>
                        )}


                    </Dropzone>
                    <br/>
                    {t("Shift + Click to make point(minimum 3 pair of points)")}
                </div>
                <br/>
                <div>
                    <canvas onClick={(e)=> {
                        console.log("click on image",this.state.points.length === this.props.mapPoints.length,this.state.points.length, this.props.mapPoints.length)
                        if (this.state.points.length === this.props.mapPoints.length && e.shiftKey) {
                            var canvas = document.getElementById("georefcanvas");
                            var ctx = canvas.getContext("2d");
                            var cw = (ctx.canvas.width= 700);
                            var ch = (ctx.canvas.height= 600);
                            var newWidth = cw * scale;
                            var newHeight = ch * scale;
                            let translate1=-((newWidth-cw)/2);
                            let translate2=-((newHeight-ch)/2);
                            let clicks = this.state.clicks;
                            let points = this.state.points;
                            let mapPoints = this.props.mapPoints;
                            let that = this;
                            clicks++;
                            var m = this.getPosition(e);
                            console.log(points.length, mapPoints.length);
                            this.drawCoordinates(m, pointSize,1, points.length);
                            if (clicks === 1) {
                                setTimeout(function () {
                                    if (clicks === 1) {
                                        // on click add a new point to the points array
                                        points.push({x:m.x,y:m.y});
                                    } else { // on double click
                                        // 1. check if point in path
                                        for (let i = 0; i < points.length; i++) {
                                            ctx.beginPath();
                                            ctx.arc(points[i].x, points[i].y, pointSize, 0, Math.PI * 2, true);

                                            if (ctx.isPointInPath(m.x, m.y)) {
                                                points.splice(i, 1); // remove the point from the array
                                                break;// if a point is found and removed, break the loop. No need to check any further.
                                            }
                                        }

                                        //clear the canvas
                                        ctx.clearRect(0, 0, cw, ch);
                                    }
                                    var img = new Image();
                                    img.onload = function () {
                                        console.log(img.width,img.height);
                                        //createImageBitmap(img, { resizeWidth: 600, resizeHeight: 400, resizeQuality: 'high' })
                                        //.then(function(imageBitmap) {
                                            ctx.save();
                                            ctx.drawImage(img, 0, 0);
                                            points.map((p,index) => {
                                                that.drawCoordinates(p, pointSize,1,index+1);
                                            });
                                            ctx.restore();
                                        //});

                                    }

                                    img.src = that.state.image2;
                                    clicks = 0;
                                    console.log(points, mapPoints);
                                    that.props.addMapPoint();
                                    let array = [];
                                    for (let i = 0 ; i < points.length;i++){
                                        if (mapPoints[i]!==undefined)
                                            array.push({imageX:points[i].x,imageY:points[i].y,mapX:mapPoints[i].x,mapY:mapPoints[i].y});
                                        else
                                            array.push({imageX:points[i].x,imageY:points[i].y});
                                    }
                                    that.setState({points: points,table:array});

                                }, this.state.timeout);
                            }
                        }
                    }}
                            id="georefcanvas" ClipToBounds="True" width="700" height="600" style={{cursor:"crosshair"}}/>
                    <br/>
                </div>
                <div style={{zIndex:99999,position: "relative", backgroundColor: 'white'}}>
                    <Form>
                        <Form.Field>
                            {t("Resample Algorithm")}
                        </Form.Field>
                        <Form.Group inline>
                            <Form.Radio
                                label={t('Bilinear')}
                                name='radioGroup'
                                value='Bilinear'
                                checked={this.state.radioValue === 'Bilinear'}
                                onChange={this.radioChange}
                            />
                            <Form.Radio
                                label={t('Nearest Neighbour')}
                                name='radioGroup'
                                value='NearestNeighbour'
                                checked={this.state.radioValue === 'NearestNeighbour'}
                                onChange={this.radioChange}
                            />
                            <Form.Radio
                                label={t('Cubic')}
                                name='radioGroup'
                                value='Cubic'
                                checked={this.state.radioValue === 'Cubic'}
                                onChange={this.radioChange}
                            />
                            <Form.Radio
                                label={t('CubicSpline')}
                                name='radioGroup'
                                value='CubicSpline'
                                checked={this.state.radioValue === 'CubicSpline'}
                                onChange={this.radioChange}
                            />
                            <Form.Radio
                                label={t('Lanczos')}
                                name='radioGroup'
                                value='Lanczos'
                                checked={this.state.radioValue === 'Lanczos'}
                                onChange={this.handleChange}
                            />
                        </Form.Group>
                        <Form.Field>
                            <Form.Input
                                label={`${t('Opacity')} : ${this.state.opacity} `}
                                min={0}
                                max={1}
                                name='opacity'
                                onChange={this.rangeChange}
                                step={0.01}
                                type='range'
                                value={this.state.opacity}
                            />
                        </Form.Field>
                    </Form>
                    <br/>
                    <Button basic onClick={async ()=>{
                        if ( arrayTable.length >=3)
                            await this.imageToMap(false);
                        else
                            this.props.alert("error",t('Create at least 3 points.'))
                    }}>{t("Preview")}</Button>
                    <Button onClick={async()=>{
                        if ( arrayTable.length >=3)
                            await this.imageToMap(true);
                        else
                            this.props.alert("error",t('Create at least 3 points.'))
                    }} basic>{t("Add as layer")}</Button>
                    <Button onClick={() => {
                        if ( arrayTable.length >=3){
                            const a = document.createElement("a");
                            a.href = this.state.imageURL;
                            a.download = this.state.fileName;
                            document.body.appendChild(a);
                            a.click();
                            document.body.removeChild(a);
                        }
                        else{
                            this.props.alert("error",t('Create at least 3 points.'))
                        }
                        //window.open(this.state.imageURL, "_blank");
                    }} basic>{t("Download")}</Button>
                    <br/>
                    <table>
                        <thead>
                        <th style={{textAlign:"center"}}>index</th>
                        <th style={{textAlign:"center"}}>imageX</th>
                        <th style={{textAlign:"center"}}>imageY</th>
                        <th style={{textAlign:"center"}}>pointX</th>
                        <th style={{textAlign:"center"}}>pointY</th>
                        <th style={{textAlign:"center"}}>{t("Delete")}</th>
                        </thead>
                        <tbody>
                        {arrayTable.map((item, index) => (
                            <tr key={item + index}>
                                <td style={{border: "1px solid black",
                                    margin: "0px 0px",
                                    padding: "5px 5px",}}>{index}</td>
                                <td onClick={()=>{
                                    console.log(index)
                                    let table = this.state.table;
                                    let array = this.state.points;
                                    var sign = window.prompt();
                                    array[index].x = sign;
                                    table[index].imageX = sign;
                                    this.updatePoint(array);
                                    this.setState({table: table})
                                }} style={{border: "1px solid black",
                                    margin: "0px 0px",
                                    padding: "5px 5px",}}>{item.imageX}</td>
                                <td onClick={()=>{
                                    console.log(index)
                                    let table = this.state.table;
                                    let array = this.state.points;
                                    var sign = window.prompt();
                                    array[index].y = sign;
                                    table[index].imageY = sign;
                                    this.updatePoint(array);
                                    this.setState({table: table})
                                }} style={{border: "1px solid black",
                                    margin: "0px 0px",
                                    padding: "5px 5px",}}>{item.imageY}</td>
                                <td onClick={()=>{
                                    console.log(index)
                                    let table = this.state.table;
                                    let array = this.props.mapPoints
                                    var sign = window.prompt();
                                    array[index].x = Number.parseFloat(sign);
                                    table[index].mapX = Number.parseFloat(sign);
                                    this.props.updatePoint(index,Number.parseFloat(sign),'x');
                                    this.setState({table: table})
                                }} style={{border: "1px solid black",
                                    margin: "0px 0px",
                                    padding: "5px 5px",}}>{item.mapX}</td>
                                <td onClick={()=>{
                                    console.log(index)
                                    let table = this.state.table;
                                    let array = this.props.mapPoints
                                    var sign = window.prompt();
                                    array[index].y = Number.parseFloat(sign);
                                    table[index].mapY = Number.parseFloat(sign);
                                    this.props.updatePoint(index,Number.parseFloat(sign),'y');
                                    this.setState({table: table})
                                }} style={{border: "1px solid black",
                                    margin: "0px 0px",
                                    padding: "5px 5px",}}>{item.mapY}</td>
                                <td onClick={()=>{
                                    console.log(index)
                                    let table = this.state.table;
                                    let array = this.state.points;
                                    array.splice(index, 1);
                                    table.splice(index, 1);
                                    this.props.deletePoint(index)
                                    this.updatePoint(array);
                                    this.setState({table: table,array:array})
                                }} style={{border: "1px solid black",
                                    margin: "0px 0px",
                                    padding: "5px 5px",}}>{"X"}</td>
                            </tr>

                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        )}}
export default GeoReference;
