import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";

export default function LoadOnStart(props) {
    const [loading,setLoading] = useState(false)
    let params = useParams();
    console.log(params)
    useEffect(() => {
        if (loading)
            return
        setLoading(true)
        console.log(' before custom-render-trigger')
        window.dispatchEvent(new Event('custom-render-trigger'));
        console.log(' after custom-render-trigger')
    }, [loading, params.layer, props]);



}