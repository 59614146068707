import React from "react";
import {Dropdown,Button} from "semantic-ui-react";
import axios from "axios";
import Cookies from "js-cookie";
const config = require('../config');
const hostBackend = config.HOSTBackend;
const portBackend = config.PORTBackend;
const protocolBackend = config.ProtocolBackend;
const prefixBackend = config.PrefixBackend;

var urlBackend;
if (portBackend === "" || portBackend === undefined){
    urlBackend=protocolBackend+'://'+hostBackend+prefixBackend;
}
else{
    urlBackend=protocolBackend+'://'+hostBackend+':'+portBackend+prefixBackend;
}

class GeometryFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            wordBasic:this.props.word.reverse(),
            periodBasic:this.props.age_period,
            word:this.props.word.reverse(),
            period:this.props.age_period,
            age_word:this.props.age_word,
            value:[0,4600],
            value_from:null,
            value_to:null
        }
    };
    onSelectFrom = (event,{value}) => {
        var res = value.split(" ");
        let obj_value = parseFloat(res[0]);
        let obj_index = res[1];
        let obj_valueTo;
        //console.log(obj_value,obj_index);
        for (let i = 0; i<this.state.age_word.length;i++) {
            //console.log(age_word[i].object_index,obj_index)
            if (this.state.age_word[i].object_index.replace(/\s/g, "") === obj_index.replace(/\s/g, "")) {
                //console.log(i,this.state,age_period);
                obj_valueTo =this.state.age_word[i].age_to;
                this.setState({period:this.state.period.slice(i)})
            }
        }
        let arr =Array.from(this.state.periodBasic);
        for (let i = 0 ;i<arr.length;i++) {
            let res2 = arr[i].value.split(" ");
            let obj_value = parseFloat(res2[0]);
            if (obj_value < obj_valueTo) {
                arr.splice(i, 1);
                i--;
            }
        }

        this.setState({period:arr,value:[obj_value,this.state.value[1]],value_from:value});
        this.props.handle([obj_value,this.state.value[1]]);
    };
    onSelectTo =(event,{value}) => {
        var res = value.split(" ");
        let obj_value = parseFloat(res[0]);
        this.setState({value:[this.state.value[0],obj_value],value_to:value});
        this.props.handle([this.state.value[0],obj_value])
    };
    render() {
        const { t } = this.props;
        // eslint-disable-next-line no-unused-vars
        let files;
        return(
            <div style={{display:this.props.display}} >
                <div>
                    <Dropdown id={"AgeDropDown1"} value={this.state.value_from} options={this.state.word.reverse()} onChange={this.onSelectFrom} fluid selection placeholder="Выбирите начальный период" />
                    <br/>
                    <Dropdown id={"AgeDropDown2"} value={this.state.value_to} options={this.state.period} onChange={this.onSelectTo} fluid selection placeholder="Выбирите конечный период" />
                    <br/><br/>
                    <span><Button style={{transform: "scale(0.815)"}} basic size={'medium'} onClick={(e,val) => {
                        axios.post(urlBackend+'/postQuery', {query:{type:'get_fields_by_age',select_type:'by_age',select_params:{age_to:this.state.value_to,age_from:this.state.value_from}}})
                            .then((res) => {
                                console.log(res.data)
                                let id_array =[];
                                for (let i = 0 ; i < res.data.length; i++) {
                                    id_array.push(res.data[i].fields_id);
                                }
                                console.log(id_array)
                                this.props.filterhandler(id_array)
                                //this.setState({selectedLayer:val.value,objectsDict:res.data});
                            })
                            .catch((err) => {console.log(err);})
                    }}>{"Filter"}</Button> </span>
                </div>
            </div>
        )}}
export default GeometryFilter;
