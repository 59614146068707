import React from "react";
import {Button, Dropdown, Icon, Input, Label} from "semantic-ui-react";
import axios from "axios";
import Cookies from "js-cookie";
import Dropzone from "react-dropzone";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Slide from "@mui/material/Slide";
const config = require('../config');
const hostBackend = config.HOSTBackend;
const portBackend = config.PORTBackend;
const protocolBackend = config.ProtocolBackend;
const prefixBackend = config.PrefixBackend;
var urlBackend;
if (portBackend === "" || portBackend === undefined){
    urlBackend=protocolBackend+'://'+hostBackend+prefixBackend;
}
else{
    urlBackend=protocolBackend+'://'+hostBackend+':'+portBackend+prefixBackend;
}
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
class AddFiles extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            display:"none",
            filesType:"",
            filesName:"",
            publicationError:"",
            PublicationFiles:null,
            addDisplay:"none",
            publicationText:"Files to Upload: ",
            exist:false,
            open:false,
            fileName:"",
        }
    };
    render() {
        // eslint-disable-next-line no-unused-vars
        const { t } = this.props;
        let files;
        return(
            <div style={{display:this.props.display}} >
                <Button basic size={"mini"} onClick={()=>{
                    if (this.state.addDisplay === "none")
                        this.setState({addDisplay:"block"})
                    else
                        this.setState({addDisplay:"none"})
                }}><Icon name='plus'  />{"Add new"}</Button>
                <div style={{display:this.state.addDisplay}}>
                    {this.state.publicationError}
                    <br/>
                    <div>
                        <span >{"Имя файла:"}<Input value={this.state.filesName}  onChange={(e,val) =>{
                            this.setState({filesName:val.value})
                        }}/></span>
                    </div>
                    <Dropzone onDrop={acceptedFiles => {
                        files = acceptedFiles;
                        console.log(acceptedFiles[0].name)
                        if (acceptedFiles[0].name !== undefined)
                            this.setState({PublicationFiles:acceptedFiles,publicationText:"Files to Upload: "+ acceptedFiles[0].name});
                        else
                            this.setState({PublicationFiles:acceptedFiles,publicationText:"Files to Upload: "});
                    }}
                    >
                        {({getRootProps, getInputProps}) => (
                            <section>
                                <div style={{height:"35px",width:"300px",borderRadius: "15px",padding: "10px",border:"1px solid black"}} {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    {t("Click or Drag'n'Drop file here")}
                                </div>
                            </section>
                        )}
                    </Dropzone>
                    <Dialog
                        classes={{ root: 'MenuItem'}}
                        open={this.state.open}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={()=>{this.setState({open:false})}}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle id="alert-dialog-slide-title">{"Similar publication already exists in the database, please use search to check"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description">
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={()=>{this.setState({open:false})}} color="primary">
                                Cancel uploading
                            </Button>
                            <Button onClick={()=> {
                                let name = this.state.fileName;
                                let type;
                                if (this.state.PublicationFiles){
                                    if (this.state.PublicationFiles[0])
                                        type=this.state.PublicationFiles[0].type;
                                    else
                                        type=null;
                                }
                                else
                                    type=null;
                            }} color="primary">
                                Upload anyway
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {((this.state.PublicationFiles !== undefined  && this.state.PublicationFiles !== null))&&(<span><Label basic>{this.state.publicationText+" "}</Label><br/></span>)}
                    <Button basic onClick={()=>{
                        if (this.state.publicationType !== "" && this.state.publicationName !== "" && ((this.state.PublicationFiles!==null && this.state.PublicationFiles!==undefined) )){
                            const dataform = new FormData();
                            console.log(this.state.PublicationFiles);
                            if(this.state.PublicationFiles!==null && this.state.PublicationFiles!==undefined)
                                dataform.append('file',this.state.PublicationFiles[0]);
                            else
                                dataform.append('file',"none");
                            axios.post(urlBackend+'/uploadtext', dataform, {
                                // receive two    parameter endpoint url ,form data
                            }).then(res => { // then print response status
                                //console.log(res,this.state.id)
                                let that = this;
                                console.log(this.props,res)
                                let layer = this.props.layer;
                                let name = null;
                                let type = null;
                                if (res.data === ""){
                                    name = null;
                                    type = null;
                                }
                                else {
                                    name = res.data.filename;
                                    type = this.state.PublicationFiles[0].type;
                                }
                                let values = "'"+name+"' , (select f_layer_id from layer where f_layer_tablename = '"+this.props.layer+"'), "+this.props.id+", "+Cookies.get("id")+", '"+type+"', 191";
                                axios.post(urlBackend+'/postQuery', {query:{type:"add_files", select_type:"insert", select_params:{values:values}}})
                                    .then((res) => {
                                        console.log(that.props)
                                        that.props.update(that.props.id,layer);
                                        console.log(res)
                                    }).catch((error) => {
                                        console.log(error)
                                    });
                            }).catch((error) => {
                                console.log(error)
                            });
                        }else{
                            this.setState({publicationError:"Fill required fields"})
                        }
                    }}>{"Save"}</Button>
                </div>
            </div>
        )}}
export default AddFiles;
