import React from "react";
import {Button, Dropdown, Input, Label} from "semantic-ui-react";
import axios from "axios";
import Plot from "react-plotly.js";
import ScrollContainer from "react-indiana-drag-scroll";
import {urlBackend} from "../VariableInitialize"

function sortByKey(array, key, order) {
    if (order)
        return array.sort(function(a, b) {
            var x = a[key]; var y = b[key];
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
    else
        return array.sort(function(a, b) {
            var x = a[key]; var y = b[key];
            return ((x > y) ? -1 : ((x < y) ? 1 : 0));
        });
}

function OrButton(props) {
    if (props.index === props.list.length-1)
        return (
            <Button style={{backgroundColor:'green'}}
                    onClick={() => {
                        //console.log(props);
                        props.onOrClick(props.index,props.list);
                    }}>{"OR"}</Button>
        );
    else{
        return (
            <Button style={{backgroundColor:'red'}}
                    onClick={() => {
                        //console.log(props);
                        props.onMinusClick(props.index,props.list);
                    }}>{"-"}</Button>
        );
    }
}


class KernTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            arraytype:[{key:"test",text:"test",value:"test"}],
            arrayx:[{key:"",text:"",value:""}],
            arrayy:[{key:"",text:"",value:""}],
            arrayVar:[{key:"",text:"",value:""}],
            array:[{key:"1396",text:"6747",value:"1396"},{key:"1397",text:"6828",value:"1397"},{key:"564",text:"4304",value:"564"}] ,
            list:[{index:0,list:[{id:0,param:'',type:'',eq:'',parameq:'',array:[{key:"",text:"",value:""}]}]}],
            arrayEq:[{key:">",text:">",value:"GREATER"},{key:"<",text:"<",value:"LESS"}],
            wells: 1396,
            typex:'',
            typey:'',
            typeVar:'',
            typeEq:"LESS",
            paramx:'',
            paramy:'',
            paramVar:'',
            paramEq:'',
            body:[],
            canonBody:[],
            canonLayout:"",
            table:'',
            layout:{},
            chartTypes:[{key:"Scatter Plots",text:"Scatter Plots",value:1},
                {key:"Line Charts",text:"Line Charts",value:2},
                {key:"Fill Area",text:"Fill Area",value:3},
                {key:"Log Plots",text:"Log Plots",value:4}],
        };
        this.onOrClick = this.onOrClick.bind(this);
        this.onMinusClick = this.onMinusClick.bind(this);
    }
    onOrClick(index,list) {
        //console.log(index,list);
        list.push({index:list.length,list:[{id:0,param:'',type:'',eq:'',parameq:'',array:[{key:"",text:"",value:""}]}]});
        this.setState({list:list})
    };
    onMinusClick(index,list) {
        //console.log(index,list);
        list.splice(index, 1);
        for (let i = 0 ; i < list.length;i++)
            list[i].index = i;
        this.setState({list:list})

    }
    sortArrays(arrays, comparator = (a, b) => (a < b) ? -1 : (a > b) ? 1 : 0) {
        let arrayKeys = Object.keys(arrays);
        let sortableArray = Object.values(arrays)[0];
        let indexes = Object.keys(sortableArray);
        let sortedIndexes = indexes.sort((a, b) => comparator(sortableArray[a], sortableArray[b]));

        let sortByIndexes = (array, sortedIndexes) => sortedIndexes.map(sortedIndex => array[sortedIndex]);

        if (Array.isArray(arrays)) {
            return arrayKeys.map(arrayIndex => sortByIndexes(arrays[arrayIndex], sortedIndexes));
        } else {
            let sortedArrays = {};
            arrayKeys.forEach((arrayKey) => {
                sortedArrays[arrayKey] = sortByIndexes(arrays[arrayKey], sortedIndexes);
            });
            return sortedArrays;
        }
    }
    render() {
        return (
            <div>
                {"Wells:"}
                <Dropdown
                    multiple
                    options={this.state.array}
                    onChange={(e,val) => {
                        //console.log(e,val.value);
                        this.setState({wells:val.value});
                    }}
                    //value={this.state.selected}
                    selection
                    placeholder={"Choose Well"} />
                <br/>
                {"Analysis Type X:"}
                <Dropdown
                    options={this.state.arraytype}
                    onChange={(e,val) => {
                        //console.log(val.value)
                        let add = '' +val.value + "/parameters/"
                        axios.get(urlBackend+'/kern',{headers: { 'addition':add}})
                            .then((res) => {
                                //console.log(res)
                                let array = []
                                for (let i = 0 ; i < res.data.length;i++){
                                    array.push({key:res.data[i].short_name_rus,text:res.data[i].short_name_rus,value:res.data[i].id})
                                }
                                this.setState({arrayx:array,typex:val.value});
                            })
                            .catch((error) => {
                                console.log(error)
                            });
                    }}
                    //value={this.state.selected}
                    selection
                    placeholder={"Choose Type X"} />
                <br/>
                {"Parameters X:"}
                <Dropdown
                    options={this.state.arrayx}
                    onChange={(e,val) => {
                        //console.log(e,val)
                        this.setState({paramx:val.value});
                    }}
                    //value={this.state.selected}
                    selection
                    placeholder={"Choose X"} />
                <br/>
                {"Analysis Type Y:"}
                <Dropdown
                    options={this.state.arraytype}
                    onChange={(e,val) => {
                        //console.log(e,val)
                        let add = '' +val.value + "/parameters/"
                        axios.get(urlBackend+'/kern',{headers: { 'addition':add}})
                            .then((res) => {
                                let array = []
                                for (let i = 0 ; i < res.data.length;i++){
                                    array.push({key:res.data[i].short_name_rus,text:res.data[i].short_name_rus,value:res.data[i].id})
                                }
                                this.setState({arrayy:array,typey:val.value});
                            })
                            .catch((error) => {
                                console.log(error)
                            });
                    }}
                    //value={this.state.selected}
                    selection
                    placeholder={"Choose Type Y"} />
                <br/>
                {"Parameters Y:"}
                <Dropdown
                    options={this.state.arrayy}
                    onChange={(e,val) => {
                        this.setState({paramy:val.value});
                        //console.log(e,val)
                    }}
                    //value={this.state.selected}
                    selection
                    placeholder={"Choose Y"} />
                <br/>
                {this.state.list.map(({index,list}) => {
                    //console.log(list);
                    return(
                        <span>
                                {list.map(({id,param,type,eq,parameq,array}) => {
                                    return(
                                        <span>
                                            {"---"}
                                            <br/>
                                <span style={{display:"flex"}}>
                                        <Dropdown
                                            compact
                                            options={this.state.arraytype}
                                            onChange={(e,val) => {
                                                //console.log(e,val)
                                                let add = '' +val.value + "/parameters/"
                                                axios.get(urlBackend+'/kern',{headers: { 'addition':add}})
                                                    .then((res) => {
                                                        let array = []
                                                        for (let i = 0 ; i < res.data.length;i++){
                                                            array.push({key:res.data[i].short_name_rus,text:res.data[i].short_name_rus,value:res.data[i].id})
                                                        }
                                                        //console.log(id,this.state.list[index].list[id]);
                                                        this.state.list[index].list[id].array = array;
                                                        this.state.list[index].list[id].type = val.value;
                                                        //index2:list[index].list.length
                                                        this.setState({arrayVar:array,typeVar:val.value});
                                                    })
                                                    .catch((error) => {
                                                        console.log(error)
                                                    });
                                            }}
                                            //value={this.state.selected}
                                            selection
                                            placeholder={" "} />
                                        <Dropdown
                                            compact
                                            options={array}
                                            onChange={(e,val) => {
                                                this.state.list[index].list[id].param = val.value;
                                                this.setState({paramVar:val.value});
                                                //console.log(e,val)
                                            }}
                                            //value={this.state.selected}
                                            selection
                                            placeholder={" "} />
                                        <Dropdown
                                            compact
                                            options={this.state.arrayEq}
                                            onChange={(e,val) => {
                                                this.state.list[index].list[id].eq = val.value;
                                                this.setState({typeEq:val.value});
                                                //console.log(e,val)
                                            }}
                                            //value={this.state.selected}
                                            selection
                                            placeholder={" "} />
                                        <Input style={{width:170}} onChange={(e,val) => {
                                            //console.log(e,val.value)
                                            this.state.list[index].list[id].parameq = val.value;
                                            this.setState({paramEq:val.value});
                                        }}/>
                                        <Button style={{backgroundColor:'green'}}
                                                onClick={() => {
                                                    let list = this.state.list;
                                                    //console.log(id,index,list,list[index]);
                                                    list[index].list.push({id:list[index].list.length,param:'',type:'',eq:'',parameq:'',array:[{key:"",text:"",value:""}]});
                                                    this.setState({list:list})
                                                }}>{"+"}</Button>
                                        <Button style={{backgroundColor:'red'}}
                                                onClick={() => {
                                                    //console.log(id);
                                                    let list = this.state.list;
                                                    if (list[index].list.length >1) {
                                                        list[index].list.splice(id, 1);
                                                        for (let i = 0 ; i < list[index].list.length;i++)
                                                            list[index].list[i] = i;
                                                        this.setState({list:list})
                                                    }
                                                    else if (list[index].list.length === 1 ) {
                                                        //console.log(list)
                                                        list[index].list.splice(id, 1);
                                                        this.setState({list:list});
                                                        setTimeout(() => {
                                                                list[index].list.push({id:list[index].list.length,param:'',type:'',eq:'',parameq:'',array:[{key:"",text:"",value:""}]});
                                                                this.setState({list:list});
                                                            },
                                                            50);
                                                    }
                                                }}>{"-"}</Button>
                                </span>
                                </span>
                                    )
                                })}
                            <br/>
                                <OrButton list={this.state.list} index={index} onMinusClick={this.onMinusClick} onOrClick={this.onOrClick}/>
                            </span>
                    )})}
                <br/>
                <Button onClick={() => {
                    //console.log(this.state.paramx,this.state.paramy,this.state.typex,this.state.typey,this.state.wells)
                    const formUrlEncoded = x =>
                        Object.keys(x).reduce((p, c) => p.replace(/\s/g, '') + `&${c.replace(/\s/g, '')}=${encodeURIComponent(x[c].replace(/\s/g, ''))}`, '')
                    function getFormData(object) {
                        const formData = new FormData();
                        Object.keys(object).forEach(key => {
                            //console.log("keys :",key,object[key]);
                            //console.log(object[key] === null)
                            formData.append(key, object[key]);
                        });
                        return formData;
                    }
                    //console.log(this.state.wells.length,this.state.typeVar,this.state.paramVar,this.state.typeEq,this.state.paramEq)
                    var conditions = {};
                    var index = 0;
                    var groupnbr = 0;
                    for (let j = 0;j < this.state.list.length;j++){
                        for (let h = 0 ; h < this.state.list[j].list.length ;h++) {
                            //console.log(h,this.state.list[j],this.state.list[j].list[h],this.state.list[j].list[h].id)
                            let analyzetype = 'form-'+groupnbr+'-analysis_type';
                            let parameter = 'form-'+groupnbr+'-parameter';
                            let operation = 'form-'+groupnbr+'-operation';
                            let filter = 'form-'+groupnbr+'-filter_value';
                            let group = 'form-'+groupnbr+'-conditions_group';
                            groupnbr++;
                            //console.log(j,h,this.state.list[j],this.state.list[j].list[h])
                            let cond = {
                                [analyzetype]: this.state.list[j].list[h].type.toString(),
                                [parameter]: this.state.list[j].list[h].param.toString(),
                                [operation]: this.state.list[j].list[h].eq.toString(),
                                [filter]: this.state.list[j].list[h].parameq.toString(),
                                [group]: (j + 1).toString(),
                            }
                            conditions = {...conditions, ...cond};
                        }
                    }
                    let total = 1;
                    for (let j = 0;j < this.state.list.length;j++){
                        for (let h = 0 ; h < this.state.list[j].list.length ;h++) {
                            total++;
                        }}
                    total = total ;
                    //console.log(conditions);
                    if (this.state.wells.length === 1)
                        var bodyFormData =
                            {'form-TOTAL_FORMS': total.toString(),
                                'form-INITIAL_FORMS': "0",
                                'form-MIN_NUM_FORMS': "0",
                                'form-MAX_NUM_FORMS': "1000",
                                'CONDITION-GROUPS-TOTAL': this.state.list.length.toString(),
                                'wells': this.state.wells.toString(),
                                'analysis_type_x': this.state.typex.toString(),
                                'parameters_x': this.state.paramx.toString(),
                                'analysis_type_y': this.state.typey.toString(),
                                'parameters_y': this.state.paramy.toString(),
                            };
                    else if (this.state.wells.length === 2) {
                        //console.log("two wells")
                        var bodyFormData =
                            {
                                'form-TOTAL_FORMS': total.toString(),
                                'form-INITIAL_FORMS': "0",
                                'form-MIN_NUM_FORMS': "0",
                                'form-MAX_NUM_FORMS': "1000",
                                'CONDITION-GROUPS-TOTAL': this.state.list.length.toString(),
                                'wells': this.state.wells[0].toString(),
                                'wells ': this.state.wells[1].toString(),
                                'analysis_type_x': this.state.typex.toString(),
                                'parameters_x': this.state.paramx.toString(),
                                'analysis_type_y': this.state.typey.toString(),
                                'parameters_y': this.state.paramy.toString(),
                            };
                    }
                    else if (this.state.wells.length === 3) {
                        var bodyFormData =
                            {
                                'form-TOTAL_FORMS': total.toString(),
                                'form-INITIAL_FORMS': "0",
                                'form-MIN_NUM_FORMS': "0",
                                'form-MAX_NUM_FORMS': "1000",
                                'CONDITION-GROUPS-TOTAL': this.state.list.length.toString(),
                                'wells': this.state.wells[0].toString(),
                                'wells ': this.state.wells[1].toString(),
                                'wells  ': this.state.wells[2].toString(),
                                'analysis_type_x': this.state.typex.toString(),
                                'parameters_x': this.state.paramx.toString(),
                                'analysis_type_y': this.state.typey.toString(),
                                'parameters_y': this.state.paramy.toString(),
                            };
                    }

                    //'form-0-analysis_type': this.state.typeVar.toString(),
                    //                                 'form-0-parameter': this.state.paramVar.toString(),
                    //                                 'form-0-operation': this.state.typeEq.toString(),
                    //                                 'form-0-filter_value': this.state.paramEq.toString(),
                    //                                 'form-0-conditions_group': "1"
                    bodyFormData = {...bodyFormData,...conditions};
                    //console.log(bodyFormData);
                    let fmdt = formUrlEncoded(bodyFormData)
                    //console.log(fmdt)
                    axios.post(urlBackend+'/kernapi', fmdt,{headers:{'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8','Accept': 'application/json, text/javascript, */*; q=0.01','Accept-Language':' ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7'}})
                        .then((res) => {
                            //'1330' '1761' '1330' '1766'
                            console.log((this.state.typex.toString() === '1330' && this.state.typey.toString() ==='1340'))
                            console.log((this.state.paramx.toString() === '1761'))
                            console.log((this.state.paramy.toString()==='1766'))
                            console.log(((this.state.typex.toString() === '1330' && this.state.typey.toString() ==='1340')&&(this.state.paramx.toString() === '1761')&&(this.state.paramy.toString()==='1766')))
                            if ((this.state.typex.toString() === '1330' && this.state.typey.toString() ==='1330')&&(this.state.paramx.toString() === '1761')&&(this.state.paramy.toString()==='1766')){
                                console.log('Capilar lines',res.data.plot_data)
                                let dataSet = [];
                                for (let j=0; j<res.data.plot_data[0].x.length; j++){
                                    let x = [];
                                    let y = [];
                                    for (let i=0; i<res.data.plot_data.length;i++){
                                        x.push(res.data.plot_data[i].x[j]);
                                        y.push(res.data.plot_data[i].y[j]);
                                    }
                                    console.log(x,y)
                                    let arrays = this.sortArrays([y, x])
                                    console.log(arrays)
                                    let trace = {
                                        x:arrays[1],
                                        y:arrays[0],
                                        mode: 'lines+markers',
                                        name: 'spline'+ j,
                                        line: {shape: 'spline'},
                                        type: 'scatter'
                                    }
                                    dataSet.push(trace)

                                }
                                console.log(dataSet)
                                console.log(res.data,this.state.typex.toString(),this.state.paramx.toString(),this.state.typey.toString(),this.state.paramy.toString())
                                this.setState({layout:res.data.plot_layout,body:dataSet,table:res.data.table_html,canonBody:res.data.plot_data,canonLayout:res.data.plot_layout})
                            }
                            else{
                                console.log(res.data,this.state.typex.toString(),this.state.paramx.toString(),this.state.typey.toString(),this.state.paramy.toString())
                                this.setState({layout:res.data.plot_layout,body:res.data.plot_data,table:res.data.table_html,canonBody:res.data.plot_data,canonLayout:res.data.plot_layout})
                            }

                        }).catch((error) => {
                        console.log(error)
                    });
                }}>{"Apply"}</Button>
                <br/>
                {this.state.body.length >0 && (
                    <div>
                        <Label>{"Choose Chart Type"}</Label>
                        <Dropdown
                            options={this.state.chartTypes}
                            onChange={(e,val) => {
                                console.log(val.value);
                                let tempBody=this.state.body.slice();
                                console.log(this.state.body,tempBody)
                                let newBody=[];
                                let newLayout={};
                                switch (val.value) {

                                    case 1:
                                        this.setState({body:this.state.canonBody,layout:this.state.canonLayout});
                                        break;
                                    case 2:
                                        for ( let i =0 ; i < tempBody.length;i++){
                                            //x: [Array],
                                            //y: [Array],
                                            //mode: 'markers',
                                            //type: 'scatter',
                                            //name: 'Скважина 6747:idx=1'
                                            newBody.push({x:tempBody[i].x,y:tempBody[i].y,name:tempBody[i].name,mode:"lines"});


                                        }
                                        newLayout={
                                            title: 'Line Chart'
                                        };
                                        console.log(newBody,newLayout)
                                        this.setState({body:newBody,layout:newLayout})
                                        break;
                                    case 3:
                                        for ( let i =0 ; i < tempBody.length;i++){
                                            //x: [Array],
                                            //y: [Array],
                                            //mode: 'markers',
                                            //type: 'scatter',
                                            //name: 'Скважина 6747:idx=1'
                                            newBody.push({x:tempBody[i].x,y:tempBody[i].y,name:tempBody[i].name,fill: 'tonexty', type: 'scatter'});


                                        }
                                        newLayout={
                                            title: 'Area Chart'
                                        };
                                        console.log(newBody,newLayout)
                                        this.setState({body:newBody,layout:newLayout})

                                        break;
                                    case 4:
                                        for ( let i =0 ; i < tempBody.length;i++){
                                            //x: [Array],
                                            //y: [Array],
                                            //mode: 'markers',
                                            //type: 'scatter',
                                            //name: 'Скважина 6747:idx=1'
                                            newBody.push({x:tempBody[i].x,y:tempBody[i].y,name:tempBody[i].name, type: 'scatter'});


                                        }
                                        newLayout={
                                            title: 'Area Chart',
                                            xaxis: {
                                                type: 'log',
                                                autorange: true
                                            },
                                            yaxis: {
                                                type: 'log',
                                                autorange: true
                                            }
                                        };
                                        console.log(newBody,newLayout)
                                        this.setState({body:newBody,layout:newLayout})
                                        break;
                                    default:
                                        this.setState({body:this.state.canonBody,layout:this.state.canonLayout});
                                        break;
                                }
                            }}
                            //value={this.state.selected}
                            selection
                            placeholder={"Chart types"} />
                        <br/>
                        <Plot
                            data={this.state.body}
                            layout={ this.state.layout}
                            onClick={(data) => {
                                console.log("onClick ",data)
                            }}
                        />
                    </div>
                )}
                <ScrollContainer className="scroll-container">
                    {//<InnerHTML html={this.state.table} />
                    }
                </ScrollContainer>
            </div>
        )
    }
}
export default KernTab;

