import React, { useEffect, useState } from 'react';

const MyGeoMapLasso = ({ map, lasso_state, onLassoComplete }) => {
    const [lassoPoints, setLassoPoints] = useState([]);
    const [isLassoActive, setIsLassoActive] = useState(false);

    useEffect(() => {
        if (!map) return;

        const handleMouseMove = (e) => {
            if (isLassoActive && lasso_state && e.originalEvent.altKey) {
                setLassoPoints((prevPoints) => [
                    ...prevPoints,
                    [e.lngLat.lng, e.lngLat.lat],
                ]);
            }
        };



        const handleKeyDown = (e) => {
            if (e.key === 'Alt') {
                setIsLassoActive(true);
            } else if (e.key === 'Escape') {
                setLassoPoints([]);
                if (map.getSource('lasso')) {
                    map.removeLayer('lasso');
                    map.removeSource('lasso');
                }
            }
        };

        const handleKeyUp = (e) => {
            if (e.key === 'Alt') {
                setIsLassoActive(false);
                if (lassoPoints.length > 2) {
                    onLassoComplete(lassoPoints);
                }
                // Не очищаем точки лассирования здесь, чтобы полигон оставался видимым
            }
        };

        map.on('mousemove', handleMouseMove);
        //map.on('click', handleClick);
        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);

        return () => {
            map.off('mousemove', handleMouseMove);
            //map.off('click', handleClick);
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
        };
    }, [map, isLassoActive, lassoPoints, onLassoComplete]);

    useEffect(() => {
        if (!map) return;

        if (!lasso_state) {
            setLassoPoints([]);
            if (map.getSource('lasso')) {
                map.removeLayer('lasso');
                map.removeSource('lasso');
            }
        }
    }, [lasso_state, map]);

    useEffect(() => {
        if (!map) return;

        if (lassoPoints.length > 1) {
            if (map.getSource('lasso')) {
                map.getSource('lasso').setData({
                    type: 'Feature',
                    geometry: {
                        type: 'Polygon',
                        coordinates: [lassoPoints],
                    },
                });
            } else {
                map.addSource('lasso', {
                    type: 'geojson',
                    data: {
                        type: 'Feature',
                        geometry: {
                            type: 'Polygon',
                            coordinates: [lassoPoints],
                        },
                    },
                });
                map.addLayer({
                    id: 'lasso',
                    type: 'fill',
                    source: 'lasso',
                    layout: {},
                    paint: {
                        'fill-pattern': 'pattern',
                        'fill-color': '#008888',
                        'fill-opacity': 0.5
                    },
                });
            }
        } else if (map.getSource('lasso')) {
            map.removeLayer('lasso');
            map.removeSource('lasso');
        }
    }, [lassoPoints, map]);

    return null;
};



export default MyGeoMapLasso;