import React, {useEffect, useState} from 'react';
import "../Styles/MyGeoMapLinesTable.css"

const MyGeoMapLinesTable = ({ initialData, close, addLine, resetLine, editLine,lineLength, lines }) => {
    const [data, setData] = useState([]);
    const [linesLength,setLinesLength] = useState(0)
    const [linesData,setLinesData] = useState(null)
    const handleAddData = (linesData2,lineLength2) => {
        if (data.length < lineLength2){
            let currentData = data;
            currentData.push({z:0,points:linesData2[lineLength2-1].geometry.coordinates})
            setData(currentData)
        }
    }
    useEffect(() =>{
        setLinesLength(lineLength)
        setLinesData(lines)
        handleAddData(lines,lineLength)
    }, [handleAddData, lineLength, lines]);
    const handleZChange = (index, value) => {
        const newData = [...data];
        newData[index].z = value;
        setData(newData);
        console.log(newData)
    };
    const handleAddLine = () => {
        console.log("handle addline click")
        addLine();
    };

    const handleResetLine = () => {
        resetLine();
    };

    return (
        <div className={"linesTable"} style={{ border: '1px solid black', padding: '20px', position: 'absolute'}}>
            <button
                onClick={close}
                style={{ position: 'absolute', top: '10px', right: '10px' }}
            >
                Close
            </button>
            <div style={{ marginBottom: '10px' }}>
                <button onClick={handleAddLine}>Add Line</button>
                <button onClick={handleResetLine} style={{ marginLeft: '10px' }}>Reset</button>
            </div>
            <table border="1">
                <thead>
                <tr>
                    <th>Line</th>
                    <th>Z</th>
                    <th>Edit</th>
                </tr>
                </thead>
                <tbody>
                {data.map((row, index) => (
                    <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                            <input
                                type="number"
                                value={row.z}
                                onChange={(e) => handleZChange(index, e.target.value)}
                            />
                        </td>
                        <td>
                            <button onClick={() => editLine(index)}>Edit</button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default MyGeoMapLinesTable;