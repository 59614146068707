import React from 'react';
import {Form, Checkbox, Button, Grid, Segment, Table} from 'semantic-ui-react'
import { withTranslation } from 'react-i18next';
import Cookies from "js-cookie";
import axios from "axios";
import {styled, alpha,ThemeProvider, createTheme } from '@mui/material/styles';
const theme = createTheme();
const config = require('../config');
const hostBackend = config.HOSTBackend;
const portBackend = config.PORTBackend;
const protocolBackend = config.ProtocolBackend;
const prefixBackend = config.PrefixBackend;
var urlBackend;
if (portBackend === "" || portBackend === undefined){
    urlBackend=protocolBackend+'://'+hostBackend+prefixBackend;
}
else{
    urlBackend=protocolBackend+'://'+hostBackend+':'+portBackend+prefixBackend;
}

const GroupDiv = styled('div')(({ theme }) => ({
    height:"400px",
    overflowY:"scroll",
    width: '99%',
    '& > * + *': {
        marginTop: theme.spacing(2),
    },
}));

function GroupPermissionForObject(props) {
    const t = props.t
    let groupsPerm =props.permArray;
    let style = {position: 'absolute',backgroundColor:"#FFFFFF",border: "thick double #000000",right:"10px",top:props.csstop+"px",height:"400px",zIndex:9999999}
    if (props.csstop === null){
        if (props.display)
            style = {display:"block"}
        else
            style = {display:"none"}
    }
    let multipler = 1
    let buttonStyle = {}
    if (props.type !== undefined && props.type !== null)
    {

        style.transform = 'scale(0.7)'
        style.transformOrigin = 'top right'
        style.width = '65%'
        buttonStyle = {transform:'scale(1.2)',transformOrigin:'left'}
    }
    console.log(props)
    if (props.display)
        return (
            <ThemeProvider theme={theme}>
            <GroupDiv style={style} >
                {(props.csstop !== null)&&(<><Button basic size={'mini'} onClick={props.close} icon><i className="close icon"/></Button>
                <br/></>)}
                {props.permArray.map(({ name, permissions},index) => {
                    let permission = permissions;
                    if ( permission !== null)
                        permission = permission.replace(/\s/g, '');
                    return(
                        <div>
                            {console.log(props.permArray)}
                            <Grid >
                                <Grid.Row className={"perm-row"}>
                                    <Grid.Column width={1}>
                                    </Grid.Column>
                                    <Grid.Column width={15}>
                                        {t('Group')}: <b>{name}</b>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row className={"perm-row"}>
                                    <Grid.Column width={1}>
                                    </Grid.Column>
                                    <Grid.Column width={3}>
                                        <Checkbox
                                            radio
                                            label={t('None')}
                                            name='checkboxRadioGroup'
                                            value={null}
                                            style={{height:"23px"}}
                                            checked={permission === null}
                                            onClick={(e, data) => {console.log(data.value); groupsPerm[index].permissions = data.value; props.arrayChange(groupsPerm);}}
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={4}>
                                        <Checkbox
                                            radio
                                            label={t('Read')}
                                            name='checkboxRadioGroup'
                                            value='read'
                                            style={{height:"23px"}}
                                            checked={permission === 'read'}
                                            onClick={(e, data) => {console.log(data.value); groupsPerm[index].permissions = data.value; props.arrayChange(groupsPerm);}}
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={6}>
                                        <Checkbox
                                            radio
                                            label={t('Write')}
                                            name='checkboxRadioGroup'
                                            value='change'
                                            style={{height:"23px"}}
                                            checked={permission === 'change'}
                                            onClick={(e, data) => {console.log(data.value); groupsPerm[index].permissions = data.value; props.arrayChange(groupsPerm);}}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </div>
                        )
                })}
                {(props.id !== null)&&( <Button style={buttonStyle} basic size={'mini'} onClick={()=>{
                    console.log( props.permArray,props.permArrayBasic)
                    if ( props.type === 'publications'){
                        for (let i=0; i< props.permArrayBasic.length;i++){
                            //console.log(props.permArrayBasic[i] === null , i, typeof props.permArrayBasic[i].permissions,props.id,props.permArray[i].id)
                            let permCode = null;

                            if ( props.permArray[i].permissions === 'read')
                                permCode = 1;
                            if ( props.permArray[i].permissions === 'change')
                                permCode = 2;
                            if ( props.permArrayBasic[i].permissions === null && props.permArray[i].permissions !== null){
                                //insert
                                console.log("insert from NULL to " + props.permArray[i].permissions)
                                let PermQuary = {type:"insert_Groups_Permission",select_type:"insert_Groups_Permission_Publications_From_Attachment_ID",select_params:{object_id:props.id,group_id:props.permArray[i].id,permission:permCode}}
                                axios.post(urlBackend+'/postQuery', {query: PermQuary})
                                    .then((res) => {
                                        console.log(res)
                                    })
                                    .catch((error)=>{console.log(error)})
                            }else if ( props.permArrayBasic[i].permissions !== null && props.permArray[i].permissions === null){
                                //delete
                                console.log("delete from " + props.permArrayBasic[i].permissions)
                                let PermQuary = {type:"delete_Groups_Permission",select_type:"delete_Groups_Permission_Publications_From_Attachment_ID",select_params:{object_id:props.id,group_id:props.permArray[i].id}}
                                axios.post(urlBackend+'/postQuery', {query: PermQuary})
                                    .then((res) => {
                                        console.log(res)
                                    })
                                    .catch((error)=>{console.log(error)})
                            }else if ( props.permArrayBasic[i].permissions !== null && props.permArray[i].permissions !== null && props.permArrayBasic[i].permissions !== props.permArray[i].permissions){
                                //update
                                console.log("update from " +props.permArrayBasic[i].permissions + " to " + props.permArray[i].permissions)
                                let PermQuary = {type:"update_Groups_Permission",select_type:"update_Groups_Permission_Publications_From_Attachment_ID",select_params:{object_id:props.id,group_id:props.permArray[i].id,permission:permCode}}
                                axios.post(urlBackend+'/postQuery', {query: PermQuary})
                                    .then((res) => {
                                        console.log(res)
                                    })
                                    .catch((error)=>{console.log(error)})
                            }
                        }
                        props.close();
                    }
                    else if ( props.type === 'geotiff'){
                        for (let i=0; i< props.permArrayBasic.length;i++){
                            //console.log(props.permArrayBasic[i] === null , i, typeof props.permArrayBasic[i].permissions,props.id,props.permArray[i].id)
                            let permCode = null;

                            if ( props.permArray[i].permissions === 'read')
                                permCode = 1;
                            if ( props.permArray[i].permissions === 'change')
                                permCode = 2;
                            if ( props.permArrayBasic[i].permissions === null && props.permArray[i].permissions !== null){
                                //insert
                                console.log("insert from NULL to " + props.permArray[i].permissions)
                                let PermQuary = {type:"insert_Groups_Permission",select_type:"insert_Groups_Permission_Geotiff_From_Attachment_ID",select_params:{object_id:props.id,group_id:props.permArray[i].id,permission:permCode}}
                                axios.post(urlBackend+'/postQuery', {query: PermQuary})
                                    .then((res) => {
                                        console.log(res)
                                    })
                                    .catch((error)=>{console.log(error)})
                            }else if ( props.permArrayBasic[i].permissions !== null && props.permArray[i].permissions === null){
                                //delete
                                console.log("delete from " + props.permArrayBasic[i].permissions)
                                let PermQuary = {type:"delete_Groups_Permission",select_type:"delete_Groups_Permission_Geotiff_From_Attachment_ID",select_params:{object_id:props.id,group_id:props.permArray[i].id}}
                                axios.post(urlBackend+'/postQuery', {query: PermQuary})
                                    .then((res) => {
                                        console.log(res)
                                    })
                                    .catch((error)=>{console.log(error)})
                            }else if ( props.permArrayBasic[i].permissions !== null && props.permArray[i].permissions !== null && props.permArrayBasic[i].permissions !== props.permArray[i].permissions){
                                //update
                                console.log("update from " +props.permArrayBasic[i].permissions + " to " + props.permArray[i].permissions)
                                let PermQuary = {type:"update_Groups_Permission",select_type:"update_Groups_Permission_Geotiff_From_Attachment_ID",select_params:{object_id:props.id,group_id:props.permArray[i].id,permission:permCode}}
                                axios.post(urlBackend+'/postQuery', {query: PermQuary})
                                    .then((res) => {
                                        console.log(res)
                                    })
                                    .catch((error)=>{console.log(error)})
                            }
                        }
                        props.close();
                    }else{
                        for (let i=0; i< props.permArrayBasic.length;i++){
                            //console.log(props.permArrayBasic[i] === null , i, typeof props.permArrayBasic[i].permissions,props.id,props.permArray[i].id)
                            let permCode = null;

                            if ( props.permArray[i].permissions === 'read')
                                permCode = 1;
                            if ( props.permArray[i].permissions === 'change')
                                permCode = 2;
                            if ( props.permArrayBasic[i].permissions === null && props.permArray[i].permissions !== null){
                                //insert
                                console.log("insert from NULL to " + props.permArray[i].permissions)
                                let PermQuary = {type:"insert_Groups_Permission",select_type:"insert_Groups_Permission",select_params:{object_id:props.id,group_id:props.permArray[i].id,permission:permCode, layer_id:props.layer_id}}
                                axios.post(urlBackend+'/postQuery', {query: PermQuary})
                                    .then((res) => {
                                        console.log(res)
                                    })
                                    .catch((error)=>{console.log(error)})
                            }else if ( props.permArrayBasic[i].permissions !== null && props.permArray[i].permissions === null){
                                //delete
                                console.log("delete from " + props.permArrayBasic[i].permissions)
                                let PermQuary = {type:"delete_Groups_Permission",select_type:"delete_Groups_Permission",select_params:{object_id:props.id,group_id:props.permArray[i].id, layer_id:props.layer_id}}
                                axios.post(urlBackend+'/postQuery', {query: PermQuary})
                                    .then((res) => {
                                        console.log(res)
                                    })
                                    .catch((error)=>{console.log(error)})
                            }else if ( props.permArrayBasic[i].permissions !== null && props.permArray[i].permissions !== null && props.permArrayBasic[i].permissions !== props.permArray[i].permissions){
                                //update
                                console.log("update from " +props.permArrayBasic[i].permissions + " to " + props.permArray[i].permissions)
                                let PermQuary = {type:"update_Groups_Permission",select_type:"update_Groups_Permission",select_params:{object_id:props.id,group_id:props.permArray[i].id, permission:permCode, layer_id:props.layer_id}}
                                axios.post(urlBackend+'/postQuery', {query: PermQuary})
                                    .then((res) => {
                                        console.log(res)
                                    })
                                    .catch((error)=>{console.log(error)})
                            }
                        }
                        props.close();
                    }

                }} icon>{t("Change Permission for object")}</Button>)}
                <br/>
                <br/>
                {""}
            </GroupDiv>
            </ThemeProvider>
        )
    else
        return(<div></div>)
}


export default withTranslation()(GroupPermissionForObject);
