import {useEffect, useRef, useState} from "react";

export default function MyGeoMapFeatureState(props) {
    /*
    const [stateID,setStateID] = useState(null)
    const [stateSource,setStateSource] = useState(null)
    const [stateLayer,setStateLayer] = useState(null)
    const [map, setMap] = useState(null)
    useEffect(() => {
        setMap(props.map)
        setStateID(props.id)
        setStateLayer(props.sourceLayer)
        setStateSource(props.source )

    }, [props, props.id, props.map, props.source, props.sourceLayer,props.onLeave]);
    */
    useEffect(() => {
        if (!props.map.current) return;

        const setFeatureState = () => {
            props.map.current.setFeatureState(
                { source: props.source, sourceLayer: props.layer, id: props.id },
                { color: "#ece834" }
            );
        };

        const removeFeatureState = () => {
            props.map.current.removeFeatureState(
                { source: props.source, sourceLayer: props.layer, id: props.id }
            );
        };

        if (props.map.current.isStyleLoaded()) {
            setFeatureState();
        } else {
            props.map.current.on('styledata', setFeatureState);
        }

        return () => {
            props.map.current.off('styledata', setFeatureState);
            removeFeatureState();
        };
    }, [props.map, props.id, props.layer, props.source]);

    /*useEffect(() => {
        if ( stateID && stateSource && setStateLayer){
            map.current.on('click', setStateLayer, (e) => {
                if (stateID) {
                    map.current.setFeatureState(
                        {source: stateSource, id: stateID},
                        {hover: true}
                    );
                }
            });
            map.current.on('mouseleave', setStateLayer, () => {
                if (stateID) {
                    map.current.setFeatureState(
                        {source: stateSource, id: stateID},
                        {hover: false}
                    );
                }
                setStateID(null)
                setStateLayer(null)
                setStateSource(null )
            });
        }


    }, [stateLayer,stateSource,stateID,map]);

     */
    return(<></>)
}