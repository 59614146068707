import React from 'react';
import {viewer3d} from "../VariableInitialize";
import {Helmet} from "react-helmet";
class Geo3DViewer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            slider:false,
        }
    };

    render() {
        let url_postfix = "";
        if (this.props.url !== null && this.props.url !== undefined)
            url_postfix = url_postfix + "?file_url="+this.props.url
        console.log("HELP")
        return(
            <div className={"geo3d"} id={"geo3d"} style={{display:"block",width:"100%"}}>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{"Геомодель"}</title>
                    <meta name="description" content={"просмотр gird сеток 3d онлайн xyz earthvision построение карт по точкам"} />
                </Helmet>
                <iframe heigth={"90%"} style={{top:"0px"}} src={viewer3d+url_postfix}></iframe>
            </div>
        )}}
export default Geo3DViewer;
