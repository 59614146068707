import React from "react";
import {SketchPicker} from "react-color";
import reactCSS from 'reactcss';

class LayerBorderColor extends React.Component {
    state = {
        displayColorPicker: false,
        color: '#F5A623',
        id:this.props.value,
        index:null
    };
    componentDidMount(){
        for (let i = 0 ; i < this.props.result.length ; i++) {
            if (this.props.result[i].name === this.props.value) {
                this.setState({color : this.props.result[i].border});
            }
        }
    }
    handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
        for (let i = 0 ; i < this.props.result.length;i++){
            if (this.props.result[i].name === this.props.value) {
                this.setState({color : this.props.result[i].border,index:i});
            }
        }
    };

    handleClose = () => {
        this.setState({ displayColorPicker: false })
    };

    handleChange = (color) => {
        this.setState({ color: color.hex })
        if (this.state.index != null) {
            this.props.result[this.state.index].border = color.hex
        }
        this.props.handle();
    };

    render() {

        const styles = reactCSS({
            'default': {
                color: {
                    width: '12px',
                    height: '10px',
                    background: this.state.color,
                },
                swatch: {
                    padding: '0px',
                    background: '#fff',
                    borderRadius: '0.1px',
                    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                    display: 'inline-block',
                    cursor: 'pointer',
                },
                popover: {
                    position: 'absolute',
                    zIndex: '99999999',
                },
                cover: {
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                },
            },
        });

        return (
            <div style={{display:'inline'}}>
                <div style={ styles.swatch } onClick={ this.handleClick }>
                    <div style={ styles.color } />
                </div>
                { this.state.displayColorPicker ? <div style={ styles.popover }>
                    <div style={styles.cover } onClick={ this.handleClose }/>
                    <SketchPicker color={ this.state.color } onChange={ this.handleChange } />
                </div> : null }

            </div>
        )
    }
}
export default LayerBorderColor;