import React from "react";
import {Button, Dropdown} from "semantic-ui-react";

class Chronostrat extends React.Component {
    constructor (props, context) {
        super(props, context);
        this.state = {
            wordBasic:this.props.word.reverse(),
            periodBasic:this.props.age_period,
            word:this.props.word.reverse(),
            period:this.props.age_period,
            age_word:this.props.age_word,
            value:[0,4600],
            value_from:null,
            value_to:null
        }
    };
    onSelectFrom = (event,{value}) => {
        var res = value.split(" ");
        let obj_value = parseFloat(res[0]);
        let obj_index = res[1];
        let obj_valueTo;
        //console.log(obj_value,obj_index);
        for (let i = 0; i<this.state.age_word.length;i++) {
            //console.log(age_word[i].object_index,obj_index)
            if (this.state.age_word[i].object_index.replace(/\s/g, "") === obj_index.replace(/\s/g, "")) {
                //console.log(i,this.state,age_period);
                obj_valueTo =this.state.age_word[i].age_to;
                this.setState({period:this.state.period.slice(i)})
            }
        }
        let arr =Array.from(this.state.periodBasic);
        for (let i = 0 ;i<arr.length;i++) {
            let res2 = arr[i].value.split(" ");
            let obj_value = parseFloat(res2[0]);
            if (obj_value < obj_valueTo) {
                arr.splice(i, 1);
                i--;
            }
        }

        this.setState({period:arr,value:[obj_value,this.state.value[1]],value_from:value});
        this.props.handle([obj_value,this.state.value[1]]);
    };
    onSelectTo =(event,{value}) => {
        var res = value.split(" ");
        let obj_value = parseFloat(res[0]);
        this.setState({value:[this.state.value[0],obj_value],value_to:value});
        this.props.handle([this.state.value[0],obj_value])
    };
    render() {

        let word = this.state.word;
        let period = this.state.period;
        /**/
        console.log("from ",this.state.word[0]," to ",this.state.period[0],this.props)
        return(
            <div>
                <Dropdown id={"ChronoDropDown1"} value={this.state.value_from} options={this.state.word.reverse()} onChange={this.onSelectFrom} fluid selection placeholder="Выбирите начальный период" />
                <br/>
                <Dropdown id={"ChronoDropDown2"} value={this.state.value_to} options={this.state.period} onChange={this.onSelectTo} fluid selection placeholder="Выбирите конечный период" />
                <Button style={{transform: "scale(0.815)"}} basic onClick={() => {
                    this.setState({period:this.state.periodBasic,word:this.state.wordBasic,value:[0,4600],value_from:null,value_to:null});
                    this.props.handle(null)
                }} size={'medium'}>{"Reset"}</Button>
            </div>
        )}}
export default Chronostrat;
