import React from 'react';
import {Button, Checkbox, Icon, Input, Label} from 'semantic-ui-react'
import "../Styles/MyGeoMapGraticule.css"
export function MyGeoMapPointCreate (props) {
    const [x,setX] = React.useState(0);
    const [y,setY] = React.useState(0);
    const [trigger,setTrigger] = React.useState(false);

    return(
        <div className="point" style={{display:props.display }} >
                <span>
                        {props.error}
                    <Button basic size={'mini'} onClick={props.close} icon><i className="close icon"/></Button>
                        <br/>
                        <span style={{display:"flex"}}>{"X:"}<Input defaultValue={x} type={"number"} size={"mini"} onChange={(e,val) =>{
                            setX(val.value)
                        }} /></span>
                        <span style={{display:"flex"}}>{"Y:"}<Input defaultValue={y} type={"number"} size={"mini"} onChange={(e,val) =>{
                            setY(val.value)
                        }}/></span>
                        <Checkbox className={"cheb"} onClick={() => {
                            console.log(trigger)
                            setTrigger(!trigger)
                        }} slider label={"Zoom to Point"} />
                        <Button size={"mini"} onClick={() => {
                            console.log(x,y);props.create(y,x,trigger)}}>Add Point</Button>
                </span>
        </div>
    )
}