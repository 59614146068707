import {useEffect} from "react";
import * as turf from "@turf/turf";
import Cookies from "js-cookie";


export default function MyGeoMapClear(props) {

    useEffect(() => {
        console.log("clear")
        if (!props.map || props.map.current === null)
            return
        console.log(props.map.current.getStyle().layers)
        let layers = props.map.current.getStyle().layers
        console.log(layers,layers.length)
        for (let i = 0 ; i < layers.length; i++){
            //console.log(layers[i])
            for (let j = 0 ; j < props.result.length;j++){
                //console.log(layers[i],props.result[j],props.result[j].type)
                if ( props.result[j].type ==='image'){
                    console.log(layers[i].id.includes(props.result[j].name),layers[i].id,props.result[j].id,props.result[j].name)
                    if (layers[i].id.includes(props.result[j].name) && layers[i].id !== props.result[j].id && !layers[i].id.includes(props.result[j].name+"Text")){
                        console.log(layers[i])
                        props.map.current.removeLayer(layers[i].id)
                    }
                }
                console.log(props.user_id)
            }
        }
        console.log(layers)
    }, [props.map, props.result, props.data2, props.user_id]);

}