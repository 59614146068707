import React from 'react';
import { Button,Label,Dropdown,Input, Popup, Message, Grid, Table, Icon } from 'semantic-ui-react'
import i18n from "../i18n";
import '../Styles/Reserves.css'
import Plot from '../../node_modules/react-plotly.js/react-plotly';
import axios from "axios";
import ReservesSuccessProbability from "./ReservesSuccessProbability";
import {ReservesGraphPreview} from "./ReservesGraphPreview";
const config = require('../config');
const reservesApi = config.reservesApi;
const default_state = () => ({
    paramsGas: [
        {name: "s", name_en: "Areas", name_ru: "Площадь", popup: "Площадь", distribution: null, values: {}},
        {
            name: "hef",
            name_en: "Reservoir thickness",
            name_ru: "Эффективная мощность",
            popup: "Эффективная мощность",
            distribution: null,
            values: {}
        },
        {
            name: "poro",
            name_en: "Porosity",
            name_ru: "Пористость",
            popup: "Пористость",
            distribution: null,
            values: {}
        },
        {
            name: "Kг",
            name_en: "Gas saturation factor",
            name_ru: "Коэффициен газонасыщенности",
            popup: "Коэффициен газонасыщенности",
            distribution: null,
            values: {}
        },
        {
            name: "αн",
            name_en: "Correction for deviation from ideal gas (initial conditions)",
            name_ru: "Поправка на отклонение от идеального газа(начальные условия)",
            popup: "Поправка на отклонение от идеального газа(начальные условия)",
            distribution: null,
            values: {}
        },
        {
            name: "Рн",
            name_en: "Initial reservoir pressure",
            name_ru: "Начальное пластовое давление",
            popup: "Начальное пластовое давление",
            distribution: null,
            values: {}
        },
        {
            name: "αк",
            name_en: "Correction for deviation from ideal gas (final conditions)",
            name_ru: "Поправка на отклонение от идеального газа(конечные условия)",
            popup: "Поправка на отклонение от идеального газа(конечные условия)",
            distribution: null,
            values: {}
        },
        {
            name: "Рк",
            name_en: "Final reservoir pressure",
            name_ru: "Конечное пластовое давление",
            popup: "Конечное пластовое давление",
            distribution: null,
            values: {}
        },
        {
            name: "Рст",
            name_en: "Standard Pressure",
            name_ru: "Стандартное Давление",
            popup: "Стандартное Давление",
            distribution: null,
            values: {}
        },
        {
            name: "Тст",
            name_en: "Standard Temperature",
            name_ru: "Стандартная Температура",
            popup: "Стандартная Температура",
            distribution: null,
            values: {}
        },
        {
            name: "Тпл",
            name_en: "Initial Reservoir Temperature",
            name_ru: "Началльная Пластовая Температура",
            popup: "Началльная Пластовая Температура",
            distribution: null,
            values: {}
        },
    ],
    paramsGasDropdownBasic: [ "s", "hef", "poro", 'Kг', 'αн', 'Рн', 'αк', 'Рк', 'Тпл'],
    paramsGasDropdown: [ "s", "hef", "poro", 'Kг', 'αн', 'Рн', 'αк', 'Рк', 'Тпл'],
    ParamsGasList:{s:"Areas",hef:"Reservoir thickness",poro:"Porosity",Kг:"Gas saturation factor",αн:"Correction for deviation from ideal gas (initial conditions)",Рн:"Initial reservoir pressure",αк:"Correction for deviation from ideal gas (final conditions)",Рк:"Final reservoir pressure",Рст:"Standard Pressure",Тст:"Standard Temperature",Тпл:"Initial Reservoir Temperature"},
    paramsGasSelected: [],
    unitsGasList: ["М³"],
    unitsGas: null,
    submitError:false,
    probability:1,
});
class ReservesGas extends React.Component {
    constructor(props) {
        super(props);
        this.state = {...default_state()};
        this.onSend = this.onSend.bind(this);
        this.reset = this.reset.bind(this);
        this.probabilityChange = this.probabilityChange.bind(this)
    };
    probabilityChange (probability) {
        console.log(probability)
        this.setState({probability:probability})
    }
    onSend () {
        const { t } = this.props;
        console.log(this.state.samples,this.state.paramsGasSelected,this.state.paramsGas)
        let samples = this.state.samples;
        let formula = 's*hef*kper*Кг*(αн*Рн-αк*Pк)/Рст*Тст/Тпл';
        //"s", "hef", "Kper", 'Kг', 'αн', 'Рн', 'αк', 'Рк', 'Тепл'
        let formulaObj = {s:"1",hef:"1",poro:"1",Kг:"1",αн:"1",Рн:"1",αк:"1",Рк:"1",Рст:"0.10133",Тст:"293",Тпл:"1"}
        if (this.state.samples === undefined || this.state.samples === null || isNaN(this.state.samples)) {
            samples = 10000;
        }
        console.log(this.state.paramsGasSelected)
        for ( let i = 0; i < this.state.paramsGasSelected.length;i++){
            console.log(this.state.paramsGasSelected[i] )
            formulaObj[this.state.paramsGasSelected[i]] = this.state.paramsGasSelected[i]

            console.log(this.state.paramsGasSelected[i] ,formulaObj[this.state.paramsGasSelected[i]])
        }
        console.log(formulaObj);
        formula = formulaObj["s"]+"*"+formulaObj["hef"]+"*"+formulaObj["poro"]+"*"+formulaObj["Kг"]+"*("+formulaObj["αн"]+"*"+formulaObj["Рн"]+
            "-"+formulaObj["αк"]+"*"+formulaObj["Рк"]+")/"+formulaObj["Рст"]+"*"+formulaObj["Тст"]+"/"+formulaObj["Тпл"]

        console.log(formula);
        let newJsonFormat = {
            "name": "Model",
            "seed": null,
            "num_samples": samples,
            "config": {
                "region_name": {
                    "composition": "Composition",
                    "inputs": {},
                    "results": {
                    }
                }
            }
        }
        newJsonFormat.config.region_name.results[formula] = {"equation" : formula,"probability":this.state.probability,}
        let params = JSON.parse(JSON.stringify(this.state.paramsGas));
        for (let i = 0; i < params.length; i++) {
            console.log(params[i].distribution)
            if (params[i].distribution === null || params[i].distribution === undefined) {
                console.log(params[i])
                params.splice(i, 1);
                i--;
            }
            else {
                let valuesArray = Object.values(params[i].values)
                for (let j = 0; j < valuesArray.length; j++) {
                    if (valuesArray[j] === undefined || valuesArray[j] === null) {
                        this.setState({submitError:true,submitErrorMassage:"An fields is empty or has invalid value. Please correct and try again"})
                        return;
                    }
                }
                console.log(params[i].distribution,valuesArray[1] , valuesArray[0])
                if (params[i].distribution === 'uniform' || params[i].distribution === 'triangular') {
                    if (valuesArray[0] > valuesArray[1]){
                        this.setState({submitError:true,submitErrorMassage:"Right cant be less then Left. " + params[i].name_ru})
                        return;
                    }

                }
                if (params[i].distribution === 'beta'|| params[i].distribution === 'truncated normal'){
                    if (valuesArray[2] > valuesArray[3]){
                        this.setState({submitError:true,submitErrorMassage:"Right cant be less then Left. " + params[i].name_ru})
                        return;
                    }

                }
                if (params[i].distribution === 'normal'){
                    console.log(valuesArray[0],valuesArray[1],valuesArray[0]-(3*valuesArray[1]),(valuesArray[0]-(3*valuesArray[1]))>0)
                    if ((valuesArray[0]-(3*valuesArray[1]))<0){
                        this.setState({submitError:true,submitErrorMassage:"Error in params for normal distribution. " + params[i].name_ru})
                        return;
                    }

                }
                newJsonFormat.config.region_name.inputs[params[i].popup] = {
                    "variable":params[i].name,
                    "distribution": {
                        "name": params[i].distribution,
                        "params": params[i].values
                    }
                }
            }
        }
        console.log(params)
        let results = {data:[]};
        let graphics = [];
        let layout = {};
        let result_graph = [];
        let result_layout = {};
        let cumulative_data = [];
        let cumulative_layout = {};
        console.log(params)
        console.log(results)
        console.log(newJsonFormat)
        axios.post(reservesApi+'/getGraphData',newJsonFormat,{ headers: { "Content-Type": "application/json" } })
            .then(result => {
                console.log(result.data)
                console.log(result.data.region_name)
                console.log(Object.keys(result.data.region_name))
                let keys = Object.keys(result.data.region_name);
                let data = result.data.region_name;
                layout = {
                    height: 700,
                    width: 1200,
                    grid: {rows: Math.ceil(Math.sqrt(params.length+1)), columns: Math.round(Math.sqrt(params.length+1)), pattern: 'independent'},
                    yaxis: {
                        title: {
                            text: i18n.t('frequency'),
                            standoff: 0
                        }
                    },
                    xaxis:{
                        title: {
                            text:i18n.t(keys[0]),
                            standoff:8
                        }
                    }
                };
                result_layout = {
                    height: 700,
                    width: 1200,
                    yaxis: {
                        title: {
                            text: i18n.t('frequency'),
                            standoff: 0
                        }
                    },
                    xaxis:{
                        title: {
                            text:i18n.t('result')+ " : " +i18n.t(keys[keys.length-1])  + " " + this.state.unitsGas,
                            standoff:8
                        }
                    }
                };
                cumulative_layout  = {
                    height: 700,
                    width: 1200,
                    yaxis: {
                        title: {
                            text: i18n.t('frequency'),
                            standoff: 0
                        }
                    },
                    xaxis:{
                        title: {
                            text:i18n.t('result')+ " : " +i18n.t(keys[keys.length-1])  + " " + this.state.unitsGas,
                            standoff:8
                        }
                    }
                };
                for (let i = 0 ; i<keys.length-1; i++){
                    console.log(data[keys[i]].values,keys[i])
                    let trace1 = {
                        x: data[keys[i]].values,
                        xaxis: "x"+(i+1),
                        yaxis: "y"+(i+1),
                        type: "histogram",
                        histnorm: "probability density",
                        opacity: 0.8,
                        name:i18n.t(keys[i]),
                        marker: {
                            color: this.props.colors[i],
                        },
                    };
                    graphics.push(trace1);
                    if (i>0){
                        layout['yaxis'+(i+1)] = {
                            title:{
                                text:i18n.t('frequency'),
                                standoff:0
                            }
                        }
                        layout['xaxis'+(i+1)] ={
                            title: {
                                text:i18n.t(keys[i]),
                                standoff:8
                            }
                        }
                    }
                }
                let trace1 = {
                    x: data[keys[keys.length-1]].values,
                    xaxis: "x",
                    yaxis: "y",
                    type: "histogram",
                    histnorm: "probability density",
                    opacity: 0.8,
                    name:i18n.t(keys[keys.length-1]),
                    marker: {
                        color: this.props.colors[keys.length-1],
                    },
                };
                result_graph.push(trace1)
                let trace2 = {
                    x: data[keys[keys.length-1]].values,
                    xaxis: "x",
                    yaxis: "y",
                    type: "histogram",
                    histnorm: "probability density",
                    cumulative: { enabled: true, direction: "decreasing" },
                    opacity: 0.8,
                    name:i18n.t(keys[keys.length-1]),
                    marker: {
                        color: this.props.colors[keys.length],
                    },
                };
                let trace2P10dot = {
                    x: [Math.min.apply(Math, data[keys[keys.length-1]].values),data[keys[keys.length-1]].stats.P10[0],data[keys[keys.length-1]].stats.P10[0]],
                    y: [data[keys[keys.length-1]].stats.P10[1]/100,data[keys[keys.length-1]].stats.P10[1]/100,0],
                    mode: 'lines',
                    name: 'P10',
                    line: {
                        color: 'rgb(0,0,0)',
                        dash: 'dot',
                        width: 2
                    }
                };
                let trace2P50dot = {
                    x: [Math.min.apply(Math, data[keys[keys.length-1]].values),data[keys[keys.length-1]].stats.P50[0],data[keys[keys.length-1]].stats.P50[0]],
                    y: [data[keys[keys.length-1]].stats.P50[1]/100,data[keys[keys.length-1]].stats.P50[1]/100,0],
                    mode: 'lines',
                    name: 'P50',
                    line: {
                        color: 'rgb(0,0,0)',
                        dash: 'dot',
                        width: 2
                    }
                };
                let trace2P90dot = {
                    x: [Math.min.apply(Math, data[keys[keys.length-1]].values),data[keys[keys.length-1]].stats.P90[0],data[keys[keys.length-1]].stats.P90[0]],
                    y: [data[keys[keys.length-1]].stats.P90[1]/100,data[keys[keys.length-1]].stats.P90[1]/100,0],
                    mode: 'lines',
                    name: 'P90',
                    line: {
                        color: 'rgb(0,0,0)',
                        dash: 'dot',
                        width: 2
                    }
                };
                var trace3 = {
                    x: [data[keys[keys.length-1]].stats.P10[0], data[keys[keys.length-1]].stats.P50[0], data[keys[keys.length-1]].stats.P90[0]],
                    y: [data[keys[keys.length-1]].stats.P10[1]/100, data[keys[keys.length-1]].stats.P50[1]/100, data[keys[keys.length-1]].stats.P90[1]/100],
                    mode: 'markers+text',
                    type: 'scatter',
                    name: 'markers P10,P50,P90',
                    text: ['P10 '+parseFloat(data[keys[keys.length-1]].stats.P10[0]).toFixed(2), 'P50 '+parseFloat(data[keys[keys.length-1]].stats.P50[0]).toFixed(2), 'P90 '+parseFloat(data[keys[keys.length-1]].stats.P90[0]).toFixed(2)],
                    textposition: 'top center',
                    textfont: {
                        family:  'Raleway, sans-serif'
                    },
                    marker: { size: 12 }
                };
                cumulative_data.push(trace2,trace2P10dot,trace2P50dot,trace2P90dot,trace3)
                this.props.onSetData(graphics,layout,result_graph,result_layout,cumulative_data,cumulative_layout)
                //this.setState({ graphData:graphics, graphLayout:layout, graphResultData:result_graph, graphResultLayout:result_layout,graphResultCumulativeData:cumulative_data,
                //    graphResultCumulativeLayout:cumulative_layout, submitError:false, submitErrorMassage:null,})
            })
            .catch(err => {console.log(err)})

    };
    reset () {
        this.props.resetGraph()
        this.setState({...default_state()})
        console.log(this.state)
    };
    onDropdownChange (option, value, name) {
        let params = JSON.parse(JSON.stringify(this.state.paramsGas));
        let dist ;
        let index = 0;
        let list ={};
        for (let i = 0; i < params.length; i++) {
            if (params[i].name === name) {
                params[i].distribution = value.value;
                dist = value.value;
                index = i;
            }
        }
        console.log(index,dist,this.props.distribution)
        for (let i = 0 ; i < this.props.distribution.length;i++){
            console.log(dist,this.props.distribution[i].name)
            if (dist === this.props.distribution[i].name){
                let values = this.props.distribution[i].values;
                for (let j = 0; j < values.length;j++){
                    list[values[j]] = null;
                }
                params[index].values = list;
            }
        }
        console.log(params,list)
        this.setState({paramsGas:params})
    };
    paramsDelete (value){
        console.log(value)
        let paramsToSelect = this.state.paramsGasDropdown;
        let selectedParams = this.state.paramsGasSelected;
        //["GRV", "NPV", "HCPV", "s", "h", "ntg", "hef", "poro", "sw", "fvf", "ef", "geomf", "top_depth", "bot_depth", "owc", "gwc", "dens", "Kper"],
        paramsToSelect.push(value);
        selectedParams.splice(selectedParams.indexOf(value),1);
        this.setState({paramsGasDropdown: paramsToSelect, paramsGasSelected: selectedParams})
    };
    paramsSelection (value){
        console.log(value)
        let paramsToSelect = this.state.paramsGasDropdown;
        let selectedParams = this.state.paramsGasSelected;
        //["GRV", "NPV", "HCPV", "s", "h", "ntg", "hef", "poro", "sw", "fvf", "ef", "geomf", "top_depth", "bot_depth", "owc", "gwc", "dens", "Kper"],
        paramsToSelect.splice(paramsToSelect.indexOf(value),1);
        selectedParams.push(value);
        this.setState({paramsGasDropdown: paramsToSelect, paramsGasSelected: selectedParams})
    };
    onUnitsSelect (value) {
        //["Баррель", "Тонны", "М³"]
        let selectedParams = this.state.paramsGasSelected;
        switch (value) {
            case "Баррель":
                if (selectedParams.includes('dens'))
                    selectedParams.splice(selectedParams.indexOf("dens"),1);
                break;
            case "Тонны":
                selectedParams.push("dens");
                break;
            case "М³":
                if (selectedParams.includes('dens'))
                    selectedParams.splice(selectedParams.indexOf("dens"),1);
                break;
        }
        this.setState({unitsGas:value})
    };
    render() {
        let samplesError = false;
        if(this.state.submitError&&(this.state.samples === undefined || this.state.samples ===null))
            samplesError = true;
        return (
            <>
                <Dropdown className={"size75dropdown"}
                          placeholder={"Select units"}
                          selection
                          onChange={(event,options)=>{console.log(options.value);this.onUnitsSelect(options.value)}}
                          options= {this.state.unitsGasList.map(data => ({key: data, text:data, value: data}))} />
                {(this.state.unitsGas !== null )&&(
                    <>
                        <br/>
                        <Dropdown className={"size75dropdown"}
                                  placeholder={"Select params"}
                                  selection
                                  onChange={(event,options)=>{this.paramsSelection(options.value)}}
                                  options= {this.state.paramsGasDropdown.map(data => ({key: data, text:i18n.t(this.state.ParamsGasList[data]), value: data}))} />
                    </>
                )}
                {((this.state.paramsGasSelected.length > 0 && this.state.unitsGas !== "Тонны") || (this.state.paramsGasSelected.length > 1))&&(
                    <>
                        {this.state.paramsGas.map(({name,name_en,name_ru,popup,distribution,values},index)=>{
                            if (this.state.paramsGasSelected.includes(name))
                                return (
                                    <>
                                        <div >
                                            <Popup className={"popup"} content={name} trigger={<Label className={"size75label3"} >{i18n.t(name_en)}</Label>}/>
                                            <Dropdown
                                                className={"size75dropdown"}
                                                placeholder={'Select Distribution'}
                                                selection
                                                onChange={(option,value)=>{this.onDropdownChange(option,value,name)}}
                                                options= {this.props.distributionDropdown.map(data => ({key: data, text:i18n.t(data), value: data}))}
                                            />
                                            <div className={"size75div"}>
                                                {Object.keys(values).map((key,value) => {
                                                    let error = false;
                                                    if (this.state.submitError && ( values[key] === undefined || values[key] === null || isNaN(values[key])))
                                                        error = true;
                                                    return (
                                                        <div className={"size75div2"}><Label className={"size75label2"}>{key}</Label><Input key={name+key} id={name+key} className={"size75input"} value={this.state.paramsGas[index].values[key]} error={error} min="0" type={"number"} onChange={(e,input)=>{
                                                            let params = this.state.paramsGas;
                                                            if ( Number.parseFloat(input.value)>=0 || input.value === ''){
                                                                if (Number.parseFloat(input.value)>=1 &&(name==="ntg"||name==="poro"||name==="sw"||name==="geomf"||name==="den"||name==="ef") &&(key==='left'||key==='right'||key==='mode'||key==='mean')){
                                                                    document.getElementById(name+key).value = 1;
                                                                    params[index].values[key] = 1;
                                                                    this.setState({params: params})
                                                                }
                                                                else{
                                                                    params[index].values[key] = Number.parseFloat(input.value.replace('-', ''));
                                                                    this.setState({params: params})
                                                                }
                                                            }else
                                                                document.getElementById(name+key).value = document.getElementById(name+key).value.replace('-','') ;
                                                        }} />

                                                        </div>
                                                    )
                                                })}
                                                <Icon name={"eye"} onMouseEnter={(e)=>{
                                                    console.log("onMouseEnter",e.clientX,e.clientY)
                                                    this.props.setPreview(false,values,name,distribution,popup,e.clientX,e.clientY)
                                                }} onMouseLeave={(e)=>{console.log("onMouseLeave")
                                                    this.props.setPreview(true)
                                                }} />
                                            </div>
                                            {(name !== 'dens')&&(<Icon onClick={()=>this.paramsDelete(name)} bordered inverted color='red' name='trash' />)}
                                        </div>

                                    </>
                                )
                        })}
                    </>
                )}
                {(this.state.submitError)&&(<><Message size={"small"} compact negative>
                    <Message.Header>{"Error"}</Message.Header>
                    <p>{this.state.submitErrorMassage}</p>
                </Message><br/></>)}
                <ReservesSuccessProbability setProbability={this.probabilityChange} display={"block"}/>
                <div className={"size75div3"}>
                    <Grid>
                        <Grid.Column width={8}>
                            <Table className={"ui very basic table"}>
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell collapsing>
                                            <Label className={"size75label2"}>
                                                {i18n.t("Samples")}
                                            </Label>
                                            <Input className={"size75input"} error={samplesError} type={"number"} min="0" value={this.state.samples}  onChange={(e,input)=>{
                                                if (Number.parseFloat(input.value)>=0  || input.value === '')
                                                    this.setState({samples:Number.parseFloat(input.value)})
                                            }} style={{width:"100px"}} />
                                            <Button className={"size75Button"} onClick={this.onSend}>
                                                {i18n.t("Send")}
                                            </Button>
                                            <Button className={"size75Button"} onClick={this.reset}>
                                                {i18n.t("Reset")}
                                            </Button>
                                        </Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        </Grid.Column>
                    </Grid>
                </div>
            </>
        )
    }
}
export default ReservesGas;