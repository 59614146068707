import React from "react";
import {LoginDisplay} from "./appMenu";
import { Button,Input,Label } from 'semantic-ui-react'
import axios from "axios";
import Cookies from 'js-cookie'
var config = require('../config');
const HOST2 = config.HOSTBackend;
const PORT = config.PORTBackend;
const HTTP = config.ProtocolBackend;
const PREFIX = config.PrefixBackend;
var urlBackend=HTTP+'://'+HOST2+':'+PORT+PREFIX;
if (PORT === "" || PORT === undefined){
    urlBackend=HTTP+'://'+HOST2+PORT;
}
else{
    urlBackend=HTTP+'://'+HOST2+':'+PORT+PREFIX;
}
const authURL = urlBackend+'/auth';
const checkURL = urlBackend+'/auth-check';
const resetURL = config.resetURL;
const regURL = config.regURL;

export default function FormPage(props) {
    const [login,setLogin]=React.useState(null);
    const [register,setRegister]=React.useState(null);
    const [detail,setDetail]=React.useState(null);
    const [disable,setDisable]=React.useState(1);
    const [disableIF,setDisableIF]=React.useState(false);
    const [color,setColor] =React.useState("rgba(255, 255, 255, 1)")
    const handleSubmit = (event) => {
        setDetail(null);
        event.preventDefault();
        setDisable(0.5);
        setColor("rgb(172,172,172)");
        setDisableIF(true)
        /*axios.get(authURL,{
            headers: { 'Access-Control-Allow-Origin':'*','Authorization':'Basic '+ Base64.encode(data) }})
            .then((res) => {
                console.log(res);
                if (res.data.id){
                    Cookies.set("id",res.data.id);
                    Cookies.set("username",res.data.username);
                    Cookies.set("email",res.data.email);
                    setDisableIF(false)
                    setDisable(1);
                    setColor("rgba(255, 255, 255, 1)");
                    LoginDisplay('none');
                    props.refresh();
                    }
                else if (res.data.detail) {
                    setDetail(res.data.detail)
                    setDisableIF(false)
                    setDisable(1);
                    setColor("rgba(255, 255, 255, 1)");
                }
                else {
                    setDetail("Login Server Error")
                    setDisableIF(false)
                    setDisable(1);
                    setColor("rgba(255, 255, 255, 1)");
                }

            }).catch((error) => {
            console.log(error)
            setDetail("Login Server Error")
            setDisableIF(false)
            setDisable(1);
            setColor("rgba(255, 255, 255, 1)");
        });*/
                axios.post(authURL, {
                    "username": login, "password":register
                }).then((response) => {
                        if (response.data.message === undefined) {
                            Cookies.set('token', response.data[0], {expires: 365})
                            Cookies.set('session', response.data[1], {expires: 14})
                            axios.post(checkURL, {
                                Cookie: "" + Cookies.get('token') + "; " + Cookies.get('session') + ";"
                            }).then((response) => {
                                console.log(response.data);
                                if (response.data.user_id) {
                                    axios.post(urlBackend+'/postQuery', {query:{type:"get_Groups_Permission",select_type:"get_Groups_Permission_Without_Object",select_params:{user_id:response.data.user_id}}})
                                        .then((res) => {
                                            let groupArray = [];
                                            for (let i = 0;i< res.data[0].get_permission_groups.length;i++){
                                                groupArray.push(res.data[0].get_permission_groups[i].name);
                                            }
                                            Cookies.remove("groups");
                                            Cookies.set("groups", groupArray);
                                            Cookies.set("id", response.data.user_id);
                                            Cookies.set("username", response.data.user);
                                            Cookies.set("role", response.data.role);
                                            setDisableIF(false)
                                            setDisable(1);
                                            setColor("rgba(255, 255, 255, 1)");
                                            props.close()
                                            props.refresh();
                                        }).catch((error) => {
                                        console.log(error)
                                    });
                                } else if (response.data.detail) {
                                    setDetail(response.data.detail)
                                    setDisableIF(false)
                                    setDisable(1);
                                    setColor("rgba(255, 255, 255, 1)");
                                } else {
                                    setDetail("Login Server Error")
                                    setDisableIF(false)
                                    setDisable(1);
                                    setColor("rgba(255, 255, 255, 1)");
                                }
                            }).catch((error) => {
                                console.log(error)
                                setDetail("Login Server Error")
                                setDisableIF(false)
                                setDisable(1);
                                setColor("rgba(255, 255, 255, 1)");
                            });
                        }
                        else{
                            setDetail(response.data.message)
                            setDisableIF(false)
                            setDisable(1);
                            setColor("rgba(255, 255, 255, 1)");
                        }
                }).catch((error) => {
                    console.log(error)
                    setDetail("Login Server Error")
                    setDisableIF(false)
                    setDisable(1);
                    setColor("rgba(255, 255, 255, 1)");
                });
        if (Cookies.get("id")!==undefined)
            props.handleSingin();
    };
    const handleChangeLogin = (event) => {
        setLogin(event.target.value);
    };
    const handleChangePassword = (event) => {
        setRegister(event.target.value)
    };
    const handleClose = () => {
        props.close()
    };
    return (
        <div className="login" style={{display:props.display ,opacity: disable, backgroundColor: color}}>
            <Button basic size={'mini'} onClick={props.close} onClickCapture={props.refresh}  icon><i className="close icon"/></Button>
                    <form>
                        <p className="h5 text-center mb-4">Sign in</p>
                        {(detail!==null) && (
                            <p className="h5 text-center mb-4">{detail}</p>
                        )}
                        <div className="grey-text">
                            <Label>{"Type your login"}</Label>
                            <Input name={"email"} loading={disableIF} value={login} autoComplete="username" icon="envelope" group type="email" validate error="wrong"
                                      success="right" onChange={handleChangeLogin} />
                            <Label>{"Type your password"}</Label>
                            <Input name={"password"} loading={disableIF} autoComplete="current-password" value={register} icon="lock" group type="password" validate onChange={handleChangePassword}/>
                            <a target="_blank" rel="noopener noreferrer" href={resetURL}>Reset password</a>
                            <br/>
                            <a target="_blank" rel="noopener noreferrer" href={regURL}>Register</a>
                        </div>
                        <div className="text-center">
                            <Button type="submit" value="Submit" color={"blue"} onClick={handleSubmit} disabled={disableIF} onClickCapture={props.handleSingin}>Login</Button>
                        </div>
                    </form>
        </div>
    );
};

