import React, {Component} from "react";
import {Button} from "semantic-ui-react";

class LayerDelete extends Component {
    constructor (props, context) {
        super(props, context);
        this.state = {
            value: 0.5,
            id:this.props.value,
        }

    }


    handleChange = (value) => {
        this.setState({
            value: value
        });
        console.log(value)
        this.props.delete(this.props.value);
        // for (let i = 0 ; i < LayerOptions.length;i++){
        //    if (LayerOptions[i].name === this.state.id) {
        //        LayerOptions.splice(i, 1); i--;
        //    }
        //}
        //console.log(result,dataname);
        this.props.handle();
    };

    render () {
        const { value } = this.state;
        return (
            <div>
                <Button circular size={'mini'} basic icon={'trash'} onClickCapture={this.props.handler} onClick={this.handleChange}/>
            </div>
        )
    }
}
export default LayerDelete;