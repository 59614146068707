import React, { Component } from 'react'
import { Form, Checkbox } from 'semantic-ui-react'

export default class FilterCheckBoxGroup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: null,
            display:"none"
        }
    }
    handleChange = (e, { value }) => {
        this.setState({ value })
        this.props.handler(value,this.props.name,this.props.id)

    };
    handleIcon = (e, { value }) => {
        if (this.state.display === "none")
            this.setState({display:"block"})
        else
            this.setState({display:"none"})
    };
    render() {
        return (
                <div style={{display:this.props.display}}>
                <Form.Group grouped>
                    <Form.Field style={{height:"20px"}}>
                        <Checkbox
                            radio
                            label='None'
                            name='checkboxRadioGroup'
                            value={null}
                            checked={this.state.value === null}
                            onChange={this.handleChange}
                        />
                    </Form.Field>
                    <Form.Field style={{height:"20px"}}>
                        <Checkbox
                            radio
                            label='Not Null'
                            name='checkboxRadioGroup'
                            value={true}
                            checked={this.state.value === true}
                            onChange={this.handleChange}
                        />
                    </Form.Field>
                    <Form.Field style={{height:"20px"}}>
                        <Checkbox
                            radio
                            label='Null'
                            name='checkboxRadioGroup'
                            value={false}
                            checked={this.state.value === false}
                            onChange={this.handleChange}
                        />
                    </Form.Field>
                </Form.Group>
                </div>
        )
    }
}
