import React from 'react';
import {  Button } from 'semantic-ui-react'
import ScrollContainer from "react-indiana-drag-scroll";
import axios from "axios";
var config = require('../config');
const hostBackend = config.HOSTBackend;
const portBackend = config.PORTBackend;
const protocolBackend = config.ProtocolBackend;
const prefixBackend = config.PrefixBackend;
var urlBackend=protocolBackend+'://'+hostBackend+':'+portBackend+prefixBackend;
if (portBackend === "" || portBackend === undefined){
    urlBackend=protocolBackend+'://'+hostBackend+prefixBackend;
}
else{
    urlBackend=protocolBackend+'://'+hostBackend+':'+portBackend+prefixBackend;
}

class FilterItemList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filterListDragging:false,
            filterListY:300,
            filterListButtonX:"",
            filterListButtonY:"",
            AnchorPoint:{ x: 0, y: 0 },
            show:false,
            item:null
        }
    };
    inputChange=(e,value)=>{
        this.setState({input:value.value})
    };
    onRowClick=(item)=>{
        this.setState({show:false})
        let id = item.qc_id;
        let layer = item.layer;
        let layerID = '';
        for (let h = 0 ; h < this.props.result.length; h++){
            if (this.props.result[h].name.toLowerCase() === layer.toLowerCase()){
                layerID = this.props.result[h].id;
            }
        }
        let quary = 'select user_id as user,(select description from '+layer+'_infos where '+layer+'_id = '+id+' and view_state IS null limit 1) as desc, original_name as OriginalName,'+layer+'_id as qc_id,\''+layer+'\' as sourcetable,\''+layerID+'\' as sourceid, ST_AsGeoJSON(wkb_geometry) as geom from '+ layer+
            ' where '+layer+'_id='+id;
        axios.post(urlBackend+'/layerid', {query:quary})
            .then((res) => {
                res.data[0].coordinates = JSON.parse(res.data[0].geom).coordinates;
                res.data[0].id = res.data[0].qc_id;
                res.data[0].source = res.data[0].sourceid;
                res.data[0].geometry = JSON.parse(res.data[0].geom);
                res.data[0].type = res.data[0].geometry.type;
                res.data[0].sourceLayer = 'public.' + res.data[0].sourcetable;
                let proxy = {};
                proxy.features = [res.data[0]];
                proxy.point = {x:500,y:250};
                proxy.features[0].properties= {OriginalName:res.data[0].originalname};
                this.props.onRowClick(proxy);
            }).catch((error) => {
            console.log(error)
        });
    };
    listener=(e)=> {
        console.log(e,this.state);
        if (this.state.filterListDragging) {
            const pixelDifference = this.state.filterListButtonY - e.screenY;
            this.setState({filterListY: this.state.filterListY + pixelDifference, filterListButtonY: e.screenY})
        }
    }
    render() {
    console.log('resize')
        return(
            <div id="filterItemList" className="filterItemList" style = {{display:this.props.display,height:this.state.filterListY+"px"}} onMouseUpCapture={(e) => {

            }}
                 onMouseUp={(e) => {
                     e.persist();
                     if (this.state.filterListDragging){
                         const main = document.getElementById('main');
                         main.removeEventListener('mouseup',this.listener);
                         this.setState({filterListDragging:false})
                     }
                     }}>
                {"Properties Table of Displayed Features filtered " + this.props.filterPropsList.length +" lines"}<br/>
                <div style={{display:"inline"}}>
                    <Button className={"cheb"} onMouseDown={(e) => {
                        const main = document.getElementById('main');
                        main.addEventListener('mouseup',this.listener);
                        e.persist();
                        this.setState({filterListDragging:true,filterListButtonX:e.screenX,filterListButtonY :e.screenY})
                    }
                    } icon="arrows alternate vertical" basic size="mini"/>
                    <div style={{float: 'right'}}>
                    <Button className={"cheb"} onClick={(e) => {
                        this.props.close();
                    }
                    } icon="close" basic size="mini"/>
                    </div>
                </div>
                <ScrollContainer className="scroll-container">

                    <table>
                        {this.state.show ? (
                            <ul
                                className="contextmenu"
                                style={{
                                    top: this.state.AnchorPoint.y,
                                    left: this.state.AnchorPoint.x
                                }}
                            >
                                <li onClick={()=>this.onRowClick(this.state.item)} style={{cursor:'pointer'}}>Open Infos</li>
                                <hr className="divider" />
                                <li onClick={()=>this.setState({show:false})} style={{cursor:'pointer'}}>Exit</li>
                            </ul>
                        ) : (
                            <> </>
                        )}
                        <tr key={"header"}>
                            {Object.keys(this.props.filterPropsList[0]).map((key) => {
                                if ( key !== 'qc_id'|| key === 'layer' )
                                return(
                                    <th className={"th-Stick"}>{key}</th>
                                )
                            })}
                        </tr>
                        {this.props.filterPropsList.map((item) => {

                            return(
                            <tr
                                onContextMenu={(e)=> {
                                    e.persist();
                                    e.preventDefault()
                                    this.setState({AnchorPoint:{ x: e.pageX, y: e.pageY },show:true,item:item});
                                }}
                                 key={item.id}>

                                {Object.entries(item).map(([key,val]) => {
                                        if ( key === 'qc_id'   ){
                                            return ;
                                        }
                                        else if (val.length >=100)
                                            return(
                                                <td><div style={{overflow: "scroll",maxHeight: "100px",maxWidth: "100px"}}>{val}</div></td>
                                            )
                                        else
                                            return(
                                                <td><div style={{maxHeight: "100px",maxWidth: "100px"}}>{val}</div></td>
                                            )
                                    }
                                )}
                            </tr>
                        )})}
                    </table>
                </ScrollContainer>
            </div>
        )}
}
export default FilterItemList;
