import React from "react";
import {Button,Icon,Label,Form} from "semantic-ui-react";
import Cookies from 'js-cookie';
import {BrowserView, MobileView} from "react-device-detect";
class UploadLas extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            file: null,
            filename:null,
        };
    }
    fileInputRef = React.createRef();
    fileChange = e => {
        this.setState({ file: e.target.files[0],filename:e.target.files[0].name }, () => {
            console.log("File chosen --->", this.state.file);
        });
    };
    fileUpload = file => {
        const formData = new FormData();
        formData.append("file", file);
        console.log(file)
        this.props.getFile(formData);
    };
    render() {
        /**/
        let disabled = true
        if (this.state.file !== null)
            disabled = false
        return(

            <div >
                <Form onSubmit={this.onFormSubmit}>
                    <Form.Field>
                        <Button
                            content="Choose File"
                            labelPosition="left"
                            icon="file"
                            onClick={() => this.fileInputRef.current.click()}
                        />
                        {(this.state.filename !== null) && (
                            <>
                                <br/>
                                <Label>Выбранный файл : {this.state.filename}</Label>
                            </>
                        )}
                        <input
                            ref={this.fileInputRef}
                            type="file"
                            hidden
                            onChange={this.fileChange}
                        />
                    </Form.Field>
                    <Button disabled={disabled} onClick={()=>this.fileUpload(this.state.file)} type="submit">Upload</Button>
                </Form>
            </div>


        )}}
export default UploadLas;
