import React from "react";
import {Button} from "semantic-ui-react";

class LayerButtonFilter extends React.Component {
    state = {
        title:this.props.title,
        active:false
    };
    handlerClick = () => {
        this.props.setName(this.props.title);
        this.props.handler(this.props.title)
    };
    render() {
        //
        return (
            <Button circular size={'mini'} icon={{ className: 'user-filter' }} basic onClickCapture={this.handlerClick} />
        )};
}
export default LayerButtonFilter;