import React, {Component} from "react";
import {Button} from "semantic-ui-react";

class LayerGeoTiffToDB extends Component {
    constructor (props, context) {
        super(props, context);
        this.state = {
            error:""
        }
    }
    handleChange = () => {
        for ( let i = 0 ;i < this.props.result.length;i++) {
            if (this.props.result[i].name === this.props.value && this.props.result[i].type === 'image') {
                if (!this.props.result[i].loaded){
                    this.props.result[i].loaded = true;
                    this.props.loader(this.props.value,false);
                }
                else
                    this.props.loader(this.props.value,true);
            }
        }
    };
    render () {
        return (
            <div className='slider-horizontal'>
                {this.state.error}
                <br/>
                <Button onClick={this.handleChange}>{"Load GeoTiff"}</Button>
            </div>
        )
    }
}
export default LayerGeoTiffToDB;