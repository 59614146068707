
import React from "react";
import Slide from "@mui/material/Slide";

export function arrayRemove(arr, value) {
    return arr.filter(
        function(ele){
            return ele.name !== value;
        }
    );
}
export const MathMean = (array) =>{
    const sum = array.reduce((a, b) => Number.parseFloat(a) + Number.parseFloat(b), 0);
    const avg = (sum / array.length) || 0;
    return avg;
}
export const stringToColour = (str) => {
    let hash = 0;
    str.split('').forEach(char => {
        hash = char.charCodeAt(0) + ((hash << 5) - hash)
    })
    let colour = '#'
    for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xff
        colour += value.toString(16).padStart(2, '0')
    }
    return colour.toUpperCase()
}
export function compareMax( a, b ) {
    if ( a.max > b.max ){
        return -1;
    }
    if ( a.max < b.max ){
        return 1;
    }
    return 0;
}
export function compareMaxReverse( a, b ) {
    if ( a.max < b.max ){
        return -1;
    }
    if ( a.max > b.max ){
        return 1;
    }
    return 0;
}
export function rainbow(numOfSteps, step) {
    // This function generates vibrant, "evenly spaced" colours (i.e. no clustering). This is ideal for creating easily distinguishable vibrant markers in Google Maps and other apps.
    // Adam Cole, 2011-Sept-14
    // HSV to RBG adapted from: http://mjijackson.com/2008/02/rgb-to-hsl-and-rgb-to-hsv-color-model-conversion-algorithms-in-javascript
    var r, g, b;
    var h = step / numOfSteps;
    var i = ~~(h * 6);
    var f = h * 6 - i;
    var q = 1 - f;
    switch(i % 6){
        case 0: r = 1; g = f; b = 0; break;
        case 1: r = q; g = 1; b = 0; break;
        case 2: r = 0; g = 1; b = f; break;
        case 3: r = 0; g = q; b = 1; break;
        case 4: r = f; g = 0; b = 1; break;
        case 5: r = 1; g = 0; b = q; break;
    }
    var c = "#" + ("00" + (~ ~(r * 255)).toString(16)).slice(-2) + ("00" + (~ ~(g * 255)).toString(16)).slice(-2) + ("00" + (~ ~(b * 255)).toString(16)).slice(-2);
    return (c);
}
export function shuffle(array) {
    let currentIndex = array.length,  randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex != 0) {

        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
    }

    return array;
}
export function getUserAgent  () {
    const { userAgent } = navigator
    let match = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []
    let temp

    if (/trident/i.test(match[1])) {
        temp = /\brv[ :]+(\d+)/g.exec(userAgent) || []

        return `IE ${temp[1] || ''}`
    }

    if (match[1] === 'Chrome') {
        temp = userAgent.match(/\b(OPR|Edge)\/(\d+)/)

        if (temp !== null) {
            return temp.slice(1).join(' ').replace('OPR', 'Opera')
        }

        temp = userAgent.match(/\b(Edg)\/(\d+)/)

        if (temp !== null) {
            return temp.slice(1).join(' ').replace('Edg', 'Edge (Chromium)')
        }
    }

    match = match[2] ? [ match[1], match[2] ] : [ navigator.appName, navigator.appVersion, '-?' ]
    temp = userAgent.match(/version\/(\d+)/i)

    if (temp !== null) {
        match.splice(1, 1, temp[1])
    }

    return match.join(' ')
}


export function getIntervalls(min,max, nInt) {
    const c = Math.floor((max-min) / nInt);
    const r = [];
    for (var i = min; i <= max; i += c) {
        const a = i == 0 ? i : i += 1;
        const b = i + c > max ? max : i + c;
        if (a < max) r.push([a, b])
    }
    return r;
}


export const meters2degress = function(x,y) {
    let lon = x *  180 / 20037508.34 ;
    let lat = Math.atan(Math.exp(y * Math.PI / 20037508.34)) * 360 / Math.PI - 90;
    return [lon, lat]
};


export function normalize(string) {
    console.log(string)
    if (string.toString() !== undefined)
        return string.toString().trim().toLowerCase();
}

export function ArrayToProbability (array,nulls, pin_count = 20) {
    if (!nulls){
        let min_value = Math.min(...array);
        let max_value = Math.max(...array) + 0.1;
        let value_diff = max_value - min_value;
        let pin = value_diff / pin_count
        let pin_arrays = []
        let pin_start = min_value;
        let pin_end = min_value + pin;
        let probability_array = []
        let pins_array = []
        let x_array = []
        for (let i = 0 ; i < pin_count;i++){
            let pin_array = []
            for ( let j = 0 ; j < array.length ; j++){
                if ( array[j] >= pin_start && array[j] < pin_end){
                    pin_array.push(array[j])
                }
            }
            pin_arrays.push(pin_array)
            pins_array.push([pin_start,pin_end])
            x_array.push(pin_start)
            pin_start += pin;
            pin_end += pin;

        }
        for ( let i = 0 ; i < pin_arrays.length;i++){
            probability_array.push(pin_arrays[i].length/(array.length/100)/100)
        }
        console.log(pin_arrays,min_value,max_value,pin,probability_array,pins_array)
        return {width:pin, x:x_array, y:probability_array}
    }else {
        let nullArray = [];
        let valueArray = [];
        for ( let j = 0 ; j < array.length;j++){
            if ( array[j] === 0 ){
                nullArray.push(array[j])
            }else{
                valueArray.push(array[j])
            }
        }
        let min_value = Math.min(...valueArray);
        let max_value = Math.max(...valueArray) + 0.1;
        let value_diff = max_value - min_value;
        let pin = value_diff / pin_count
        let pin_arrays = []
        let pin_start = min_value;
        let pin_end = min_value + pin;
        let probability_array = []
        let pins_array = []
        let x_array = []
        pin_arrays.push(nullArray)
        pins_array.push([0,0])
        for (let i = 0 ; i < pin_count;i++){
            let pin_array = []
            for ( let j = 1 ; j < valueArray.length ; j++){
                if ( valueArray[j] >= pin_start && valueArray[j] < pin_end){
                    pin_array.push(valueArray[j])
                }
            }
            pin_arrays.push(pin_array)
            pins_array.push([pin_start,pin_end])
            x_array.push(pin_start)
            pin_start += pin;
            pin_end += pin;

        }
        for ( let i = 0 ; i < pin_arrays.length;i++){
            probability_array.push(pin_arrays[i].length/(array.length/100)/100)
        }
        console.log(pin_arrays,min_value,max_value,pin,probability_array,pins_array)
        return {width:pin,x:x_array, y:probability_array}
    }

}
export function getUniqueFeatures(array, comparatorProperty) {
    var existingFeatureKeys = {};
    // Because features come from tiled vector data, feature geometries may be split
    // or duplicated across tile boundaries and, as a result, features may appear
    // multiple times in query results.
    var uniqueFeatures = array.filter(function(el) {
        if (existingFeatureKeys[el.properties[comparatorProperty]]) {
            return false;
        } else {
            existingFeatureKeys[el.properties[comparatorProperty]] = true;
            return true;
        }
    })

    return uniqueFeatures;
}



export function contains(a, obj) {
    for (var i = 0; i < a.length; i++) {
        if (a[i].id === obj.id) {
            return i;
        }
    }
    return false;
}


export function linearRegression(x,y){
    var lr = {};
    var n = y.length;
    var sum_x = 0;
    var sum_y = 0;
    var sum_xy = 0;
    var sum_xx = 0;
    var sum_yy = 0;

    for (var i = 0; i < y.length; i++) {

        sum_x += x[i];
        sum_y += y[i];
        sum_xy += (x[i]*y[i]);
        sum_xx += (x[i]*x[i]);
        sum_yy += (y[i]*y[i]);
    }

    lr['sl'] = (n * sum_xy - sum_x * sum_y) / (n*sum_xx - sum_x * sum_x);
    lr['off'] = (sum_y - lr.sl * sum_x)/n;
    lr['r2'] = Math.pow((n*sum_xy - sum_x*sum_y)/Math.sqrt((n*sum_xx-sum_x*sum_x)*(n*sum_yy-sum_y*sum_y)),2);

    return lr;
}

export function convertRange( value, r1, r2 ) {
    console.log(value,r1[ 0 ],r2[ 1 ],r2[ 0 ],r1[ 1 ],( value - r1[ 0 ] ),( r2[ 1 ] - r2[ 0 ] ),( r1[ 1 ] - r1[ 0 ] ))
    return ( value - r1[ 0 ] ) * ( r2[ 1 ] - r2[ 0 ] ) / ( r1[ 1 ] - r1[ 0 ] ) + r2[ 0 ];
}

export function normalizeRange (data,range,range2,symbol,size){
    let max = Number.MIN_VALUE;
    let min = Number.MAX_VALUE;
    if (range2 !== undefined) {
        max = range2[1]
        min = range2[0]
    }
    else{
        for (let i = 0; i < data.length; i++)
        {
            if(data[i]>max)
            {
                max = data[i];
            }
        }

        for (let i = 0; i < data.length; i++)
        {
            if(data[i]<min)
            {
                min = data[i];
            }
        }
    }


    for (let i = 0; i < data.length; i++)
    {
        let norm;
        if ( min === max)
            min = min-1;
        if (symbol === true)
            norm = convertRange(data[i],[min,max],range)*size;
        else
            norm = convertRange(data[i],[min,max],range)/size;

        data[i] = norm;
    }
    return (data);
}
export function lineToSigments(startPoint,endPoint,numberOfSigments) {
    let startX = startPoint[0];
    let startY = startPoint[1];
    let endX = endPoint[0];
    let endY = endPoint[1];
    let newXArray = [];
    let newYArray = [];
    for ( let i = 0 ; i<numberOfSigments;i++){
        newXArray.push(startX+((i/numberOfSigments)*(endX-startX)))
        newYArray.push(startY+((i/numberOfSigments)*(endY-startY)))
    }
    newXArray.push(endX)
    newYArray.push(endY)
    return ({x:newXArray,y:newYArray});
}
export function switchDict(val) {
    let value = val.value;
    let dictvalue = val.value;
    let dict = 'universal_dict'
    switch(val.value) {
        case 'field_location':
            value = 'location';
            dictvalue = val.value;
            break;
        case 'field_terrain':
            value = 'terrain';
            dictvalue = val.value;
            break;
        case 'well_stock':
            value = 'well_stock_id';
            dictvalue = val.value;
            break;
        case 'well_type':
            value = 'well_type_id';
            dictvalue = val.value;
            break;
        case 'protected_area':
            value = 'protected_areas_type';
            dictvalue = val.value;
            break;
        case 'well_status':
            value = 'current_status_id';
            dictvalue = val.value;
            break;
        case 'object_type':
            value = 'object_type_id';
            dictvalue = val.value;
            break;
        case 'outcrop':
            value = 'outcrop_id';
            dictvalue = val.value;
            break;
        case 'object_significance':
            value = 'object_significance_id';
            dictvalue = val.value;
            break;
        case 'currency':
            value = 'currency_id';
            dictvalue = val.value;
            break;
        case 'operator_id':
            dict = '';
            break;
        case 'operator_name':
            dict = '';
            break;
        case 'area':
            value='area';
            dict = '';
            break;
        case 'auction_value':
            dict = '';
            break;
        case 'paid_value':
            dict = '';
            break;
        default:
            break;
    }
    return({value:value,dict:dict,dictvalue:dictvalue});
}

export const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});