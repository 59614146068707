import {Button, Icon, Label} from "semantic-ui-react";
import axios from "axios";
import {publication_type_word, tags_word, urlBackend, urlMartin} from "../VariableInitialize";
import ScrollContainer from "react-indiana-drag-scroll";
import Cookies from "js-cookie";
import i18n from "../i18n";
import Dialog from "@mui/material/Dialog";
import {Transition} from "../functions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import React from "react";
import GroupPermissionForObject from "./GroupPermissionForObject";
import AddLasFiles from "./AddLasFiles";
import UpdateLasFiles from "./UpdateLasFiles"
import {withTranslation} from "react-i18next";
const UpdateLasFilesTranslate = withTranslation()(UpdateLasFiles)
const AddLasFilesTranslate = withTranslation()(AddLasFiles)

class FeatureInfosLasFiles extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lasFilesDisplay:"none",
            lasUpdateDisplay:"none",
            lasUpdateDict: null,
            lasEditID:null,
            lasFiles:[],
            lasFielsWait:false,
            lasSort:false,
            open:false,
            ObjectToDelete:null,
            GeotiffGroupPermDisplay:false,
            GeotiffGroupPermCssTop:0,
            GeotiffObjectID:null,
            GeotiffGroupPermArray:[{}],
            GeotiffGroupPermArrayBasic:[{}]
        }
        this.updateLasTable = this.updateLasTable.bind(this)
    }

    //publication layer object_id
    onSort(sortKey){
        //Publication
        const data = this.state.lasFiles;
        console.log(data);
        data.sort((a,b) => {

            console.log(a[sortKey],b[sortKey])
            if (this.state.lasSort) {
                if (!a[sortKey]) {
                    // Change this values if you want to put `null` values at the end of the array
                    return +1;
                }

                if (!b[sortKey]) {
                    // Change this values if you want to put `null` values at the end of the array
                    return -1;
                }
                return a[sortKey].toString().localeCompare(b[sortKey].toString());
            }else {
                if (!a[sortKey]) {
                    // Change this values if you want to put `null` values at the end of the array
                    return +1;
                }

                if (!b[sortKey]) {
                    // Change this values if you want to put `null` values at the end of the array
                    return -1;
                }
                return b[sortKey].toString().localeCompare(a[sortKey].toString());
            }
        })
        this.setState({lasFiles:data,lasSort:!this.state.lasSort})
    }
    updateLasTable(id) {
        if (id === undefined)
            id = this.props.id;
        axios.post(urlBackend+'/postQuery', {query:{type:"get_las_files",select_type:"get_all_researches",select_params:{id:id}}})
            .then((res) => {
                console.log(res.data);
                //this.props.publication = res.data;
                this.setState({lasFilesDisplay:"block",lasFiles :res.data,lasUpdateDisplay:"none",
                    lasUpdateDict: null})
            }).catch((error) => {
            console.log(error)
        });
    };
    render() {
        console.log(this.state.lasFilesDisplay)
        let icon = this.state.lasFilesDisplay === "none" ? 'plus' : 'minus';
        let parentWidth=window.innerWidth/2;
        console.log(parentWidth)
        let deleteID = null;
        let deleteObj = false;
        let publication =[{}];
        return(
            <div>
                <Label basic size={'medium'} onClick={() => {
                    if(this.state.lasFilesDisplay === "none"){
                        //console.log(this.state,this.props.id,"tiff",this.props,"prop")
                        this.updateLasTable(this.props.id);
                    }
                    else
                        this.setState({lasFilesDisplay:"none"})
                }}>
                    <Icon name={icon} /> {i18n.t("Las Files")}
                </Label>
                <div style={{display:this.state.lasFilesDisplay}}>
                    <UpdateLasFilesTranslate editID={this.state.lasEditID} display={this.state.lasUpdateDisplay} update={this.updateLasTable} word={this.state.lasUpdateDict} close={()=>{this.setState({lasUpdateDisplay:'none'})}} />
                    <AddLasFilesTranslate id={this.props.id} update={this.updateLasTable} display={this.state.lasFilesDisplay} layer={this.props.layer} />
                    <h2 id='titlepublications'>{i18n.t('Las Files Table')}</h2>
                    <ScrollContainer className="scroll-container">
                        <table id='publications'>
                            <tbody>
                            <tr key={"LasHeader"}>
                                <th onClick={()=>{
                                }}>{"  "}</th>
                                <th onClick={()=>{
                                    console.log("index")
                                    this.onSort("index")
                                }}>{i18n.t("Index")}</th>
                                <th onClick={()=>{
                                    console.log("name")
                                    this.onSort("name")
                                }}>{i18n.t("Name")}<Icon name='sort' size='small' /></th>

                                <th onClick={()=>{
                                    this.onSort("research_name")
                                }}>{i18n.t("research_name")}<Icon name='sort' size='small' /></th>
                                <th onClick={()=>{
                                    this.onSort("Mnemonic Group")
                                }}>{i18n.t("mnemonic")}<Icon name='sort' size='small' /></th>
                                <th onClick={()=>{
                                    this.onSort("Mnemonic Group Name")
                                }}>{i18n.t("group_name")}<Icon name='sort' size='small' /></th>
                                <th onClick={()=>{
                                    this.onSort("Method")
                                }}>{i18n.t("method")}<Icon name='sort' size='small' /></th>
                                <th onClick={()=>{
                                    this.onSort("min_value")
                                }}>{i18n.t("min_value")}<Icon name='sort' size='small' /></th>
                                <th onClick={()=>{
                                    this.onSort("max_value")
                                }}>{i18n.t("max_value")}<Icon name='sort' size='small' /></th>
                                <th onClick={()=>{
                                    this.onSort("min_depth")
                                }}>{i18n.t("min_depth")}<Icon name='sort' size='small' /></th>
                                <th onClick={()=>{
                                    this.onSort("max_depth")
                                }}>{i18n.t("max_depth")}<Icon name='sort' size='small' /></th>
                                <th onClick={()=>{
                                    this.onSort("flat")
                                }}>{i18n.t("flat")}<Icon name='sort' size='small' /></th>
                                <th onClick={()=>{
                                    this.onSort("outlier")
                                }}>{i18n.t("outlier")}<Icon name='sort' size='small' /></th>
                                <th onClick={()=>{
                                    this.onSort("description")
                                }}>{i18n.t("description")}<Icon name='sort' size='small' /></th>
                                <th onClick={()=>{
                                    this.onSort("unit")
                                }}>{i18n.t("unit")}<Icon name='sort' size='small' /></th>
                                <th onClick={()=>{
                                    console.log("author")
                                    this.onSort("ГИС подрядчик")
                                }}>{i18n.t("ГИС подрядчик")}<Icon name='sort' size='small' /></th>
                                <th onClick={()=>{
                                    console.log("source")
                                    this.onSort("source")
                                }}>{i18n.t("Source")}<Icon name='sort' size='small' /></th>
                                <th onClick={()=>{
                                    this.onSort("uploaddate")
                                }}>{i18n.t("Upload Date")}<Icon name='sort' size='small' /></th>
                                <th onClick={()=>{
                                }}>{"Edit Group"}</th>
                            </tr>
                            {this.state.lasFiles.map(({id,filename,name,source,updatedate,author,year,research_name,mnemonic,group_name,method,min_value,max_value,min_depth,max_depth,flat,outlier,description,unit},index) => {
                                console.log(author,year,research_name,mnemonic,group_name,method)
                                let name2 = filename;
                                let perm
                                if (name === "" || name === undefined || name === null)
                                {
                                    name = "Not Availible"
                                }
                                let date=[];
                                if ( filename !== undefined) {
                                    //console.log(name2.indexOf("-"));
                                    name2 = name2.slice(name2.indexOf("-")+1);
                                    name2 = name2.slice(name2.indexOf("-")+1);
                                }
                                //console.log(tags,filename,name,(filename === null || filename ===undefined || filename === "null"))
                                let fileurl = urlBackend+'/displayimages?file='+filename;
                                if (filename === null || filename ===undefined || filename === "" || filename === "null" || filename === "undefined")
                                    fileurl = false;
                                console.log(fileurl)
                                return (
                                    <tr onMouseDown={()=>{}} key={index}>
                                        <td>
                                            <>
                                                <Icon onClick={()=>{
                                                    this.props.lasHandler(fileurl)
                                                }} name={'line graph'}></Icon>
                                            </>
                                        </td>
                                        <td>{index}</td>
                                        <td><a href={fileurl.replace('+','%2b')}>{name}</a></td>
                                        <td>{research_name}</td>
                                        <td>{mnemonic}</td>
                                        <td>{group_name}</td>
                                        <td>{method}</td>
                                        <td>{min_value}</td>
                                        <td>{max_value}</td>
                                        <td>{min_depth}</td>
                                        <td>{max_depth}</td>
                                        <td>{flat+"%"}</td>
                                        <td>{outlier+"%"}</td>
                                        <td>{description}</td>
                                        <td>{unit}</td>
                                        <td>{author}</td>
                                        <td>{source}</td>
                                        <td>{updatedate}</td>
                                        <td><Icon name='edit' onClick={(e)=> {
                                            e.persist()
                                            console.log(id)
                                            axios.post(urlBackend+'/postQuery', {query:{type:"get_las_files",select_type:"get_mnemonics_group",select_params:{id:id}}})
                                                .then((res) => {
                                                    console.log(res.data);
                                                    //this.props.publication = res.data;
                                                    this.setState({lasUpdateDisplay:"block",
                                                        lasUpdateDict: res.data,lasEditID:id})
                                                }).catch((error) => {
                                                console.log(error)
                                            });
                                        }} /></td>
                                    </tr>
                                )
                            })}
                            {(deleteObj) && (
                                <Dialog
                                    classes={{ root: 'MenuItem'}}
                                    open={this.state.open}
                                    TransitionComponent={Transition}
                                    keepMounted
                                    onClose={this.handleCancelDelete}
                                    aria-labelledby="alert-dialog-slide-title"
                                    aria-describedby="alert-dialog-slide-description"
                                >
                                    <DialogTitle id="alert-dialog-slide-title">{"Delete"}</DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-slide-description">
                                            Confirm Delete
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={this.handleCancelDelete} color="primary">
                                            Disagree
                                        </Button>
                                        <Button onClick={()=> {
                                            console.log("DELETE FROM attachments WHERE f_attachment_id = " + this.state.publicationDeleteID);
                                            axios.post(urlBackend+'/layerid', {query:"DELETE FROM attachments WHERE f_attachment_id = " + this.state.publicationDeleteID})
                                                .then((res) => {
                                                    let publicationQuary = {type:"get_publications",select_type:"get_publications",select_params:{id:this.state.array[1].value,layer:this.props.layer,user_id:Cookies.get('id')}}
                                                    console.log(this.props.layer,publicationQuary)
                                                    axios.post(urlBackend+'/postQuery', {query:publicationQuary})
                                                        .then((res) => {
                                                            console.log(res.data);
                                                            publication = res.data;
                                                            this.setState({publicationDisplay:"block",publication :res.data})
                                                        }).catch((error) => {
                                                        console.log(error)
                                                    }).catch((error) => {
                                                        console.log(error)
                                                    });

                                                });
                                        }} color="primary">
                                            Agree
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            )}
                            </tbody>
                        </table>
                    </ScrollContainer>
                </div>
            </div>
        )
    }
}
export default FeatureInfosLasFiles;