import React from 'react';
import Alert from '@mui/material/Alert';
import Button from "@mui/material/Button";
import {alpha, styled} from "@mui/material/styles";
let trigger = true;
const AlertDiv = styled('div')(({ theme }) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));
export default function Activation(props) {
    console.log("Activation function")

    let Message;
    let state = "";
    if (props.state === "error"){
        Message =" Error: " +props.value;
        state="error";
    }

    else{
        Message =" User " +props.value + " successully activated.";
        state = "success";
    }

    let display = true;
    return (
        <AlertDiv>
            {(display && trigger) && (
                <Alert action={
                    <Button onClick={()=>{trigger=false;props.update()}} color="inherit" size="small">
                        x
                    </Button>
                } severity={state}>{Message}</Alert>
            )}
        </AlertDiv>

    );
}
