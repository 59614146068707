import React, {useEffect, useState} from "react";
import i18n from "../i18n";
import {Checkbox} from "semantic-ui-react";

export default function InteractionFilter(props) {
    const [active,setActive] = useState(false)
    return(<><Checkbox
        slider
        size={'mini'}
        label={i18n.t('Interactive')}
        className={'checkbox'}
        checked={active}
        onChange={ () => {
            props.setinteraction(props.id)
            setActive(!active)
        }}/></>)
}