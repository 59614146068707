import React from "react";
import {Dropdown,Button} from "semantic-ui-react";
import axios from "axios";
import Cookies from "js-cookie";
import "../Styles/GeometryFilter.css"
const config = require('../config');

const hostBackend = config.HOSTBackend;
const portBackend = config.PORTBackend;
const protocolBackend = config.ProtocolBackend;
const prefixBackend = config.PrefixBackend;
var urlBackend;
if (portBackend === "" || portBackend === undefined){
    urlBackend=protocolBackend+'://'+hostBackend+prefixBackend;
}
else{
    urlBackend=protocolBackend+'://'+hostBackend+':'+portBackend+prefixBackend;
}

class GeometryFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            layerDict:[
                {value:"regions",key:"RegionsLayerFilter",text:"Regions"},
                {value:"basins",key:"BasinsLayerFilter",text:"Basins"}
            ],
            selectedLayer:null,
            objectsDict:[],
            selectedObject:null,
            query:null
        }
    };
    render() {
        const { t } = this.props;
        // eslint-disable-next-line no-unused-vars
        let files;
        return(
            <div style={{display:this.props.display}} >
                    <div>

                        <span>{"Layer:"}<Dropdown
                            options={this.state.layerDict}
                            value={this.state.selectedLayer}
                            onChange={(e,val) => {
                                let query = {type:'get_'+val.value+'_infos',select_type:'get_all'}
                                axios.post(urlBackend+'/postQuery', {query:query})
                                    .then((res) => {
                                        console.log(res.data,this.props.id.toLowerCase())
                                        this.setState({selectedLayer:val.value,objectsDict:res.data});
                                    })
                                    .catch((err) => {console.log(err);})

                            }}
                            selection
                            search
                            style={{overflow: 'inherit'}}
                            placeholder={"Layer"} /></span>
                        <br/><br/>
                        <span>{"Objects:"}<Dropdown
                            options={this.state.objectsDict}
                            value={this.state.selectedObject}
                            search
                            style={{overflow: 'inherit'}}
                            onChange={(e,val) => {
                                let query = {type:'geometry_filter',select_type:'geometry_filter',select_params:{
                                        column_id: this.props.id.toLowerCase()+'_id',
                                        filterlayer:this.props.id.toLowerCase(),
                                        bylayer:this.state.selectedLayer,
                                        id:val.value,}}
                                this.setState({selectedObject:val.value,query:query});
                            }}
                            selection
                            placeholder={"Objects"} /></span>
                        <br/><br/>
                        <span><Button style={{transform: "scale(0.815)"}} basic size={'medium'} onClick={(e,val) => {
                            axios.post(urlBackend+'/postQuery', {query:this.state.query})
                                .then((res) => {
                                    console.log(res.data)
                                    let id_array =[];
                                    for (let i = 0 ; i < res.data.length; i++) {
                                        id_array.push(res.data[i].id);
                                    }
                                    console.log(id_array)
                                    this.props.filterhandler(id_array)
                                    //this.setState({selectedLayer:val.value,objectsDict:res.data});
                                })
                                .catch((err) => {console.log(err);})
                        }}>{"Filter"}</Button> </span>
                    </div>
            </div>
        )}}
export default GeometryFilter;
