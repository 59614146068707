import React, { useState, useEffect, useRef, useCallback } from 'react';

const MyGeoMapLines = ({ map, active, reset, onResetComplete, onDeactivate }) => {
    const [lines, setLines] = useState([]);
    const currentLine = useRef([]);
    const lineIndex = useRef(0);
    const isActive = useRef(active);

    useEffect(() => {
        isActive.current = active;
        if (!active) {
            if (currentLine.current.length > 1) {
                const newLine = {
                    type: 'Feature',
                    geometry: {
                        type: 'LineString',
                        coordinates: [...currentLine.current],
                    },
                };
                setLines((prevLines) => {
                    const updatedLines = [...prevLines];
                    updatedLines[lineIndex.current] = newLine;
                    return updatedLines;
                });
                currentLine.current = [];
                lineIndex.current = lines.length;
            }
        }
    }, [active, lines.length]);

    useEffect(() => {
        console.log("reset")
        if (reset) {
            resetLayer();
        }
    }, [reset]);

    useEffect(() => {
        if (map) {
            map.on('click', handleMapClick);
        }
        return () => {
            if (map) {
                map.off('click', handleMapClick);
            }
        };
    }, [map]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                if (isActive.current) {
                    onDeactivate(lines.length,lines);
                }
            }
        };
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [lines.length, onDeactivate]);

    const handleMapClick = useCallback((e) => {
        if (!isActive.current) return;

        const newPoint = [e.lngLat.lng, e.lngLat.lat];
        currentLine.current.push(newPoint);

        if (currentLine.current.length > 1) {
            const newLine = {
                type: 'Feature',
                geometry: {
                    type: 'LineString',
                    coordinates: [...currentLine.current],
                },
            };
            setLines((prevLines) => {
                const updatedLines = [...prevLines];
                updatedLines[lineIndex.current] = newLine;
                return updatedLines;
            });
        }
    }, []);

    useEffect(() => {
        updateMapLayer();
    }, [lines]);

    const updateMapLayer = useCallback(() => {
        if (map.getSource('lines-source')) {
            map.getSource('lines-source').setData({
                type: 'FeatureCollection',
                features: lines,
            });
        } else {
            map.addSource('lines-source', {
                type: 'geojson',
                data: {
                    type: 'FeatureCollection',
                    features: lines,
                },
            });
            map.addLayer({
                id: 'lines-layer',
                type: 'line',
                source: 'lines-source',
                layout: {
                    'line-join': 'round',
                    'line-cap': 'round',
                },
                paint: {
                    'line-color': '#888',
                    'line-width': 4,
                },
            });
        }
    }, [map, lines]);

    const resetLayer = useCallback(() => {
        console.log("delete layer")
        if (map.getLayer('lines-layer')) {
            map.removeLayer('lines-layer');
        }
        if (map.getSource('lines-source')) {
            map.removeSource('lines-source');
        }
        setLines([]);
        currentLine.current = [];
        lineIndex.current = 0;
        if (onResetComplete) {
            onResetComplete();
        }
    }, [map, onResetComplete]);

    return null;
};

export default MyGeoMapLines;
