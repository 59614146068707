import React from 'react';
import Alert from '@mui/material/Alert';
import Button from "@mui/material/Button";
import {alpha, styled} from "@mui/material/styles";
const AlertDiv = styled('div')(({ theme }) => ({
    root: {
        width: '100%',
        zIndex: '9999',
        position: 'absolute',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));
let trigger = true;

export default function Warning(props) {
    let Message =" For full support please use Google Chrome. Now you are using " +props.browser;
    let display = true;
    if (props.browser.includes("Chrome"))
        display = false;
    return (
        <AlertDiv>
        {(display && trigger) && (
            <Alert action={
                <Button onClick={()=>{trigger=false;props.update()}} color="inherit" size="small">
                    x
                </Button>
            } severity="warning">{Message}</Alert>
        )}
        </AlertDiv>

    );
}
