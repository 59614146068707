import React, {useEffect, useState} from "react";
import i18n from "../i18n";
import {Checkbox} from "semantic-ui-react";

const LayerButtonDisplayToggle = ({result, data, data2, presetdata, title, handle, dataset, custom, layerorder}) => {
    const [active, setActive] = useState(false);
    let check = false;
    for (let i = 0; i<result.length;i++){
        if (result[i].name === title) {
            check = result[i].visibility === "visible";
        }
    }
    useEffect(() => {
        // Обновляем заголовок документа с помощью API браузера
        setActive(check);
    });
    //console.log(title,i18n.t(title))
    return (
        <Checkbox
            slider
            checked={active}
            size={'mini'}
            className={'checkbox'}
            label={i18n.t(title)}
            onChange={ () => {
                handle();
                let dataSetForChange;
                if (dataset === 2)
                    dataSetForChange = presetdata;
                else
                    dataSetForChange = data;
                for (let i = 0; i < result.length; i++) {
                    if (result[i].name === title) {
                        if (active === false) {
                            result[i].visibility = 'visible';
                            handle();
                            for (let j = 0; j < data2.length; j++) {
                                if (data2[j].name === title+"Text") {
                                    data2[j].enable = true;
                                }
                            }
                            if (custom !== undefined)
                                layerorder();
                            setActive(true)}
                        else{
                            result[i].visibility = 'none';
                            for (let j = 0; j < data2.length; j++) {
                                if (data2[j].name === title+"Text") {
                                    data2[j].enable = false;
                                }
                            }
                            //data2[i].enable = false;
                            setActive(false)}
                    }
                }
            }}/>
    );
};
export default LayerButtonDisplayToggle;