import {useEffect} from "react";
import * as turf from "@turf/turf";


export default function MyGeoMapFlyTo(props) {

    useEffect(() => {
    if (props.type === "bbox" && !props.coordinates.center){
        console.log(props.coordinates)
        let features =  props.coordinates
        if (props.coordinates.features && props.coordinates.features.type)
            features = props.coordinates.features

        let bbox = turf.bbox(features);
        console.log(bbox)
        if (bbox[0] > 180 || bbox[0] < -180 || !Number.isFinite(bbox[0]))
            bbox[0] =-180
        if (bbox[1] > 90 || bbox[1] < -90 || !Number.isFinite(bbox[1]))
            bbox[1] =-90
        if (bbox[2] < -180 || bbox[2] > 180 || !Number.isFinite(bbox[2]))
            bbox[2] = 180
        if (bbox[3] > 90 || bbox[3] < -90 || !Number.isFinite(bbox[3]))
            bbox[3] =90
        console.log(bbox)
        props.map.current.fitBounds(bbox, {padding: 100})
    }else{
        props.map.current.flyTo({
                center: [
                   Number(props.coordinates.center[0]) ,
                    Number(props.coordinates.center[1])
                ],
                zoom: 11,
                essential: true
            });
    }


    }, [props.map,props.coordinates,props.type]);

}