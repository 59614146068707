export const Main = {
    en: {
        translation: {
            "TectonicOrder":"Order",
            "StructureType":"Morpho Type",
            "TectonicType":"Structure Type",
            "co2_world_sources":"CCS Projects",
            "Кейсы":"Cases",
            "Почему MyGeoMap":"Why MyGeoMap",
            "Преимущества":"Advantages",
            "Open Map":"Open Map",
            "Open":"Open",
            "Map":"Map",
            "Login":"Login",
            "MSU_Branches":"MSU Branches and Bases",
            "co2_emissions":"CO2 Emissions",
            "Las Files":"Las Files",
            "Tools":"Tools",
            "result": "Result",
            "frequency":'Probability',
            "Площадь*":"Areas*",
            "Общий объем породы":"Gross rock volume",
            "Чистый объем пор":"Net pore volume",
            "Поровый объем углеводородов":"Hydrocarbon pore volume",
            "Gas saturation factor":"Gas saturation factor",
            "Correction for deviation from ideal gas (initial conditions)":"Correction for deviation from ideal gas (initial conditions)",
            "Initial reservoir pressure":"Initial reservoir pressure",
            "Correction for deviation from ideal gas (final conditions)":"Correction for deviation from ideal gas (final conditions)",
            "Final reservoir pressure":"Final reservoir pressure",
            "Standard Pressure":"Standard Pressure",
            "Standard Temperature":"Standard Temperature",
            "Initial Reservoir Temperature":"Initial Reservoir Temperature",
            "Gross rock volume":"Gross rock volume",
            "Net pore volume":"Net pore volume",
            "Hydrocarbon pore volume":"Hydrocarbon pore volume",
            "Areas":"Areas",
            "Total thickness":"Total thickness",
            "Net-to-gross":"Net-to-gross",
            "Reservoir thickness":"Reservoir thickness",
            "Porosity":"Porosity",
            "Water saturation":"Water saturation",
            "Formation volume factor":"Formation volume factor",
            "Expansion factor":"Expansion factor",
            "Geometric correction factor":"Geometric correction factor",
            "Top depth":"Top depth",
            "Bottom depth":"Bottom depth",
            "Oil-water contact":"Oil-water contact",
            "Gas-water contact":"Gas-water contact",
            "Oil density":"Oil density",
            "Conversion Factor":"Conversion Factor",
            "Общая мощность":"Total thickness",
            "Коэффициент песчанности":"Reservoir thickness",
            "Эффективная мощность*":"Reservoir thickness*",
            "Эффективная мощность":"Reservoir thickness",
            "Пористость*":"Porosity*",
            "Водонасыщенность*":"Water saturation*",
            "Объемный коэффициент*":"Formation volume factor*",
            "Пористость":"Porosity",
            "Водонасыщенность":"Water saturation",
            "Объемный коэффициент":"Formation volume factor",
            "Коэффициент расширения":"Expansion factor",
            "Плотность нефти":"Oil density",
            "Геометрический фактор":"Geometric correction factor",
            "Кровля пласта":"Top depth",
            "Подошва пласта":"Bottom depth",
            "ВНК":"Oil-water contact",
            "ГНК":"Gas-water contact",
            "uniform":"uniform",
            "beta":"beta",
            "lognormal":"lognormal",
            "triangular":"triangular",
            "truncated normal":"truncated normal",
            "normal":"normal",
            "Error":"Error",
            "Select Distribution":"Select Distribution",
            "Samples":"Samples",
            "Send":"Send",
            "Kern":"Kern",
            "Reserves":"Reserve",
            "calculation":"calculation",
            "Similar publication already exists in the database, please use search to check":"Similar publication already exists in the database, please use search to check",
            "Cancel uploading":"Cancel uploading",
            "Upload anyway":"Upload anyway",
            "lang":"en",
            "Классификация месторождений нефти и газа. ":"Classification of oil and gas fields.",
            "уникальные — более 300 млн т нефти или 300 млрд м³ газа;":"unique - more than 300 million tons of oil or 300 billion m³ of gas;",
            "крупные — от 30 до 300 млн т нефти или от 30 до 300 млрд м³ газа;":"large - from 30 to 300 million tons of oil or from 30 to 300 billion m³ of gas;",
            "средние — от 3 до 30 млн т нефти или от 3 до 30 млрд м³ газа; или от 3 до 30 млрд м³ газа;":"medium - from 5 to 30 million tons of oil or from 5 to 30 billion m³ of gas;",
            "мелкие — от 1 до 3 млн т нефти или от 1 до 3 млрд м³ газа;":"small - from 1 to 5 million tons of oil or from 1 to 5 billion m³ of gas;",
            "очень мелкие — менее 1 млн т нефти, менее 1 млрд м³ газа;":"very small - less than 1 million tons of oil, less than 1 billion m³ of gas;",
            "Классификация месторождений нефти ":"Classification of oil fields",
            "и газа":"and gas",
            'По величине извлекаемых запасов:':"In terms of recoverable reserves:",
            "уникальные — более 300 млн т нефти ":"unique — more than 300 million tons of oil",
            "или 300 млрд м³ газа;":"or 300 billion m³ of gas;",
            "крупные — от 30 до 300 млн т нефти":"large — from 30 to 300 million tons of oil",
            "или от 30 до 300 млрд м³ газа;":"or from 30 to 300 billion m³ of gas;",
            "средние — от 3 до 30 млн т нефти ":"medium - from 3 to 30 million tons of oil",
            "или от 3 до 30 млрд м³ газа;":"or from 3 to 30 billion m³ of gas;",
            "мелкие — от 1 до 3 млн т нефти ":"small - from 1 to 3 million tons of oil",
            "или от 1 до 3 млрд м³ газа;":"or from 1 to 3 billion m³ of gas;",
            "очень мелкие — менее 1 млн т":"very small - less than 1 million tons",
            "нефти, менее 1 млрд м³ газа":"oil, less than 1 billion m³ of gas",
            "Only my objects":"Only my objects",
            "Disable/Enable Popups":"Disable/Enable Popups",
            "Change to Preset":"Change to Preset",
            "Change to Custom":"Change to Custom",
            "Exploration and Production":"Exploration and Production",
            "Geologic Structures":"Geologic Structures",
            "Infrastructure":"Infrastructure",
            "infra_obj":"Industry",
            "Ecology":"Ecology",
            "Administrative structures":"Administrative structures",
            "User Layers":"User Layers",
            "Layouts":"Layouts",
            "Legends":"Legends",
            "Стратиграфия":"Stratigraphy",
            "Тип полезных ископаемых":"Тип полезных ископаемых",
            "Административный округ":"Administrative District",
            "Расположение":"Location",
            "Местность":"Terrain",
            "Год закрытия":"Shutdown Year",
            "Год начала производства":"Start Production Year",
            "Планируемый год начала производства":"Planed Start Production Year",
            "Метод открытия":"Discovery Method",
            "Максимальная высота":"Max Elevation",
            "Максимальная глубина воды":"Max Water Depth",
            "Начальная цена аукциона":"Auction Price",
            "Платеж победителя":"Paid Price",
            "Лицензионные обязательства":"License Obligation",
            "Степень Уверенности":"Confidence Degree",
            "Дата окончания лицензи":"Expire Date",
            "Дата выдачи лицензии":"Issue Date",
            "ShortDesc":"Short Description",
            "Straton":"Straton",
            "Статус лицензии":"License Status",
            "Reference":"Reference",
            "GeoPosition":"Geo Position",
            "Тип лицензии":"License Type",
            "Outcrop":"Ourcrop",
            "Лицензионный номер":"License Number",
            "ObjectType":"Object Type",
            "Compas":"Compas",
            "domain":"Domain",
            "seismic_type":"Seismics Type",
            "signal_source":"Signal Source",
            "ProtectedAreasType":"Protected Areas Type",
            "field_work_year":"Field Work Year",
            "processing_year":"Processing Year",
            "OriginalName":"Object Name",
            "Тип":"Type",
            "Площадь":"Area",
            "Классификация":"Classification",
            "qc_id":"ID",
            "Подтип":"Subtype",
            "Класс":"Class",
            "Возраст":"Age",
            "Разведанные_ресурсы_нефти":"Explored Resources Oil",
            "Разведанные_ресурсы_конденсата":"Explored Resources Condensate",
            "Разведанные_ресурсы_газа":"Explored Resources Gas",
            "Неразведанные_ресурсы_нефти":"Undiscovered Resources Oil",
            "Неразведанные_ресурсы_конденсата":"Undiscovered Resources Condensate",
            "Неразведанные_ресурсы_газа":"Undiscovered Resources Gas",
            "Классификация_по_полезным_ископае":"Classification by Minerals",
            "Lithology":"Lithology",
            "Main_reservoir":"Main Reservoir",
            "Main_source_rock":"Main Source Rock",
            "Год":"Year",
            "Location":"Location",
            "Terrain":"Terrain",
            "ShutdownYear":"Shutdown Year",
            "StartProductionYear":"Start Production Year",
            "PlanedStartProductionYear":"Planed Start Production Year",
            "DiscoveryMethod":"Discovery Method",
            "MaxElevation":"Max Elevation",
            "MaxWaterDepth":"Max Water Depth",
            "Имя_лицензии":"Name",
            "Лицензионный_номер":"License Number",
            "Источник":"Source",
            "Степень_уверенности":"Confidence Degree",
            "Тип_лицензии":"License Type",
            "Статус_лицензии":"License Status",
            "Лицензионные_обязательства":"License Obligation",
            "Дата_выдачи":"Issue Date",
            "Дата_окончания":"Expire Date",
            "Оператор":"Operator",
            "Платеж_победителя":"Paid Price",
            "Начальная_цена_аукциона":"Auction Price",
            "Структура":"Structure",
            "Порядок":"Order",
            "Морфогенетический_тип":"Morphogenetic Type",
            "object_name":"Name",
            "object_type":"Type",
            "geo_index":"Geo Index",
            "outcrop":"Outcrop",
            "Geo_Index":"Geo Index",
            "Geo_compos":"Geo Compos",
            "Default Color Displayed":"Default Color Displayed",
            "single":"single",
            "multiple":"multiple",
            "gradient":"gradient",
            "default":"default",
            "Fill color:": "Fill color: ",
            "Border color: ":"Border color: ",
            "Display text":"Display text",
            "Transparent":"Transparent",
            "Hover":"Hover",
            "Popup":"Popup",
            "Oil At Place":"Stock Tank Oil Initially In Place",
            "Basins":"Basins",
            "SubBasins":"SubBasins",
            "Fields":"Fields",
            "Licenses":"Licenses",
            "Wells":"Wells",
            "Info":"Infrastucure",
            "GoogleSat":"Google Satellite",
            "GoogleHyb":"Google Hybrid",
            "BingAerial":"Bing Aerial",
            "ArcGISTopo":"ArcGIS Topographic",
            "Seismics":"Seismics",
            "GeologicMap":"Geologic Map",
            "TectonicMap":"Tectonic Map",
            "GeologicObjects":"Geologic Objects",
            "Regions":"Regions",
            "ProtectedAreas":"Protected Areas",
            "Recovarable Oil":"Recovarable Oil",
            "Start Reserves":"Start Reserves",
            "Current Reserves":"Current Reserves",
            "Residual Extrudable Reserves":"Residual Extrudable Reserves",
            "Change Permission for object":"Change Permission for object",
            "Group":"Group",
            "None":"None",
            "Read":"Read",
            "Write":"Write",
            "DeleteP":"Delete",
            "Search: ":"Search: ",
            "Files Table":"Files Table",
            "Files":"Files",
            "Create at least 5 points.":"Create at least 5 pair of points.",
            "Shift + Click to make point(minimum 5 pair of points)":"Shift + Click to make point(minimum 5 pair of points)",
            "Search near point":"Search near point",
            "Opacity":"Opacity",
            "Lanczos":"Lanczos",
            "CubicSpline":"CubicSpline",
            "Cubic":"Cubic",
            "Nearest Neighbour":"Nearest Neighbour",
            "Bilinear":"Bilinear",
            "Resample Algorithm":"Resample Algorithm",
            "About project":"About project",
            "Layers":"Layer",
            "Upload":"Upload",
            "Upload2":"Upload",
            "my layer":"my layer",
            "Georeferencing":"Georeferencing",
            "Help":"Help",
            "Search":"Search",
            "Reset":"Reset",
            "About":"About",
            "Reset options":"Reset settings",
            "Login/Register":"Login/Register",
            "Register":"Register",
            "Download":"Download",
            "Add as layer":"Add as layer",
            "Preview":"Preview",
            "Click or Drag'n'Drop file here":"Click or Drag'n'Drop file here",
            "Delete":"Delete",
            "Logout":"Logout",
            "Profile":"Profile",
            "Библиотека":"Library",
            "Гео Модель":"Geo Model",
            "Поиск Аналогов":"Search for Analogs",
            "f_infra_obj_infos_name_rus":"Name",
            "nominal_co2_injection":"Nominal CO2 Injection",
            "reservoir_type_id":"Reservoir Type",
            "status_id":"Status",
            'formation_id':"Formation",
            'project_start_year':"Project Start Year",
            "project_stop_year":"Project Stop Year",
            "operator_str":"Operator",
            "Welcome to React": "Welcome to React and react-i18next",
            "Category":"Category",
            "Subcategory":"Subcategory",
            "Company":"Company",
            "emissions_value":" Emissions Value",
        }
    },
    ru: {
        translation: {
            "TectonicOrder":"Порядок",
            "StructureType":"Вид Структуры",
            "TectonicType":"Тип Структуры",
            "co2_world_sources":"CCS Проекты",
            "Кейсы":"Кейсы",
            "Почему MyGeoMap":"Почему MyGeoMap",
            "Преимущества":"Преимущества",
            "Open Map":"Открыть Карту",
            "Open":"Открыть",
            "Map":"Карту",
            "MSU_Branches":"Филиалы и Базы МГУ",
            "co2_emissions":"Выбросы CO2",
            "Las Files":"Las Файлы",
            "Tools":"Инструменты",
            "Gas saturation factor":"Коэффициен газонасыщенности",
            "Correction for deviation from ideal gas (initial conditions)":"Поправка на отклонение от идеального газа(начальные условия)",
            "Initial reservoir pressure":"Начальное пластовое давление",
            "Correction for deviation from ideal gas (final conditions)":"Поправка на отклонение от идеального газа(конечные условия)",
            "Final reservoir pressure":"Конечное пластовое давление",
            "Standard Pressure":"Standard Pressure",
            "Standard Temperature":"Standard Temperature",
            "Initial Reservoir Temperature":"Началльная Пластовая Температура",
            "Gross rock volume":"Общий объем породы",
            "Net pore volume":"Чистый объем пор",
            "Hydrocarbon pore volume":"Поровый объем углеводородов",
            "Общий объем породы":"Общий объем породы",
            "Чистый объем пор":"Чистый объем пор",
            "Поровый объем углеводородов":"Поровый объем углеводородов",
            "Areas":"Площадь",
            "Total thickness":"Общая мощность",
            "Net-to-gross":"Коэффициент песчанности",
            "Reservoir thickness":"Эффективная мощность",
            "Porosity":"Пористость",
            "Water saturation":"Водонасыщенность",
            "Formation volume factor":"Объемный коэффициент",
            "Expansion factor":"Коэффициент расширения",
            "Geometric correction factor":"Геометрический фактор",
            "Top depth":"Кровля пласта",
            "Bottom depth":"Подошва пласта",
            "Oil-water contact":"ВНК",
            "Gas-water contact":"ГНК",
            "Oil density":"Плотность нефти",
            "Conversion Factor":"Пересчетный коэффициен",
            "result": "Результат",
            "frequency":'Вероятность',
            "Площадь*":"Площадь*",
            "Общая мощность":"Общая мощность",
            "Коэффициент песчанности":"Коэффициент песчанности",
            "Эффективная мощность*":"Эффективная мощность*",
            "Пористость*":"Пористость*",
            "Водонасыщенность*":"Водонасыщенность*",
            "Объемный коэффициент*":"Объемный коэффициент*",
            "Коэффициент расширения":"Коэффициент расширения",
            "Плотность нефти":"Плотность нефти",
            "Геометрический фактор":"Геометрический фактор",
            "Кровля пласта":"Кровля пласта",
            "Подошва пласта":"Подошва пласта",
            "ВНК":"ВНК",
            "ГНК":"ГНК",
            "uniform":"Равномерное",
            "beta":"Бета",
            "lognormal":"Лог Нормальное",
            "triangular":"Треугольное",
            "truncated normal":"Усеченное Нормальное",
            "normal":"Нормальное",
            "Error":"Ошибка",
            "Select Distribution":"Выбрать Распределение",
            "Samples":"Выборка",
            "Send":"Отправить",
            "Kern":"Керн",
            "calculation":"запасов",
            "Reserves":"Подсчет",
            "Similar publication already exists in the database, please use search to check":"Похожая публикация уже существует в базе, пожалуйста используйте поиск для проверки",
            "Cancel uploading":"Отменить загрузку",
            "Upload anyway":"Продолжить загрузку",
            "lang":"ru",
            "Классификация месторождений нефти и газа. ":"Классификация месторождений нефти и газа. ",
            "уникальные — более 300 млн т нефти или 300 млрд м³ газа;":"уникальные — более 300 млн т нефти или 300 млрд м³ газа;",
            "крупные — от 30 до 300 млн т нефти или от 30 до 300 млрд м³ газа;":"крупные — от 30 до 300 млн т нефти или от 30 до 300 млрд м³ газа;",
            "средние — от 3 до 30 млн т нефти или от 3 до 30 млрд м³ газа; или от 3 до 30 млрд м³ газа;":"средние — от 5 до 30 млн т нефти или от 5 до 30 млрд м³ газа; или от 5 до 30 млрд м³ газа;",
            "мелкие — от 1 до 3 млн т нефти или от 1 до 3 млрд м³ газа;":"мелкие — от 1 до 5 млн т нефти или от 1 до 5 млрд м³ газа;",
            "очень мелкие — менее 1 млн т нефти, менее 1 млрд м³ газа;":"очень мелкие — менее 1 млн т нефти, менее 1 млрд м³ газа;",
            "Классификация месторождений нефти ":"Классификация месторождений нефти ",
            "и газа":"и газа",
            'По величине извлекаемых запасов:':"По величине извлекаемых запасов:",
            "уникальные — более 300 млн т нефти ":"уникальные — более 300 млн т нефти ",
            "или 300 млрд м³ газа;":"или 300 млрд м³ газа;",
            "крупные — от 30 до 300 млн т нефти":"крупные — от 30 до 300 млн т нефти",
            "или от 30 до 300 млрд м³ газа;":"или от 30 до 300 млрд м³ газа;",
            "средние — от 3 до 30 млн т нефти ":"средние — от 3 до 30 млн т нефти ",
            "или от 3 до 30 млрд м³ газа;":"или от 3 до 30 млрд м³ газа;",
            "мелкие — от 1 до 3 млн т нефти ":"мелкие — от 1 до 3 млн т нефти ",
            "или от 1 до 3 млрд м³ газа;":"или от 1 до 3 млрд м³ газа;",
            "очень мелкие — менее 1 млн т":"очень мелкие — менее 1 млн т",
            "нефти, менее 1 млрд м³ газа":"нефти, менее 1 млрд м³ газа",
            "Only my objects":"Только мои Объекты",
            "Disable/Enable Popups":"Включить/Выключить Подсказки",
            "Change to Preset":"Изменить на Пресет",
            "Change to Custom":"Изменить на Пользовательский",
            "Exploration and Production":"Разведка и Добыча",
            "Geologic Structures":"Геологические Cтруктуры",
            "Infrastructure":"Инфраструктура",
            "infra_obj":"Промышленность",
            "Ecology":"Экология",
            "Administrative structures":"Административные структуры",
            "User Layers":"Пользовательские слои",
            "Layouts":"Подложки",
            "Legends":"Легенда",
            "Стратиграфия":"Стратиграфия",
            "Тип полезных ископаемых":"Тип полезных ископаемых",
            "Административный округ":"Административный округ",
            "Расположение":"Расположение",
            "Местность":"Местность",
            "Год закрытия":"Год закрытия",
            "Год начала производства":"Год начала производства",
            "Планируемый год начала производства":"Планируемый год начала производства",
            "Метод открытия":"Метод открытия",
            "Максимальная высота":"Максимальная высота",
            "Максимальная глубина воды":"Максимальная глубина воды",
            "Начальная цена аукциона":"Начальная цена аукциона",
            "Платеж победителя":"Платеж победителя",
            "Лицензионные обязательства":"Лицензионные обязательства",
            "Степень Уверенности":"Степень Уверенности",
            "Дата окончания лицензи":"Дата окончания лицензии",
            "Дата выдачи лицензии":"Дата выдачи лицензии",
            "ShortDesc":"Краткое описание",
            "Straton":"Стратон",
            "Статус лицензии":"Статус лицензии",
            "Reference":"Ссылка",
            "GeoPosition":"Гео-позиция",
            "Тип лицензии":"Тип лицензии",
            "Outcrop":"Ourcrop",
            "Лицензионный номер":"Лицензионный номер",
            "ObjectType":"Тип Объекта",
            "Compas":"Состав",
            "domain":"Домен",
            "seismic_type":"Тип сейсморазведки",
            "signal_source":"Источник Сигнала",
            "ProtectedAreasType":"Тип Заповедника",
            "field_work_year":"Год полевых работ",
            "processing_year":"Год обработки",
            "OriginalName":"Название объекта",
            "Тип":"Тип",
            "Площадь":"Площадь",
            "Классификация":"Классификация",
            "qc_id":"ИД",
            "Подтип":"Подтип",
            "Класс":"Класс",
            "Возраст":"Возраст",
            "Разведанные_ресурсы_нефти":"Разведанные Ресурсы Нефти",
            "Разведанные_ресурсы_конденсата":"Разведанные Ресурсы Конденсата",
            "Разведанные_ресурсы_газа":"Разведанные Ресурсы Газа",
            "Неразведанные_ресурсы_нефти":"Неразведанные Ресурсы Нефти",
            "Неразведанные_ресурсы_конденсата":"Неразведанные Ресурсы Конденсата",
            "Неразведанные_ресурсы_газа":"Неразведанные Ресурсы Газа",
            "Классификация_по_полезным_ископае":"Классификация по Полезным Ископаемым",
            "Lithology":"Литология",
            "Main_reservoir":"Главный резервуар",
            "Main_source_rock":"Main Source Rock",
            "Год":"Год",
            "Location":"Расположение",
            "Terrain":"Местность",
            "ShutdownYear":"Год Закрытия",
            "StartProductionYear":"Год Начала Производства",
            "PlanedStartProductionYear":"Планируемый Год Начала Производства",
            "DiscoveryMethod":"Метод Обнаружения",
            "MaxElevation":"Максимальная Высота",
            "MaxWaterDepth":"Максимальная Глубина",
            "Имя_лицензии":"Имя",
            "Лицензионный_номер":"Лицензионный Номер",
            "Источник":"Источник",
            "Степень_уверенности":"Степень Уверенности",
            "Тип_лицензии":"Тип Лицензии",
            "Статус_лицензии":"Статус Лицензии",
            "Лицензионные_обязательства":"Лицензионные Обязательства",
            "Дата_выдачи":"Дата Выдачи",
            "Дата_окончания":"Дата Окончания",
            "Оператор":"Оператор",
            "Платеж_победителя":"Платеж Победителя",
            "Начальная_цена_аукциона":"Начальная Цена Аукциона",
            "Структура":"Структура",
            "Порядок":"Порядок",
            "Морфогенетический_тип":"Морфогенетический Тип",
            "object_name":"Имя",
            "object_type":"Тип",
            "geo_index":"Гео Индекс",
            "outcrop":"Outcrop",
            "Geo_Index":"Гео Индекс",
            "Geo_compos":"Гео Компас",
            "Default Color Displayed":"Отображен стандартный цвет",
            "single":"Одиночная",
            "multiple":"По свойству",
            "gradient":"Градиент",
            "default":"Стандартная",
            "Fill color:": "Цвет заливки: ",
            "Border color: ":"Цвет обводки: ",
            "Display text":"Отобразить текст",
            "Transparent":"Прозрачность",
            "Hover":"Выделение",
            "Popup":"Всплывающее Окно",
            "Basins":"Бассейны",
            "SubBasins":"НГО",
            "Fields":"Месторождения",
            "Licenses":"Лицензии",
            "Wells":"Скважины",
            "Seismics":"Сейсмика",
            "GeologicMap":"Геологическая карта",
            "TectonicMap":"Тектоническая карта",
            "GeologicObjects":"Геологические Объекты",
            "Regions":"Регионы",
            "ProtectedAreas":"Заповедные Зоны",
            "Info":"Инфраструктура",
            "GoogleSat":"Google Спутниковая",
            "GoogleHyb":"Google Гибридная",
            "BingAerial":"Bing Воздушная",
            "ArcGISTopo":"ArcGIS Топографическая",
            "Oil At Place":"Начальные Объемы Нефти в Поверхностных Условиях",
            "Recovarable Oil":"Извлекаемые Запасы Нефти",
            "Start Reserves":"Начальные запасы",
            "Current Reserves":"Текующие запасы",
            "Residual Extrudable Reserves":"Остаточные извлекаемые запасы",
            "Change Permission for object":"Изменить права для объекта",
            "Group":"Группа",
            "None":"Нет",
            "Read":"Чтение",
            "Write":"Изменение",
            "DeleteP":"Удаление",
            "Search: ":"Поиск: ",
            "Files Table":"Таблица Файлов",
            "Files":"Файлы",
            "Create at least 5 points.":"Создайте как минимум 5 пар точек",
            "Shift + Click to make point(minimum 5 pair of points)":"Shift + Click для создания точки(минимум 5 пар точек)",
            "Search near point": "Поиск возле точки",
            "Opacity":"Прозрачность",
            "Lanczos": "Ланцоша",
            "CubicSpline":"Кубический Сплайн",
            "Cubic":"Кубический",
            "Nearest Neighbour":"Ближайших Соседей",
            "Bilinear":"Билинейный",
            "Resample Algorithm":"Алгоритм ресемплинга",
            "About project":"О проекте",
            "Layers":"Слои",
            "Upload":"Загрузить",
            "Upload2":"Загрузить",
            "my layer":"свой слой",
            "Georeferencing":"Гео привязка",
            "Help":"Помощь",
            "Search":"Поиск",
            "Reset options":"Сброс настроек",
            "Login/Register":"Вход/Регистрация",
            "Login":"Войти",
            "Register":"Регистрация",
            "Download":"Скачать",
            "Add as layer":"Добавить как слой",
            "Preview":"Предпросмотр",
            "Click or Drag'n'Drop file here":"Нажмите или Перетащите файл сюда",
            "Delete":"Удалить",
            "Reset":"Сброс",
            "About":"О нас",
            "Logout":"Выйти",
            "Profile":"Профиль",
            "Библиотека":"Библиотека",
            "Гео Модель":"Гео Модель",
            "Поиск Аналогов":"Поиск Аналогов",
            "f_infra_obj_infos_name_rus":"Имя",
            "nominal_co2_injection":"Ёмкость Хранилища",
            "reservoir_type_id":"Тип Коллектора",
            "status_id":"Статус",
            'formation_id':"Название Горизонта ",
            'project_start_year':"Год Начала Проекта",
            "project_stop_year":"Год Конца Проекта",
            "operator_str":"Оператор",
            "Welcome to React": "Bienvenue à React et react-i18next",
            "Category":"Категория",
            "Subcategory":"Подкатегория",
            "Company":"Компания",
            "emissions_value":" Количество выбросов",
        }
    }
};