import React from 'react';
import Alert from '@mui/material/Alert';
import Button from "@mui/material/Button";
import {alpha, styled} from "@mui/material/styles";
const AlertDiv = styled('div')(({ theme }) => ({
    root: {
        width: '100%',
        zIndex: '9999',
        position: 'absolute',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));
export default function AlertMessage(props) {
    const [timer,setTimer] = React.useState(5);
    let Message;
    let state = "";
    if (props.state === "error"){
        Message =" Error: " +props.value;
        state="error";
    }
    else if (props.state === "info"){
        Message = props.value;
        state="info";
    }
    else{
        Message = props.value ;
        state = "success";
    }

    //set timer to close alert
    if(timer === 5 && props.display)
        setTimeout(() => {
            props.close()
        }, 5000)

    //countdown reset
    if (timer < 1 && props.display)
        setTimer(5)

    //countdown
    if(timer > 1 && props.display)
        setTimeout(() => {
            setTimer(timer-1)
        }, 1000)



    console.log(props.display)

    return (
        <AlertDiv>
            {(props.display) && (
                <Alert action={
                    <Button onClick={()=>{props.close();props.update()}} color="inherit" size="small">
                        x
                    </Button>
                } severity={state}>{Message} {timer}</Alert>
            )}
        </AlertDiv>

    );
}
