import React from 'react';

function DisplayImages(props) {
    console.log(props);
    return(
        <div style={{display:props.display}}>
            <img src={props.url} height="100" width="134"  alt={props.url}/>
        </div>
    );
}

export default DisplayImages;
