import {useEffect, useRef, useState} from "react";
const deepCompare = (arg1, arg2) => {
    if (Object.prototype.toString.call(arg1) === Object.prototype.toString.call(arg2)){
        if (Object.prototype.toString.call(arg1) === '[object Object]' || Object.prototype.toString.call(arg1) === '[object Array]' ){
            if (Object.keys(arg1).length !== Object.keys(arg2).length ){
                return false;
            }
            return (Object.keys(arg1).every(function(key){
                return deepCompare(arg1[key],arg2[key]);
            }));
        }
        return (arg1===arg2);
    }
    return false;
}
export default function MyGeoMapSource(props) {
    const [tiles,setTiles] = useState(undefined)
    const [data,setData] =useState(undefined)
    const [loaded,setLoaded] =useState(false)
    useEffect(() => {
        //console.log(props.map,props.type,props.id,props.tileSize,props.promoteId,props.maxTileCacheSize,props.minzoom,props.maxzoom,props.tiles,props.data)

        if (!props.map || props.map.current === null )
            return
        props.map.current.once('styledata',()=>{
            if (!props.map.current.getSource(props.id)) {
                if (props.type === 'geojson'){
                    props.map.current.addSource(props.id, {
                        'type': props.type,
                        'data': props.data
                    });
                    setData(structuredClone(props.data))
                }else if(props.type === 'raster'){
                    console.log(props.id,props.type,props.tiles,props.tileSize)
                    props.map.current.addSource(props.id, {
                        'type': props.type,
                        'tiles': props.tiles,
                        'tileSize':(props.tileSize ? props.tileSize : 256)
                    });
                }else if(props.type === 'image') {
                    props.map.current.addSource(props.id, {
                        'type': props.type,
                        'url': props.url,
                        'coordinates':props.coordinates
                    });
                }else if(props.type === 'vector'){
                    console.log(props.id,props.type)
                    props.map.current.addSource(props.id, {
                        'type': props.type,
                        'tiles': props.tiles,
                        'tileSize': 512,
                        'maxzoom':props.maxzoom,
                        'minzoom':props.minzoom,
                        'maxTileCacheSize':props.maxTileCacheSize,
                        'promoteId':props.promoteId
                    });
                }
            }
            setLoaded(true)
        })
        

    }, [props.visibility, props.map, props.type, props.id, props.tileSize, props.promoteId, props.maxTileCacheSize, props.minzoom, props.maxzoom, props.tiles, props.data, props.coordinates]);
    useEffect(() => {
        //console.log('change tiles',props.map,props.map.current,loaded)
        if (!props.map || props.map.current === null || !loaded)
            return
        //console.log("has map",props.id,props.tiles,tiles,props.type === 'vector' , tiles )
        if ( props.type === 'vector' && tiles && props.tiles[0] !== tiles[0] && props.map.current.getSource(props.id)){
            console.log("change tiles" ,props.id, tiles , props.tiles , props.tiles !== tiles)
            props.map.current.getSource(props.id).setTiles(props.tiles);
            setTiles(props.tiles)
        }else if(!tiles) {
            setTiles(props.tiles)
        }
    }, [loaded, props.id, props.map, props.tiles, props.type, tiles]);
    useEffect(() => {
        console.log('change tiles geojson',props.map,props.map.current,loaded,data,props.data)
        if (props.type !== 'geojson' || !props.map || props.map.current === null || !loaded)
            return
        console.log("has map geojson",props.id,props.data,data , props.data !== data, JSON.stringify(props.data)!==JSON.stringify(data),deepCompare(data,props.data))
        if (  data  && !deepCompare(data,props.data) && props.map.current.getSource(props.id)){
            console.log("change tiles" ,props.id, data , props.data , props.data !== data)
            props.map.current.getSource(props.id).setData(props.data);
            setData(structuredClone(props.data))
        }else if(!data) {
            setData(structuredClone(props.data))
        }
    }, [loaded, props.id, props.map, JSON.stringify(props.data), props.type, data]);
    return(<></>)
}