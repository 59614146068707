import React from 'react';
import {urlBackend, viewer3d} from "../VariableInitialize";
import {Button, Icon, Input, Label} from "semantic-ui-react";
import axios from "axios";
import Cookies from "js-cookie";
class LibraryTool extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            publications:this.props.publications,
            page:null,
            search:null,
            count:this.props.count,
            filter:false
        }
        this.onPageClick = this.onPageClick.bind(this);
        this.onFilterClick = this.onFilterClick.bind(this);
        this.onResetClick = this.onResetClick.bind(this);
    };
    onFilterClick () {
        let publicationQuary = {type:"get_publications",select_type:"get_publications_pages_filter",select_params:{user_id:Cookies.get('id'),offset:0,search:this.state.search}}
        axios.post(urlBackend+'/postQuery', {query:{type:"get_publications",select_type:"get_publications_count_filter",select_params:{user_id:Cookies.get('id'),search:this.state.search}}})
            .then((result) => {
                console.log(result)
                axios.post(urlBackend+'/postQuery', {query:publicationQuary})
                    .then((res) => {
                        console.log(res.data)
                        this.setState({publications :res.data,count:result.data[0].count,filter:true})
                        //publicationCount
                    }).catch((error) => {
                    console.log(error)
                });
            }).catch((error) => {
            console.log(error)
        });
    }
    onResetClick () {
        let publicationQuary = {type:"get_publications",select_type:"get_publications_all",select_params:{user_id:Cookies.get('id')}}
        axios.post(urlBackend+'/postQuery', {query:{type:"get_publications",select_type:"get_publications_count",select_params:{user_id:Cookies.get('id')}}})
            .then((result) => {
                console.log(result)
                axios.post(urlBackend+'/postQuery', {query:publicationQuary})
                    .then((res) => {
                        console.log(res.data)
                        this.setState({publications :res.data,count:result.data[0].count,filter:false})
                        //publicationCount
                    }).catch((error) => {
                    console.log(error)
                });
            }).catch((error) => {
            console.log(error)
        });
    }
    onPageClick () {
        console.log(this.state, this.state.page)
        let publicationQuary ={};
        if (this.state.filter)
            publicationQuary = {type:"get_publications",select_type:"get_publications_pages_filter",select_params:{user_id:Cookies.get('id'),search:this.state.search,offset:50*(Number.parseInt(this.state.page)-1)}}
        else
            publicationQuary = {type:"get_publications",select_type:"get_publications_pages",select_params:{user_id:Cookies.get('id'),offset:50*(Number.parseInt(this.state.page)-1)}}
        axios.post(urlBackend+'/postQuery', {query:publicationQuary})
            .then((res) => {
                console.log(res.data)
                this.setState({publications :res.data})
                //publicationCount
            }).catch((error) => {
            console.log(error)
        });
    }
    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.publications !== prevProps.publications) {
            this.setState({publications:this.props.publications,count:this.props.count})
        }
    }
    render() {

        return(
            <div className={"library"} id={"library"} style={{display:"block"}}>
                <Button basic size={'mini'} onClick={this.props.close} icon><i className="close icon"/></Button>
                <br/>
                {"Number of Publications : " +this.state.count}
                <br/>
                <Label>{"Search"}</Label>
                <Input value={this.state.search} onChange={(e,data)=>this.setState({search:data.value})}/>
                <Button onClick={this.onFilterClick} size='tiny'>{"Filter"}</Button>
                <Button onClick={this.onResetClick} size='tiny'>{"Reset"}</Button>
                <span style={{float:"right"}}><Label basic>{"To Page"}</Label>
                    <Input value={this.state.page} style={{width:'100px'}} type={"number"} onChange={(e,data)=>{
                        console.log(this.state.page,Number.parseInt(data.value),Math.ceil(this.state.count / 50),(Number.parseInt(data.value) <= Math.ceil(this.state.count / 50)))
                        if (Number.parseInt(data.value) <= Math.ceil(this.state.count / 50))
                            this.setState({page:data.value}
                        )}} />
                    <Button onClick={this.onPageClick} size='tiny'>{"Go"}</Button>
                    <Label basic>{"of " + Math.ceil(this.state.count / 50) + " Pages "}</Label></span>
                <table id='publications'>
                    <tbody>
                    <tr key={"all"}>
                        <th onClick={()=>{
                        }}>{"  "}</th>
                        <th onClick={()=>{
                            console.log("index")
                            this.onSort("index")
                        }}>{"Index"}</th>
                        <th onClick={()=>{
                            console.log("name")
                            this.onSort("name")
                        }}>{"Name"}<Icon name='sort' size='small' /></th>
                        <th onClick={()=>{
                            console.log("author")
                            this.onSort("author")
                        }}>{"Author"}<Icon name='sort' size='small' /></th>
                        <th onClick={()=>{
                            console.log("doi")
                            this.onSort("doi")
                        }}>{"Doi"}<Icon name='sort' size='small' /></th>
                        <th onClick={()=>{
                            console.log("url")
                            this.onSort("url")
                        }}>{"Url"}<Icon name='sort' size='small' /></th>
                        <th onClick={()=>{
                            console.log("type")
                            this.onSort("type")
                        }}>{"Publication Type"}<Icon name='sort' size='small' /></th>
                        <th onClick={()=>{
                            console.log("tags")
                            this.onSort("tags")
                        }}>{"Publication Tags"}<Icon name='sort' size='small' /></th>
                        <th onClick={()=>{
                            console.log("uploaddate")
                            this.onSort("uploaddate")
                        }}>{"Year"}<Icon name='sort' size='small' /></th>
                        <th onClick={()=>{
                            console.log("layername")
                            this.onSort("layername")
                        }}>{"Layer"}<Icon name='sort' size='small' /></th>
                    </tr>
                    {this.state.publications.map(({filename,name,permission,uploaddate,type,user,id,tags,author,doi,url,layername},index) => {
                        //console.log(filename,name,permission,uploaddate,type,user,id,tags,author,doi,url,layername)
                        let name2 = filename;
                        let urlFinal = url;
                        let perm
                        if (permission !== undefined && permission !== null)
                            perm = permission[0];
                        if (name === "" || name === undefined || name === null)
                        {
                            name = "Not Availible"
                        }
                        let date=[];
                        if ( filename !== undefined) {
                            //console.log(name2.indexOf("-"));
                            name2 = name2.slice(name2.indexOf("-")+1);
                            name2 = name2.slice(name2.indexOf("-")+1);
                        }


                        if (urlFinal === undefined || urlFinal === null || urlFinal === "")
                            urlFinal = false
                        let tags_display = tags;
                        if (tags !== undefined && tags !== null)
                            if ( tags.length > 0){
                                tags_display = ''
                                for (let i = 0; i < tags.length; i++) {
                                    //console.log(tags[i],tags_display);
                                    if (i === tags.length-1)
                                        tags_display = tags_display + tags[i] + ' \n'
                                    else
                                        tags_display = tags_display + tags[i] + ', \n'
                                }
                            }
                        //console.log(tags,filename,name,(filename === null || filename ===undefined || filename === "null"))
                        let fileurl = urlBackend+'/displayimages?file='+filename;
                        if (filename === null || filename ===undefined || filename === "" || filename === "null" || filename === "undefined")
                            fileurl = false;
                        //console.log(fileurl)
                        return (
                            <tr onMouseDown={()=>{}} key={index}>
                                <td>{(type === "Las файл")&&(
                                    <>
                                        <Icon onClick={()=>{
                                            this.props.lasHandler(fileurl)
                                        }} name={'line graph'}></Icon>
                                    </>
                                )}</td>
                                <td>{index+1}</td>
                                {(fileurl===false)&&(
                                    <td>{name}</td>
                                )}
                                {(fileurl!==false)&&(
                                    <td><a href={fileurl.replace('+','%2b')}>{name}</a></td>
                                )}

                                <td>{author}</td>
                                <td><a href={doi} target="_blank">{doi}</a></td>
                                {(urlFinal)&&(
                                    <td onMouseOver={()=>console.log(url)}><a href={url} target="_blank">{"link"}</a></td>
                                )}
                                {(!urlFinal)&&(
                                    <td></td>
                                )}

                                <td>{type}</td>
                                <td>{tags_display}</td>
                                <td>{uploaddate}</td>
                                <td>{layername}</td>
                            </tr>
                        )
                    })}

                    </tbody>
                </table>
            </div>
        )}}
export default LibraryTool;
