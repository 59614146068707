import React from 'react';
import ReservesFilterInterface from "./ReservesFilterInterface";

class Help extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    };

    render() {
        console.log("MapReserves mounted")
        return(
            <div className={"map-reserves"} style={{display:this.props.display,width: '100%',position: 'inherit'}}>
                {(this.props.display==="block")&&(<ReservesFilterInterface display={"block"} object_id={this.props.ids} multiple={true} />)}

            </div>
        )}}
export default Help;
