import React from "react";
import {Button, Dropdown, Icon, Input, Label} from "semantic-ui-react";
import axios from "axios";
import Slide from "@mui/material/Slide";
import Cookies from "js-cookie";
const config = require('../config');
const hostBackend = config.HOSTBackend;
const portBackend = config.PORTBackend;
const protocolBackend = config.ProtocolBackend;
const prefixBackend = config.PrefixBackend;
var urlBackend;
if (portBackend === "" || portBackend === undefined){
    urlBackend=protocolBackend+'://'+hostBackend+prefixBackend;
}
else{
    urlBackend=protocolBackend+'://'+hostBackend+':'+portBackend+prefixBackend;
}
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
class UpdateLasFiles extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editObjectID:null,
            display:"none",
            lasMnemonicGroup:null
        }
    };
    updateForm() {

        this.setState({
            editObjectID:this.props.editID,
            lasMnemonicGroup:null,
        })

    }
    componentDidUpdate(prevProps) {
        if(this.props.editID!== prevProps.editID) // Check if it's a new user, you can also use some unique property, like the ID  (this.props.user.id !== prevProps.user.id)
        {
            this.updateForm();
        }
    }
    render() {
        const { t } = this.props;

        //let infos = document.getElementById('infos')
        //console.log(infos.body.scrollTop)

        return(
            <div style={{display:this.props.display,position: 'absolute',backgroundColor:"#FFFFFF",border: "thick double #000000", right:"3px", top:this.props.editPositionTop+"px", zIndex:'99999999'}} >
                <Button basic size={'mini'} onClick={()=>this.props.close()} icon><i className="close icon"/></Button>
                <br/>
                <div>
                    <br/>
                    <span >{"Мнемоническая Группа:"}<Dropdown
                        options={this.props.word}
                        value={this.state.lasMnemonicGroup}
                        onChange={(e,val) => {
                            console.log(val,val.value)
                            this.setState({lasMnemonicGroup:val.value})
                        }}
                        selection
                        placeholder={"LAS Mnemonic Group"} /></span>
                </div>
                <Button basic onClick={()=>{

                    let query = {query:{type:"insert_las_files", select_type:"update", select_params:{
                                id: this.props.editID,
                                group_id:this.state.lasMnemonicGroup,
                                }}}
                    axios.post(urlBackend+'/postQuery', query, {
                    }).then(res => {
                        console.log(res.data)
                        this.props.update()
                        //this.setState({url:urlBackend+'/displayimages?file='+res.data.filename});

                    }).catch((error) => {
                        console.log(error)
                    });
                }}>{"Save"}</Button>
            </div>
        )}}
export default UpdateLasFiles;
