import React from "react";
import {Button, Checkbox, Input} from "semantic-ui-react";


class PointCreate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            x:  0,
            y:  0,
            trigger: false,
        }
    }
    render() {
        /**/
        return(
            <div className="point" style={{display:this.props.display }} >
                <span>
                        {this.props.error}
                    <Button basic size={'mini'} onClick={this.props.close} icon><i className="close icon"/></Button>
                        <br/>
                        <span style={{display:"flex"}}>{"X:"}<Input defaultValue={this.state.x} type={"number"} size={"mini"} onChange={(e,val) =>{
                            this.setState({x:val.value})
                        }} /></span>
                        <span style={{display:"flex"}}>{"Y:"}<Input defaultValue={this.state.y} type={"number"} size={"mini"} onChange={(e,val) =>{
                            this.setState({y:val.value})
                        }}/></span>
                        <Checkbox className={"cheb"} onClick={() => {
                            if (this.state.trigger === false)
                                this.setState({trigger:true})
                            else
                                this.setState({trigger:false})
                        }} slider label={"Zoom to Point"} />
                        <Button size={"mini"} onClick={() => {console.log(this.state.x,this.state.y);this.props.create(this.state.y,this.state.x,this.state.trigger)}}>Add Point</Button>
                </span>
            </div>
        )
    }
}
export default PointCreate;