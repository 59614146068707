import _ from 'lodash'
import React from 'react'
import { Icon, Label, Menu, Table } from 'semantic-ui-react'
import ScrollContainer from "react-indiana-drag-scroll";

function Reducer(state, action) {
    let from = 0;
    let to = 20;
    switch (action.type) {
        case 'CHANGE_SORT':
            from = state.currentPage * 20;
            to = (state.currentPage+1)*20;
            console.log(from, to, state)
            if (state.column === action.column) {
                return {
                    ...state,
                    data: state.data.slice().reverse(),
                    displayData:state.data.slice().reverse().slice(from,to),
                    direction:
                        state.direction === 'ascending' ? 'descending' : 'ascending',
                }
            }

            return {
                column: action.column,
                data: _.sortBy(state.data, [action.column]),
                displayData:_.sortBy(state.data, [action.column]).slice(from,to),
                direction: 'ascending',
                pages: Math.ceil(state.data.length/20),
                currentPage:state.currentPage
            }
        case 'UPDATE':
            return {
                ...state,
                data:action.data.slice(),
                displayData:action.data.slice(0,20),
                pages: Math.ceil(action.data.length/20),
                currentPage:state.currentPage
            }
        case 'PAGE_CHANGE':
            from = action.page * 20;
            to = (action.page+1)*20
            return {
                ...state,
                displayData:state.data.slice(from,to),
                currentPage:action.page
            }
        default:
            throw new Error()
    }
}


function CapillaryTable({capillaryData}) {
    const [state, dispatch] = React.useReducer(Reducer, {
        column: null,
        data: capillaryData,
        displayData:capillaryData.slice(0,20),
        direction: null,
        pages: Math.ceil(capillaryData.length/20),
        currentPage:0
    })
    const { column, data, displayData, direction, pages, currentPage } = state
    React.useEffect(() => {
        dispatch({type:'UPDATE',data:capillaryData})
    }, [capillaryData]);
    let pagesArray =  [];
    const rowsDict = [
            {key:"capillary_id",text:"Index",value:"capillary_id"},
            {key:"type",text:"Type",value:"type"},
            {key:"area",text:"Area",value:"area"},
            {key:"field",text:"Field",value:"field"},
            {key:"well",text:"Well",value:"well"},
            {key:"age",text:"Age",value:"age"},
            {key:"object",text:"Object",value:"object"},
            {key:"facion",text:"Facion",value:"facion"},
            {key:"environment",text:"Environment",value:"environment"},
            {key:"danhem",text:"Danhem",value:"danhem"},
            {key:"gran",text:"Gran",value:"gran"},
            {key:"portype",text:"Portype",value:"portype"},
            {key:"well_number",text:"Well Number",value:"well_number"},
            {key:"well_number_new",text:"Well Number New",value:"well_number_new"},
            {key:"dolb",text:"dolb",value:"dolb"},
            {key:"depth",text:"Depth",value:"depth"},
            {key:"depthgis",text:"Depth GIS",value:"depthgis"},
            {key:"insoluble",text:"Insoluble",value:"insoluble"},
            {key:"calcit",text:"Calcit",value:"calcit"},
            {key:"dolomit",text:"Dolomit",value:"dolomit"},
            {key:"nearest",text:"Nearest",value:"nearest"},
            {key:"lito",text:"Lito",value:"lito"},
            {key:"description",text:"Description",value:"description"},
            {key:"litogroup",text:"Lito Group",value:""},
            {key:"kper",text:"kper",value:"kper"},
            {key:"krop",text:"krop",value:"krop"},
            {key:"0",text:"0",value:"data_0"},
            {key:"0.005",text:"0.005",value:"data_0_005"},
            {key:"0.010",text:"0.010",value:"data_0_010"},
            {key:"0.015",text:"0.015",value:"data_0_015"},
            {key:"0.025",text:"0.025",value:"data_0_025"},
            {key:"0.050",text:"0.050",value:"data_0_050"},
            {key:"0.1",text:"0.1",value:"data_0_1"},
            {key:"0.2",text:"0.2",value:"data_0_2"},
            {key:"0.3",text:"0.3",value:"data_0_3"},
            {key:"0.5",text:"0.5",value:"data_0_5"},
            {key:"0.8",text:"0.8",value:"data_0_8"},
            {key:"1.0",text:"1.0",value:"data_1_0"},
            {key:"1.2",text:"1.2",value:"data_1_2"},
        ];
    for (let i = 0; i <pages ;i++){
        pagesArray.push(i);
    }
    return (
    <ScrollContainer className="scroll-container">
        <Table sortable compact celled>
            <Table.Header>
                <Table.Row>
                    {rowsDict.map(({key,text,value})=>{
                        return(
                            <Table.HeaderCell
                                sorted={column === value ? direction : null}
                                onClick={() => dispatch({ type: 'CHANGE_SORT', column: value })}
                            >{text}</Table.HeaderCell>
                        )
                    })}
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {displayData.map(({capillary_id,type,area,field,well,age,object,faction,environment,danhem,gran,portype,well_number,well_number_new,dolb,depth,
                                                   depthgis,insoluble,calcit,dolomit,nearest,lito,description,litogroup,kper,krop,data_0, data_0_005, data_0_010, data_0_015,
                                                   data_0_025, data_0_050,data_0_1, data_0_2, data_0_3, data_0_5, data_0_8, data_1_0, data_1_2}, index) => {
                    return(
                        <Table.Row positive>
                            <Table.Cell>{index}</Table.Cell>
                            <Table.Cell>{type}</Table.Cell>
                            <Table.Cell>{area}</Table.Cell>
                            <Table.Cell>{field}</Table.Cell>
                            <Table.Cell>{well}</Table.Cell>
                            <Table.Cell>{age}</Table.Cell>
                            <Table.Cell>{object}</Table.Cell>
                            <Table.Cell>{faction}</Table.Cell>
                            <Table.Cell>{environment}</Table.Cell>
                            <Table.Cell>{danhem}</Table.Cell>
                            <Table.Cell>{gran}</Table.Cell>
                            <Table.Cell>{portype}</Table.Cell>
                            <Table.Cell>{well_number}</Table.Cell>
                            <Table.Cell>{well_number_new}</Table.Cell>
                            <Table.Cell>{dolb}</Table.Cell>
                            <Table.Cell>{depth}</Table.Cell>
                            <Table.Cell>{depthgis}</Table.Cell>
                            <Table.Cell>{insoluble}</Table.Cell>
                            <Table.Cell>{calcit}</Table.Cell>
                            <Table.Cell>{dolomit}</Table.Cell>
                            <Table.Cell>{nearest}</Table.Cell>
                            <Table.Cell>{lito}</Table.Cell>
                            <Table.Cell>{description}</Table.Cell>
                            <Table.Cell>{litogroup}</Table.Cell>
                            <Table.Cell>{kper}</Table.Cell>
                            <Table.Cell>{krop}</Table.Cell>
                            <Table.Cell>{data_0}</Table.Cell>
                            <Table.Cell>{data_0_005}</Table.Cell>
                            <Table.Cell>{data_0_010}</Table.Cell>
                            <Table.Cell>{data_0_015}</Table.Cell>
                            <Table.Cell>{data_0_025}</Table.Cell>
                            <Table.Cell>{data_0_050}</Table.Cell>
                            <Table.Cell>{data_0_1}</Table.Cell>
                            <Table.Cell>{data_0_2}</Table.Cell>
                            <Table.Cell>{data_0_3}</Table.Cell>
                            <Table.Cell>{data_0_5}</Table.Cell>
                            <Table.Cell>{data_0_8}</Table.Cell>
                            <Table.Cell>{data_1_0}</Table.Cell>
                            <Table.Cell>{data_1_2}</Table.Cell>
                        </Table.Row>
                    )
                })}
            </Table.Body>
            <Table.Footer>
                <Table.Row>
                    <Table.HeaderCell colSpan='47'>
                        <Menu floated='left' pagination>
                            <Menu.Item onClick={()=>{
                                if ( currentPage > 0)
                                    dispatch({type:'PAGE_CHANGE',page:currentPage-1})
                            }} as='a' icon>
                                <Icon name='chevron left' />
                            </Menu.Item>
                            {pagesArray.map((page, index) =>{
                                return(
                                    <Menu.Item onClick={()=>{
                                        dispatch({type:'PAGE_CHANGE',page:page})
                                    }} as='a'>{page+1}</Menu.Item>
                                )
                            })}
                            <Menu.Item onClick={()=>{
                                if ( currentPage < (pages-1))
                                    dispatch({type:'PAGE_CHANGE',page:currentPage+1})
                            }} as='a' icon>
                                <Icon name='chevron right' />
                            </Menu.Item>
                        </Menu>
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Footer>
        </Table>
    </ScrollContainer>
    )
}

export default CapillaryTable