import React from 'react';
import {styled, alpha,ThemeProvider, createTheme } from '@mui/material/styles';
import Slider from '@mui/material/Slider';
import Input from '@mui/material/Input';
import Grid from '@mui/material/Grid';
import RefreshIcon from '@mui/icons-material/Refresh';
import IconButton from '@mui/material/IconButton';
const PrettoSlider = styled(Slider)(({theme})=> ({
    root: {
        color: '#545454',
        height: 8,
    },
    thumb: {
        height: 20,
        width: 20,
        backgroundColor: '#F8F8F8',
        border: '1px solid currentColor',
        marginTop: -9,
        marginLeft: -12,
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },
    active: {},
    valueLabel: {
        color:'#A9A9A9',
        left: 'calc(-50% + 1px)',
        transform:"scale(0.7) translateY(-10px)",
    },
    track: {
        height: 3,
        borderRadius: 2,
    },
    rail: {
        height: 3,
        borderRadius: 2,
        color:'#e6e6e6'
    },
}));
const SliderDiv = styled('div')(({ theme }) => ({
    width: 340,
}));

export default function RangeSlider(props) {
    const [value, setValue] = React.useState([props.min, props.max]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        props.handle2(newValue,props.name)
    };
    const handleInputChange = (event) => {
        setValue(event.target.value === '' ? '' : [Number(event.target.value),value[1]]);
        props.handle2(value,props.name)
    };
    const handleInputChange2 = (event) => {
        setValue(event.target.value === '' ? '' : [value[0],Number(event.target.value)]);
        props.handle2(value,props.name)
    };
    const handleClick = () => {
        setValue([props.min, props.max]);
        props.handle2(null,props.name);
    }
    const handleBlur = () => {
        if (value[0] < props.min) {
            setValue([props.min,value[1]]);
        } else if (value[0] > props.max) {
            setValue([props.max,value[1]]);
        } else if (value[1] < props.min) {
            setValue([value[1],props.min]);
        } else if (value[1] > props.max) {
            setValue([value[1],props.max]);
        }
    };
    return (
        <SliderDiv >
            <br/>
            <br/>
            <Grid container spacing={1} alignItems="center">
                <Grid item xs={10}>
                    <PrettoSlider
                        style={{left:"10px",width:"260px"}}
                        value={value}
                        min={props.min}
                        max={props.max}
                        step={1}
                        onChange={handleChange}
                        valueLabelDisplay="auto"
                        aria-labelledby="Years"
                    />
                </Grid>
                <Grid item xs={1}>
                    <IconButton aria-label="reset" onClick={handleClick} ><RefreshIcon /></IconButton>
                </Grid>
                <Grid item xs={12} sm={7}>
                    <div style={{left:"5px"}}>
                        {"From  "}
                        <Input
                            style={{width: 55,}}
                            value={value[0]}
                            //margin="dense"
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            inputProps={{
                                step: 1,
                                min: props.min,
                                max: props.max,
                                type: 'number',
                                'aria-labelledby': 'input-start-year',
                            }}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} sm={5}>
                    <div style={{right:"5px"}}>
                        {"To  "}
                        <Input
                            style={{width: 55,}}
                            value={value[1]}
                            //margin="dense"
                            onChange={handleInputChange2}
                            onBlur={handleBlur}
                            inputProps={{
                                step: 1,
                                min: props.min,
                                max: props.max,
                                type: 'number',
                                'aria-labelledby': 'input-end-year',
                            }}
                        />
                    </div>
                </Grid>
            </Grid>

        </SliderDiv>
    );
}
