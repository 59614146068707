import React, {Component} from "react";
import {stringify} from "wkt";
import {Button} from "semantic-ui-react";

class LayerGeoJsonToDB extends Component {
    constructor (props, context) {
        super(props, context);
        this.state = {
            value: this.props.value,
        }
    }

    handleChange = (value) => {
        //console.log(this.props.value,this.state.value,value)
        let properties=[];
        let prop = [];
        let name = '';
        for ( let i = 0 ;i < this.props.result.length;i++){
            if(this.props.result[i].name === this.props.value && this.props.result[i].type === 'geojson') {
                //console.log(result[i].data)
                let dataForCheck =  JSON.parse(JSON.stringify(this.props.result[i].data));
                //console.log(dataForCheck)
                /*for (let r = 0;r<dataForCheck.features.length;r++){
                    for (let t = 1 ; t < dataForCheck.features.length;t++) {
                        console.log(dataForCheck.features[r].geometry, dataForCheck.features[t].geometry,dataForCheck.features[r].geometry === dataForCheck.features[t].geometry,r !== t)
                        if (JSON.stringify(dataForCheck.features[r].geometry) === JSON.stringify(dataForCheck.features[t].geometry)){
                            if ( r !== t )
                                dataForCheck.features.splice(t, 1);
                        }
                    }
                }*/
                this.props.result[i].data = dataForCheck;
                //console.log(result[i].data)
                //console.log(dataForCheck)
                properties = Object.getOwnPropertyNames(this.props.result[i].data.features[0].properties)
                name = properties[0];

                for(let j = 0; j<properties.length;j++) {
                    prop.push({key:properties[j],text:properties[j],value:properties[j]})
                }
                const { stringify } = require('wkt');
                let resultToDisplay= [];
                let data = this.props.result[i].data;
                let request = [];
                let PropList = [];
                //console.log("File length =" ,data.features.length )
                let res2 = [];
                for (let h = 0 ; h <data.features.length ; h++) {
                    let reqIndex = h;
                    let reqBody = "";
                    if (data.features[h].geometry !== null) {
                        let geometry = data.features[h].geometry;
                        console.log(geometry);
                        geometry = stringify(geometry);
                        //console.log(h !== 59,"id = ",h,data.features[h].properties,geometry.length)
                        if (geometry !== 'MULTIPOLYGON ()') {
                            res2.push(h);
                            let quary = "SELECT public.get_similar_geo_objects_with_params(ST_SetSRID(ST_GeomFromText('"+geometry+"'),4326), 'licenses', '{\"lic_nbr\":\""+data.features[h].properties.lic_nbr+"\"}'::json)";
                            //quary = "SELECT public.get_similar_geo_objects(ST_SetSRID(ST_GeomFromText('"+geometry+"'),4326), 'basins') ";
                            reqBody =quary;
                            //console.log(data.features[h].properties)
                            PropList.push(data.features[h].properties);
                        }
                        else
                            PropList.push(null)
                        request.push({index:reqIndex,quary:reqBody});
                    }
                    else {
                        PropList.push(null)
                        request.push({index:reqIndex,quary:reqBody});
                    }
                }
                //request = request.slice(0, -1)
                //console.log(request);
                prop.push({key:"---",text:"---",value:"---"});
                properties = prop;
                resultToDisplay.push({quarylist:request,properties:PropList})
                //console.log(resultToDisplay)

                this.props.loader(this.props.value);
                //makeRequestsFromArray(request).then(r => console.log(r));
            }
        }

    };

    render () {
        const { value } = this.state;
        return (
            <div className='slider-horizontal'>
                <Button onClick={this.handleChange}>{"Add to DataBase"}</Button>
            </div>
        )
    }
}
export default LayerGeoJsonToDB;