import React from "react";
import {Button, Dropdown, Icon, Input, Label} from "semantic-ui-react";
import axios from "axios";
import Cookies from "js-cookie";
import i18n from "../i18n";
import Dropzone from "react-dropzone";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Slide from "@mui/material/Slide";
import GroupPermissionForObject from "./GroupPermissionForObject";
const config = require('../config');
const hostBackend = config.HOSTBackend;
const portBackend = config.PORTBackend;
const protocolBackend = config.ProtocolBackend;
const prefixBackend = config.PrefixBackend;
var urlBackend;
if (portBackend === "" || portBackend === undefined){
    urlBackend=protocolBackend+'://'+hostBackend+prefixBackend;
}
else{
    urlBackend=protocolBackend+'://'+hostBackend+':'+portBackend+prefixBackend;
}
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
class AddPublications extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            display:"none",
            publicationType:"",
            publicationTags:[],
            url:"",
            mapType:"",
            tags:[],
            author:"",
            doi:"",
            year:null,
            PermissionDisplay:"none",
            groupPermArray:[],
            groupPermArrayBasic:[],
            PermissionDisplayIcon:'plus',
            publicationError:"",
            PublicationFiles:null,
            addDisplay:"none",
            publicationText:"Files to Upload: ",
            exist:false,
            open:false,
            fileName:"",
        }
    };
    setPermission(id) {
        for (let i=0; i< this.state.groupPermArrayBasic.length;i++){
            let permCode = null;
            if ( this.state.groupPermArray[i].permissions === 'read')
                permCode = 1;
            if ( this.state.groupPermArray[i].permissions === 'change')
                permCode = 2;
            if ( this.state.groupPermArrayBasic[i].permissions === null && this.state.groupPermArray[i].permissions !== null){
                //insert
                console.log("insert from NULL to " + this.state.groupPermArray[i].permissions)
                let PermQuary = {type:"insert_Groups_Permission",select_type:"insert_Groups_Permission_Publications",select_params:{object_id:id,group_id:this.state.groupPermArray[i].id,permission:permCode}}
                axios.post(urlBackend+'/postQuery', {query: PermQuary})
                    .then((res) => {
                        console.log(res)
                    })
                    .catch((error)=>{console.log(error)})
            }else if ( this.state.groupPermArrayBasic[i].permissions !== null && this.state.groupPermArray[i].permissions === null){
                //delete
                console.log("delete from " + this.state.groupPermArrayBasic[i].permissions)
                let PermQuary = {type:"delete_Groups_Permission",select_type:"delete_Groups_Permission_Publications",select_params:{object_id:id,group_id:this.state.groupPermArray[i].id}}
                axios.post(urlBackend+'/postQuery', {query: PermQuary})
                    .then((res) => {
                        console.log(res)
                    })
                    .catch((error)=>{console.log(error)})
            }else if ( this.state.groupPermArrayBasic[i].permissions !== null && this.state.groupPermArray[i].permissions !== null && this.state.groupPermArrayBasic[i].permissions !== this.state.groupPermArray[i].permissions){
                //update
                console.log("update from " +this.state.groupPermArrayBasic[i].permissions + " to " + this.state.groupPermArray[i].permissions)
                let PermQuary = {type:"update_Groups_Permission",select_type:"update_Groups_Permission_Publications",select_params:{object_id:id,group_id:this.state.groupPermArray[i].id,permission:permCode}}
                axios.post(urlBackend+'/postQuery', {query: PermQuary})
                    .then((res) => {
                        console.log(res)
                    })
                    .catch((error)=>{console.log(error)})
            }
        }
    }
    render() {
        const { t } = this.props;
        // eslint-disable-next-line no-unused-vars
        let files;
        return(
            <div style={{display:this.props.display}} >
                <Button basic size={"mini"} onClick={()=>{
                    if (this.state.addDisplay === "none"){
                        let PermQuary = {type:"get_Groups_Permission",select_type:"get_Groups_Permission_Without_Object",select_params:{user_id:Cookies.get("id")}}
                        axios.post(urlBackend+'/postQuery', {query: PermQuary})
                            .then((res) => {
                                console.log(res)
                                this.setState({addDisplay:"block",groupPermArray:res.data[0].get_permission_groups,groupPermArrayBasic:JSON.parse(JSON.stringify(res.data[0].get_permission_groups))})
                            })
                            .catch((error)=>{console.log(error)})
                    }
                    else
                        this.setState({addDisplay:"none"})
                }}><Icon name='plus'  />{i18n.t("Add new publication")}</Button>
                <div style={{display:this.state.addDisplay}}>
                    {this.state.publicationError}
                    <br/>
                    <div>
                        <span >{i18n.t("Имя публикации : ")}<Input value={this.state.publicationName}  onChange={(e,val) =>{
                            this.setState({publicationName:val.value})
                        }}/></span>
                        <br/>
                        <span >{i18n.t("Автор : ")}<Input value={this.state.author}  onChange={(e,val) =>{
                            this.setState({author:val.value})
                        }}/></span>
                        <br/>
                        <span >{i18n.t("Doi : ")}<Input value={this.state.doi}  onChange={(e,val) =>{
                            this.setState({doi:val.value})
                        }}/></span>
                        <br/>
                        <span >{i18n.t("Год Публикации : ")}<Input value={this.state.year}  onChange={(e,val) =>{
                            this.setState({year:val.value})
                        }}/></span>
                        <br/>
                        <span >{i18n.t("Ссылка : ")}<Input value={this.state.url}  onChange={(e,val) =>{
                            this.setState({url:val.value})
                        }}/></span>
                        <br/>
                        <span >{i18n.t("Теги : ")}<Dropdown
                            options={this.props.tags}
                            onChange={(e,val) => {
                                console.log(val)
                                this.setState({publicationTags:val.value})
                            }}
                            selection
                            multiple={true}
                            placeholder={i18n.t("Tags")} /></span>
                        <br/>
                        <span >{i18n.t("Тип публикации : ")}<Dropdown
                            options={this.props.word}
                            value={this.state.publicationType}
                            onChange={(e,val) => {
                                console.log(this.props.word,val)
                                this.setState({publicationType:val.value})
                            }}
                            selection
                            placeholder={i18n.t("Publications Type")} /></span>
                    </div>
                    <Dropzone onDrop={acceptedFiles => {
                        files = acceptedFiles;
                        console.log(acceptedFiles[0].name)
                        if (acceptedFiles[0].name !== undefined)
                            this.setState({PublicationFiles:acceptedFiles,publicationText:i18n.t("Files to Upload : ")+ acceptedFiles[0].name});
                        else
                            this.setState({PublicationFiles:acceptedFiles,publicationText:i18n.t("Files to Upload : ")});
                    }}
                    >
                        {({getRootProps, getInputProps}) => (
                            <section>
                                <div style={{height:"50px",width:"300px",borderRadius: "15px",padding: "10px",border:"1px solid black"}} {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    {i18n.t("Drag'n'drop files here, or click to select files.")}
                                </div>
                            </section>
                        )}

                    </Dropzone>
                    <Button basic size={"mini"} onClick={()=>{
                        if (this.state.PermissionDisplay === "none")
                            this.setState({PermissionDisplay:"block",PermissionDisplayIcon:'minus'})
                        else
                            this.setState({PermissionDisplay:"none",PermissionDisplayIcon:'plus'})
                    }}><Icon name={this.state.PermissionDisplayIcon}  />{i18n.t("Show/Hide Permission Editing")}</Button>
                    <br/>
                    <div style={{display:this.state.PermissionDisplay}}>
                        <GroupPermissionForObject csstop={null} arrayChange={(array)=>{this.setState({groupPermArray:array})}} permArrayBasic={this.state.groupPermArrayBasic} permArray={this.state.groupPermArray} id={null} close={()=>{this.setState({PermissionDisplay:"none"})}} display={this.state.PermissionDisplay} />
                    </div>

                        <Dialog
                            classes={{ root: 'MenuItem'}}
                            open={this.state.open}
                            TransitionComponent={Transition}
                            keepMounted
                            onClose={()=>{this.setState({open:false})}}
                            aria-labelledby="alert-dialog-slide-title"
                            aria-describedby="alert-dialog-slide-description"
                        >
                            <DialogTitle id="alert-dialog-slide-title">{t("Similar publication already exists in the database, please use search to check")}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-slide-description">
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={()=>{this.setState({open:false})}} color="primary">
                                    {i18n.t("Cancel uploading")}
                                </Button>
                                <Button onClick={()=> {
                                    let doi ;
                                    if (this.state.doi.includes("https://doi.org/"))
                                        doi = this.state.doi;
                                    else
                                        doi = "https://doi.org/"+this.state.doi;
                                    let layer = this.props.layer;
                                    let url = null;
                                    if ( this.state.url !== undefined && this.state.url !=="")
                                        url = this.state.url;
                                    let name = this.state.fileName;
                                    let type;
                                    if (this.state.PublicationFiles){
                                        if (this.state.PublicationFiles[0])
                                            type=this.state.PublicationFiles[0].type;
                                        else
                                            type=null;
                                    }
                                    else
                                        type=null;
                                    let values = "'"+name+"' , (select f_layer_id from layer where f_layer_tablename = '"+this.props.layer+"'), "+this.props.id+", "+Cookies.get("id")+", '"+type+"', 181"
                                    axios.post(urlBackend+'/postQuery', {query:{type:"attachments_insert", select_type:"insert", select_params:{values:values}}})
                                        .then((res) => {
                                            let values2 = ""+res.data[0].id+", "+this.state.publicationType+",'"+this.state.publicationName+"',"+this.state.year+",'"+doi+"','"+url+"','"+this.state.author+"',"+Cookies.get("id")+""
                                            console.log(res.data[0].id)
                                            axios.post(urlBackend+'/postQuery', {query:{type:"publication_insert", select_type:"insert", select_params:{values:values2}}})
                                                .then((res) => {
                                                    let bulkCreate = [];
                                                    for (let i = 0; i < this.state.publicationTags.length;i++){
                                                        bulkCreate.push({f_publications_id:res.data[0].f_publication_id,tag_id:this.state.publicationTags[i]})
                                                    }
                                                    this.setPermission(res.data[0].f_publication_id)
                                                    axios.post(urlBackend+'/postQuery', {query:{type:"insert_publications_tags",select_type:"insert", select_params:{bulk:bulkCreate}}})
                                                        .then((res) => {
                                                            this.props.update(this.props.id,layer);
                                                            this.setState({publicationText:"Files Uploaded:"+name,publicationType:"",publicationTags:[],
                                                                url:"",
                                                                mapType:"",
                                                                tags:[],
                                                                author:"",
                                                                doi:"",
                                                                year:"",
                                                                publicationError:"",
                                                                publicationName:"",
                                                                PublicationFiles:null})
                                                        })
                                                        .catch((err) => {console.log(err)});
                                                }).catch((error) => {
                                                console.log(error)
                                            });

                                        }).catch((error) => {
                                        console.log(error)
                                    });
                                }} color="primary">
                                    {i18n.t("Upload anyway")}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    {((this.state.PublicationFiles !== undefined  && this.state.PublicationFiles !== null)||(this.state.url !== "" && this.state.url !== null))&&(<span><Label basic>{this.state.publicationText+" "}</Label><br/></span>)}
                    <Button basic onClick={()=>{
                        console.log(this.state)
                        if (this.state.publicationType !== "" && this.state.publicationName !== "" && ((this.state.PublicationFiles!==null && this.state.PublicationFiles!==undefined) ||(this.state.url !== "" && this.state.url !== null))){
                            const dataform = new FormData();
                            console.log(this.state.PublicationFiles);
                            if(this.state.PublicationFiles!==null && this.state.PublicationFiles!==undefined)
                                dataform.append('file',this.state.PublicationFiles[0]);
                            else
                                dataform.append('file',"none");
                            let doi ;
                            if (this.state.doi.includes("https://doi.org/"))
                                doi = this.state.doi;
                            else
                                doi = "https://doi.org/"+this.state.doi;
                            //console.log(dataform,this.state.selectedFile);
                            axios.post(urlBackend+'/uploadtext', dataform, {
                                // receive two    parameter endpoint url ,form data
                            }).then(res => { // then print response status
                                //console.log(res,this.state.id)
                                console.log(this.props,res)
                                let layer = this.props.layer;
                                let name = null;
                                let type = null;
                                let url = null;
                                if ( this.state.url !== undefined && this.state.url !=="")
                                    url = this.state.url;
                                if (res.data === ""){
                                    name = "";
                                    type = null;
                                }
                                else {
                                    name = res.data.filename;
                                    type = this.state.PublicationFiles[0].type;
                                }
                                axios.post(urlBackend+'/postQuery', {query:{type:"publication_exists", select_type:"exists", select_params:{publicationName:this.state.publicationName,author:this.state.author,url:this.state.url,name:name}}})
                                    .then((res) => {
                                        console.log(res)
                                        if (res.data[0].exists) {
                                            console.log(name)
                                            this.setState({fileName:name,open:true});

                                        }
                                        else{
                                            let values = "'"+name+"' , (select f_layer_id from layer where f_layer_tablename = '"+this.props.layer+"'), "+this.props.id+", "+Cookies.get("id")+", '"+type+"', 181";
                                            axios.post(urlBackend+'/postQuery', {query:{type:"attachments_insert", select_type:"insert", select_params:{values:values}}})
                                                .then((res) => {
                                                    console.log(res)
                                                    let values2 = ""+res.data[0].id+", "+this.state.publicationType+",'"+this.state.publicationName+"',"+this.state.year+",'"+doi+"','"+url+"','"+this.state.author+"',"+Cookies.get("id")+""
                                                    axios.post(urlBackend+'/postQuery', {query:{type:"publication_insert", select_type:"insert", select_params:{values:values2}}})
                                                        .then((res) => {
                                                            let bulkCreate = [];
                                                            for (let i = 0; i < this.state.publicationTags.length;i++){
                                                                bulkCreate.push({f_publications_id:res.data[0].f_publication_id,tag_id:this.state.publicationTags[i]})
                                                            }
                                                            this.setPermission(res.data[0].f_publication_id)
                                                            axios.post(urlBackend+'/postQuery', {query:{type:"insert_publications_tags",select_type:"insert", select_params:{bulk:bulkCreate}}})
                                                                .then((res) => {
                                                                    this.props.update(this.props.id,layer);
                                                                    this.setState({publicationText:"Files Uploaded:"+name,publicationType:"",publicationTags:[],
                                                                        url:"",
                                                                        mapType:"",
                                                                        tags:[],
                                                                        author:"",
                                                                        doi:"",
                                                                        year:"",
                                                                        publicationError:"",
                                                                        publicationName:"",
                                                                        PublicationFiles:null})
                                                                })
                                                                .catch((err) => {console.log(err)});

                                                        }).catch((error) => {
                                                        console.log(error)
                                                    });

                                                }).catch((error) => {
                                                console.log(error)
                                            });
                                        }
                                    }).catch((error) => {
                                    console.log(error)
                                });

                                //this.setState({url:urlBackend+'/displayimages?file='+res.data.filename});

                            }).catch((error) => {
                                console.log(error)
                            });
                        }else{
                            this.setState({publicationError:"Fill required fields"})
                        }
                    }}>{i18n.t("Save")}</Button>
                </div>
            </div>
        )}}
export default AddPublications;
