import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";

export default function LoadLoginOnStart(props) {
    const [loading,setLoading] = useState(false)
    let params = useParams();
    console.log(params)

    useEffect(() => {
        if (loading)
            return
        setLoading(true)
        props.loginhandle()
    }, [loading, props]);



}