import React from "react";
import {Button, Dropdown} from "semantic-ui-react";
import axios from "axios";
const config = require('../config');
const hostMartin = config.HOSTMartin;
const portMartin = config.PORTMartin;
const protocolMartin = config.ProtocolMartin;
const prefixMartin = config.PrefixMartin;
var urlMartin=protocolMartin+'://'+hostMartin+':'+portMartin+prefixMartin;
if (portMartin=== "" || portMartin === undefined){
    urlMartin=protocolMartin+'://'+hostMartin+prefixMartin;
}
else{
    urlMartin=protocolMartin+'://'+hostMartin+':'+portMartin+prefixMartin;
}
const hostBackend = config.HOSTBackend;
const portBackend = config.PORTBackend;
const protocolBackend = config.ProtocolBackend;
const prefixBackend = config.PrefixBackend;
var urlBackend=protocolBackend+'://'+hostBackend+':'+portBackend+prefixBackend;
if (portBackend === "" || portBackend === undefined){
    urlBackend=protocolBackend+'://'+hostBackend+prefixBackend;
}
else{
    urlBackend=protocolBackend+'://'+hostBackend+':'+portBackend+prefixBackend;
}
class AddHeatMapMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            display:"none",
            layers:["Basins","SubBasins","Fields","Licenses","GeologicMap","TectonicMap","GeoloicObjects","ProtectedAreas","Wells"],
            layerstype:["heatmap","bubble"],
            layer:"",
            layertype:"",
            layer_view:"",
            layername : "",
            layerattr:"",
            res : '',
            sourcelayer : "",
            props:null,
            properties:[""],
        }
    };
    render() {
        console.log(this.props.coord)
        return(
            <div className={"addheatmap"} style={{display:this.props.display,left:"360px",top:this.props.coord.y}} >
                <Button basic size={'mini'} onClick={()=>{
                    this.setState({layer:null,layertype:null,layerattr:null})
                    this.props.addLayer(null,null,null,null,null,null,null,"close")
                }} icon><i className="close icon"/></Button>
                {"Choose layer type :"}
                <Dropdown  options={this.state.layerstype.map(data => ({key: data, text:data, value: data}))} onChange={(option,value)=>{
                    console.log(option,value);
                    let name = value.value;
                    this.setState({layertype:name});
                    //this.props.addLayer(layername,res,sourcelayer)
                }} value={this.state.layertype} fluid selection placeholder="Select an option" />
                <br/>
                {"Choose layer :"}
                <Dropdown  options={this.state.layers.map(data => ({key: data, text:data, value: data}))} onChange={(option,value)=>{
                    console.log(option,value);
                    let name = value.value;
                    let layername = "HeatmapLicenses";
                    let res = urlMartin+'/public.mvtlicensestext/{z}/{x}/{y}.pbf';
                    let sourcelayer = "public.mvtlicensestext";
                    let props = ["density"];
                    let layer_view="";
                    switch(name){
                        case 'Basins':
                            layername = "Heatmap_Basins";
                            res = urlMartin+'/public.mvtbasinstext/{z}/{x}/{y}.pbf';
                            sourcelayer = "public.mvtbasinstext";
                            props=["area"];
                            layer_view = "v_basins";
                            break;
                        case 'SubBasins':
                            layername = "Heatmap_SubBasins";
                            res = urlMartin+'/public.mvtsubbasinstext/{z}/{x}/{y}.pbf';
                            sourcelayer = "public.mvtsubbasinstext";
                            props=["area"];
                            layer_view = "v_subbasins";
                            break;
                        case 'Fields':
                            layername = "Heatmap_Fields";
                            res = urlMartin+'/public.mvtfieldstext/{z}/{x}/{y}.pbf';
                            sourcelayer = "public.mvtfieldstext";
                            props=["area"];
                            layer_view = "v_fields";
                            break;
                        case 'Licenses':
                            layername = "Heatmap_Licenses";
                            res = urlMartin+'/public.mvtlicensestext/{z}/{x}/{y}.pbf';
                            sourcelayer = "public.mvtlicensestext";
                            props=["area","auction_value","paid_value"];
                            layer_view = "v_licenses";
                            break;
                        case 'GeologicMap':
                            layername = "Heatmap_GeologicMap";
                            res = urlMartin+'/public.mvtgeologicmaptext/{z}/{x}/{y}.pbf';
                            sourcelayer = "public.mvtgeologicmaptext";
                            props = ["density"];
                            break;
                        case 'TectonicMap':
                            layername = "Heatmap_TectonicMap";
                            res = urlMartin+'/public.mvttectonicmaptext/{z}/{x}/{y}.pbf';
                            sourcelayer = "public.mvttectonicmaptext";
                            props = ["density"];
                            break;
                        case 'GeoloicObjects':
                            layername = "Heatmap_GeoloicObjects";
                            res = urlMartin+'/public.mvtgeologicobjectstext/{z}/{x}/{y}.pbf';
                            sourcelayer = "public.mvtgeologicobjectstext";
                            props = ["density"];
                            break;
                        case 'ProtectedAreas':
                            layername = "Heatmap_ProtectedAreas";
                            res = urlMartin+'/public.mvtprotectedareastext/{z}/{x}/{y}.pbf';
                            sourcelayer = "public.mvtprotectedareastext";
                            props = ["density"];
                            break;
                        case 'Wells':
                            layername = "Heatmap_Wells";
                            res = urlMartin+'/public.mvtwellstext/{z}/{x}/{y}.pbf';
                            sourcelayer = "public.mvtwellstext";
                            props = ["density"];
                            break;
                        default :
                            break;
                    }
                    this.setState({layername:layername,res:res,sourcelayer:sourcelayer,props:props,layer:name,layer_view:layer_view});
                    //this.props.addLayer(layername,res,sourcelayer)
                }} value={this.state.layer} fluid selection placeholder="Select an option" />
                <br/>
                {"Choose property : "}
                {(this.state.props !== null)&&(<Dropdown  options={this.state.props.map(data => ({key: data, text:data, value: data}))} onChange={(option,value)=>{
                    function getIntervalls(min,max, nInt) {
                        const c = Math.floor((max-min) / nInt);
                        const r = [];
                        for (var i = min; i <= max; i += c) {
                            const a = i === 0 ? i : i += 1;
                            const b = i + c > max ? max : i + c;
                            if (a < max) r.push([a, b])
                        }
                        return r;
                    }
                    if (this.state.layertype === "heatmap") {
                        let name = value.value;
                        let name_prefix = "Heatmap_"+this.state.layer;
                        let paint ={
                            // Increase the heatmap weight based on frequency and property magnitude
                            'heatmap-weight': [
                                'interpolate',
                                ['linear'],
                                ['get', 'area'],
                                0,
                                0,
                                6,
                                1
                            ],
                            // Increase the heatmap color weight weight by zoom level
                            // heatmap-intensity is a multiplier on top of heatmap-weight
                            'heatmap-intensity': [
                                'interpolate',
                                ['linear'],
                                ['zoom'],
                                0,
                                1,
                                9,
                                3
                            ],
                            // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
                            // Begin color ramp at 0-stop with a 0-transparancy color
                            // to create a blur-like effect.
                            'heatmap-color': [
                                'interpolate',
                                ['linear'],
                                ['heatmap-density'],
                                0,
                                'rgba(33,102,172,0)',
                                0.2,
                                'rgb(103,169,207)',
                                0.4,
                                'rgb(209,229,240)',
                                0.6,
                                'rgb(253,219,199)',
                                0.7,
                                'rgb(178,145,23)',
                                0.8,
                                'rgb(239,138,98)',
                                0.9,
                                'rgb(178,79,58)',
                                1,
                                'rgb(178,24,43)'
                            ],
                            // Adjust the heatmap radius by zoom level
                            'heatmap-radius': [
                                'interpolate',
                                ['linear'],
                                ['zoom'],
                                0,
                                2,
                                9,
                                20
                            ],
                            // Transition from heatmap to circle layer by zoom level
                            'heatmap-opacity': [
                                'interpolate',
                                ['linear'],
                                ['zoom'],
                                7,
                                1,
                                9,
                                0
                            ]
                        };
                        let min ;
                        let max;
                        switch(name){
                            case 'area':
                                paint ={
                                    // Increase the heatmap weight based on frequency and property magnitude
                                    'heatmap-weight': [
                                        'interpolate',
                                        ['linear'],
                                        ['get', 'area'],
                                        0,
                                        0,
                                        6,
                                        1
                                    ],
                                    // Increase the heatmap color weight weight by zoom level
                                    // heatmap-intensity is a multiplier on top of heatmap-weight
                                    'heatmap-intensity': [
                                        'interpolate',
                                        ['linear'],
                                        ['zoom'],
                                        0,
                                        1,
                                        9,
                                        3
                                    ],
                                    // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
                                    // Begin color ramp at 0-stop with a 0-transparancy color
                                    // to create a blur-like effect.
                                    'heatmap-color': [
                                        'interpolate',
                                        ['linear'],
                                        ['heatmap-density'],
                                        0,
                                        'rgba(33,102,172,0)',
                                        0.2,
                                        'rgb(103,169,207)',
                                        0.4,
                                        'rgb(209,229,240)',
                                        0.6,
                                        'rgb(253,219,199)',
                                        0.7,
                                        'rgb(178,145,23)',
                                        0.8,
                                        'rgb(239,138,98)',
                                        0.9,
                                        'rgb(178,79,58)',
                                        1,
                                        'rgb(178,24,43)'
                                    ],
                                    // Adjust the heatmap radius by zoom level
                                    'heatmap-radius': [
                                        'interpolate',
                                        ['linear'],
                                        ['zoom'],
                                        0,
                                        2,
                                        9,
                                        20
                                    ],
                                    // Transition from heatmap to circle layer by zoom level
                                    'heatmap-opacity': [
                                        'interpolate',
                                        ['linear'],
                                        ['zoom'],
                                        7,
                                        1,
                                        9,
                                        0
                                    ]
                                };
                                axios.post(urlBackend+'/layerid', {query:'select min('+name+') as min,max('+name+') as max from '+ this.state.layer_view} )
                                    .then((res) => {
                                        console.log(res)
                                        max = res.data[0].max;
                                        min = res.data[0].min;
                                    }).catch((error) => {
                                    console.log(error)
                                });
                                break;
                            case 'auction_value':
                                paint ={
                                    // Increase the heatmap weight based on frequency and property magnitude
                                    'heatmap-weight': [
                                        'interpolate',
                                        ['linear'],
                                        ['get', 'auction_value'],
                                        0,
                                        0,
                                        6,
                                        1
                                    ],
                                    // Increase the heatmap color weight weight by zoom level
                                    // heatmap-intensity is a multiplier on top of heatmap-weight
                                    'heatmap-intensity': [
                                        'interpolate',
                                        ['linear'],
                                        ['zoom'],
                                        0,
                                        1,
                                        9,
                                        3
                                    ],
                                    // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
                                    // Begin color ramp at 0-stop with a 0-transparancy color
                                    // to create a blur-like effect.
                                    'heatmap-color': [
                                        'interpolate',
                                        ['linear'],
                                        ['heatmap-density'],
                                        0,
                                        'rgba(33,102,172,0)',
                                        0.2,
                                        'rgb(103,169,207)',
                                        0.4,
                                        'rgb(209,229,240)',
                                        0.6,
                                        'rgb(253,219,199)',
                                        0.7,
                                        'rgb(178,145,23)',
                                        0.8,
                                        'rgb(239,138,98)',
                                        0.9,
                                        'rgb(178,79,58)',
                                        1,
                                        'rgb(178,24,43)'
                                    ],
                                    // Adjust the heatmap radius by zoom level
                                    'heatmap-radius': [
                                        'interpolate',
                                        ['linear'],
                                        ['zoom'],
                                        0,
                                        2,
                                        9,
                                        20
                                    ],
                                    // Transition from heatmap to circle layer by zoom level
                                    'heatmap-opacity': [
                                        'interpolate',
                                        ['linear'],
                                        ['zoom'],
                                        7,
                                        1,
                                        9,
                                        0
                                    ]
                                };
                                axios.post(urlBackend+'/layerid', {query:'select min('+name+') as min,max('+name+') as max from '+ this.state.layer_view} )
                                    .then((res) => {
                                        console.log(res)
                                        max = res.data[0].max;
                                        min = res.data[0].min;
                                    }).catch((error) => {
                                    console.log(error)
                                });
                                break;
                            case 'paid_value':
                                paint ={
                                    // Increase the heatmap weight based on frequency and property magnitude
                                    'heatmap-weight': [
                                        'interpolate',
                                        ['linear'],
                                        ['get', 'paid_value'],
                                        0,
                                        0,
                                        6,
                                        1
                                    ],
                                    // Increase the heatmap color weight weight by zoom level
                                    // heatmap-intensity is a multiplier on top of heatmap-weight
                                    'heatmap-intensity': [
                                        'interpolate',
                                        ['linear'],
                                        ['zoom'],
                                        0,
                                        1,
                                        9,
                                        3
                                    ],
                                    // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
                                    // Begin color ramp at 0-stop with a 0-transparancy color
                                    // to create a blur-like effect.
                                    'heatmap-color': [
                                        'interpolate',
                                        ['linear'],
                                        ['heatmap-density'],
                                        0,
                                        'rgba(33,102,172,0)',
                                        0.2,
                                        'rgb(103,169,207)',
                                        0.4,
                                        'rgb(209,229,240)',
                                        0.6,
                                        'rgb(253,219,199)',
                                        0.7,
                                        'rgb(178,145,23)',
                                        0.8,
                                        'rgb(239,138,98)',
                                        0.9,
                                        'rgb(178,79,58)',
                                        1,
                                        'rgb(178,24,43)'
                                    ],
                                    // Adjust the heatmap radius by zoom level
                                    'heatmap-radius': [
                                        'interpolate',
                                        ['linear'],
                                        ['zoom'],
                                        0,
                                        2,
                                        9,
                                        20
                                    ],
                                    // Transition from heatmap to circle layer by zoom level
                                    'heatmap-opacity': [
                                        'interpolate',
                                        ['linear'],
                                        ['zoom'],
                                        7,
                                        1,
                                        9,
                                        0
                                    ]
                                };
                                axios.post(urlBackend+'/layerid', {query:'select min('+name+') as min,max('+name+') as max from '+ this.state.layer_view} )
                                    .then((res) => {
                                        console.log(res)
                                        max = res.data[0].max;
                                        min = res.data[0].min;
                                    }).catch((error) => {
                                    console.log(error)
                                });
                                break;
                            default :
                                break;
                        }
                        //this.setState({layername:layername,res:res,sourcelayer:sourcelayer,props:props,layer:name});
                        this.setState({layer:null,layertype:null,layerattr:null})
                        this.props.addLayer(name_prefix+"_"+name,this.state.res,this.state.sourcelayer,paint,min,max,name,"heatmap")
                    }
                    else {
                        let name = value.value;
                        let name_prefix = "Bubble_"+this.state.layer;
                        let paint ={
                            'circle-radius': [
                                'interpolate',
                                ['linear'],
                                ['number', ['get', name]],
                                0,
                                4,
                                5,
                                24
                            ],
                            'circle-color': [
                                'interpolate',
                                ['linear'],
                                ['number', ['get', name]],
                                0,
                                '#2DC4B2',
                                1,
                                '#3BB3C3',
                                2,
                                '#669EC4',
                                3,
                                '#8B88B6',
                                4,
                                '#A2719B',
                                5,
                                '#AA5E79'
                            ],
                            'circle-opacity': 0.8
                        }
                        let min ;
                        let max;
                        switch(name){
                            case 'area':
                                axios.post(urlBackend+'/layerid', {query:'select min('+name+') as min,max('+name+') as max from '+ this.state.layer_view} )
                                    .then((res) => {
                                        console.log(res)
                                        max = res.data[0].max;
                                        min = res.data[0].min;
                                        let interval = getIntervalls(min,max,6)
                                        paint ={
                                            'circle-radius': [
                                                'interpolate',
                                                ['linear'],
                                                ['number', ['get', name]],
                                                min,
                                                4,
                                                max,
                                                24
                                            ],
                                            'circle-color': [
                                                'interpolate',
                                                ['linear'],
                                                ['number', ['get', name]],
                                                interval[0][0],
                                                '#2DC4B2',
                                                interval[1][0],
                                                '#3BB3C3',
                                                interval[2][0],
                                                '#669EC4',
                                                interval[3][0],
                                                '#8B88B6',
                                                interval[4][0],
                                                '#A2719B',
                                                interval[5][0],
                                                '#AA5E79'
                                            ],
                                            'circle-opacity': 0.8
                                        }
                                        this.setState({layer:null,layertype:null,layerattr:null})
                                        this.props.addLayer(name_prefix+"_"+name,this.state.res,this.state.sourcelayer,paint,min,max,name,"bubble")
                                    }).catch((error) => {
                                    console.log(error)
                                });
                                break;
                            case 'auction_value':
                                axios.post(urlBackend+'/layerid', {query:'select min('+name+') as min,max('+name+') as max from '+ this.state.layer_view} )
                                    .then((res) => {
                                        console.log(res)
                                        max = res.data[0].max;
                                        min = res.data[0].min;
                                        let interval = getIntervalls(min,max,6)
                                        paint ={
                                            'circle-radius': [
                                                'interpolate',
                                                ['linear'],
                                                ['number', ['get', name]],
                                                min,
                                                4,
                                                max,
                                                24
                                            ],
                                            'circle-color': [
                                                'interpolate',
                                                ['linear'],
                                                ['number', ['get', name]],
                                                interval[0][0],
                                                '#2DC4B2',
                                                interval[1][0],
                                                '#3BB3C3',
                                                interval[2][0],
                                                '#669EC4',
                                                interval[3][0],
                                                '#8B88B6',
                                                interval[4][0],
                                                '#A2719B',
                                                interval[5][0],
                                                '#AA5E79'
                                            ],
                                            'circle-opacity': 0.8
                                        }
                                        this.setState({layer:null,layertype:null,layerattr:null})
                                        this.props.addLayer(name_prefix+"_"+name,this.state.res,this.state.sourcelayer,paint,min,max,name,"bubble")
                                    }).catch((error) => {
                                    console.log(error)
                                });
                                break;
                            case 'paid_value':
                                axios.post(urlBackend+'/layerid', {query:'select min('+name+') as min,max('+name+') as max from '+ this.state.layer_view} )
                                    .then((res) => {
                                        console.log(res)
                                        max = res.data[0].max;
                                        min = res.data[0].min;
                                        let interval = getIntervalls(min,max,6)
                                        paint ={
                                            'circle-radius': [
                                                'interpolate',
                                                ['linear'],
                                                ['number', ['get', name]],
                                                min,
                                                4,
                                                max,
                                                24
                                            ],
                                            'circle-color': [
                                                'interpolate',
                                                ['linear'],
                                                ['number', ['get', name]],
                                                interval[0][0],
                                                '#2DC4B2',
                                                interval[1][0],
                                                '#3BB3C3',
                                                interval[2][0],
                                                '#669EC4',
                                                interval[3][0],
                                                '#8B88B6',
                                                interval[4][0],
                                                '#A2719B',
                                                interval[5][0],
                                                '#AA5E79'
                                            ],
                                            'circle-opacity': 0.8
                                        }
                                        this.setState({layer:null,layertype:null,layerattr:null})
                                        this.props.addLayer(name_prefix+"_"+name,this.state.res,this.state.sourcelayer,paint,min,max,name,"bubble")
                                    }).catch((error) => {
                                    console.log(error)
                                });
                                break;
                            default :
                                break;
                        }
                        //this.setState({layername:layername,res:res,sourcelayer:sourcelayer,props:props,layer:name});
                    }
                }} value={this.state.layerattr} fluid selection placeholder="Select an option" />)}
                <br/>

            </div>
        )}}
export default AddHeatMapMenu;
