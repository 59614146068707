import React, {useEffect, useRef, useState} from "react";

export default function MyGeoMapImage(props) {
    useEffect(() => {
        setTimeout(async () => {
            console.log(props.map, "startLoading", props.map.current,props.name,props.image)
            if (!props.map.current) return
            props.map.current.loadImage(
                props.image,
                function (err, image) {
                    console.log(image, "loaded", err)
                    if (err) throw err;
                    props.map.current.addImage(props.name, image);
                }
            );
            let image = await props.map.current.loadImage(props.image);
            props.map.current.addImage(props.name, image.data);
            props.map.current.on('styleimagemissing', (e) => {
                const id = e.id; // id of the missing image
                console.log(id, "loaded", e)
                // Check if this missing icon is
                // one this function can generate.
                if (!id.includes(props.name)) return;

                props.map.current.loadImage(
                    props.image,
                    function (err, image) {
                        console.log(image, "loaded", err)
                        if (err) throw err;
                        props.map.current.addImage(props.name, image);
                    }
                );
            });
        }, 1000)


    }, [props.map,props.image,props.name,props.result]);

}