import React, {useEffect, useRef, useState} from "react";
import maplibregl from "maplibre-gl";

export default function MyGeoMapLayer(props) {
    const [iteration,setIteration] = React.useState(1)
    const [visibility,setVisibility] = useState(null)
    const [enable,setEnable] = useState(null)
    const [loaded,setLoaded] = useState(false)
    useEffect(() => {
        //console.log(props, props.id, props.layout, props.map, props.onClick, props.onHover, props.onLeave, props.paint, props.source, props.sourceLayer, props.type)
        if (!props.map || props.map.current === null)
            return
        //console.log("has map")
        props.map.current.once('styledata',()=>{
            //console.log("on load")
            if (props.type !== "multilayer"){
                //console.log("not multilayer")
                let paint = {}
                let options = {
                    'id': props.id,
                    'type': props.type,
                    'source': props.source,
                }
                if (props.paint)
                    options.paint = props.paint;
                if (props.filter)
                    options.filter = props.filter;
                if (props.layout)
                    options.layout = props.layout;
                if (props.sourceLayer)
                    options['source-layer'] = props.sourceLayer
                //console.log(options,props.before)
                let before = props.before
                if (!props.before || props.before.includes('Info') )
                    before = null
                if (!props.before || props.before.includes('.csv') || props.before.includes('.geojson')){
                    if (props.map.current.getLayer('0'+props.before) )
                        before = '0'+props.before
                    else if (props.map.current.getLayer('00'+props.before) )
                        before = '00'+props.before
                    else if (!props.map.current.getLayer(props.before) )
                        before = null
                }
                //console.log(options,before,props)
                //console.log(props.map.current.getStyle().layers)
                /*
                if (props.map.current.getLayer(props.id) ){
                    console.log("deleting layer")
                    props.map.current.removeLayer(props.id)
                }

                 */
                let current_visibility = props.visibility
                if (!props.map.current.getLayer(props.id)) {
                    console.log("new_Layer",current_visibility,props.id)
                    if (!props.map.current.getLayer(before))
                        props.map.current.addLayer(options);
                    else
                        props.map.current.addLayer(options,before);
                }else{
                    //console.log("old_Layer",current_visibility)
                    if (props.paint)
                        for (let i = 0 ; i < Object.keys(props.paint).length ;i++){
                            //console.log("old_Layer change props",props.id, Object.keys(props.paint)[i], Object.values(props.paint)[i])
                            props.map.current.setPaintProperty(props.id, Object.keys(props.paint)[i], Object.values(props.paint)[i]);
                        }
                    if (props.layout)
                        for (let i = 0 ; i < Object.keys(props.layout).length ;i++){
                            //console.log("old_Layer change props text",props.id, Object.keys(props.layout)[i], Object.values(props.layout)[i])
                            props.map.current.setLayoutProperty(props.id, Object.keys(props.layout)[i], Object.values(props.layout)[i]);
                        }
                    //console.log("old_Layer change visability",props.id, 'visibility', current_visibility)
                    props.map.current.setLayoutProperty(props.id, 'visibility', current_visibility);
                }
                setVisibility(props.visibility)

            }
            else{
                let length = props.layers.length;
                //console.log("multilayer",length)
                if (length !== undefined)
                    for (let j = 0; j < length; j++) {
                        if (!props.map.current.getLayer(props.layers[j].idlayer)) {
                            if (props.layers[j].type === "line"){
                                props.map.current.addLayer({
                                        'id': props.layers[j].idlayer,
                                        'beforeid':props.before,
                                        'type': 'line',
                                        'source': 'lines',
                                        'source-layer': props.layers[j].idlayer,
                                        'paint': {
                                            'line-width': props.layers[j].size,
                                            'line-color': props.layers[j].color
                                        },
                                        'layout': {
                                            'visibility': props.visibility,
                                        }
                                    }
                                );}
                            else if (props.layers[j].type === "area"){
                                props.map.current.addLayer({
                                        'id': props.layers[j].idlayer,
                                        'beforeid':props.before,
                                        'type': 'fill',
                                        'source': 'lines',
                                        'source-layer': props.layers[j].idlayer,
                                        'paint': {
                                            'fill-color': props.layers[j].color
                                        },
                                        'layout': {
                                            'visibility': props.visibility,
                                        }
                                    }
                                );
                            }
                            else
                                props.map.current.addLayer({
                                        'id':props.layers[j].idlayer,
                                        'beforeid':props.before,
                                        'type': 'circle',
                                        'source': 'lines',
                                        'source-layer': props.layers[j].idlayer,
                                        'paint': {
                                            'circle-radius': props.layers[j].size*2,
                                            'circle-color': props.layers[j].color
                                        },
                                        'layout': {
                                            'visibility': props.visibility,
                                        }
                                    }
                                );
                        }else{

                        }
                        props.map.current.setLayoutProperty(props.layers[j].idlayer, 'visibility', props.visibility);
                    }
            }

            //console.log("before events")
            let hoveredStateId = null;
                //console.log("event creation")
                props.map.current.on('mouseenter', props.id, () => {
                    props.map.current.getCanvas().style.cursor = 'pointer';
                });

                // Change it back to a pointer when it leaves.
                props.map.current.on('mouseleave', props.id, () => {
                    props.map.current.getCanvas().style.cursor = '';
                });
                if (props.layertype === "geojson"){
                    console.log("geojson",props.id)
                    if (!loaded)
                        props.map.current.on('click', props.id, (e) => {
                            console.log("geojson click")
                            var coordinates = e.lngLat;
                            var description = JSON.stringify(e.features[0].properties);
                            description = description.replace("{","");
                            description = description.replace("}","");
                            description = description.replace(/,/g, '<br/>');
                            // Ensure that if the map is zoomed out such that multiple
                            // copies of the feature are visible, the popup appears
                            // over the copy being pointed to.
                            while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                                coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                            }
                            console.log(description,coordinates)
                            new maplibregl.Popup()
                                .setLngLat(coordinates)
                                .setHTML(description)
                                .addTo(props.map.current);
                        });
                }else if (props.type !== "raster"){
                    //console.log("popup event ",props.id,props.popup)
                    props.map.current.on('mousemove', props.id, function(e) {
                        //console.log("popup event created",props.id,props.popup)
                        if (props.popup ){
                            props.map.current.getCanvas().style.cursor = 'pointer';
                            //console.log(e.features,e.features.length)
                            if ( e.features.length > 0){
                                var coordinates = e.lngLat;
                                var description = e.features[0].properties.OriginalName;
                                while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                                    coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                                }
                                if ( props.map_popup.current)
                                    props.map_popup.current.setLngLat(e.lngLat).setHTML(description).addTo(props.map.current);
                            }
                        }} );
                    props.map.current.on('mouseleave', props.id, function(e) {
                        if (props.map.current)
                            props.map.current.getCanvas().style.cursor = '';
                        if (props.map_popup && props.map_popup.current)
                            props.map_popup.current.remove();
                    });
                }
                if ( props.type !== "raster"){
                    if (props.onHover) {
                        props.map.current.on('mousemove', props.id, (e)=>{});
                    }

                        props.map.current.on('mouseleave', props.id, (e)=>{
                            if (hoveredStateId) {
                                props.map.current.setFeatureState(
                                    {source: props.source, id: hoveredStateId,sourceLayer:props.sourceLayer},
                                    {hover: false}
                                );
                            }
                            hoveredStateId = null;
                        });
                        props.map.current.on('click', props.id, (e)=>{
                            //console.log(e);
                            if (e.features.length > 0) {
                                if (hoveredStateId) {
                                    props.map.current.setFeatureState(
                                        {source: props.source, id: hoveredStateId,sourceLayer:props.sourceLayer},
                                        {hover: false}
                                    );
                                }
                                hoveredStateId = e.features[0].id;
                                props.map.current.setFeatureState(
                                    {source: props.source, id: hoveredStateId,sourceLayer:props.sourceLayer},
                                    {hover: true}
                                );
                            }
                            //if (props.onClick)
                                //props.onClick(e)
                        });

                }


            setLoaded(true)
        })
    }, [props.popup, props.map_popup, props.layertype, props.layers, props.visibility, props.id, props.layout, props.map, props.onClick, props.onHover, props.onLeave, props.paint, props.source, props.sourceLayer, props.type, props]);
    useEffect(() => {
        //console.log(props.type, props.map, props.visibility, props.id)
        if (!props.map || props.map.current === null || !loaded)
            return

            console.log('loaded',props.id,props.visibility)
            if (props.type !== "multilayer"){
                props.map.current.setLayoutProperty(props.id, 'visibility', props.visibility);
            }

    }, [props.type, props.map, props.visibility, props.id]);
    useEffect(() => {
        if (!props.map || props.map.current === null || !loaded)
            return
        console.log('loaded',props.id,props.visibility)
        if (props.map.current.getLayer(props.id)) {
            if (props.paint)
                for (let i = 0 ; i < Object.keys(props.paint).length ;i++){
                    //console.log("old_Layer change props",props.id, Object.keys(props.paint)[i], Object.values(props.paint)[i])
                    props.map.current.setPaintProperty(props.id, Object.keys(props.paint)[i], Object.values(props.paint)[i]);
                }
            if (props.layout)
                for (let i = 0 ; i < Object.keys(props.layout).length ;i++){
                    //console.log("old_Layer change props text",props.id, Object.keys(props.layout)[i], Object.values(props.layout)[i])
                    props.map.current.setLayoutProperty(props.id, Object.keys(props.layout)[i], Object.values(props.layout)[i]);
                }
            //console.log("old_Layer change visability",props.id, 'visibility', props.visibility)
            //console.log(props.map.current.getStyle().layers)
            props.map.current.setLayoutProperty(props.id, 'visibility', props.visibility);
        }
        
    }, [ props.id, props.layout, props.map, props.paint,props.visibility, loaded]);
    return(<></>)
}