import React from 'react';
import axios from "axios";
import { Button,Input,Label } from 'semantic-ui-react'


var config = require('../config');
const hostBackend = config.HOSTBackend;
const portBackend = config.PORTBackend;
const protocolBackend = config.ProtocolBackend;
const prefixBackend = config.PrefixBackend;
var urlBackend=protocolBackend+'://'+hostBackend+':'+portBackend+prefixBackend;
if (portBackend === "" || portBackend === undefined){
    urlBackend=protocolBackend+'://'+hostBackend+prefixBackend;
}
else{
    urlBackend=protocolBackend+'://'+hostBackend+':'+portBackend+prefixBackend;
}
class WikimapiaApi extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message:"",
            input:"",
        }
    };
    inputChange=(e,value)=>{
        this.setState({input:value.value})
    };

    inputChangeButton=(e,value)=>{
        this.props.getcenter();
        let center = this.props.center
        console.log(e,value.value,this.state.input)
        //let wikimapiaApiRequest= "http://api.wikimapia.org/?key=9CFC4FAF-7DF2B98B-B51D215E-B49C0DAF-1589FD7E-326A1A3D-B9EF723C-9F3AFE84&function=place.getbyid&id="+value.value+"&format=json"
        let wikimapiaApiRequest2= "http://api.wikimapia.org/?key=example&function=place.getnearest&lat="+center.lat+"&lon="+center.lng+"&format=json&pack=&language=en&count=50&category="+this.state.input;
        //http://api.wikimapia.org/?key=example&function=place.getnearest&lat=48.858252&lon=2.29451&format=json&pack=&language=en&count=50&category=
        console.log(wikimapiaApiRequest2,this.props.center)
        this.props.getcenter();
        console.log(this.props.center);
        axios.post(urlBackend+'/wikimapiaApi', {url:wikimapiaApiRequest2}, {
        }).then(result => {
            this.setState({message:result.data}) ;
            if(result.data.places[0] !== undefined) {
                console.log(result.data.places[0].polygon)

                let features = [];
                for (let j = 0 ; j< result.data.places.length;j++){
                    let polygon =[];
                    let properties = {"title":result.data.places[j].title,"url":result.data.places[j].url};
                    for (let i =0 ; i< result.data.places[j].polygon.length;i++){
                        polygon.push([result.data.places[j].polygon[i].x,result.data.places[j].polygon[i].y])

                    }
                    let feature =
                        { "type": "Feature",
                            "geometry": {
                                "type": "Polygon",
                                "coordinates": [
                                    polygon
                                ]
                            },
                            "properties": properties
                        }
                    features.push(feature);
                }
                let geojson = { "type": "FeatureCollection",
                    "features": features

                }
                console.log(geojson)
                this.props.displayApiResult(geojson);
                this.setState({message:JSON.stringify(result.data) +"/n"+ JSON.stringify(geojson)}) ;
            }
            else {
                this.setState({message:result.data}) ;
            }

        }).catch((error) => {
            console.log(error)
        });
    };
    render() {

        return(
            <div className={"wikimapia"} style={{display:this.props.display}}>
                <Label>Category: </Label>
                <Input onChange={this.inputChange}/>
                <Button onClick={this.inputChangeButton}>Send</Button>
                <br/>
                {JSON.stringify(this.state.message)}
            </div>
        )}}
export default WikimapiaApi;
