import axios from "axios";
//import {useEffect, useState} from "react";
import {useState, useEffect, useRef} from "react";
import React from 'react';
import Draggable from 'react-draggable';
import Plot from 'react-plotly.js';
import * as d3 from 'd3';
import "./Visualizer3D.css"
//import logo from './logo.svg';
//import './App.css';
import 'semantic-ui-css/semantic.min.css'
import {
    Button,
    Checkbox,
    Dropdown,
    Icon,
    Modal,
    ModalHeader,
    ModalContent,
    ModalActions,
    Popup,
    Radio,
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableHeaderCell,
    TableRow,
    TextArea,
    //Segment,
    //Header,
} from 'semantic-ui-react';

import {viewer3dApi } from "../../config"
//viewer3dApi + 'get_surface_plot'
//import SliderView from 'semantic-ui-react-slider';
//import Slider from 'react-slider';



const Visualizer3D = (props) => {
    //const serverUrl = 'https://dev.mygeomap.ru:2007/' ;
    //const serverUrl = 'http://91.107.126.122:8080' ;
    //const serverUrl = 'http://localhost:8095' ;
    const { onStartupUrl } = props;
    const { fileOnMapRenderer } = props;
    const [widthRatio,setWidthRation] = useState(1700) ;
    const [heightRatio,setHeightRatio] = useState(900) ;
    useEffect(() => {
        if (props.widthRatio)
            setWidthRation(props.widthRatio)
        if (props.heightRatio)
            setHeightRatio(props.heightRatio)
    }, [props.widthRatio,props.heightRatio]);
    const [MainMenuVisible, setMainMenuVisible] = useState(true);
    const [MainMenuSliceVisible, setMainMenuSliceVisible] = useState(false);
    const [ExpandedMenuVisible, setExpandedMenuVisible] = useState(false);
    const [DownloadMenuVisible, setDownloadMenuVisible] = useState(false);
    const [downloadColumnVisible, setDownloadColumnVisible] = useState(false);
    const [downloadMainAction, setDownloadMainAction] = useState('download_png');
    const [downloadMenuCrs, setDownloadMenuCrs] = useState(false);
    const [downloadMenuProjFieldValue,setDownloadMenuProjFieldValue] =useState('');
    const [loadingVisible, setLoadingVisible] = useState(false);
    //const [colorShemeEditorVisible, setColorShemeEditorVisible] = useState(false);
    const [plotData, setPlotData] = useState([]);
    const [plotLayout, setPlotLayout] = useState({});
    const [plotDataSlice, setPlotDataSlice] = useState([]);
    //для графика сечения

    const [plotLayoutSlice, setPlotLayoutSlice] = useState({width: 320, height: 240, title: 'Сечение:',});

    //const [slicePoints, setSlicePoints] = useState([[],[]]);//для графика сечения
    let slicePoints = useRef([[],[]]);
    //const [slicePoint1, setSlicePoint1] = useState([]);//для графика сечения
    //const [slicePoint2, setSlicePoint2] = useState([]);//для графика сечения
    //const [sliceClick, setSliceClick] = useState(0);
    let sliceClick = useRef(0);

    const [sliceYMinMax, setSliceYMinMax] = useState([99999999,-99999999]);
    const [sliceZMinMax, setSliceZMinMax] = useState([99999999,-99999999]);
    //const [plotFrames, setPlotFrames] = useState([]);
    //const [plotConfig, setPlotConfig] = useState({});
    //const [file, setFile] = useState(null);
    const [files, setFiles] = useState([]);

    const [opacity, setOpacity] = useState(1);
    const [scale, setScale] = useState(0.3);
    //const [scaleD, setScaleD] = useState(4);
    //const [coordInvert, setCoordInvert] = useState(true);
    // interpolation options
    //const [applyInterpolation, setApplyInterpolation] = useState(false);
    const [showOriginalPoints, setShowOriginalPoints] = useState(false);
    const [gridNums, setGridNums] = useState(50);
    const [applyInterpIDW, setApplyInterpIDW] = useState(false);
    const [power, setPower] = useState(4);
    const [interpKnn, setInterpKnn] = useState(3);
    const [applyInterpPolynomialFit, setApplyInterpPolynomialFit] = useState(false);
    const [applyInterpPolynomialFitDegree, setApplyInterpPolynomialFitDegree] = useState(5);
    const [applyInterpPolynomialFitInteraction, setApplyInterpPolynomialFitInteraction] = useState(true);
    const [applyInterpPolynomialFitIntercept, setApplyInterpPolynomialFitIntercept] = useState(true);
    const [applyInterpKriging, setApplyInterpKriging] = useState(false);
    const [applyInterpRBF, setApplyInterpRBF] = useState(false);

    //const [showOriginalPoints, setShowOriginalPoints] = useState(false);

    //const [mainAction, setMainAction] = useState('draw_surface');
    //const [drawAsPseudoSurface, setDrawAsPseudoSurface] = useState(true);
    //const [showPoints, setShowPoints] = useState(false);
    //const [dotColor, setDotColor] = useState('#ff0000');
    //const [dotSize, setDotSize] = useState(2);
    // download geojson
    const [colorNums, setColorNums] = useState(10);
    const [geojsonAsPolygons, setGeojsonAsPolygons] = useState(true);
    const [geojsonAsBox, setGeojsonAsBox] = useState(false);
    const [geojsonAsHexagons, setGeojsonAsHexagons] = useState(false);
    //const [bore, setBore] = useState(false);
    const [downloadMenuCurrentID, setDownloadMenuCurrentID] = useState('');
    //const [isolinesDraw, setIsolinesDraw] = useState(false);
    //const [isolinesNums, setIsolinesNums] = useState(10);
    const [pageSettingsAutohide, setPageSettingsAutohide] = useState(true);
    const [colorMapOptions, setColorMapOptions] = useState([
        { key: 'gist_rainbow_r',  value: 'gist_rainbow_r',  text: 'gist_rainbow_r' },
        { key: 'gist_rainbow',    value: 'gist_rainbow',    text: 'gist_rainbow' },
        { key: 'viridis',         value: 'viridis',         text: 'viridis' },
        { key: 'seismic',         value: 'seismic',         text: 'Сейсмика' },
        { key: 'plasma',          value: 'plasma',          text: 'plasma' },
        { key: 'inferno',         value: 'inferno',         text: 'inferno' },
        { key: 'magma',           value: 'magma',           text: 'magma' },
        { key: 'Greys',           value: 'Greys',           text: 'Серый',          style: { color: 'gray' } },
        { key: 'Purples',         value: 'Purples',         text: 'Purples',        style: { color: 'purple' } },
        { key: 'Blues',           value: 'Blues',           text: 'Синий',          style: { color: 'blue' } },
        { key: 'Greens',          value: 'Greens',          text: 'Зеленый',        style: { color: 'green' } },
        { key: 'Oranges',         value: 'Oranges',         text: 'Оранжевый',      style: { color: 'orange' } },
        { key: 'Reds',            value: 'Reds',            text: 'Красный',        style: { color: 'red' } },
        { key: 'Greys_r',         value: 'Greys_r',         text: 'Серый' },
        { key: 'summer',          value: 'summer',          text: 'summer' },
        { key: 'autumn',          value: 'autumn',          text: 'autumn' },
        { key: 'winter',          value: 'winter',          text: 'winter' },
        { key: 'Spectral',        value: 'Spectral',        text: 'Spectral' },
        { key: 'coolwarm',        value: 'coolwarm',        text: 'coolwarm' },
        { key: 'Set1',            value: 'Set1',            text: 'Set1' },
        { key: 'custom',          value: 'custom',          text: 'Собственные' },
    ]);
    const [colorCustomMap, setColorCustomMap] = useState([]);
    //const [colorMap, setColorMap] = useState('gist_rainbow_r');
    const colorSchemesSet = {
        'gist_rainbow_r': [['0.0', 'rgb(255,0,191)'],['0.111', 'rgb(182,0,255)'],['0.222', 'rgb(41,0,255)'],['0.333', 'rgb(0,99,255)'],['0.444', 'rgb(0,235,255)'],['0.555', 'rgb(0,255,134)'],['0.666', 'rgb(0,255,0)'],['0.7778', 'rgb(135,255,0)'],['0.889', 'rgb(255,234,0)'],['1.0', 'rgb(255,99,0)'],],
        'gist_rainbow': [['0.0', 'rgb(255,0,40)'],['0.111', 'rgb(255,93,0)'],['0.222', 'rgb(255,234,0)'],['0.333', 'rgb(135,255,0)'],['0.444', 'rgb(0,255,0)'],['0.555', 'rgb(0,255,139)'],['0.666', 'rgb(0,235,255)'],['0.7778', 'rgb(0,99,255)'],['0.889', 'rgb(41,0,255)'],['1.0', 'rgb(177,0,255)'],],
        'viridis': [['0.0', 'rgb(68,1,84)'],['0.111', 'rgb(72,35,116)'],['0.222', 'rgb(64,67,135)'],['0.333', 'rgb(52,95,141)'],['0.444', 'rgb(41,120,142)'],['0.555', 'rgb(32,144,140)'],['0.666', 'rgb(34,167,132)'],['0.7778', 'rgb(66,190,113)'],['0.889', 'rgb(121,209,81)'],['1.0', 'rgb(186,222,39)'],],
        'seismic': [['0.0', 'rgb(0,0,76)'],['0.111', 'rgb(0,0,146)'],['0.222', 'rgb(0,0,219)'],['0.333', 'rgb(53,53,255)'],['0.444', 'rgb(153,153,255)'],['0.555', 'rgb(255,253,253)'],['0.666', 'rgb(255,153,153)'],['0.7778', 'rgb(255,53,53)'],['0.889', 'rgb(229,0,0)'],['1.0', 'rgb(179,0,0)'],],
        'plasma': [['0.0', 'rgb(12,7,134)'],['0.111', 'rgb(64,3,156)'],['0.222', 'rgb(106,0,167)'],['0.333', 'rgb(144,14,163)'],['0.444', 'rgb(176,42,143)'],['0.555', 'rgb(203,71,119)'],['0.666', 'rgb(224,100,97)'],['0.7778', 'rgb(241,130,76)'],['0.889', 'rgb(252,166,53)'],['1.0', 'rgb(252,204,37)'],],
        'inferno': [['0.0', 'rgb(0,0,3)'],['0.111', 'rgb(22,11,57)'],['0.222', 'rgb(65,9,103)'],['0.333', 'rgb(107,23,110)'],['0.444', 'rgb(147,37,103)'],['0.555', 'rgb(187,55,84)'],['0.666', 'rgb(220,80,57)'],['0.7778', 'rgb(242,117,26)'],['0.889', 'rgb(251,164,10)'],['1.0', 'rgb(246,213,66)'],],
        'magma': [['0.0', 'rgb(0,0,3)'],['0.111', 'rgb(20,13,53)'],['0.222', 'rgb(59,15,111)'],['0.333', 'rgb(101,26,128)'],['0.444', 'rgb(140,41,128)'],['0.555', 'rgb(182,54,121)'],['0.666', 'rgb(221,73,104)'],['0.7778', 'rgb(246,110,91)'],['0.889', 'rgb(253,159,108)'],['1.0', 'rgb(253,205,144)'],],
        'Greys': [['0.0', 'rgb(255,255,255)'],['0.111', 'rgb(243,243,243)'],['0.222', 'rgb(226,226,226)'],['0.333', 'rgb(205,205,205)'],['0.444', 'rgb(181,181,181)'],['0.555', 'rgb(149,149,149)'],['0.666', 'rgb(122,122,122)'],['0.7778', 'rgb(95,95,95)'],['0.889', 'rgb(64,64,64)'],['1.0', 'rgb(30,30,30)'],],
        'Purples': [['0.0', 'rgb(252,251,253)'],['0.111', 'rgb(241,240,246)'],['0.222', 'rgb(226,225,239)'],['0.333', 'rgb(205,205,228)'],['0.444', 'rgb(182,182,216)'],['0.555', 'rgb(157,153,199)'],['0.666', 'rgb(134,130,188)'],['0.7778', 'rgb(115,99,172)'],['0.889', 'rgb(97,64,155)'],['1.0', 'rgb(80,31,139)'],],
        'Blues': [['0.0', 'rgb(247,251,255)'],['0.111', 'rgb(227,238,248)'],['0.222', 'rgb(207,225,242)'],['0.333', 'rgb(181,211,233)'],['0.444', 'rgb(147,196,222)'],['0.555', 'rgb(106,173,213)'],['0.666', 'rgb(74,151,201)'],['0.7778', 'rgb(46,126,188)'],['0.889', 'rgb(23,100,171)'],['1.0', 'rgb(8,74,146)'],],
        'Greens': [['0.0', 'rgb(247,252,245)'],['0.111', 'rgb(232,246,228)'],['0.222', 'rgb(211,237,204)'],['0.333', 'rgb(183,226,176)'],['0.444', 'rgb(152,212,147)'],['0.555', 'rgb(115,195,117)'],['0.666', 'rgb(75,176,97)'],['0.7778', 'rgb(47,152,78)'],['0.889', 'rgb(21,126,58)'],['1.0', 'rgb(0,101,40)'],],
        'Oranges': [['0.0', 'rgb(255,245,235)'],['0.111', 'rgb(254,233,212)'],['0.222', 'rgb(253,216,179)'],['0.333', 'rgb(253,193,139)'],['0.444', 'rgb(253,167,97)'],['0.555', 'rgb(252,140,59)'],['0.666', 'rgb(243,112,27)'],['0.7778', 'rgb(226,85,8)'],['0.889', 'rgb(196,64,1)'],['1.0', 'rgb(158,51,3)'],],
        'Reds': [['0.0', 'rgb(255,245,240)'],['0.111', 'rgb(254,228,216)'],['0.222', 'rgb(252,201,180)'],['0.333', 'rgb(252,169,141)'],['0.444', 'rgb(251,138,106)'],['0.555', 'rgb(250,105,73)'],['0.666', 'rgb(241,68,50)'],['0.7778', 'rgb(217,38,35)'],['0.889', 'rgb(187,20,25)'],['1.0', 'rgb(153,12,19)'],],
        'Greys_r': [['0.0', 'rgb(0,0,0)'],['0.111', 'rgb(29,29,29)'],['0.222', 'rgb(64,64,64)'],['0.333', 'rgb(95,95,95)'],['0.444', 'rgb(122,122,122)'],['0.555', 'rgb(150,150,150)'],['0.666', 'rgb(181,181,181)'],['0.7778', 'rgb(205,205,205)'],['0.889', 'rgb(226,226,226)'],['1.0', 'rgb(242,242,242)'],],
        'summer': [['0.0', 'rgb(0,127,102)'],['0.111', 'rgb(25,140,102)'],['0.222', 'rgb(51,153,102)'],['0.333', 'rgb(77,166,102)'],['0.444', 'rgb(102,178,102)'],['0.555', 'rgb(128,191,102)'],['0.666', 'rgb(153,204,102)'],['0.7778', 'rgb(178,216,102)'],['0.889', 'rgb(204,229,102)'],['1.0', 'rgb(229,242,102)'],],
        'autumn': [['0.0', 'rgb(255,0,0)'],['0.111', 'rgb(255,25,0)'],['0.222', 'rgb(255,51,0)'],['0.333', 'rgb(255,77,0)'],['0.444', 'rgb(255,102,0)'],['0.555', 'rgb(255,128,0)'],['0.666', 'rgb(255,153,0)'],['0.7778', 'rgb(255,178,0)'],['0.889', 'rgb(255,204,0)'],['1.0', 'rgb(255,229,0)'],],
        'winter': [['0.0', 'rgb(0,0,255)'],['0.111', 'rgb(0,25,242)'],['0.222', 'rgb(0,51,229)'],['0.333', 'rgb(0,77,216)'],['0.444', 'rgb(0,102,204)'],['0.555', 'rgb(0,128,191)'],['0.666', 'rgb(0,153,178)'],['0.7778', 'rgb(0,178,166)'],['0.889', 'rgb(0,204,153)'],['1.0', 'rgb(0,229,140)'],],
        'Spectral': [['0.0', 'rgb(158,1,66)'],['0.111', 'rgb(211,60,78)'],['0.222', 'rgb(244,109,67)'],['0.333', 'rgb(253,174,97)'],['0.444', 'rgb(254,224,139)'],['0.555', 'rgb(254,254,190)'],['0.666', 'rgb(230,245,152)'],['0.7778', 'rgb(172,221,163)'],['0.889', 'rgb(102,194,165)'],['1.0', 'rgb(51,137,188)'],],
        'coolwarm': [['0.0', 'rgb(58,76,192)'],['0.111', 'rgb(88,118,226)'],['0.222', 'rgb(123,158,248)'],['0.333', 'rgb(159,190,254)'],['0.444', 'rgb(192,211,245)'],['0.555', 'rgb(221,220,219)'],['0.666', 'rgb(241,202,182)'],['0.7778', 'rgb(247,173,143)'],['0.889', 'rgb(237,132,103)'],['1.0', 'rgb(215,84,68)'],],
        //'Pastel1': [['0.0', 'rgb(251,180,174)'],['0.111', 'rgb(242,242,242)'],['0.222', 'rgb(242,242,242)'],['0.333', 'rgb(242,242,242)'],['0.444', 'rgb(242,242,242)'],['0.555', 'rgb(242,242,242)'],['0.666', 'rgb(242,242,242)'],['0.7778', 'rgb(242,242,242)'],['0.889', 'rgb(242,242,242)'],['1.0', 'rgb(242,242,242)'],],
        //'Paired': [['0.0', 'rgb(166,206,227)'],['0.111', 'rgb(177,89,40)'],['0.222', 'rgb(177,89,40)'],['0.333', 'rgb(177,89,40)'],['0.444', 'rgb(177,89,40)'],['0.555', 'rgb(177,89,40)'],['0.666', 'rgb(177,89,40)'],['0.7778', 'rgb(177,89,40)'],['0.889', 'rgb(177,89,40)'],['1.0', 'rgb(177,89,40)'],],
        'Set1': [['0.0', 'rgb( 163 , 207 , 251 )'], ['0.1', 'rgb( 163 , 207 , 251 )'], ['0.1', 'rgb( 228 , 26 , 28 )'], ['0.2', 'rgb( 228 , 26 , 28 )'],
            ['0.2', 'rgb( 55 , 126 , 184 )'], ['0.3', 'rgb( 55 , 126 , 184 )'], ['0.3', 'rgb( 77 , 175 , 74 )'], ['0.4', 'rgb( 77 , 175 , 74 )'],
            ['0.4', 'rgb( 152 , 78 , 163 )'], ['0.5', 'rgb( 152 , 78 , 163 )'], ['0.5', 'rgb( 255 , 127 , 0 )'], ['0.6', 'rgb( 255 , 127 , 0 )'],
            ['0.6', 'rgb( 255 , 255 , 51 )'], ['0.7', 'rgb( 255 , 255 , 51 )'], ['0.7', 'rgb( 166 , 86 , 40 )'], ['0.8', 'rgb( 166 , 86 , 40 )'],
            ['0.8', 'rgb( 247 , 129 , 191 )'], ['0.9', 'rgb( 247 , 129 , 191 )'], ['0.9', 'rgb( 153 , 153 , 153 )'], ['1.0', 'rgb( 153 , 153 , 153 )'],],
        //'tab20b': [['0.0', 'rgb(57,59,121)'],['0.111', 'rgb(222,158,214)'],['0.222', 'rgb(222,158,214)'],['0.333', 'rgb(222,158,214)'],['0.444', 'rgb(222,158,214)'],['0.555', 'rgb(222,158,214)'],['0.666', 'rgb(222,158,214)'],['0.7778', 'rgb(222,158,214)'],['0.889', 'rgb(222,158,214)'],['1.0', 'rgb(222,158,214)'],],
        //'tab20c': [['0.0', 'rgb(49,130,189)'],['0.111', 'rgb(217,217,217)'],['0.222', 'rgb(217,217,217)'],['0.333', 'rgb(217,217,217)'],['0.444', 'rgb(217,217,217)'],['0.555', 'rgb(217,217,217)'],['0.666', 'rgb(217,217,217)'],['0.7778', 'rgb(217,217,217)'],['0.889', 'rgb(217,217,217)'],['1.0', 'rgb(217,217,217)'],],
    };
    const [colorMapsSet, setColorMapsSet] = useState(colorSchemesSet);
    const [startColor, setStartColor] = useState('#ff0000');
    const [endColor, setEndColor] = useState('#0000ff');
    const [colorSchemeName, setColorSchemeName] = useState('');
    //const [scalerMinValue, setScalerMinValue] = useState(0);
    //const [scalerMaxValue, setScalerMaxValue] = useState(100);
    //const [sliderMinValue, setSliderMinValue] = useState(0);
    //const [sliderMaxValue, setSliderMaxValue] = useState(100);
    //const [sliderValue, setSliderValue] = useState([0, 100]);


    const interpKrigingVariogrammOptions = [
        { key: 'linear',      value: 'linear',      text: 'linear' },
        { key: 'power',       value: 'power',       text: 'power' },
        { key: 'gaussian',    value: 'gaussian',    text: 'gaussian' },
        { key: 'spherical',   value: 'spherical',   text: 'spherical' },
        { key: 'exponential', value: 'exponential', text: 'exponential' },
        { key: 'hole-effect', value: 'hole-effect', text: 'hole-effect' },
    ];
    const [krigingVariogramm, setKrigingVariogramm] = useState('linear');
    const [krigingNlag, setKrigingNlag] = useState(6);
    const [krigingNugget, setKrigingNugget] = useState(0.0001);
    const [krigingSlope, setKrigingSlope] = useState('-');
    const [krigingScale, setKrigingScale] = useState('-');
    const [krigingExponent, setKrigingExponent] = useState('-');
    const [krigingSill, setKrigingSill] = useState(1.0);
    const [krigingRange, setKrigingRange] = useState('-');
    const interpRBFSplineOptions = [
        { key: 'linear',              value: 'linear',                text: 'linear' },
        { key: 'cubic',               value: 'cubic',                 text: 'cubic' },
        { key: 'quintic',             value: 'quintic',               text: 'quintic' },
        { key: 'multiquadric',        value: 'multiquadric',          text: 'multiquadric' },
        { key: 'inverse_multiquadric', value: 'inverse_multiquadric', text: 'inverse_multiquadric' },
        { key: 'inverse_quadratic',    value: 'inverse_quadratic',    text: 'inverse_quadratic' },
        { key: 'thin_plate_spline',   value: 'thin_plate_spline',     text: 'thin_plate_spline' },
        { key: 'gaussian',            value: 'gaussian',              text: 'gaussian' },
    ];
    const [rbfSpline, setRbfSpline] = useState('linear');
    const [rbfEpsilon, setRbfEpsilon] = useState(1.0);

    const [openApplyInterp, setOpenApplyInterp] = useState(false);
    const [currentModalFileID, setCurrentModalFileID] = useState();

    const [errorText, setErrorText] = useState('');
    const [buttonCancel, setButtonCancel] = useState(false);
    const crsOptions = [
        { key: '4326', value: '4326', text: 'ESPG:4326' },
        { key: '3857', value: '3857', text: 'ESPG:3857' },
        { key: 'custom', value: 'custom', text: 'строчка proj' },
    ]
    // Создание формы и отправка данных на сервер
    const makeSubmitFormData = (formData, files, i, doDowload) => {
        //console.log(files);
        //console.log(files[i]);
        formData.append('file', files[i].file);
        formData.append('file_id', files[i].id);
        if (doDowload) {
            console.log('do download');
            if (files[i].downloadMainAction === 'download_geojson') {
                console.log('as geojson');
                formData.append('main_action', 'download_geojson');
                formData.append('color_nums', files[i].colorNums);
                if (files[i].geojsonAsPolygons) {
                    formData.append('draw_as_polygons', true);
                }
                if (files[i].geojsonAsBox) {
                    formData.append('draw_as_box', true);
                }
                if (files[i].geojsonAsHexagons) {
                    formData.append('draw_as_hexagons', true);
                }
            } else if (files[i].downloadMainAction === 'download_png') {
                formData.append('main_action', 'download_png');
            } else if (files[i].downloadMainAction === 'download_gltf') {
                formData.append('main_action', 'download_gltf');
            } else {
                console.log('no choice');
            }
        } else {
            console.log('No downloadm surface');
            formData.append('main_action', 'draw_surface');
        }
        formData.append('z_scale', scale);
        formData.append('scaleD', files[i].scaleD);
        formData.append('coord_invert', files[i].coordInvert);
        formData.append('opacity', files[i].opacity);
        formData.append('apply_interpolation', files[i].applyInterpolation);
        //TODO if apply_interpolation add interpolation params
        // show_original_points
        // apply_interp_idw apply_interp_polynomial_fit apply_interp_polynomial_fit23 apply_interp_polynomial_fit_interaction apply_interp_polynomial_fit_intercept
        // apply_interp_kriging apply_interp_kriging_variogram apply_interp_kriging_nlags apply_interp_kriging_nugget apply_interp_kriging_slope apply_interp_kriging_scale apply_interp_kriging_exponent apply_interp_kriging_sill apply_interp_kriging_range
        // apply_interp_RBF apply_interp_RBF_spline apply_interp_RBF_epsilon
        //
        if (files[i].type==='surface' || files[i].type==='surface_iso') {
            formData.append('draw_as_pseudo_surface', true);
            formData.append('color_map', files[i].colorMap);
            if (files[i].colorMap === 'custom') {
                //console.log('map: ' + files[i].colorMap);
                //const customColorMap = colorMapOptions.filter(option => option.value === files[i].colorMap);
                //console.log('map: ' + JSON.stringify(customColorMap));
                //console.log('startC: ' + customColorMap[0].startColor);
                //console.log('endC: ' + customColorMap[0].endColor);
                formData.append('custom_color_map', files[i].colorMapStartColor + ";" + files[i].colorMapEndColor);
            }
            if (files[i].colorscaleOffset > files[i].zmin) {
                formData.append('colorscale_offset', files[i].colorscaleOffset);
            }
            if (files[i].colorscaleOffsetB < files[i].zmax) {
                formData.append('colorscale_offsetB', files[i].colorscaleOffsetB);
            }
            formData.append('colorscale_transparency', files[i].colorscaleTransparency);

            if (files[i].type==='surface_iso') {
                formData.append('isolines_draw', files[i].isolinesDrawA);
                formData.append('isolines_nums', files[i].isolinesNums);
                formData.append('isolines_width', files[i].isolinesWidth);
            }

            //if (slicePoints[0].length && slicePoints[1].length) {
            //  console.log('slicePoints:', slicePoints[0], slicePoints[1]);
            //  formData.append('draw_slice', true);
            //  formData.append('slice_points', [slicePoints[0], slicePoints[1]]);
            //}
            //console.log('POST slicePoints:', slicePoints.current);
            if (slicePoints && slicePoints.current && slicePoints.current[0]?.length && slicePoints.current[1]?.length) {
                //console.log('slicePoints:', slicePoints);
                formData.append('draw_slice', true);
                //formData.append('slice_points', [slicePoint1, slicePoint2]);
                formData.append('slice_points', [slicePoints.current[0], slicePoints.current[1]]);
            }
        } else if (files[i].type==='points') {
            formData.append('show_points', true);
            formData.append('dotColor', files[i].dotColor);
            formData.append('dotSize', files[i].dotSize);
        } else if (files[i].type==='bore') {
            formData.append('draw_bore', true);
        }
        if (files[i].applyInterpolation) {
            formData.append('show_original_points', files[i].showOriginalPoints);
            formData.append('grid_nums', files[i].gridNums);

            if (files[i].applyInterpIDW) {
                formData.append('apply_interp_idw', true);
                formData.append('power', files[i].power);
                formData.append('interp_knn', files[i].interpKnn);
            } else if (files[i].applyInterpPolynomialFit) {
                formData.append('apply_interp_polynomial_fit', true);
                formData.append('apply_interp_polynomial_fit23', files[i].applyInterpPolynomialFitDegree);
                formData.append('apply_interp_polynomial_fit_interaction', files[i].applyInterpPolynomialFitInteraction);
                formData.append('apply_interp_polynomial_fit_intercept', files[i].applyInterpPolynomialFitIntercept);
            } else if (files[i].applyInterpKriging) {
                formData.append('apply_interp_kriging', true);
                formData.append('apply_interp_kriging_variogram', files[i].krigingVariogramm);
                formData.append('apply_interp_kriging_nlags', files[i].krigingNlag);
                formData.append('apply_interp_kriging_nugget', files[i].krigingNugget);
                formData.append('apply_interp_kriging_slope', files[i].krigingSlope);
                formData.append('apply_interp_kriging_scale', files[i].krigingScale);
                formData.append('apply_interp_kriging_exponent', files[i].krigingExponent);
                formData.append('apply_interp_kriging_sill', files[i].krigingSill);
                formData.append('apply_interp_kriging_range', files[i].krigingRange);

            } else if (files[i].applyInterpRBF) {
                formData.append('apply_interp_RBF', true);
                formData.append('apply_interp_RBF_spline', files[i].rbfSpline);
                formData.append('apply_interp_RBF_epsilon', files[i].rbfEpsilon);
            }
        }
        return formData;
    }
    // вызов создания формы и отправка данных на сервер
    const handleSubmit = async (event) => {
        setButtonCancel(true);
        console.log('post start');
        setLoadingVisible(true);
        let xmin, ymin, xmax, ymax, zmin, zmax; // для стрелки
        //let custom_crs_need;
        for (let i = 0; i < files.length; i++) {
            //const file = files[i];
            var formData = new FormData();
            formData = makeSubmitFormData(formData, files, i, false);

            setErrorText('отправка запроса #' + i);
            //console.log('axios' + i);
            //if (buttonCancel) {
            await axios.post(viewer3dApi + 'get_surface_plot', formData, {headers: {'Content-Type': 'multipart/form-data'}})
                .then(res => {
                    //console.log('response ok');
                    setErrorText('запрос исполнен');
                    //const index = newData.findIndex(data => data.index === res.data.misc.file_props.file_id);

                    zmin = res.data.misc.file_props.zmin;
                    files[i].zmin = zmin;
                    zmax = res.data.misc.file_props.zmax;
                    files[i].zmax = zmax;
                    //
                    setSliceZMinMax((prevMinMax) => {
                        const [prevMin, prevMax] = prevMinMax;
                        const newMin = Math.min(prevMin, zmin);
                        const newMax = Math.max(prevMax, zmax);
                        return [newMin, newMax];
                    });
                    //const current_minz = files[i].zmin + (files[i].zmax - files[i].zmin) / 2
                    setPlotData(prevData => {
                        // копию предыдущ plotData
                        let updatedPlotData = [...prevData];
                        const plotIndex = updatedPlotData.findIndex(plot => plot.id === files[i].id);
                        if (plotIndex !== -1) {
                            //console.log("plotdata index found");
                            let plotData = res.data.data[0];
                            // если есть ихогипсы
                            // удалить если уже есть
                            //updatedPlotData = updatedPlotData.filter(plot => !(plot.id === files[i].id && plot.type === "scatter3d"));

                            updatedPlotData[plotIndex] = {...plotData, id: files[i].id,};// on: { plotly_click: handleModelClick }};

                            if (res.data.data.length > 1) {
                                for (let j = 1; j < res.data.data.length; j++) {
                                    let additionalPlotIndex = updatedPlotData.length;
                                    let additionalPlotData = res.data.data[j];

                                    updatedPlotData[additionalPlotIndex] = {...additionalPlotData, id: files[i].id};
                                }
                            }
                        } else {
                            console.log("plotdata index not found");
                        }

                        // slice
                        if (res.data.misc.file_props.points_slice) {
                            const points = res.data.misc.file_props.points_slice;
                            const x_values = points.map(point => point[0]);
                            const y_values = points.map(point => point[1]);
                            const z_values = points.map(point => point[2]);

                            const yMin = Math.min(...z_values);
                            const yMax = Math.max(...z_values);

                            const slice = {
                                id: files[i].id,
                                type: 'scatter3d',
                                mode: 'lines',
                                x: x_values,
                                y: y_values,
                                z: z_values,
                                opacity: 1,
                                line: {
                                    width: 6,
                                    color: "#ff0000",
                                    //reversescale: false
                                },
                                name: "сечение",
                                showscale: false,
                                showlegend: false,
                            };
                            updatedPlotData.push(slice);
                            const simpleLine = {
                                type: 'scatter',
                                mode: 'lines',
                                x: x_values, // проекция на ось
                                y: z_values, // значения z
                                line: {
                                    width: 2,
                                    color: '#FF0000',
                                },
                                name: 'Линия сечения',
                                showscale: false,
                                showlegend: false,
                            };
                            //const updatedLines = [...plotDataSlice, simpleLine];
                            //setPlotDataSlice(updatedLines);
                            setPlotDataSlice(prevSlice => {
                                // копию предыдущ plotData
                                let updatedSliceData = [...prevSlice, simpleLine];
                                updatedSliceData.push(simpleLine);
                                return updatedSliceData;
                            });

                            // обновить границы графика сечения от каждой модели
                            setSliceYMinMax((prevSliceMinMax) => {
                                const [prevMin, prevMax] = prevSliceMinMax;
                                const newMin = Math.min(prevMin, yMin);
                                const newMax = Math.max(prevMax, yMax);
                                return [newMin, newMax];
                            });
                        }

                        return updatedPlotData;
                        // Заменяем i-тый элемент новым графиком
                        //updatedPlotData[i] = res.data.data[0];
                        //return updatedPlotData;
                    });

                    setPlotLayout(res.data.layout)
                    if (pageSettingsAutohide) {
                        setExpandedMenuVisible(!ExpandedMenuVisible);
                        //setMainMenuVisible(false);
                    }
                    setPlotLayoutSlice({width: 600,height: 400,title: 'Сечение:',})

                    // установка доп.опций
                    files[i].colorscaleOffset = zmin;
                    files[i].colorscaleOffsetB = zmax;

                    // обновление xmin, ymin, xmax, ymax для стрелки
                    if (i === 0) {
                        xmin = res.data.misc.file_props.xmin;
                        ymin = res.data.misc.file_props.ymin;
                        xmax = res.data.misc.file_props.xmax;
                        ymax = res.data.misc.file_props.ymax;
                    } else {
                        xmin = Math.min(xmin, res.data.misc.file_props.xmin);
                        ymin = Math.min(ymin, res.data.misc.file_props.ymin);
                        xmax = Math.max(xmax, res.data.misc.file_props.xmax);
                        ymax = Math.max(ymax, res.data.misc.file_props.ymax);
                    }

                    //custom_crs_need = res.data.misc.file_props.custom_crs_need;
                    //console.log('Custom CRS need' + res.data.misc.file_props.custom_crs_need);
                    files[i].custom_crs_need = res.data.misc.file_props.custom_crs_need;
                    setErrorText('запрос #' + i + 'обработан');

                    files[i].optionsVisible = false;
                })
                .catch(error => {
                    console.log('Ошибка error', error);
                    setErrorText('ошибка при отправке запроса # ' + i);
                    setLoadingVisible(false);
                });
            //}

        }

        // добавление стрелки если нет
        console.log('cds:' + xmin +' '+ ymin +' '+ xmax +' '+ ymax);
        setPlotData(prevData => {
            // копию предыдущ plotData
            let updatedPlotData = [...prevData];
            if (!updatedPlotData.some(plot => plot.id === 'arrow')) {
                console.log('cds2:' + xmin +' '+ ymin +' '+ xmax +' '+ ymax);

                const arrow = {
                    id: 'arrow',
                    type: 'mesh3d',
                    colorscale: [[0,"#ff0000"], [1, "#00ff00"]],
                    x : [xmin + (xmax - xmin) * 0.05, xmin + (xmax - xmin) * 0.10, xmin + (xmax - xmin) * 0.10, xmin + (xmax - xmin) * 0.10, xmin + (xmax - xmin) * 0.15],
                    y : [ymax - (ymax - ymin) * 0.15, ymax - (ymax - ymin) * 0.05, ymax - (ymax - ymin) * 0.12, ymax - (ymax - ymin) * 0.13, ymax - (ymax - ymin) * 0.15],
                    z : [zmax - (zmax - zmin) * 0.10, zmax - (zmax - zmin) * 0.10, zmax - (zmax - zmin) * 0.05, zmax - (zmax - zmin) * 0.10, zmax - (zmax - zmin) * 0.10,],
                    intensity: [zmax - (zmax - zmin) * 0.10, zmax - (zmax - zmin) * 0.10, zmax - (zmax - zmin) * 0.05, zmax - (zmax - zmin) * 0.10, zmax - (zmax - zmin) * 0.10,],

                    i : [0,0,1,2],
                    j : [1,2,2,3],
                    k : [2,3,4,4],
                    name: "север там",
                    showscale: false,
                };

                updatedPlotData.push(arrow);
            }
            return updatedPlotData;
        })
        setDownloadColumnVisible(true);
        setLoadingVisible(false);
        setButtonCancel(false);
    };
    // запрос на скачивание файла
    const handleDownloadSubmit = async (e, fileid) => {
        // TODO отправка формы
        const file = files.find(file => file.id === fileid);
        console.log('post download start id=' + file.id);
        //setLoadingVisible(true);
        var formData = new FormData();
        formData = makeSubmitFormData(formData, [file], 0, true);
        if (downloadMenuCrs) {
            console.log(' CRS установлена');
            formData.append('file_custom_crs', downloadMenuCrs);
            if (downloadMenuCrs === "custom" ) {
                console.log('custom CRS');
                formData.append('file_custom_crs', downloadMenuProjFieldValue);
            } else {
                console.log('NO text ProJ');
            }
        } else {
            console.log(' CRS НЕ установлена');
        }

        setErrorText('отправка запроса на скачивание #');
        console.log('axios download file');
        await axios.post(viewer3dApi + 'get_surface_plot', formData, {headers: {'Content-Type': 'multipart/form-data'}, responseType: 'blob'})
            .then(res => {
                console.log('response diwnload ok');
                let filename = file.name;
                if (file.downloadMainAction === 'download_geojson') {
                    filename += '.geojson';
                } else if (file.downloadMainAction === 'download_png') {
                    filename += '.zip';
                } else if (file.downloadMainAction === 'download_gltf') {
                    filename += '.gltf';
                }
                //saveAs(blob, filename);
                const url = URL.createObjectURL(res.data);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                link.remove();
                setErrorText('запрос исполнен');
            })
            .catch(error => {
                console.log('Ошибка error', error);
                setErrorText('ошибка при отправке скачивании запроса # ' + file.name);
                setLoadingVisible(false);
            });
    };
    /*const handleSubmitGEOREFTEST = async (event) => {
      console.log('georef test post start');
      for (let i = 0; i < files.length; i++) {
        var dataform2 = new FormData();
        dataform2.append('my_file', files[i].file);
        dataform2.append('my_type', '.jpg');
        axios.post('http://127.0.0.1:4447/zipReproject', dataform2).then((res)  => {
          console.log('answer OK');
        }).catch((err)=>{
            console.log('answer ERR');
        })
      }
    }*/
    const modelType = [
        { key: 'surface',     value: 'surface',     text: 'поверхность' },
        { key: 'surface_iso', value: 'surface_iso', text: 'поверхность с изогипсами' },
        { key: 'points',      value: 'points',      text: 'точками' },
        { key: 'bore',        value: 'bore',        text: 'скважины' },
    ];

    // on file form change
    const handleFileChange = (e) => {
        const newFiles = Array.from(e.target.files).map((file) => ({
            id: Math.random(),
            //index: files.length + index,
            file: file,
            name: file.name,
            opacity: opacity,
            visible: true,
            type: 'surface',
            scaleD: 4,
            coordInvert: true,
            applyInterpolation: false,
            gridNums: 50,
            applyInterpPolynomialFitInteraction: true,
            applyInterpPolynomialFitIntercept: true,
            interpKrigingVariogrammOptions: 'linear',
            krigingNlag: 6,
            krigingNugget: 0.0001,
            krigingSill: 1.0,
            rbfEpsilon: 1.0,
            colorMap: 'gist_rainbow_r',
            colorMapStartColor: '',
            colorMapEndColor: '',
            dotColor: '#FF0000',
            dotSize: 2,
            downloadMainAction: 'download_png',
            geojsonAsPolygons: true,
            optionsVisible: false,
            isolinesDrawA: false,
            isolinesNums: 10,
            isolinesWidth: 0.8,
            coloscaleOffset: 0.0,
            zmin: 0.0,
            zmax: 1.0,
            colorscaleOffset: 0,
            colorscaleOffsetB: 1,
            colorscaleTransparency: true,
            custom_crs_need: false,
            proj: '',
            point1: [],
            point2: [],
        }));

        setFiles(prevFiles => [...prevFiles, ...newFiles]);
        setDownloadMenuCurrentID(newFiles[0].id);
        setPlotData(prevData => [...prevData, ...newFiles.map(file => ({id: file.id}))]);
    };

    const handleSettingChange = (id, setting, value) => {
        setFiles(prevFiles => {
            const newFiles = prevFiles.map(file => file.id === id ? {...file, [setting]: value} : file);
            const newPlotData = plotData.map(data => {
                if (data.id !== id) return data;
                switch (setting) {
                    case "opacity":
                        if (data.type !== "scatter3d") {
                            return {...data, opacity: value};
                        } else {
                            return data;
                        }
                    case "dotColor":
                        return {...data, marker: {...data.marker, color: value}};
                    case "dotSize":
                        return {...data, marker: {...data.marker, size: value}};
                    case "colorMap":
                        //console.log('CM: ' + 'value ' + value + ' ' + colorMapsSet);
                        if (value === 'custom') {
                            console.log('onchange CC custom value' + value);
                            //console.log( ...data);
                            return {...data,
                                colorMap: 'custom',
                                // TODO colorMapStartColor: '#ff0000',
                                // TODO colorMapEndColor: '00FF00',
                            };
                        } else {
                            return {...data,
                                colorMap: value,
                                colorscale: colorMapsSet[value]};
                        }
                    case "colorMapStartEndColor":
                        // Добавляем цвета в градиент
                        //var scale = d3.scaleLinear().domain([0, 1]).range([startColor, endColor]);
                        var scale = d3.scaleLinear().domain([0, 1]).range([value[0], value[1]]);
                        var sc = []
                        for (var i = 0; i <= 1; i += 0.1) {
                            var color = d3.rgb(scale(i));
                            var rgbColor = 'rgb(' + color.r + ',' + color.g + ',' + color.b + ')';
                            sc.push([i.toFixed(3), rgbColor]);
                        }
                        console.log('start colors to ' + value[0] + ' _ ' + value[1] + ' ' + sc);
                        //setStartColor(value[0]);
                        //setEndColor(value[1]);
                        //colorMapStartColor: value[0], colorMapEndColor: value[1]
                        return {...data,
                            colorscale: sc,
                        };
                    case "applyInterpIDW":
                        return {...data, applyInterpIDW: false};
                    case "downloadMainAction":
                        setDownloadMainAction(value);
                        return {...data, downloadMainAction: value};
                    case "geojsonAsPolygons":
                        setGeojsonAsBox(false);setGeojsonAsHexagons(false);setGeojsonAsPolygons(value);
                        setDownloadMainAction('download_geojson');
                        return {...data, geojsonAsPolygons: value, geojsonAsBox: false, geojsonAsHexagons: false};
                    case "geojsonAsBox":
                        setGeojsonAsBox(value);setGeojsonAsHexagons(false);setGeojsonAsPolygons(false);
                        setDownloadMainAction('download_geojson');
                        return {...data, geojsonAsPolygons: false, geojsonAsBox: value, geojsonAsHexagons: false};
                    case "geojsonAsHexagons":
                        setGeojsonAsBox(false);setGeojsonAsHexagons(value);setGeojsonAsPolygons(false);
                        setDownloadMainAction('download_geojson');
                        return {...data, geojsonAsPolygons: false, geojsonAsBox: false, geojsonAsHexagons: value};
                    case "color_nums":
                        setColorNums(value);
                        return {...data, colorNums: value};
                    case "optionsVisible":
                        return {...data, optionsVisible: value};
                    case "isolinesDrawA":
                        console.log('iso to ' + value);
                        //setIsolinesDraw(value);
                        return {...data, isolinesDrawA: value};
                    case "isolinesNums":
                        //setIsolinesNums(value);
                        //setIsolinesDraw(true);
                        return {...data, isolinesNums: value, isolinesDrawA: true};
                    case "isolinesWidth":
                        return {...data, isolinesWidth: value};
                    case "colorscaleOffset":
                        console.log('offset value: ' + value);
                        return {...data, colorscaleOffset: value};
                    case "colorscaleOffsetB":
                        console.log('offset B value: ' + value);
                        return {...data, colorscaleOffsetB: value};
                    case "colorscaleTransparency":
                        return {...data, colorscaleTransparency: value};
                    case "visible":
                        return {...data, visible: value};
                    default:
                        return data;
                }
            });
            setPlotData(newPlotData);
            return newFiles;
        });
    };
    /*const handleRemove = (event, index) => {
      setFiles(prevFiles => prevFiles.filter(file => file.index !== index));
      for (let i = 0; i < files.length; i++) {

        files[i].index
      }
      setPlotData(prevPlotData =>
        prevPlotData.filter((plot, plotIndex) => plotIndex !== index)
        );
    };*/
    const handleRemove = (event, id) => {
        setFiles(prevFiles => prevFiles.filter(file => file.id !== id));
        setPlotData(prevPlotData => prevPlotData.filter((plot) => plot.id !== id));
    };

    const handleOpacityAllChange = (e) => {
        const newOpacity = e.target.value;
        setOpacity(newOpacity);

        setFiles(prevFiles => prevFiles.map(file => ({...file, opacity: newOpacity})));

        setPlotData(prevPlotData => prevPlotData.map(data => ({...data, opacity: newOpacity})));
    };

    const handleChangeScale = () => {
        let newLayout = {...plotLayout};
        newLayout.scene.aspectratio.z = scale;
        setPlotLayout(newLayout);
    };

    const handleOpenModalDownload = (e, file) => {
        console.log('MENUDOWNLOAD ' + file.id);
        setDownloadMenuCurrentID(file.id);
        setDownloadMenuVisible(true);
        setColorNums(file.colorNums);
        setGeojsonAsPolygons(file.geojsonAsPolygons);
        setGeojsonAsBox(file.geojsonAsBox);
        setGeojsonAsHexagons(file.geojsonAsHexagons);
        setDownloadMainAction(file.downloadMainAction);
        setDownloadMenuProjFieldValue(file.proj);
        //222
    };


    const setFormFromFile = (file) => {
        setShowOriginalPoints(file.showOriginalPoints); //showOriginalPoints
        setGridNums(file.gridNums); //gridNums
        setApplyInterpIDW(file.applyInterpIDW); //applyInterpIDW
        setPower(file.power); //power
        setInterpKnn(file.interpKnn); //interpKnn
        setApplyInterpPolynomialFit(file.applyInterpPolynomialFit); //applyInterpPolynomialFit
        setApplyInterpPolynomialFitDegree(file.applyInterpPolynomialFitDegree); //applyInterpPolynomialFitDegree
        setApplyInterpPolynomialFitInteraction(file.applyInterpPolynomialFitInteraction); //applyInterpPolynomialFitInteraction
        setApplyInterpPolynomialFitIntercept(file.applyInterpPolynomialFitIntercept); //applyInterpPolynomialFitIntercept
        setApplyInterpKriging(file.applyInterpKriging); //applyInterpKriging
        setKrigingVariogramm(file.interpKrigingVariogrammOptions); //interpKrigingVariogrammOptions
        setKrigingNlag(file.krigingNlag); //krigingNlag
        setKrigingNugget(file.krigingNugget); //krigingNugget
        setKrigingSlope(file.krigingSlope); //krigingSlope
        setKrigingScale(file.krigingScale); //krigingScale
        setKrigingExponent(file.krigingExponent); //krigingExponent
        setKrigingSill(file.krigingSill); // krigingSill
        setKrigingRange(file.krigingRange); //krigingRange
        setApplyInterpRBF(file.applyInterpRBF); //applyInterpRBF
        setRbfSpline(file.rbfSpline); //rbfSpline
        setRbfEpsilon(file.rbfEpsilon); //rbfEpsilon
    }
    const setFileFromForm = () => {
        var oldfileid = currentModalFileID;
        setFiles(prevFiles => {
            const newFiles = prevFiles.map(file => file.id === oldfileid ? {
                ...file,
                applyInterpolation: true, //showOriginalPoints
                showOriginalPoints: showOriginalPoints, //showOriginalPoints
                gridNums: gridNums, //gridNums
                applyInterpIDW: applyInterpIDW, //applyInterpIDW
                power: power, //power
                interpKnn: interpKnn, //interpKnn
                applyInterpPolynomialFit: applyInterpPolynomialFit, //applyInterpPolynomialFit
                applyInterpPolynomialFitDegree: applyInterpPolynomialFitDegree, //applyInterpPolynomialFitDegree
                applyInterpPolynomialFitInteraction: applyInterpPolynomialFitInteraction, //applyInterpPolynomialFitInteraction
                applyInterpPolynomialFitIntercept: applyInterpPolynomialFitIntercept, //applyInterpPolynomialFitIntercept
                applyInterpKriging: applyInterpKriging, //applyInterpKriging
                interpKrigingVariogrammOptions: krigingVariogramm, //interpKrigingVariogrammOptions
                krigingNlag: krigingNlag, //krigingNlag
                krigingNugget: krigingNugget, //krigingNugget
                krigingSlope: krigingSlope, //krigingSlope
                krigingScale: krigingScale, //krigingScale
                krigingExponent: krigingExponent, //krigingExponent
                krigingSill: krigingSill, // krigingSill
                krigingRange: krigingRange, //krigingRange
                applyInterpRBF: applyInterpRBF, //applyInterpRBF
                rbfSpline: rbfSpline, //rbfSpline
                rbfEpsilon: rbfEpsilon, //rbfEpsilon
            } : file);
            return newFiles;
        });
        setOpenApplyInterp(false)
    }
    const setFormFromDefaults = () => {
        setShowOriginalPoints(true); //showOriginalPoints
        setGridNums(50); //gridNums
        setApplyInterpIDW(false); //applyInterpIDW
        setPower(4); //power
        setInterpKnn(3); //interpKnn
        setApplyInterpPolynomialFit(false); //applyInterpPolynomialFit
        setApplyInterpPolynomialFitDegree(5); //applyInterpPolynomialFitDegree
        setApplyInterpPolynomialFitInteraction(true); //applyInterpPolynomialFitInteraction
        setApplyInterpPolynomialFitIntercept(true); //applyInterpPolynomialFitIntercept
        setApplyInterpKriging(false); //applyInterpKriging
        setKrigingVariogramm('linear'); //interpKrigingVariogrammOptions
        setKrigingNlag(6); //krigingNlag
        setKrigingNugget(.0001); //krigingNugget
        setKrigingSlope('-'); //krigingSlope
        setKrigingScale('-'); //krigingScale
        setKrigingExponent('-'); //krigingExponent
        setKrigingSill(1.0); // krigingSill
        setKrigingRange('-'); //krigingRange
        setApplyInterpRBF(false); //applyInterpRBF
        setRbfSpline('linear'); //rbfSpline
        setRbfEpsilon(1.0); //rbfSpline
    }
    const onclickApplyInterp = (file) => {
        if (file.applyInterpolation) {
            console.log(' !!! interp from file ');
            setFormFromFile(file);
        } else {
            console.log('Empty interp');
            setFormFromDefaults();
        }
        setCurrentModalFileID(file.id);
        setOpenApplyInterp(true);
    }

    useEffect(() => {
        // Загрузка цветовых схем из кук при загрузке страницы
        // Проверка на существование
        if (document.cookie.includes("vis3d_colorSchemes")) {
            const colorSchemes = JSON.parse(document.cookie.replace(/(?:(?:^|.*;\s*)vis3d_colorSchemes\s*\=\s*([^;]*).*$)|^.*$/, "$1"));
            console.log('startup colorScehes ' + colorSchemes);
            //setColorMapOptions(prevOptions => [...prevOptions, ...colorSchemes]);
            var cmaps = {};
            for (var i = 0; i < colorSchemes.length; i++) {
                cmaps[colorSchemes[i].name] = colorSchemes[i];
            }
            setColorCustomMap(colorSchemes);
        }
        // обработать по урл файл сервером и отрисовать на карте
        if (onStartupUrl) {
            console.log('on startup URL available');
            var formData = new FormData();
            formData.append('file_url', onStartupUrl);
            formData.append('main_action', 'download_png');
            axios.post(viewer3dApi + 'get_surface_plot', formData, {headers: {'Content-Type': 'multipart/form-data', responseType: 'blob'}})
                .then(res => {
                    console.log('onstartup response ok');
                    fileOnMapRenderer(res.data);// binary zip (PAMdataset) as blob (due axios responseType:blob parameter)
                })
                .catch(error => {
                    console.log('Ошибка error (onstartupUrl):', error);
                });
        }
    }, []);

    const handleModelClick = (modelId) => {
        //const clickedModel = plotData.find(plot => plot.id === modelId);
        const clickedModelId = modelId.points[0].data.id;
        //console.log('Clicked handle start id ',  clickedModelId);

        if (!MainMenuSliceVisible) {
            console.log('Handle click, colorscheme');
            // сменить цветовую схему той модели что кликнута
            setPlotData(prevData => {
                // копию предыдущ plotData
                //let updatedPlotData = [...prevData];
                let updatedPlotData = [...prevData].map((model) => {
                    if (model.id === clickedModelId) {
                        return {
                            ...model,
                            showscale: true,
                        };
                    } else {
                        return {
                            ...model,
                            showscale: false,
                        };
                    }
                });
                return updatedPlotData;
            })
        } else {
            //console.log('Slicer, clicked handle model: ',  modelId);
            //const clickedModel = files.find(file => file.id === clickedModelId);
            //console.log('Slicer, clicked handle model ID: ',  clickedModel.id);
            const dotx = modelId.points[0].x;
            const doty = modelId.points[0].y;
            //console.log('px: ', dotx);
            //console.log('py: ', doty);
            //console.log('click:', sliceClick.current);


            //setSlicePoint1([dotx, doty]);
            //setSlicePoint1(currentData => ({...currentData, [dotx, doty]}));
            //setSlicePoint1(prev=>([dotx, doty]));

            //console.log('click IF:', sliceClick.current);
            if (sliceClick.current === 0) {
                //console.log('slicePoints: ', slicePoints.current);
                console.log('DOT1');
                slicePoints.current[0] = [dotx, doty];
                console.log('new slicePoints: ', slicePoints.current);
                /*const dot1 = {
                  id: 'point_id_1',
                  type: 'scatter3d',
                  mode: 'markers',
                  x: [slicePoints.current[0][0]],
                  y: [slicePoints.current[0][1]],
                  z: [slicePoints.current[0][2]],
                  marker: {
                    size: 10,
                    color: '#ff00ff',
                  },
                  name: 'Точка сечения 1',
                };
                setPlotData(prevData => {
                  let updatedPlotData = [...prevData];
                  updatedPlotData.push(dot1);
                  return updatedPlotData;
                });*/
            } else if (sliceClick.current === 1) {
                console.log('DOT2');
                //console.log('slicePoints: ', slicePoints.current);
                slicePoints.current[1] = [dotx, doty];
                //console.log('new slicePoints: ', slicePoints.current);

                //console.log('start plane ' + slicePoints[0] + ' ' + slicePoints[1]);
                console.log('start plane ' + slicePoints.current[0] + ' ' + slicePoints.current[1]);
                const slice = {
                    id: 'slice_id',
                    type: 'mesh3d',
                    colorscale: [[0,"#ff0000"], [1, "#00ff00"]],
                    //i: [0,1,2],
                    //j: [1,1,1],
                    //k: [2,2,2],

                    x : [slicePoints.current[0][0], slicePoints.current[0][0], slicePoints.current[1][0], slicePoints.current[1][0]],
                    y : [slicePoints.current[0][1], slicePoints.current[0][1], slicePoints.current[1][1], slicePoints.current[1][1]],
                    //x : [slicePoint1[0], slicePoint1[0], dotx, dotx],
                    //y : [slicePoint1[1], slicePoint1[1], doty, doty],
                    z : [sliceZMinMax[0], sliceZMinMax[1], sliceZMinMax[0], sliceZMinMax[1]],
                    intensity: [sliceZMinMax[1], sliceZMinMax[1], sliceZMinMax[1], sliceZMinMax[1]],

                    i : [0, 1],
                    j : [1, 2],
                    k : [2, 3],
                    name: "сечение",
                    opacity: 0.1,
                    showscale: false,
                    showlegend: false,
                };
                /*const dot2 = {
                  id: 'point_id_1',
                  type: 'scatter3d',
                  mode: 'markers',
                  x: [slicePoints.current[1][0]],
                  y: [slicePoints.current[1][1]],
                  z: [slicePoints.current[1][2]],
                  marker: {
                    size: 10,
                    color: '#ff00ff',
                  },
                  name: 'Точка сечения 2',
                };*/
                setPlotData(prevData => {
                    // копию предыдущ plotData
                    let updatedPlotData = [...prevData];
                    //if (!updatedPlotData.some(plot => plot.id === 'arrow')) {
                    //  console.log('slice add');
                    //  updatedPlotData.push(slice);
                    //}
                    updatedPlotData.push(slice);
                    //updatedPlotData.push(dot2);
                    return updatedPlotData;
                })
                handleSubmit();
            } else {
                console.log('reset choice CHOICE');
                sliceClick.current = 0;
            }
            sliceClick.current += 1;
        }



    };



    const handleModelOnHover = (modelId) => {
        //const clickedModel = plotData.find(plot => plot.id === modelId);
        //const clickedModelId = modelId.points[0].data.id;
        //console.log('Clicked handle start id ',  clickedModelId);

        if (MainMenuSliceVisible && slicePoints && slicePoints.current[0]?.length && slicePoints.current[1]?.length ) {
            //if (MainMenuSliceVisible && slicePoint1.length && slicePoint2.length ) {
            console.log('hover, handle model 987 ');
            const xCoordinate = modelId.points[0].x;
            console.log('hover, x ' + xCoordinate);
            console.log('hover, y0 ' + sliceYMinMax[0]);
            console.log('hover, y1 ' + sliceYMinMax[1]);
            const updatedLayoutSlice = {
                ...plotLayoutSlice,
                shapes: [
                    {
                        type: 'line',
                        x0: xCoordinate,
                        x1: xCoordinate,
                        y0: sliceYMinMax[0],
                        y1: sliceYMinMax[1],
                        //xref: 'x',
                        //yref: 'paper',
                        line: {
                            color: 'red',
                            width: 2,
                        },
                    },
                ],
            };
            setPlotLayoutSlice(updatedLayoutSlice);

        }



    };
    const saveColorScheme = (fileid) => {
        console.log('try to save CCh name ' + colorSchemeName);

        const file = files.find(file => file.id === fileid);
        const newColorScheme = { name: colorSchemeName, startColor: file.colorMapStartColor, endColor: file.colorMapEndColor};
        const existingSchemes = colorCustomMap.filter(scheme => scheme.name !== newColorScheme.name);

        // Добавляем новую цветовую схему
        const updatedColorCustomMap = [...existingSchemes, newColorScheme];
        document.cookie = "vis3d_colorSchemes=" + JSON.stringify(updatedColorCustomMap);
        setColorCustomMap(updatedColorCustomMap);
        //file.startColor = startColor;
        //file.endColor = endColor;

        //setColorCustomMap(updatedOptions);

        /*setColorMapOptions(prevOptions => {
          // Удаление существующей
          const updatedOptions = prevOptions.filter(option => option.key !== colorSchemeName);
          console.log('apd opts '+ updatedOptions);
          // Добавление новой схемы
          updatedOptions.push(newColorScheme);
          console.log('new opts ' + updatedOptions);
          // Фильтрация updatedOptions для редактирования только цветовых схем с префиксом 'custom_'
          const customColorSchemes = updatedOptions.filter(option => option.value.startsWith('custom_'));
          console.log('customCs ' + customColorSchemes);
          document.cookie = "vis3d_colorSchemes=" + JSON.stringify(customColorSchemes);
          return updatedOptions;
        });*/
    };
    const GradientBar = ({ startColor, endColor, startOffset, endOffset }) => {
        useEffect(() => {
            let scale = d3.scaleLinear().domain([0, 1]).range([startColor, endColor]);
            if (startOffset >= 0.001 || endOffset <= 1.0) {
                console.log('of+ ');
                scale = d3.scaleLinear().domain([startOffset, endOffset]).range([startColor, endColor]);
            }
            console.log('sf: ' + startOffset);
            console.log('ef: ' + endOffset);
            const gradient = d3.select("#gradient")
                .attr("x1", "0%")
                .attr("y1", "100%")
                .attr("x2", "0%")
                .attr("y2", "0%")
                .attr("spreadMethod", "pad");

            // Добавляем цвета в градиент
            for (let i = 0; i <= 1; i += 0.05) {
                if (i >= startOffset && i <= endOffset) {
                    gradient.append("stop")
                        .attr("offset", i)
                        .attr("stop-color", scale(i))
                        .attr("stop-opacity", 1);
                } else {
                    // Заполняем отсутствующие области белым цветом
                    gradient.append("stop")
                        .attr("offset", i)
                        .attr("stop-color", "white")
                        .attr("stop-opacity", 1);
                }
            }
        }, [startColor, endColor, startOffset, endOffset]);

        return (
            <svg width="15" height="130">
                <defs>
                    <linearGradient id="gradient" />
                </defs>
                <rect width="15" height="130" style={{ fill: "url(#gradient)" }} />
            </svg>
        );
    };

    // отключить другие интерполяции кроме нажатой
    useEffect(() => {
        setApplyInterpPolynomialFit(false);
        setApplyInterpKriging(false);
        setApplyInterpRBF(false);
    }, [applyInterpIDW]);
    useEffect(() => {
        setApplyInterpIDW(false);
        setApplyInterpKriging(false);
        setApplyInterpRBF(false);
    }, [applyInterpPolynomialFit]);
    useEffect(() => {
        setApplyInterpIDW(false);
        setApplyInterpPolynomialFit(false);
        setApplyInterpRBF(false);
    }, [applyInterpKriging]);
    useEffect(() => {
        setApplyInterpIDW(false);
        setApplyInterpPolynomialFit(false);
        setApplyInterpKriging(false);
    }, [applyInterpRBF]);

    const deleteColorScheme = () => {
        // Удаление выбранной цветовой схемы из colorMapOptions
        console.log('del scheme: ' + colorSchemeName);
        //setColorMapOptions(prevOptions => prevOptions.filter(option => option.key !== colorSchemeName));

        // Обновление кук
        //document.cookie = "vis3d_colorSchemes=" + JSON.stringify(colorCustomMap.filter(option => option.name !== colorSchemeName));


        const existingSchemes = colorCustomMap.filter(scheme => scheme.name !== colorSchemeName);
        document.cookie = "vis3d_colorSchemes=" + JSON.stringify(existingSchemes);
        setColorCustomMap(existingSchemes);
        //setColorSchemeName('');
    };

    //const onSliderValuesChange = (newValues) => {
    //  setScalerMinValue(newValues[0]);
    //  setScalerMaxValue(newValues[1]);
    //};





    return (
        <>
            {/* меню Основное */}
            <div style={{position: 'absolute', width: '610px', zIndex: '1',border: '1px solid', margin: '10px', backgroundColor: 'white'}}>
                <button type="button" onClick={() => {setMainMenuVisible(!MainMenuVisible);setMainMenuSliceVisible(false);}} style={{position: 'absolute', top: '0.3vw', right: '1vw'}}>
                    { MainMenuVisible ? 'Скрыть' : 'показать'} главное меню
                </button>
                <button type="button" onClick={() => {setMainMenuSliceVisible(!MainMenuSliceVisible);setMainMenuVisible(!MainMenuVisible);}} style={{position: 'absolute', top: '2.5vw', right: '1vw'}}>
                    { MainMenuSliceVisible ? 'Скрыть' : 'показать'} Меню сечений
                </button>
                <div style={{display: MainMenuSliceVisible ? 'block' : 'none'}}>
                    <p>Выберите 2 точки на одной модели и после отправки будет отрисован график:</p>
                    <Plot divId='vis3dPlotlySlice' data={plotDataSlice} layout={ plotLayoutSlice}
                    />
                </div>

                <div style={{display: MainMenuVisible ? 'block' : 'none'}}>
                    <form>
                        <label>Выберите файлы для добавления в таблицу: </label>
                        <br className={"br-nospace"}/>
                        <input style={{width:"auto"}} type="file" onChange={handleFileChange} multiple  />
                        <br className={"br-nospace"}/>
                        <label>Общая прозрачность: <input className="container reset-style" style={{width:"unset"}} type="range" min="0.1" max="1" step="0.1" value={opacity} onChange={handleOpacityAllChange} />{opacity}</label>
                        <br className={"br-nospace"}/>
                        <label>Общий масштаб: <input className="container reset-style" style={{width:"unset"}} type="range" min="0.1" max="2" step="0.1" value={scale}
                                                     onChange={(e) => setScale(e.target.value)}
                                                     onMouseUp={handleChangeScale} />{scale}</label>
                        <br className={"br-nospace"}/>
                        <Table compact size='small' >
                            <TableHeader>
                                <TableRow key={'filename_header'}>
                                    <TableHeaderCell width={1} key={'visible'}>
                                        вкл
                                    </TableHeaderCell>
                                    <TableHeaderCell width={8} key={'filename'}>Имена файлов и кнопка настроек</TableHeaderCell>
                                    {/*<TableHeaderCell width={1} key={'makeMagic'}>Настройки файлов<Icon link name='setting' /></TableHeaderCell>*/}

                                    <TableHeaderCell width={1} key={'delete'}>Удаление</TableHeaderCell>

                                    {downloadColumnVisible &&
                                        <>
                                            <TableHeaderCell width={1} key={'dowload'}>Скачать результат</TableHeaderCell>
                                            <TableHeaderCell width={1} key={'drawOnMap'}>Отобразить на карте</TableHeaderCell>
                                        </>
                                    }
                                </TableRow>
                            </TableHeader>

                            <TableBody>
                                {files.map((file) => (
                                    <>
                                        <TableRow key={'file_' + file.id}>
                                            {/* TODO поменять onchange на onBlur для полей и onMouseUp для type=range */}
                                            <TableCell width={1} key={'visible' + file.id}>
                                                <Checkbox onChange={(e, data) => handleSettingChange(file.id, 'visible', data.checked)} checked={file.visible} />
                                            </TableCell>
                                            <TableCell width={8} key={'filename' + file.id}>
                                                <Popup content={file.name}
                                                       trigger={
                                                           <p style={{maxWidth: '22em', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                                                               {file.name}<Icon link name='setting' onClick={() => handleSettingChange(file.id, 'optionsVisible', !file.optionsVisible)} />
                                                           </p>}
                                                />

                                            </TableCell>
                                            {/*<TableHeaderCell width={1} key={'makeMagic'}><Icon link name='setting' onClick={() => handleSettingChange(file.id, 'optionsVisible', !file.optionsVisible)} /></TableHeaderCell>*/}
                                            <TableCell width={1} key={'del' + file.id}><button type="button" onClick={(e) => handleRemove(e, file.id)}>Удалить</button></TableCell>

                                            {downloadColumnVisible &&
                                                <>
                                                    <TableHeaderCell width={1} key={'download' + file.id}>
                                                        <Button type="button" onClick={(e) => handleOpenModalDownload(e, file)}>
                                                            <Icon link name='download'/>
                                                        </Button>
                                                    </TableHeaderCell>
                                                    <TableHeaderCell width={1} key={'drawOnMap' + file.id}><Icon link name='map' /></TableHeaderCell>
                                                </>
                                            }
                                        </TableRow>

                                        <Draggable
                                            bounds={{top:-310}}
                                            handle=".draghandle">
                                            <div style={{display: file.optionsVisible ? 'block' : 'none', position: 'absolute', padding: '10px', width: '520px',  border: '1px solid black', backgroundColor: 'white', zIndex: '2147483646', left: '0px', transform: 'translate(270px, 10px)'}}>
                                                <div>
                                                    <div className="draghandle" style={{backgroundColor: '#aaaaaa', cursor: 'move'}}>Настройки файла {file.name}</div>
                                                    <li style={{width: 'auto', listStyleType: 'none'}}>
                                                        <button type="button" onClick={() => handleSettingChange(file.id, 'optionsVisible', !file.optionsVisible)} style={{position: 'absolute', top: '0.5vw', right: '0.5vw'}}>
                                                            <Icon link name='close'/>
                                                        </button>
                                                        {/*<div style={{display: MainMenuVisible ? 'none' : 'block'}}>*/}
                                                        <div>
                                                            Тип действия: &nbsp;&nbsp;
                                                            <Dropdown id={'objectType_' + file.id} selection name="objectType" style={{width: '200px'}} placeholder="действие:" options={modelType}
                                                                      onChange={(e, {value}) => handleSettingChange(file.id, 'type', value)}  defaultValue={modelType[0]}/>
                                                        </div>
                                                        <div>
                                                            Прозрачность: &nbsp;&nbsp;
                                                            <input className="reset-style" id={'opacity_' + file.id} type="range" style={{width: '120px'}} min="0" max="1" step="0.05" value={file.opacity} onChange={(e) => handleSettingChange(file.id, 'opacity', e.target.value)} />{file.opacity}
                                                        </div>
                                                        <div>
                                                            Объединение: &nbsp;&nbsp;
                                                            <input className="reset-style" id={'scaled_' + file.id} type="range" style={{width: '120px'}} min="1" max="10" step="1" value={file.scaleD} onChange={(e) => handleSettingChange(file.id, 'scaleD', e.target.value)} />{file.scaleD}
                                                        </div>
                                                        <div>
                                                            Инвертировать по оси Z:  &nbsp;&nbsp;
                                                            <Checkbox toggle style={{transform:"scale(0.7)",margin:"auto"}} label='' checked={file.coordInvert} onChange={() => handleSettingChange(file.id, 'coordInvert', !file.coordInvert)} />
                                                        </div>
                                                        <div>
                                                            Интерполяция для карт по точкам &nbsp;&nbsp;
                                                            <Checkbox label='' checked={file.applyInterpolation} onChange={() => handleSettingChange(file.id, 'applyInterpolation', !file.applyInterpolation)} />
                                                            <button type="button" onClick={() => onclickApplyInterp(file)}>Изменить</button>
                                                        </div>

                                                        {(file.type === 'surface' || file.type === 'surface_iso') &&
                                                            <div>
                                                                Цветовая схема поверхности:  &nbsp;&nbsp;
                                                                <Dropdown id="colorMap" name="colorMap" selection style={{width: '130px'}} placeholder="Выберите схему:"
                                                                          options={colorMapOptions} onChange={(e, {value}) => handleSettingChange(file.id, 'colorMap', value)} defaultValue={colorMapOptions[0].value}/>
                                                            </div>
                                                        }

                                                        {file.type === 'surface_iso' &&
                                                            <div>
                                                                <Checkbox label='изогипсы&nbsp;' checked={file.isolinesDrawA} onChange={(e, data) => handleSettingChange(file.id, 'isolinesDrawA', data.checked)} />
                                                                <label>каждые &nbsp;&nbsp;</label><input id={'isolinesNums_' + file.id} type="number" min="0" style={{width: '60px'}} value={file.isolinesNums} onChange={(e) => handleSettingChange(file.id, 'isolinesNums', e.target.value)} />единиц оси Z,
                                                                толщиной <input id={'isolinesWidth_' + file.id} type="number" min="0" step="0.1" style={{width: '50px'}} value={file.isolinesWidth} onChange={(e) => handleSettingChange(file.id, 'isolinesWidth', e.target.value)} /> пикселей
                                                            </div>
                                                        }

                                                        {file.type === 'points' &&
                                                            <>
                                                                <div>
                                                                    <label>Цвет точек :</label><input id={'dotColor_' + file.id} type="color" value={file.dotColor} onChange={(e) => handleSettingChange(file.id, 'dotColor', e.target.value)} />
                                                                    <br/>
                                                                    <label>Размер точек :<input id={'dotSize_' + file.id} type="number" style={{width: '40px'}} value={file.dotSize} onChange={(e) => handleSettingChange(file.id, 'dotSize', e.target.value)} /> </label>
                                                                </div>
                                                            </>
                                                        }

                                                        {file.type === 'bore' &&
                                                            <>
                                                                <p>пока нет настроек цвета</p>
                                                            </>
                                                        }

                                                        {(file.type === 'surface' || file.type === 'surface_iso') &&
                                                            <>
                                                                <p>Ограничение цв.схемы по z:<br/></p>
                                                                {/*<Slider min={file.zmin} max={file.zmax} value={file.colorscaleOffset} orientation="vertical"/>*/}
                                                                <Checkbox label='Прозрачная обрезка' checked={file.colorscaleTransparency} onChange={() => handleSettingChange(file.id, 'colorscaleTransparency', !file.colorscaleTransparency)} />
                                                                {/*<Slider orientation="vertical"
                                  sx={{
                                    '& input[type="range"]': {
                                      WebkitAppearance: 'slider-vertical',
                                    },
                                  }}/>*/}
                                                                <div style={{display: 'flex', justifyContent: 'left', border: '1px solid', padding: '5px', marginBlockEnd: '5px'}}>
                                                                    <div style={{position: 'relative', height: '130px'}}>
                                                                        <input type="number" id={'endNumber_' + file.id} name={'endNumber_' + file.id} style={{width: '80px'}} value={file.colorscaleOffsetB} />
                                                                        <br/>
                                                                        <input type="number" id={'startNumber_' + file.id} name={'startNumber_' + file.id} value={file.colorscaleOffset} style={{width: '80px', position: 'absolute', bottom: '0'}}
                                                                               onChange={(e) => handleSettingChange(file.id, 'colorscaleOffset', e.target.value)}
                                                                        />
                                                                    </div>
                                                                    <div style={{position: 'relative', height: '130px',padding: "0px 5px"}}>
                                                                        <input className="userVertical" orient="vertical" type="range" id={'offsetSlider_' + file.id} style={{writingMode: "vertical-rl", direction: "rtl",
                                                                            width: "12px",
                                                                            margin:"0px 0"
                                                                        }} min={file.zmin} max={file.zmax}
                                                                               step={(file.zmax - file.zmin) * 0.001}
                                                                               value={file.colorscaleOffset} onChange={(e) => handleSettingChange(file.id, 'colorscaleOffset', e.target.value)}/>
                                                                        </div>
                                                                    <div  style={{position: 'relative', height: '130px',padding: "0px 5px"}}>
                                                                        <input className="userVertical" orient="vertical" type="range" id={'offsetSliderB_' + file.id} style={{writingMode: "vertical-rl", direction: "rtl",
                                                                            width: "12px",
                                                                            margin:"0px 0"
                                                                        }} min={file.zmin} max={file.zmax}
                                                                               step={(file.zmax - file.zmin) * 0.001}
                                                                               value={file.colorscaleOffsetB} onChange={(e) => handleSettingChange(file.id, 'colorscaleOffsetB', e.target.value)}/>

                                                                    </div>
                                                                    {/*<Slider range vertical defaultValue={[file.zmin, file.zmax]}
                                  min={file.zmin} max={file.zmax}
                                  value={file.colorscaleOffset}
                                  onChangeComplete={(val) => handleSettingChange(file.id, 'colorscaleOffset', val)}/>*/}



                                                                    { file.colorMap === 'custom' &&
                                                                        <>
                                                                            {/*<div style={{display: 'flex', justifyContent: 'space-around', border: '1px solid', padding: '10px', marginBlockEnd: '10px'}}>*/}
                                                                            <div style={{width: '15px', height: '130px', display: 'flex', justifyContent: 'flex-end'}}>
                                                                                <div id={'gradientDiv_' + file.id} className="gradientline" style={{width: '15px', height: '130px'}}></div>
                                                                                <GradientBar id={'gradientBar_' + file.id} startColor={file.colorMapStartColor} endColor={file.colorMapEndColor} startOffset={(file.colorscaleOffset - file.zmin)/(file.zmax - file.zmin)} endOffset={ 1 - (file.zmax - file.colorscaleOffsetB )/(file.zmax - file.zmin)} />
                                                                            </div>
                                                                            {/*</div>*/}
                                                                            <div style={{position: 'relative', height: '130px'}}>
                                                                                <input type="color" id={'startColor' + file.id} name={'startColor' + file.id} value={file.colorMapStartColor} onChange={(e) => { handleSettingChange(file.id, 'colorMapStartColor', e.target.value);}} style={{position: 'absolute', bottom: '0'}} />
                                                                                <input type="color" id={'endColor' + file.id} name={'endColor' + file.id} value={file.colorMapEndColor} onChange={(e) => { handleSettingChange(file.id, 'colorMapEndColor', e.target.value);}} />
                                                                            </div>

                                                                            <div id={'colorSchemeEditor_' + file.id} style={{display: 'flex', width: '130px', flexDirection: 'column', alignItems: 'left'}}>
                                                                                <p>Свои схемы:</p>
                                                                                <select id={'colorSchemeList_' + file.id} size="5" onChange={(e) => {
                                                                                    const selectedValue = e.target.value;
                                                                                    const selectedName = e.target.name;
                                                                                    console.log('selected value ' + selectedValue);
                                                                                    console.log('selected name ' + selectedName);
                                                                                    //const selectedScheme = colorCustomMap.find(option => option.value === selectedValue); //selectedName
                                                                                    const selectedScheme = colorCustomMap.find(option => option.name === selectedValue); //selectedName
                                                                                    //console.log('selected scheme ' + selectedScheme.key);
                                                                                    //console.log('selected scheme ' + JSON.stringify(colorMapOptions));

                                                                                    if (selectedScheme) {
                                                                                        console.log('exists ' + selectedScheme.startColor + ' ' + selectedScheme.endColor);

                                                                                        handleSettingChange(file.id, 'colorMapStartColor', selectedScheme.startColor);
                                                                                        handleSettingChange(file.id, 'colorMapEndColor', selectedScheme.endColor);
                                                                                        handleSettingChange(file.id, 'colorMap', 'custom');
                                                                                        handleSettingChange(file.id, 'colorMapStartEndColor', [selectedScheme.startColor,selectedScheme.endColor]);
                                                                                        //setStartColor(selectedScheme.startColor);
                                                                                        //setEndColor(selectedScheme.endColor);
                                                                                        setColorSchemeName(selectedScheme.name);
                                                                                    } else{
                                                                                        console.log('else');
                                                                                    }
                                                                                }
                                                                                }>
                                                                                    {colorCustomMap.map(option => <option key={option.name} value={option.value}>{option.name}</option>)}
                                                                                </select>
                                                                            </div>

                                                                            <div id={'}colorSchemeEditor2_' + file.id} style={{display: 'flex', width: '170px', flexDirection: 'column', alignItems: 'left'}}>
                                                                                <div style={{border: '1px solid', padding: '5px'}}>
                                                                                    имя цветовой схемы:
                                                                                    <input type="text" id={'schemeName_' + file.id} name="schemeName" placeholder="Название схемы" value={colorSchemeName} onChange={(e) => setColorSchemeName(e.target.value)} />
                                                                                    <button type="button" onClick={() => saveColorScheme(file.id)}>Создать или перезаписать</button>
                                                                                    <button type="button" onClick={() => deleteColorScheme(colorSchemeName)} style={{marginBlockEnd: '10px'}}>Удалить</button>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    }


                                                                </div>
                                                            </>
                                                        }
                                                    </li>
                                                    <Button type="button" onClick={handleSubmit}>Применить <Icon loading name='spinner'  style={{display: loadingVisible ? 'block' : 'none'}}/></Button>
                                                </div>
                                            </div>
                                        </Draggable>
                                    </>




                                ))}
                            </TableBody>
                        </Table>
                    </form>
                    <p>{errorText}</p>
                    <Button type="button" onClick={handleSubmit}>Отправить <Icon loading name='spinner' style={{display: loadingVisible ? 'block' : 'none'}}/></Button>
                    {/*<Button type="button" onClick={handleSubmitGEOREFTEST}>GeoRef </Button>*/}
                    <button type="button" onClick={() => setButtonCancel(!buttonCancel)} style={{display: buttonCancel ? 'block' : 'none'}}>Отменить</button>
                </div>
            </div>
            {/* меню скачивания*/}
            <div style={{
                display: DownloadMenuVisible ? 'block' : 'none', position: 'absolute', padding: '10px', width: '280px',  border: '1px solid black', backgroundColor: 'white', zIndex: '2147483647', left: '0px', transform: 'translate(470px, 10px)'
            }}>
                <div draggable={true}>
                    <div className="draghandle">зажать тут и перетянуть</div>
                    <li style={{width: 'auto', listStyleType: 'none'}}>
                        <button type="button" onClick={() => setDownloadMenuVisible(false)}>
                            <Icon link name='close'/>
                        </button>
                        {/*<div style={{display: MainMenuVisible ? 'none' : 'block'}}>*/}
                        <div>
                            <Radio label='не скачивать' name='DownloadMainAction' value='no' checked={downloadMainAction === 'no'} onChange={(e) => handleSettingChange(downloadMenuCurrentID, 'downloadMainAction', 'no')}/>
                            <br/>
                            <Radio label='Скачать json' name='DownloadMainAction' value='download_geojson' checked={downloadMainAction === 'download_geojson'} onChange={(e) => handleSettingChange(downloadMenuCurrentID, 'downloadMainAction', 'download_geojson')}/>
                            <br/>
                            <div style={{paddingLeft: '25px'}}>
                                <label>На <input type="number" value={colorNums} onChange={(e) => handleSettingChange(downloadMenuCurrentID, 'colorNums', e.target.value)} /> цветов</label><br/>
                                <Checkbox label='GeoJSON полигонами' checked={geojsonAsPolygons} onChange={(e) => handleSettingChange(downloadMenuCurrentID, 'geojsonAsPolygons', e.target.value)}/>
                                <div style={{paddingLeft: '15px'}}>
                                    <Checkbox label='интерполировать разрывы' checked={true} disabled /><br/>
                                    <Checkbox label='добавлять шкалу' checked={true} disabled/><br/>
                                    <Checkbox label='добавлять границы' checked={false} disabled/>
                                </div>
                                <Checkbox label='GeoJSON прямоугольниками' checked={geojsonAsBox} onChange={(e) => handleSettingChange(downloadMenuCurrentID, 'geojsonAsBox', e.target.value)} /><br/>
                                <Checkbox label='GeoJSON шестиугольниками' checked={geojsonAsHexagons} onChange={(e) => handleSettingChange(downloadMenuCurrentID, 'geojsonAsHexagons', e.target.value)} />
                            </div>
                            <br/>
                            <Radio label='Скачать PAMDataset' name='DownloadMainAction' value='download_png' checked={downloadMainAction === 'download_png'} onChange={(e) => handleSettingChange(downloadMenuCurrentID, 'downloadMainAction', 'download_png')}/>
                        </div>
                        {(files.length > 0) && (files.find(file => file.id === downloadMenuCurrentID)?.custom_crs_need === true)  &&
                            <>
                                <p>Внимание, требуется выбор координатной системы:</p>
                                <Dropdown placeholder='Выбрать crs' fluid search selection options={crsOptions} onChange={(e, {value}) => setDownloadMenuCrs(value)}/>
                                {downloadMenuCrs === "custom"  &&
                                    <TextArea placeholder='своя строка proj4' value={downloadMenuProjFieldValue}/>
                                }
                            </>}
                        <Button type="button" onClick={(e) => handleDownloadSubmit(e, downloadMenuCurrentID)}>
                            <Icon link name='download'/> скачать
                        </Button>
                    </li>
                </div>
            </div>
            {/* меню интерполяций*/}
            <Modal size='small' open={openApplyInterp} onClose={() => setOpenApplyInterp(false)} >
                <ModalHeader>Применить интерполирование для данных</ModalHeader>
                <ModalContent>
                    {/*<div id='apply_interpolations' style={{paddingLeft: '25px', display: applyInterpolation ? 'none' : 'block'}}>*/}
                    <Checkbox label='отображать оригинальные точки' checked={showOriginalPoints} onChange={() => setShowOriginalPoints(!showOriginalPoints)} />
                    <br/>
                    <label>Грид (сетка) на <input type="number" min="3" max="500" value={gridNums} onChange={(e) => setGridNums(e.target.value)} />точек.</label>
                    <div style={{paddingLeft: '10px'}}>
                        <Checkbox label='Simple IDW (с обратным взвешиванием расстояния):' checked={applyInterpIDW} onChange={() => setApplyInterpIDW(!applyInterpIDW)} />
                        <br/>
                        <div style={{paddingLeft: '25px', display: applyInterpIDW ? 'block' : 'none'}} >
                            <label>Power: <input type="range" min="1" max="10" step="1" value={power} onChange={(e) => setPower(e.target.value)} />
                                {power}</label>
                            <br/>
                            <label>Соседних точек при поиске соседей:
                                <br></br>
                                <input type="range" min="3" max="50" step="1" value={interpKnn} onChange={(e) => setInterpKnn(e.target.value)} />
                                {interpKnn}</label>
                        </div>
                        <Checkbox label='Полиномиальное:' checked={applyInterpPolynomialFit} onChange={() => setApplyInterpPolynomialFit(!applyInterpPolynomialFit)} />
                        <br/>
                        <div style={{paddingLeft: '25px', display: applyInterpPolynomialFit ? 'block' : 'none'}}>
                            <label>Порядок:<input type="range" min="2" max="50" step="1" value={applyInterpPolynomialFitDegree} onChange={(e) => setApplyInterpPolynomialFitDegree(e.target.value)} />
                                {applyInterpPolynomialFitDegree}</label>
                            <br/>
                            <Checkbox label='все полиномиальные признаки до указанной степени' checked={applyInterpPolynomialFitInteraction} onChange={() => setApplyInterpPolynomialFitInteraction(!applyInterpPolynomialFitInteraction)} />
                            <br/>
                            <Checkbox label='(вычислить интерцент, данные не центрированы)' checked={applyInterpPolynomialFitIntercept} onChange={() => setApplyInterpPolynomialFitIntercept(!applyInterpPolynomialFitIntercept)} />
                        </div>

                        <Checkbox label='Кригинг' checked={applyInterpKriging} onChange={() => setApplyInterpKriging(!applyInterpKriging)} />
                        <br/>
                        <div style={{paddingLeft: '25px', display: applyInterpKriging ? 'block' : 'none'}}>
                            <label>Вариограмма :<Dropdown id="apply_interp_kriging_variogram" name="apply_interp_kriging_variogram"
                                                          placeholder="Вариограмма" selection options={interpKrigingVariogrammOptions} onChange={(e, {value}) => setKrigingVariogramm(value)}
                            /></label>
                            <div style={{ paddingLeft: '25px' }}>
                                <label>nlag &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:<input type="number" min="2" value={krigingNlag} onChange={(e) => setKrigingNlag(e.target.value)} /></label>
                                <br/>
                                <label>nugget &nbsp;&nbsp;:<input type="number" value={krigingNugget} onChange={(e) => setKrigingNugget(e.target.value)} /></label>
                                <br/>
                                <div style={{paddingLeft: '25px', display: ['power', 'gaussian', 'spherical', 'exponential', 'hole-effect'].includes(krigingVariogramm) ? 'block' : 'none'}}>
                                    <div style={{display: krigingVariogramm === 'linear' ? 'block' : 'none'}}>
                                        <label>Slope :<input type='number' placeholder='Enter krigingSlope' value={krigingSlope} onChange={e => setKrigingSlope(e.target.value)}/></label>
                                    </div>
                                    <div style={{display: krigingVariogramm === 'power' ? 'block' : 'none'}}>
                                        <label>Scale &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:<input type='number' placeholder='Scale' value={krigingScale} onChange={e => setKrigingScale(e.target.value)} /></label>
                                        <br/>
                                        <label>Exponent :<input type='number' placeholder='Exponent' value={krigingExponent} onChange={e => setKrigingExponent(e.target.value)} /></label>
                                    </div>
                                    <div style={{display: ['gaussian', 'spherical', 'exponential', 'hole-effect'].includes(krigingVariogramm) ? 'block' : 'none'}}>
                                        <label>Sill &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:<input type='number' placeholder='Sill' value={krigingSill} onChange={e => setKrigingSill(e.target.value)} /></label>
                                        <br/>
                                        <label>Range &nbsp;&nbsp;&nbsp;&nbsp;:<input type='number' placeholder='Range' value={krigingRange} onChange={e => setKrigingRange(e.target.value)} /></label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Checkbox label='метод радиальных базисных функций' checked={applyInterpRBF} onChange={() => setApplyInterpRBF(!applyInterpRBF)} />
                        <br/>
                        <div style={{paddingLeft: '25px', display: applyInterpRBF ? 'block' : 'none'}}>
                            <label>Метод :<Dropdown id="apply_interp_rbf_spline" name="apply_interp_rbf_spline"
                                                    placeholder="Сплайн" selection options={interpRBFSplineOptions} onChange={(e, {value}) => setRbfSpline(value)}
                            /></label>
                            <div style={{paddingLeft: '25px', display: ['cubic', 'quintic', 'thin_plate_spline', 'linear'].includes(rbfSpline) ? 'none' : 'block'}}>
                                <label>Epsilon &nbsp;&nbsp;&nbsp;&nbsp;:<input type='number' placeholder='epsilon' value={rbfEpsilon} onChange={e => setRbfEpsilon(e.target.value)} /></label>
                            </div>
                        </div>
                    </div>
                    {/*</div>*/}
                </ModalContent>
                <ModalActions>
                    <Button negative onClick={() => setOpenApplyInterp(false)}>No</Button>
                    <Button positive onClick={() => setFileFromForm()}>Yes</Button>
                </ModalActions>
            </Modal>
            <Plot divId='vis3dPlotlyDiv' style={{position: 'absolute', width: '100%', height: '100%'}} data={plotData} layout={{...plotLayout,...{width:widthRatio,height:heightRatio}}} config={{responsive: true}}
                  onClick={handleModelClick} onHover={handleModelOnHover} />
        </>
    );
}

export default Visualizer3D;