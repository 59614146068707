import React from 'react';
import loadGIF from "../Interface/1487.gif"
class LoadingScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            point:false,
        }
        this.escFunction = this.escFunction.bind(this);
    };
    escFunction(event){
        if (event.key === "Escape") {
            this.props.close()
        }
    }
    componentDidMount(){
        document.addEventListener("keydown", this.escFunction, false);
    }
    componentWillUnmount(){
        document.removeEventListener("keydown", this.escFunction, false);
    }
    render() {
        return(
            <>
                {this.props.loading === false ? (
                    <></>
                ) : (
                    <div onKeyDown={this.handleKeyDown} className={"div-loader"} style={{display:this.props.display,width:"100%",height:"100%",zIndex:"99999999",backgroundColor:"#000000",opacity:"0.2"}}>
                        <img src={loadGIF} className="gif-loader"/>
                    </div>
                )}
            </>
        )}}

export default LoadingScreen;
