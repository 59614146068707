import React from 'react';
import UploadLas from "./UploadLas";
import axios from "axios";
import {georefApi,urlBackend} from "../VariableInitialize";
import Plot from "react-plotly.js";
import "../Styles/LasTool.css"
import {Button, Label, Loader, Checkbox, Dropdown, Input} from "semantic-ui-react";
import {compareMax,compareMaxReverse} from "../functions";
import Draggable from 'react-draggable'
import {Slider} from "@mui/material";
import {Helmet} from "react-helmet";

class LasTool extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            LasGraph : {},
            lasData: null,
            lasLoading: false,
            lasGraphType: 0,
            researchList:[],
            researchSelectedList:[],
            subplots:[],
            axisX:'Normal',
            axisX2:false,
            axisY:false,
            maxDepth:null,
            maxValue:null,
            lasFillSliderValue:0,
            axisXRangeFrom:null,
            axisXRangeTo:null,
            ReservoirRangeFrom:null,
            FillMarks:[
                {value: 0,
                    label: 'Left',},
                {value: 1,
                    label: 'None',},
                {value: 2,
                    label: 'Right',},

            ],
            DepthsMarks:[{name:"",value:null}],
            ReservoirMultipleRangeFrom:[],
            ReservoirMultipleRangeTo:[],
            ReservoirRangeTo:null,
            ReservoirMultipleXRangeFrom:[],
            ReservoirMultipleXRangeTo:[],
            maxDepthOverall:null,
            maxValueOverall:null,
            minDepthOverall:null,
            minValueOverall:null,
            lasGraphStyle:'700px',
            FillArray:["Left","None","Right"],
            Fill:'Left',
            Minimize:"block",
            colorArray: ["green", "yellow", "red", "orange", "purple", "teal", "crimson", "gold", "DarkKhaki",
                'Lavender', 'Indigo', 'olive', 'Brown', 'Salmon', "black","green", "yellow", "red", "orange", "purple",
                "teal", "crimson", "gold", "DarkKhaki", 'Lavender', 'Indigo', 'olive', 'Brown', 'Salmon', "black"]
        }
        this.getFormData = this.getFormData.bind(this);
        this.minimize = this.minimize.bind(this);
        this.onXAxisSlider = this.onXAxisSlider.bind(this);
        this.onYAxisSlider = this.onYAxisSlider.bind(this);
        this.onInputRangeFromChange = this.onInputRangeFromChange.bind(this);
        this.onInputRangeToChange = this.onInputRangeToChange.bind(this);
        this.onInputDepthRangeFromChange = this.onInputDepthRangeFromChange.bind(this);
        this.onInputDepthRangeToChange = this.onInputDepthRangeToChange.bind(this);
        this.onFillSlider = this.onFillSlider.bind(this);
        this.buildGraph = this.buildGraph.bind(this);
        this.singleGraph =this.singleGraph.bind(this);
        this.gridGraphs = this.gridGraphs.bind(this);
        this.onResearchesChange =this.onResearchesChange.bind(this);
        this.onLasFillSlider = this.onLasFillSlider.bind(this)
        this.onDepthMarkAdd = this.onDepthMarkAdd.bind(this);
        this.onDepthMarkDelete = this.onDepthMarkDelete.bind(this)
        this.DepthMarkChange = this.DepthMarkChange.bind(this)
        this.getFormDataFromUrl = this.getFormDataFromUrl.bind(this)
    };
    DepthMarkChange = (e,data,index) =>{
        console.log(e,data,index)
        let marks = this.state.DepthsMarks;
        marks[index].value = Number.parseFloat(data.value)
        this.setState({DepthsMarks:marks})
    }
    onDepthMarkAdd = (index) => {
        let marks = this.state.DepthsMarks;
        marks.push({name:"",value:null})
        this.setState({DepthsMarks:marks})
    }
    onDepthMarkDelete = (index) => {
        console.log(index)
        let marks = this.state.DepthsMarks;
        marks.splice(index,1)
        this.setState({DepthsMarks:marks})

    }
    onLasFillSlider = (e,data) =>{
        if (this.state.FillArray[data] !== this.state.Fill)
            this.setState({Fill:this.state.FillArray[data]})
    }
    getFormData (formData) {
        this.setState({lasLoading:true})
            //uploadLas
        axios.post(georefApi+'/getLas', formData)
            .then((res) => {
                console.log(res)
                let researchList = [];
                for (let i = 0; i < res.data.length; i++)
                {
                    researchList.push(res.data[i].name)
                }
                console.log(researchList)
                let maxDepth = Math.max(...res.data[1].x);
                let maxValue = Math.max(...res.data[1].y);
                let minDepth = Math.min(...res.data[1].x);
                let minValue = Math.min(...res.data[1].y);
                let maxRespDepth ;
                let maxRespValue ;
                let minRespDepth ;
                let minRespValue ;
                for (let i = 1; i < res.data.length; i++)
                {
                     maxRespDepth = Math.max(...res.data[i].x)
                     maxRespValue = Math.max(...res.data[i].y)
                     minRespDepth = Math.min(...res.data[i].x)
                     minRespValue = Math.min(...res.data[i].y)
                    if ( maxRespDepth > maxDepth)
                        maxDepth = maxRespDepth;
                    if ( maxRespValue > maxValue)
                        maxValue = maxRespValue;
                    if ( minRespDepth < minDepth)
                        minDepth = minRespDepth;
                    if ( minRespValue < minValue)
                        minValue = minRespValue;


                }
                this.setState({lasData: res.data, lasLoading:false,
                    researchList: researchList,lasGraphStyle:(res.data.length * 150)+'px',
                    maxDepth:maxDepth, maxValue:maxValue,maxDepthOverall:maxRespDepth,
                    maxValueOverall:maxRespValue,
                    minDepthOverall:minRespDepth,
                    minValueOverall:minRespValue,})
            }).catch((error) => {
            console.log(error)
        });
    };
    onYAxisSlider (e,data) {
        if (data.checked)
            this.setState({axisY:true})
        else
            this.setState({axisY:false})
    }
    getFormDataFromUrl (url) {
        console.log(url)
        fetch(url).then( res => res.blob() )
            .then( blob => {
                var fileOfBlob = new File([blob], 'file.las');
                console.log(fileOfBlob);
                const formData = new FormData();
                formData.append("file", fileOfBlob);
                this.setState({lasLoading:true})
                axios.post(georefApi+'/getLas',formData )
                    .then((res) => {
                        console.log(res)
                        let researchList = [];
                        for (let i = 0; i < res.data.length; i++)
                        {
                            researchList.push(res.data[i].name)
                        }
                        console.log(researchList)
                        this.setState({lasData: res.data, lasLoading:false, researchList: researchList,lasGraphStyle:(res.data.length * 150)+'px'})
                    }).catch((error) => {
                    console.log(error)
                });
            });
    };
    singleGraph (modifier) {
        let datatrace = [];
        let index = 0;
        let subplots = [];
        if (modifier){
            datatrace = this.state.LasGraph.data;
            subplots = this.state.subplots;
            let uniqueAxis = []
            for ( let i = 0; i < datatrace.length; i++){
                if (!uniqueAxis.includes(datatrace[i].xaxis))
                    uniqueAxis.push(datatrace[i].xaxis)
            }
            index = uniqueAxis.length;
        }

        let yLayout={};
        let xLayout={};
        let tracePositioning = [];
        let maxX=0 ;
        console.log(this.state.lasData)
        for (let i = 0 ; i< this.state.lasData.length ; i++) {
            if (this.state.researchSelectedList.includes(this.state.lasData[i].name)){
                if (Math.max(...this.state.lasData[i].y) > maxX)
                    maxX = Math.max(...this.state.lasData[i].y)
            }}
        console.log(maxX)
        for (let i = 0 ; i< this.state.lasData.length ; i++) {
            if (this.state.researchSelectedList.includes(this.state.lasData[i].name)){
                let trace = {
                    x: this.state.lasData[i].y,
                    y: this.state.lasData[i].x,
                    line: {
                        color: this.state.colorArray[i],
                        width: 1
                    },
                    xaxis: 'x',
                    yaxis: 'y',
                    type: 'scatter',
                    fillcolor: this.state.colorArray[i],
                    color:this.state.colorArray[i],
                    name: this.state.lasData[i].name
                };
                if (this.state.Fill === 'Left')
                    trace.fill = 'tozerox';
                if (modifier)
                    trace.xaxis='x'+(index+1);
                console.log(i,trace)
                let axisRange = 0.0125
                console.log((this.state.researchSelectedList.length-1),axisRange)
                let rangeTo = this.state.ReservoirMultipleRangeTo[i]+50;
                let rangeFrom = this.state.ReservoirMultipleRangeFrom[i]

                if (this.state.ReservoirMultipleRangeTo[i] === undefined)
                    rangeTo = Math.max(...this.state.lasData[i].x)+50
                else if ( this.state.ReservoirRangeTo !== null && this.state.ReservoirRangeTo !== undefined)
                    rangeTo = this.state.ReservoirRangeTo
                if (this.state.ReservoirMultipleRangeFrom[i] === undefined)
                    rangeFrom = Math.min(...this.state.lasData[i].x);
                else if ( this.state.ReservoirRangeFrom !== null && this.state.ReservoirRangeFrom !== undefined)
                    rangeFrom = this.state.ReservoirRangeFrom

                console.log(rangeTo,rangeFrom)
                let valueTo = this.state.ReservoirMultipleXRangeTo[i];
                let valueFrom = this.state.ReservoirMultipleXRangeFrom[i]
                if (this.state.ReservoirMultipleXRangeTo[i] === undefined)
                    valueTo = Math.max(...this.state.lasData[i].y)
                if (this.state.ReservoirMultipleXRangeFrom[i] === undefined)
                    valueFrom = Math.min(...this.state.lasData[i].y);
                if ( i>0){

                    if(this.state.axisY){
                        trace.yaxis='y'+(i+1);
                        console.log(this.state.ReservoirMultipleRangeTo[i],this.state.ReservoirMultipleRangeFrom[i],rangeFrom,rangeTo)

                        yLayout['yaxis'+(i+1)] = {
                            overlaying: 'y',
                            range: [rangeTo,rangeFrom],
                            autorange: false,
                            side:'right',
                            //position:0+(axisRange*(i-1)),
                            tickfont: {
                                size: 11,
                                family: 'Old Standard TT, serif',
                            },
                        }
                        yLayout['yaxis'+(i+1)].position = 0+(axisRange*(i-1))
                    }
                    trace.xaxis='x'+(i+1);
                    console.log(0+(axisRange*(i-1)),axisRange)
                    yLayout['xaxis'+(i+1)] = {
                        overlaying: 'x',
                        range: [valueFrom,valueTo],
                        autorange: false,
                        side:'botton',
                        position:0+(axisRange*(i)),
                        tickfont: {
                            size: 11,
                            family: 'Old Standard TT, serif',
                        },
                    }
                    console.log(i,trace)
                }else{
                    console.log(this.state.ReservoirMultipleRangeTo[i],this.state.ReservoirMultipleRangeFrom[i])
                    yLayout['yaxis'] = {
                        range: [rangeTo,rangeFrom],
                        autorange: false,
                        domain:[0.0125*this.state.researchSelectedList.length,1],
                        tickfont: {
                            family: 'Old Standard TT, serif',
                            size: 11,
                        },

                        };
                    if(this.state.axisY)
                        yLayout['yaxis'].domain = [0.2,1]
                    console.log(i,trace,rangeFrom,rangeTo)
                    yLayout['xaxis'] = {
                        range: [valueFrom,valueTo],
                        autorange: false,
                        domain:[0.2,1],
                        tickfont: {
                            size: 11,
                            family: 'Old Standard TT, serif',
                        },
                    }
                }

                //datatrace.push(trace)
                if (this.state.Fill === 'Right') {
                    let trace2 = {
                        x: [maxX, maxX],
                        y: [Math.min(...this.state.lasData[i].x), Math.max(...this.state.lasData[i].x)],
                        fillcolor: this.state.colorArray[i],
                        fill: 'tonextx',
                        mode: 'markers',
                        xaxis: 'x',
                        yaxis: 'y',
                        color: this.state.colorArray[i],
                        type: 'scatter',
                        name: "",
                        marker: {size: 1}
                    }
                    if (modifier)
                        trace2.xaxis='x'+(index+1);
                    //datatrace.push(trace2);
                    tracePositioning.push({trace:[trace,trace2],max:Math.max(...this.state.lasData[i].y)})
                }
                else {
                    tracePositioning.push({trace:[trace],max:Math.max(...this.state.lasData[i].y)})
                }
            }
        }
        let axisSettings = {spikemode: 'toaxis'}
        if (this.state.axisX === 'Log')
            axisSettings.type = 'log'
        axisSettings.domain = [0.2,1]
        var layout;
        console.log("1")
        if (!modifier)
        {
            console.log("11")
            let tempLayout ={}
            layout ={
                hovermode:'closest',
                width: Number.parseInt(this.state.lasGraphStyle),
                height:900,
                xaxis: axisSettings,
                yaxis:{domain:[0.2,1]}
            };
            layout = {...layout,...yLayout,...xLayout};
        }

        else{
            console.log("2")
            layout = this.state.LasGraph.layout
            let tempLayout ={}
            tempLayout['xaxis'+(index+1)] = axisSettings;
            console.log(tempLayout,layout,yLayout)
            layout = {...layout,...tempLayout,...yLayout,...xLayout};
        }

        if (this.state.Fill === 'Left')
            tracePositioning.sort(compareMax)
        else
            tracePositioning.sort(compareMaxReverse)
        for (let i = 0; i < tracePositioning.length; i++){
            for (let j = 0; j<tracePositioning[i].trace.length; j++){
                datatrace.push(tracePositioning[i].trace[j])
            }
        }
        for (let q = 0; q< this.state.DepthsMarks.length;q++){
            if (this.state.DepthsMarks[q].value !== null ){
                let tracemark = {
                    x: [0, maxX],
                    y: [this.state.DepthsMarks[q].value, this.state.DepthsMarks[q].value],
                    fillcolor: 'black',
                    //fill: 'tonextx',
                    line: {
                        color: 'black',
                        width: 2
                    },
                    xaxis: 'x',
                    yaxis: 'y',
                    color: 'black',
                    type: 'scatter',
                    name: "Reservoir From",
                }
                if (modifier){
                    tracemark.xaxis='x'+(index+1);
                }
                datatrace.push(tracemark)
            }
        }
        if (this.state.ReservoirRangeFrom!==null&& this.state.ReservoirRangeTo!==null){

            //layout.yaxis.range = [this.state.ReservoirRangeFrom-1,this.state.ReservoirRangeTo+1];
            //layout.yaxis.autorange = false;
            console.log(this.state.ReservoirRangeTo,this.state.ReservoirRangeFrom)
            let tracemin = {
                x: [0, maxX],
                y: [this.state.ReservoirRangeFrom, this.state.ReservoirRangeFrom],
                fillcolor: 'black',
                //fill: 'tonextx',
                line: {
                    color: 'black',
                    width: 2
                },
                xaxis: 'x',
                yaxis: 'y',
                color: 'black',
                type: 'scatter',
                name: "Reservoir From",
            }
            let tracemax ={
                x: [0, maxX],
                y: [this.state.ReservoirRangeTo, this.state.ReservoirRangeTo],
                fillcolor: 'black',
                //fill: 'tonextx',
                line: {
                    color: 'black',
                    width: 2
                },
                xaxis: 'x',
                yaxis: 'y',
                color: 'black',
                type: 'scatter',
                name: "Reservoir To",
            }
            if (modifier){
                tracemin.xaxis='x'+(index+1);
                tracemax.xaxis='x'+(index+1);
            }
            datatrace.push(tracemin)
            datatrace.push(tracemax)
        }
        if (!modifier)
            subplots.push('xy');
        else
            subplots.push('x'+(index+1)+'y');
        layout.grid = {
            rows: 1,
                columns: subplots.length,
                subplots:[subplots],
        };
        layout.width= Number.parseInt(subplots.length * 700)
        console.log(datatrace,layout,tracePositioning)
        this.setState({LasGraph:{layout:layout,data:datatrace,length:this.state.lasData.length},subplots:subplots});
    }
    gridGraphs () {
        console.log(this.state.ReservoirMultipleRangeFrom,this.state.ReservoirMultipleRangeTo)
        let trace1 ={
            x: this.state.lasData[0].y,
            y: this.state.lasData[0].x,
            type: 'scatter',
            xaxis: 'x',
            yaxis: 'y',
            line: {
                color:this.state.colorArray[0],
                width: 1
            },

            fillcolor: this.state.colorArray[0],
            color:this.state.colorArray[0],
            name: this.state.lasData[0].name
        };
        if (this.state.Fill === 'Left')
            trace1.fill = 'tozerox';
        let datatrace = [trace1]
        if (this.state.Fill === 'Right') {
            let trace2 = {
                x: [Math.max(...this.state.lasData[0].y), Math.max(...this.state.lasData[0].y)],
                y: [Math.min(...this.state.lasData[0].x), Math.max(...this.state.lasData[0].x)],
                fillcolor: this.state.colorArray[0],
                fill: 'tonextx',
                mode: 'markers',
                xaxis: 'x',
                yaxis: 'y',
                color: this.state.colorArray[0],
                type: 'scatter',
                name: "",
                marker: {size: 1}
            }
            datatrace.push(trace2);
        }
        for (let q = 0; q< this.state.DepthsMarks.length;q++){
            if (this.state.DepthsMarks[q].value !== null ){
                let tracemark = {
                    x: [0, Math.max(...this.state.lasData[0].y)],
                    y: [this.state.DepthsMarks[q].value, this.state.DepthsMarks[q].value],
                    //fill: 'tonextx',
                    line: {
                        color: 'black',
                        width: 2
                    },
                    xaxis: 'x',
                    yaxis: 'y',
                    color: 'black',
                    type: 'scatter',
                    name: "Reservoir To",
                }
                datatrace.push(tracemark)
            }
        }
        if (this.state.ReservoirRangeFrom!==null&& this.state.ReservoirRangeTo!==null){
            console.log(this.state.ReservoirRangeFrom,this.state.ReservoirRangeTo)
            datatrace.push({
                x: [0, Math.max(...this.state.lasData[0].y)],
                y: [this.state.ReservoirRangeFrom, this.state.ReservoirRangeFrom],
                fillcolor: 'black',
                //fill: 'tonextx',
                line: {
                    color: 'black',
                    width: 2
                },
                xaxis: 'x',
                yaxis: 'y',
                color: 'black',
                type: 'scatter',
                name: "Reservoir From",
            })
            datatrace.push({
                x: [0, Math.max(...this.state.lasData[0].y)],
                y: [this.state.ReservoirRangeTo, this.state.ReservoirRangeTo],
                fillcolor: 'black',
                //fill: 'tonextx',
                line: {
                    color: 'black',
                    width: 2
                },
                xaxis: 'x',
                yaxis: 'y',
                color: 'black',
                type: 'scatter',
                name: "Reservoir To",
            })

        }
        let subplots = ['xy']
        let subplotNames = [this.state.lasData[0].name]
        let annotations = [
            {
                text: this.state.lasData[0].name,
                x: (1/(this.state.lasData.length))/2, // координата x текста
                y: 1.1, // координата y текста
                showarrow: false, // скрыть стрелку
                xref: 'paper', // относительная координата x
                yref: 'paper', // относительная координата y
                xanchor: 'center', // положение текста по горизонтали
                yanchor: 'bottom', // положение текста по вертикали
            },
        ]
        let tempLayout = {}
        for (let i = 1 ; i< this.state.lasData.length ; i++) {
            console.log(this.state.lasData[i])
            let trace = {
                x: this.state.lasData[i].y,
                y: this.state.lasData[i].x,
                xaxis: 'x'+(i+1),
                yaxis: 'y',
                line: {
                    color: this.state.colorArray[i],
                    width: 1
                },
                type: 'scatter',
                fillcolor: this.state.colorArray[i],
                color:this.state.colorArray[i],
                name: this.state.lasData[i].name
            };
            let axisSettings = {spikemode: 'toaxis',side:'top',}
            if (this.state.axisX === 'Log')
                axisSettings.type = 'log'
            if (this.state.axisXRangeFrom!==null&& this.state.axisXRangeTo!==null)
                axisSettings.range = [this.state.axisXRangeFrom,this.state.axisXRangeTo]
            tempLayout['xaxis'+(i+1)] = axisSettings;
            if (this.state.Fill === 'Left')
                trace.fill = 'tozerox';
            datatrace.push(trace)
            if (this.state.Fill === 'Right') {
                let trace2 = {
                    x:[Math.max(...this.state.lasData[i].y),Math.max(...this.state.lasData[i].y)],
                    y: [Math.min(...this.state.lasData[i].x),Math.max(...this.state.lasData[i].x)],
                    xaxis: 'x'+(i+1),
                    yaxis: 'y',
                    fill: 'tonextx',
                    mode: 'markers',
                    type: 'scatter',
                    fillcolor: this.state.colorArray[i],
                    color:this.state.colorArray[i],
                    name: "",
                    marker: { size: 1 }
                }
                datatrace.push(trace2)
            }
            if (this.state.ReservoirRangeFrom!==null&& this.state.ReservoirRangeTo!==null){
                datatrace.push({
                    x: [0, Math.max(...this.state.lasData[i].y)],
                    y: [this.state.ReservoirRangeFrom, this.state.ReservoirRangeFrom],
                    fillcolor: 'black',
                    //fill: 'tonextx',
                    line: {
                        color: 'black',
                        width: 2
                    },
                    xaxis: 'x'+(i+1),
                    yaxis: 'y',
                    color: 'black',
                    type: 'scatter',
                    name: "Reservoir From",
                })
                datatrace.push({
                    x: [0, Math.max(...this.state.lasData[i].y)],
                    y: [this.state.ReservoirRangeTo, this.state.ReservoirRangeTo],
                    fillcolor: 'black',
                    //fill: 'tonextx',
                    line: {
                        color: 'black',
                        width: 2
                    },
                    xaxis: 'x'+(i+1),
                    yaxis: 'y',
                    color: 'black',
                    type: 'scatter',
                    name: "Reservoir To",
                })
            }
            for (let q = 0; q< this.state.DepthsMarks.length;q++){
                if (this.state.DepthsMarks[q].value !== null ){
                    let tracemark = {
                        x: [0, Math.max(...this.state.lasData[i].y)],
                        y: [this.state.DepthsMarks[q].value, this.state.DepthsMarks[q].value],
                        //fill: 'tonextx',
                        line: {
                            color: 'black',
                            width: 2
                        },
                        xaxis: 'x'+(i+1),
                        yaxis: 'y',
                        color: 'black',
                        type: 'scatter',
                        name: "Reservoir To",
                    }
                    datatrace.push(tracemark)
                }
            }
            subplots.push('x'+(i+1)+'y')
            subplotNames.push(this.state.lasData[i].name);
            //0.0457
            annotations.push({
                text: this.state.lasData[i].name,
                x: (1/(this.state.lasData.length))/2+(i*(1/(this.state.lasData.length-(1/(this.state.lasData.length))/2))),
                y: 1.1,
                showarrow: false,
                xref: 'paper',
                yref: 'paper',
                xanchor: 'center',
                yanchor: 'bottom',
            },)
        }
        let axisSettings = {spikemode: 'toaxis',side:'top'}
        if (this.state.axisX === 'Log')
            axisSettings.type = 'log'
        if (this.state.axisXRangeFrom!==null&& this.state.axisXRangeTo!==null)
            axisSettings.range = [this.state.axisXRangeFrom,this.state.axisXRangeTo]
        var layout ={...tempLayout, ...{
                annotations:annotations,
                yaxis: {autorange: 'reversed',spikemode: 'toaxis'},
                hovermode:'closest',
                width: Number.parseInt(this.state.lasGraphStyle),
                height:900,
                grid: {
                    rows: 1,
                    columns: this.state.lasData.length,
                    subplots:[subplots],
                },
                xaxis: axisSettings,

            } };
        if (this.state.ReservoirRangeFrom!==null&& this.state.ReservoirRangeTo!==null) {
            layout.yaxis.range = [this.state.ReservoirRangeFrom - 1, this.state.ReservoirRangeTo + 1];
            layout.yaxis.autorange = false;
        }
        console.log(datatrace,layout)
        this.setState({LasGraph:{layout:layout,data:datatrace,length:this.state.lasData.length}});
    }
    buildGraph (modifier) {
        if (this.state.lasData !== undefined && this.state.lasData.length >0){
            if (this.state.lasGraphType === 0)
                this.gridGraphs()
            else {
                if (this.state.researchList.length > 0 && modifier !== true)
                    this.singleGraph()
                else if(this.state.researchList.length > 0)
                    this.singleGraph(true)
            }
        }
    }
    onResearchesChange (e,data) {
        console.log(data.value,this.state.researchSelectedList,this.state.lasData)
        let maxDepth = null;
        let minDepth = null;
        if ( data.value.length > this.state.researchSelectedList.length){
            let symDifference = data.value.filter(x => !this.state.researchSelectedList.includes(x))
                .concat(this.state.researchSelectedList.filter(x => !data.value.includes(x)))

            for (let i = 0 ; i < data.value.length;i++){
                for ( let j = 0 ; j < this.state.lasData.length ;j++){
                    if (this.state.lasData[j].name === data.value[i]){
                        if (maxDepth === null){
                            maxDepth = Math.max(...this.state.lasData[j].x)
                            minDepth = Math.min(...this.state.lasData[j].x)
                        }
                        if ( maxDepth < Math.max(...this.state.lasData[j].x))
                            maxDepth = Math.max(...this.state.lasData[j].x)
                        if ( minDepth > Math.min(...this.state.lasData[j].x))
                            minDepth = Math.min(...this.state.lasData[j].x)
                    }
                }
            }
            console.log(symDifference,minDepth,maxDepth)
        }
        this.setState({researchSelectedList:data.value,ReservoirRangeFrom:Number.parseFloat(minDepth),ReservoirRangeTo:Number.parseFloat(maxDepth)})
    };
    onXAxisSlider (e,data) {
        if (data.checked)
            this.setState({axisX:'Log'})
        else
            this.setState({axisX:'Normal'})

    };
    onXAxis2Slider (e,data) {
        if (data.checked)
            this.setState({axisX2:true})
        else
            this.setState({axisX2:false})

    };
    onFillSlider (e,data) {
        if (data.checked)
            this.setState({Fill:'Right'})
        else
            this.setState({Fill:'Left'})

    };
    onInputRangeFromChange (e,data) {
        console.log("imput")
        this.setState({axisXRangeFrom:Number.parseFloat(data.value)})
    }
    onInputRangeToChange (e,data) {
        this.setState({axisXRangeTo:Number.parseFloat(data.value)})
    }
    onInputDepthRangeFromChange (e,data) {
        this.setState({ReservoirRangeFrom:Number.parseFloat(data.value)})
    }
    onInputDepthRangeToChange (e,data) {
        this.setState({ReservoirRangeTo:Number.parseFloat(data.value)})
    }
    onInputMultipleRangeFromChange (data,index) {
        let reservoitRangeArray = this.state.ReservoirMultipleXRangeFrom;
        reservoitRangeArray[index] = Number.parseFloat(data.value)
        this.setState({ReservoirMultipleXRangeFrom:reservoitRangeArray})
    }
    onInputMultipleRangeToChange (data,index) {
        let reservoitRangeArray = this.state.ReservoirMultipleXRangeTo;
        reservoitRangeArray[index] = Number.parseFloat(data.value)
        this.setState({ReservoirMultipleXRangeTo:reservoitRangeArray})
    }
    minimize () {
        if (this.state.Minimize === 'none')
            this.setState({Minimize:'block'})
        else
            this.setState({Minimize:'none'} )
    }
    componentDidUpdate(prevProps, prevState) {
        console.log(prevProps,prevState)
        if (this.state.externalData === null) {

        }
    }
    componentDidMount() {
        if ( this.props.lasURL !== null)
            this.getFormDataFromUrl(this.props.lasURL)
    }

    render() {
        console.log(this.props.lasURL)
        let style = {display:this.props.display}
        if (this.state.Minimize === "none")
            style.height = '40px'
        return(
            <>
                {(this.props.display === 'block')&&(
                    <Draggable
                        axis="both"
                        handle=".handle"
                        bounds={{top:0}}
                        defaultPosition={{x: 0, y: 0}}
                        position={null}
                        grid={[5, 5]}
                        scale={1}
                        onStart={this.handleStart}
                        onDrag={this.handleDrag}
                        onStop={this.handleStop}>
                    <div className={"LasTool"} style={style}>
                        <Helmet>
                            <meta charSet="utf-8" />
                            <title>{"Las"}</title>
                            <meta name="description" content={"просмотр las файлов данных ГИС (геофизические исследования скважин)"} />
                        </Helmet>
                        <div className="handle"><Label className={'label-no-border'} basic >{"Las Tool"}</Label> <Button style={{right:'0px',position: 'absolute'}} className={'button-no-border'} basic size={'mini'} onClick={this.props.close} icon><i className="close icon"/></Button><Button style={{right:'30px',position: 'absolute'}} className={'button-no-border'} basic size={'mini'} onClick={this.minimize} icon><i className="window minimize icon"/></Button></div>
                        <br/>
                        <div style={{overflow: 'scroll',height:"97%",display:this.state.Minimize}}>
                            <UploadLas getFile={this.getFormData}/>
                            <br/>
                            <Loader style={{overflowY:'unset',overflowX:'unset'}} active={this.state.lasLoading} />
                            {(this.state.lasData !== null && this.state.lasData !== undefined) &&(
                                <>
                                    <Label> {"File Loaded."}</Label>
                                    <br/>
                                    <Label>{"Settings : "}</Label>
                                    <br/>
                                    <Checkbox
                                        radio value={0} checked={this.state.lasGraphType === 0}
                                        onChange={(e, data) => this.setState({lasGraphType:0,lasGraphStyle:(this.state.LasGraph.length * 150)+'px'})}
                                    />
                                    <Label basic>
                                        {'Graphics for each research.'}
                                    </Label>
                                    <br/>
                                    <Checkbox
                                        radio value={1} checked={this.state.lasGraphType === 1}
                                        onChange={(e, data) => this.setState({lasGraphType:1,lasGraphStyle:"700px"})}
                                    />
                                    <Label basic>
                                        {'Single Graphic with multiple researches.'}
                                    </Label>
                                    <br/>
                                    {(this.state.lasGraphType === 1) &&(
                                        <div>
                                            <Label>{"Choose Researches : "}</Label>
                                            <Dropdown className={'dropdown-choose-research'} multiple selection
                                                      onChange={this.onResearchesChange}
                                                      placeholder='Researches'
                                                      options={this.state.researchList.map(data => ({key: data, text:data, value: data}))}/>
                                            <br/>
                                        </div>
                                    )}

                                    <span style={{padding:"2px"}}>
                                        <Label>{'Fill Graph : '}</Label>
                                        <Slider min={0}
                                                max={2}
                                                step={1}
                                                size="small"
                                                style={{width:"10%",padding:"3px",left:"25px",top:"11px",transform: "scale(0.8)"}}
                                                onChange={this.onLasFillSlider}
                                                marks={this.state.FillMarks}
                                        />
                                        <br/>
                                    </span>


                                    <br/>
                                    <Label>{'X axis type : '}</Label>
                                    <Label className={'label-no-border'} basic>{'Normal'}</Label><Checkbox style={{transform: "scale(0.8)"}} className={'slider-x-axis'} slider onChange={this.onXAxisSlider} /><Label className={'label-no-border'} basic>{'Logarithmic'}</Label>
                                    <br/>
                                    {(this.state.lasGraphType === 1)&&(<>

                                        <Label className={'label-no-border'} basic>{'Multiple X axis'}</Label><Checkbox style={{transform: "scale(0.8)"}} className={'slider-x-axis'} onChange={this.onXAxis2Slider} slider/><Label className={'label-no-border'} basic>{'Single'}</Label><br/>
                                        <br/>
                                    </>)}

                                    {(this.state.lasGraphType !== 1 ||(this.state.lasGraphType ===1 && this.state.axisX2))&&(<>
                                        <Label>{'X axis value range : '}</Label>
                                        <Label basic className={'label-no-border'}>{'From :'}</Label><Input style={{transform: "scale(0.8)"}} value={this.state.axisXRangeFrom} placeholder={this.state.minValueOverall} type={'number'} className={'input-short'} onChange={this.onInputRangeFromChange}></Input>
                                        <Label basic className={'label-no-border'}>{'To :'}</Label><Input style={{transform: "scale(0.8)"}} value={this.state.axisXRangeTo} placeholder={this.state.maxValueOverall} type={'number'} className={'input-short'} onChange={this.onInputRangeToChange}></Input>
                                        <br/>
                                    </>)}
                                    {(this.state.lasGraphType === 1 && !this.state.axisX2)&&(<>
                                        {this.state.researchSelectedList.map((data,index)=> {
                                            return (
                                                <>
                                                    <Label>{'X axis range '+data+' : '}</Label>
                                                    <Label basic className={'label-no-border'}>{'From :'}</Label><Input style={{transform: "scale(0.8)"}} type={'number'} placeholder={this.state.minValueOverall} className={'input-short'} onChange={(e,data)=>this.onInputMultipleRangeFromChange(data,index)}></Input>
                                                    <Label basic className={'label-no-border'}>{'To :'}</Label><Input style={{transform: "scale(0.8)"}} type={'number'} placeholder={this.state.maxValueOverall} className={'input-short'} onChange={(e,data)=>this.onInputMultipleRangeToChange(data,index)}></Input>
                                                    <br/>
                                                </>
                                            )

                                        })}
                                    </>)}

                                    <Label className={'label-no-border'} basic>{'Single'}</Label><Checkbox style={{transform: "scale(0.8)"}} className={'slider-x-axis'} onChange={this.onYAxisSlider} slider/><Label className={'label-no-border'} basic>{'Multiple'}</Label><br/>
                                    <>
                                        <Label>{'Reservoir depth range : '}</Label>
                                        <Label basic className={'label-no-border'}>{'From :'}</Label><Input style={{transform: "scale(0.8)"}} type={'number'} placeholder={this.state.minDepthOverall} value={this.state.ReservoirRangeFrom} className={'input-short'} onChange={this.onInputDepthRangeFromChange}></Input>
                                        <Label basic className={'label-no-border'}>{'To :'}</Label><Input style={{transform: "scale(0.8)"}} type={'number'} placeholder={this.state.maxDepthOverall} value={this.state.ReservoirRangeTo} className={'input-short'} onChange={this.onInputDepthRangeToChange}></Input>
                                        <br/>
                                    </>

                                    {this.state.researchSelectedList.map((data,index)=> {
                                        if (this.state.axisY)
                                            return (
                                                <>
                                                    <Label>{'Reservoir depth range '+data+' : '}</Label>
                                                    <Label basic className={'label-no-border'}>{'From :'}</Label><Input style={{transform: "scale(0.8)"}} type={'number'} placeholder={this.state.minDepthOverall} className={'input-short'} onChange={(e,data)=>this.onInputMultipleDepthRangeFromChange(data,index)}></Input>
                                                    <Label basic className={'label-no-border'}>{'To :'}</Label><Input style={{transform: "scale(0.8)"}} type={'number'} placeholder={this.state.maxDepthOverall} className={'input-short'} onChange={(e,data)=>this.onInputMultipleDepthRangeToChange(data,index)}></Input>
                                                    <br/>
                                                </>
                                            )
                                    })}
                                    {(this.state.DepthsMarks.map(({name,value},index)=>{
                                        console.log()
                                        return(
                                            <>
                                                <Label>{"Depth Mark :"}</Label>
                                                <Input style={{transform: "scale(0.8)"}} type={"number"} onChange={(e,data)=>this.DepthMarkChange(e,data,index)}/>
                                                <Button  style={{transform: "scale(0.8)"}} onClick={this.onDepthMarkAdd} color={"green"} >{"+"}</Button>
                                                {(this.state.DepthsMarks.length > 1)&&(
                                                    <Button style={{transform: "scale(0.8)"}} onClick={()=>this.onDepthMarkDelete(index)} color={"red"} >{"-"}</Button>
                                                )}
                                                <br/>
                                            </>
                                        )
                                    }))}

                                    <Button onClick={this.buildGraph}>{'Build a graph'}</Button>
                                    {(this.state.lasGraphType === 1  && this.state.LasGraph.data!==undefined )&&(
                                        <>
                                            <Button onClick={()=>this.buildGraph(true)}>{'Add Graph'}</Button>
                                        </>
                                    )}

                                </>
                            )}
                            {(this.state.LasGraph.data !== undefined)&&(
                                <div id={this.state.lasGraphType + this.state.subplots.length} className='container' style={{width:this.state.lasGraphStyle}}>
                                    {console.log(this.state.LasGraph.data,this.state.LasGraph.layout)}
                                    <Plot
                                        id={this.state.lasGraphType + this.state.subplots.length}
                                        style={{marginLeft:'-50px'}}
                                        onClick={(data,e)=>{
                                            console.log('click',data,e)
                                        }}
                                        data={ this.state.LasGraph.data }
                                        layout={ this.state.LasGraph.layout }/>
                                </div>
                            )}
                            <br/>
                        </div>
                    </div>
                </Draggable>
                )}
            </>
        )}}
export default LasTool;
