import React from 'react';
import {Button, Checkbox, Icon, Input, Label} from 'semantic-ui-react'
import "../Styles/MyGeoMapGraticule.css"
export function Graticule (props) {
    const [Graticule2,setGraticule2] = React.useState(false)
    const [GraticuleSize,setGraticuleSize] = React.useState(10)
    const [GraticuleSize2,setGraticuleSize2] = React.useState(1)
    const [GraticuleOpacity,setGraticuleOpacity] = React.useState(0.9)
    const [GraticuleOpacity2,setGraticuleOpacity2] = React.useState(0.75)
    const [GraticuleLineWidth,setGraticuleLineWidth] = React.useState(0.6)
    const [GraticuleLineWidth2,setGraticuleLineWidth2] = React.useState(0.3)
    const [GraticuleColor,setGraticuleColor] = React.useState('#050505')
    const [GraticuleColor2,setGraticuleColor2] = React.useState('#1f1f1f')

    function onSubmit()  {
        props.draw_graticule(
            {
                deg: Number.parseFloat(GraticuleSize),
                opacity: Number.parseFloat(GraticuleOpacity),
                width: Number.parseFloat(GraticuleLineWidth),
                color: GraticuleColor
            },
            1
        )
        if (Graticule2)
            props.draw_graticule(
                {
                    deg: Number.parseFloat(GraticuleSize2),
                    opacity: Number.parseFloat(GraticuleOpacity2),
                    width: Number.parseFloat(GraticuleLineWidth2),
                    color: GraticuleColor2
                },
                2
            )
    }
    function onDelete  ()  {
        props.draw_graticule(
            {
                deg: null,
                opacity: Number.parseFloat(GraticuleOpacity),
                width: Number.parseFloat(GraticuleLineWidth),
                color: GraticuleColor
            },
            1
        )
        props.draw_graticule(
            {
                deg: null,
                opacity: Number.parseFloat(GraticuleOpacity2),
                width: Number.parseFloat(GraticuleLineWidth2),
                color: GraticuleColor2
            },
            2
        )
    }

    function onInputChange  (value,set,type) {
        switch (set){
            case 1:
                switch (type){
                    case "size":
                        setGraticuleSize(value)
                        break;
                    case "opacity":
                        setGraticuleOpacity(value)
                        break;
                    case "width":
                        setGraticuleLineWidth(value)
                        break;
                    case "color":
                        setGraticuleColor(value)
                        break;
                }
                break;
            case 2:
                switch (type){
                    case "size":
                        setGraticuleSize2(value)
                        break;
                    case "opacity":
                        setGraticuleOpacity2(value)
                        break;
                    case "width":
                        setGraticuleLineWidth2(value)
                        break;
                    case "color":
                        setGraticuleColor2(value)
                        break;
                }
                break;
        }
    }

        return(
            <div className="graticule">
                <span style={{display:"flex"}}>Нарисовать сетку координат&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Icon onClick={()=>{
                        props.close()
                    }} corner={"top right"} name='close' /></span>
                <br/>
                <div style={{display:"flex"}}><Checkbox
                    onChange={(e, data) => setGraticule2(!Graticule2)}
                    checked={Graticule2}
                /><div style={{padding:"3px 3px"}}>Вторая сетка</div> </div>
                <br/>
                <Label size={"tiny"}>{"Настройка первой сетки координат"}</Label>
                <br/>
                <Input style={{width:"100px"}} type={"number"} size={"mini"} value={GraticuleSize} label={"Размер"} onChange={(e,data)=>{onInputChange(data.value,1,"size")}}/>
                <br/>
                <Input style={{width:"100px"}} type={"number"} size={"mini"} value={GraticuleOpacity} label={"Прозрачность"} onChange={(e,data)=>{onInputChange(data.value,1,"opacity")}}/>
                <br/>
                <Input style={{width:"100px"}} type={"number"} size={"mini"} value={GraticuleLineWidth} label={"Толщина Линии"} onChange={(e,data)=>{onInputChange(data.value,1,"width")}}/>
                <br/>
                <Input style={{width:"100px"}} size={"mini"} value={GraticuleColor} label={"Цвет"} onChange={(e,data)=>{onInputChange(data.value,1,"color")}}/>
                <br/>
                {(Graticule2)&&(
                    <>
                        <br/>
                        <Label size={"tiny"}>{"Настройка второй сетки координат"}</Label>
                        <br/>
                        <Input style={{width:"100px"}} type={"number"} size={"mini"} value={GraticuleSize2} label={"Размер"} onChange={(e,data)=>{onInputChange(data.value,2,"size")}}/>
                        <br/>
                        <Input style={{width:"100px"}} type={"number"} size={"mini"} value={GraticuleOpacity2} label={"Прозрачность"} onChange={(e,data)=>{onInputChange(data.value,2,"opacity")}}/>
                        <br/>
                        <Input style={{width:"100px"}} type={"number"} size={"mini"} value={GraticuleLineWidth2}  label={"Толщина Линии"} onChange={(e,data)=>{onInputChange(data.value,2,"width")}}/>
                        <br/>
                        <Input style={{width:"100px"}} size={"mini"} value={GraticuleColor2} label={"Цвет"} onChange={(e,data)=>{onInputChange(data.value,2,"color")}}/>
                        <br/>
                    </>
                )}
                <br/>
                <Button size={"tiny"} onClick={onSubmit}>{"Отрисовать"}</Button>
                <Button size={"tiny"} onClick={onDelete}>{"Убрать"}</Button>
            </div>
        )}

