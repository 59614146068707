import React from "react";
import {Button, Dropdown, Icon, Input, Label} from "semantic-ui-react";
import axios from "axios";
import Cookies from "js-cookie";
import Dropzone from "react-dropzone";
import Dialog from "@mui/material/Dialog";
import i18n from "../i18n";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Slide from "@mui/material/Slide";
import GroupPermissionForObject from "./GroupPermissionForObject";
const config = require('../config');
const hostBackend = config.HOSTBackend;
const portBackend = config.PORTBackend;
const protocolBackend = config.ProtocolBackend;
const prefixBackend = config.PrefixBackend;
var urlBackend;
if (portBackend === "" || portBackend === undefined){
    urlBackend=protocolBackend+'://'+hostBackend+prefixBackend;
}
else{
    urlBackend=protocolBackend+'://'+hostBackend+':'+portBackend+prefixBackend;
}
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
function ProcessCurvesToDepth(depth,curve,curveName,descr,unit,nulldata){
    let resultDepth=[],
        resultCurve=[];
    for (let i = 0 ;i < depth.length;i++){
        if (curve[i] !== '-999.250' && curve[i] !== nulldata){
            resultCurve.push(Number.parseFloat(curve[i]))
            resultDepth.push(Number.parseFloat(depth[i]))
        }
    }
    console.log(standardDeviation(resultCurve))
    let sd = outliersFromCurve(resultCurve,3)
    let flats = flatsFromCurve(resultCurve,3)
    return {curve:resultCurve,depth:resultDepth,name:curveName,minDepth:Math.min(...resultDepth),maxDepth:Math.max(...resultDepth),minCurve:Math.min(...resultCurve),maxCurve:Math.max(...resultCurve),outliers:sd, outlierPercent:sd.length/(resultCurve.length/100), flatsPercent:flats/(resultCurve.length/100), description:descr, unit:unit}
}
function flatsFromCurve (array,numberOfDeviation) {
    let flatsCount = 0
    for (let i = 0 ; i < array.length-1; i++){
        if (array[i] == array[i+1] ){
            flatsCount++;
        }
    }

    return(flatsCount)
}
function outliersFromCurve (array,numberOfDeviation) {
    let res = standardDeviation(array)
    let mean = res.mean;
    let sd = res.sd;
    let minOutliers = mean - 3 * sd;
    let maxOutliers = mean + 3 * sd;
    let arrayOfOutliers = []
    let flatsCount = 0;
    if (Math.min(...array) < minOutliers || Math.max(...array) > maxOutliers){
        for (let i = 0 ; i < array.length; i++){
            if (array[i] > maxOutliers || array[i] < minOutliers){
                arrayOfOutliers.push(array[i]);
            }
        }
    }
    for (let i = 0 ; i < array.length-1; i++){
        if (array[i] == array[i+1] ){
            flatsCount++;
        }
    }
    console.log(arrayOfOutliers)
    return(arrayOfOutliers)
}
function standardDeviation( measurements = []) {
    const mean               = computeMean(measurements) ;
    const sumOfDeltaSquareds = computeSumOfDeltaSquareds( measurements, mean ) ;
    const n                  = measurements.length ;
    const sd                 = Math.sqrt( sumOfDeltaSquareds / n ) ;

    return {mean:mean,sd:sd} ;
}

function computeMean( arr ) {
    const sum = arr.reduce( (sum,x) => (sum += x) , 0 ) ;
    const mean = sum / arr.length ;
    return mean;
}

function computeSumOfDeltaSquareds( arr , mean ) {
    const sum = arr.reduce( (s,x) => (s += computeDeltaSquared(x,mean)) );
    return sum;
}

function computeDeltaSquared( x , mean ) {
    return Math.abs( x - mean ) ** 2 ;
}
class AddLasFiles extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            display:"none",
            publicationType:"",
            publicationTags:[],
            source:"",
            mapType:"",
            tags:[],
            author:"",
            doi:"",
            year:null,
            LasName:"",
            PermissionDisplay:"none",
            groupPermArray:[],
            groupPermArrayBasic:[],
            PermissionDisplayIcon:'plus',
            publicationError:"",
            PublicationFiles:null,
            addDisplay:"none",
            publicationText:"Files to Upload: ",
            exist:false,
            open:false,
            fileName:"",
        }
    };
    setPermission(id) {
        for (let i=0; i< this.state.groupPermArrayBasic.length;i++){
            let permCode = null;
            if ( this.state.groupPermArray[i].permissions === 'read')
                permCode = 1;
            if ( this.state.groupPermArray[i].permissions === 'change')
                permCode = 2;
            if ( this.state.groupPermArrayBasic[i].permissions === null && this.state.groupPermArray[i].permissions !== null){
                //insert
                console.log("insert from NULL to " + this.state.groupPermArray[i].permissions)
                let PermQuary = {type:"insert_Groups_Permission",select_type:"insert_Groups_Permission_Publications",select_params:{object_id:id,group_id:this.state.groupPermArray[i].id,permission:permCode}}
                axios.post(urlBackend+'/postQuery', {query: PermQuary})
                    .then((res) => {
                        console.log(res)
                    })
                    .catch((error)=>{console.log(error)})
            }else if ( this.state.groupPermArrayBasic[i].permissions !== null && this.state.groupPermArray[i].permissions === null){
                //delete
                console.log("delete from " + this.state.groupPermArrayBasic[i].permissions)
                let PermQuary = {type:"delete_Groups_Permission",select_type:"delete_Groups_Permission_Publications",select_params:{object_id:id,group_id:this.state.groupPermArray[i].id}}
                axios.post(urlBackend+'/postQuery', {query: PermQuary})
                    .then((res) => {
                        console.log(res)
                    })
                    .catch((error)=>{console.log(error)})
            }else if ( this.state.groupPermArrayBasic[i].permissions !== null && this.state.groupPermArray[i].permissions !== null && this.state.groupPermArrayBasic[i].permissions !== this.state.groupPermArray[i].permissions){
                //update
                console.log("update from " +this.state.groupPermArrayBasic[i].permissions + " to " + this.state.groupPermArray[i].permissions)
                let PermQuary = {type:"update_Groups_Permission",select_type:"update_Groups_Permission_Publications",select_params:{object_id:id,group_id:this.state.groupPermArray[i].id,permission:permCode}}
                axios.post(urlBackend+'/postQuery', {query: PermQuary})
                    .then((res) => {
                        console.log(res)
                    })
                    .catch((error)=>{console.log(error)})
            }
        }
    }
    render() {
        const { t } = this.props;
        // eslint-disable-next-line no-unused-vars
        let files;
        return(
            <div style={{display:this.props.display}} >
                <Button basic size={"mini"} onClick={()=>{
                    if (this.state.addDisplay === "none"){
                        let PermQuary = {type:"get_Groups_Permission",select_type:"get_Groups_Permission_Without_Object",select_params:{user_id:Cookies.get("id")}}
                        axios.post(urlBackend+'/postQuery', {query: PermQuary})
                            .then((res) => {
                                console.log(res)
                                this.setState({addDisplay:"block",groupPermArray:res.data[0].get_permission_groups,groupPermArrayBasic:JSON.parse(JSON.stringify(res.data[0].get_permission_groups))})
                            })
                            .catch((error)=>{console.log(error)})
                    }
                    else
                        this.setState({addDisplay:"none"})
                }}><Icon name='plus'  />{i18n.t("Add Las FIles")}</Button>
                <div style={{display:this.state.addDisplay}}>
                    {this.state.publicationError}
                    <br/>
                    <div>
                        <span >{i18n.t("Имя Las Файла : ")}<Input value={this.state.LasName}  onChange={(e,val) =>{
                            this.setState({LasName:val.value})
                        }}/></span>
                        <br/>
                        <span >{i18n.t("Гис Подрядчик : ")}<Input value={this.state.author}  onChange={(e,val) =>{
                            this.setState({author:val.value})
                        }}/></span>

                        <br/>
                        <span >{i18n.t("Год : ")}<Input value={this.state.year}  onChange={(e,val) =>{
                            this.setState({year:val.value})
                        }}/></span>
                        <br/>
                        <span >{i18n.t("Источник : ")}<Input value={this.state.source}  onChange={(e,val) =>{
                            this.setState({source:val.value})
                        }}/></span>

                        <br/>
                    </div>
                    <Dropzone onDrop={acceptedFiles => {
                        files = acceptedFiles;
                        console.log(acceptedFiles[0].name)
                        if (acceptedFiles[0].name !== undefined)
                            this.setState({LasName:acceptedFiles[0].name,PublicationFiles:acceptedFiles,publicationText:i18n.t("Files to Upload : ")+ acceptedFiles[0].name});
                        else
                            this.setState({PublicationFiles:acceptedFiles,publicationText:i18n.t("Files to Upload : ")});
                    }}
                    >
                        {({getRootProps, getInputProps}) => (
                            <section>
                                <div style={{height:"50px",width:"300px",borderRadius: "15px",padding: "10px",border:"1px solid black"}} {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    {i18n.t("Drag'n'drop files here, or click to select files.")}
                                </div>
                            </section>
                        )}

                    </Dropzone>
                    <Button basic size={"mini"} onClick={()=>{
                        if (this.state.PermissionDisplay === "none")
                            this.setState({PermissionDisplay:"block",PermissionDisplayIcon:'minus'})
                        else
                            this.setState({PermissionDisplay:"none",PermissionDisplayIcon:'plus'})
                    }}><Icon name={this.state.PermissionDisplayIcon}  />{"Show/Hide Permission Editing"}</Button>
                    <br/>
                    <div style={{display:this.state.PermissionDisplay}}>
                        <GroupPermissionForObject csstop={null} arrayChange={(array)=>{this.setState({groupPermArray:array})}} permArrayBasic={this.state.groupPermArrayBasic} permArray={this.state.groupPermArray} id={null} close={()=>{this.setState({PermissionDisplay:"none"})}} display={this.state.PermissionDisplay} />
                    </div>

                    <Dialog
                        classes={{ root: 'MenuItem'}}
                        open={this.state.open}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={()=>{this.setState({open:false})}}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle id="alert-dialog-slide-title">{t("Similar publication already exists in the database, please use search to check")}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description">
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={()=>{this.setState({open:false})}} color="primary">
                                {i18n.t("Cancel uploading")}
                            </Button>
                            <Button onClick={()=> {


                            }} color="primary">
                                {i18n.t("Upload anyway")}
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {((this.state.PublicationFiles !== undefined  && this.state.PublicationFiles !== null))&&(<span><Label basic>{this.state.publicationText+" "}</Label><br/></span>)}
                    <Button basic onClick={()=>{
                        if (
                            //this.state.publicationName !== "" &&
                            ((this.state.PublicationFiles!==null && this.state.PublicationFiles!==undefined))){
                            const dataform = new FormData();
                            console.log(this.state.PublicationFiles);
                            dataform.append('file',this.state.PublicationFiles[0]);
                            axios.post(urlBackend+'/uploadLas', dataform, {
                                // receive two    parameter endpoint url ,form data
                            }).then(res => {
                                console.log(res.data)
                                let curves =res.data.CURVES
                                let curvesKeys = Object.keys(res.data.CURVES)
                                let curves_infos = res.data["CURVE INFORMATION BLOCK"]
                                let nullValue = res.data["WELL INFORMATION BLOCK"].NULL.DATA
                                let depth = curves[curvesKeys[0]]
                                let resultCurves = []
                                console.log(curves,curvesKeys,depth)
                                for (let i = 1; i < curvesKeys.length;i++){
                                    resultCurves.push(ProcessCurvesToDepth(depth,curves[curvesKeys[i]],curvesKeys[i],curves_infos[curvesKeys[i]]["DESCRIPTION OF MNEMONIC 1"],curves_infos[curvesKeys[i]]["UNIT"],nullValue))
                                }
                                console.log(resultCurves)
                                //this.setState({url:urlBackend+'/displayimages?file='+res.data.filename});
                                let query = {query:{type:"insert_las_files", select_type:"insert", select_params:{
                                            array:resultCurves,
                                            id: this.props.id,
                                            filename: res.data.filename,
                                            f_layer_id: this.props.layer,
                                            name: this.state.LasName,
                                            year:this.state.year,
                                            source: this.state.source,
                                            author: this.state.author,
                                            user_id: Cookies.get("id")}}}
                                axios.post(urlBackend+'/postQuery', query, {
                                }).then(res => {
                                    console.log(res.data)
                                    this.props.update(this.props.id)
                                    //this.setState({url:urlBackend+'/displayimages?file='+res.data.filename});

                                }).catch((error) => {
                                    console.log(error)
                                });
                            }).catch((error) => {
                                console.log(error)
                            });

                            //this.setState({url:urlBackend+'/displayimages?file='+res.data.filename});

                        }else{
                            this.setState({publicationError:i18n.t("Fill required fields")})
                        }
                    }}>{i18n.t("Save")}</Button>
                </div>
            </div>
        )}}
export default AddLasFiles;
