import React, { Component } from 'react'
import {Form, Checkbox, Label, Dropdown, Button} from 'semantic-ui-react'
import {age_word_from, age_word_to} from "../VariableInitialize";

export default class FeatureInfosReservesGroupByAge extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: null,
            display:"none",
            Groups:[{index:0,from:null,to:null,name_from:null,name_to:null}]
        }
    }
    onGroupByAgeChange = (value,index,mode) => {
        let groups = this.state.Groups;
        console.log(groups[index],groups,groups[index][mode],value)
        groups[index][mode] = Number.parseFloat(value)
        groups[index]["name_"+mode] = value
        this.setState({Groups:groups})
        this.props.handler(groups);

    };
    onAddGroup = () => {
        let groups = this.state.Groups;
        groups.push({index:groups.length,from:null,to:null,name_from:null,name_to:null})
        this.setState({Groups:groups})
    }
    onRemoveGroup = (index) => {
        let groups = this.state.Groups;
        groups.splice(index,1)
        for ( let i = 0; i < groups.length;i++){
            groups[i].index = i;
        }
        this.setState({Groups:groups})
        this.props.handler(groups);
    }
    render() {
        return (
            <div style={{display:this.props.display}}>
                <>
                    <Label>{"Select groups : "}</Label>
                    <br/>
                    {this.state.Groups.map(({index,from,to})=>{
                        if (this.state.Groups.length > 1)
                            return(<>
                                <Dropdown  selection
                                           onChange={(e,data)=>this.onGroupByAgeChange(data.value,index,'from')}
                                           placeholder='From'

                                           options={age_word_from}/>
                                <Dropdown  selection
                                           onChange={(e,data)=>this.onGroupByAgeChange(data.value,index,'to')}
                                           placeholder='to'

                                           options={age_word_to}/>
                                <Button onClick={this.onRemoveGroup} color={"red"}>{"-"}</Button>
                                <br/>
                            </>)
                        else
                            return(
                                <>
                                    <Dropdown  selection
                                               onChange={(e,data)=>this.onGroupByAgeChange(data.value,index,'from')}
                                               placeholder='From'

                                               options={age_word_from}/>
                                    <Dropdown  selection
                                               onChange={(e,data)=>this.onGroupByAgeChange(data.value,index,'to')}
                                               placeholder='to'

                                               options={age_word_to}/>
                                    <br/>
                                </>
                            )
                    })}
                    <Button onClick={this.onAddGroup} color={"green"}>{"+"}</Button>
                </>
            </div>
        )
    }
}
