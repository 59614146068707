import React from "react";
import axios from "axios";

import {Button, Dropdown, Input, Label} from "semantic-ui-react";
import Plot from "../../node_modules/react-plotly.js/react-plotly";
import "../Styles/Kern.css"
import CapillaryTable from "./CapillaryTable";
import KernTab from "./KernTab";
import {urlBackend} from "../VariableInitialize";



class Kern extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            colors:{'active':'#3F51B5','inactive':'#7197D2'},
            tabs:[{name:'Kern',status:'active'},{name:'Capillary',status:'inactive'}],
            activeTab:"Kern",
            capillary:false,
            sortingOrder:true,
            capillaryBaseData:[],
            capillaryData:[],
            capillaryFilteredData:[],
            capillaryButtonText:"Change to Capillary",
            capillaryGraphData:null,
            capillaryGraphLayout:[],
            capillaryFilters:[],
            capillarySelectedParam:'',
            capillaryFilterParams:[
                {key:"type",text:"Type",value:"type"},
                {key:"area",text:"Area",value:"area"},
                {key:"field",text:"Field",value:"field"},
                {key:"well",text:"Well",value:"well"},
                {key:"age",text:"Age",value:"age"},
                {key:"object",text:"Object",value:"object"},
                {key:"facion",text:"Facion",value:"facion"},
                {key:"environment",text:"Environment",value:"environment"},
                {key:"danhem",text:"Danhem",value:"danhem"},
                {key:"gran",text:"Gran",value:"gran"},
                {key:"portype",text:"Portype",value:"portype"},
                {key:"well_number",text:"Well Number",value:"well_number"},
                {key:"well_number_new",text:"Well Number New",value:"well_number_new"},
                {key:"dolb",text:"dolb",value:"dolb"},
                {key:"depth",text:"Depth",value:"depth"},
                {key:"depthgis",text:"Depth GIS",value:"depthgis"},
                {key:"insoluble",text:"Insoluble",value:"insoluble"},
                {key:"calcit",text:"Calcit",value:"calcit"},
                {key:"dolomit",text:"Dolomit",value:"dolomit"},
                {key:"nearest",text:"Nearest",value:"nearest"},
                {key:"lito",text:"Lito",value:"lito"},
                {key:"description",text:"Description",value:"description"},
                {key:"litogroup",text:"Lito Group",value:""},
                {key:"kper",text:"kper",value:"kper"},
                {key:"krop",text:"krop",value:"krop"},
                {key:"0",text:"0",value:"data_0"},
                {key:"0.005",text:"0.005",value:"data_0_005"},
                {key:"0.010",text:"0.010",value:"data_0_010"},
                {key:"0.015",text:"0.015",value:"data_0_015"},
                {key:"0.025",text:"0.025",value:"data_0_025"},
                {key:"0.050",text:"0.050",value:"data_0_050"},
                {key:"0.1",text:"0.1",value:"data_0_1"},
                {key:"0.2",text:"0.2",value:"data_0_2"},
                {key:"0.3",text:"0.3",value:"data_0_3"},
                {key:"0.5",text:"0.5",value:"data_0_5"},
                {key:"0.8",text:"0.8",value:"data_0_8"},
                {key:"1.0",text:"1.0",value:"data_1_0"},
                {key:"1.2",text:"1.2",value:"data_1_2"},
            ],
            capillaryOperators:[{key:">",text:">",value:"GREATER"},{key:"<",text:"<",value:"LESS"},{key:"=",text:"=",value:"EQUAL"}],
        };

    }
    addFilter (inputData,selectedParam) {
        console.log(inputData,selectedParam);
        let filters = JSON.parse(JSON.stringify(this.state.capillaryFilters));
        let data = JSON.parse(JSON.stringify(inputData));
        let type;
        let dict = null;
        let numericRows = ["Depth", "Depth GIS", "Insoluble", "krop", "kper", "Calcit", "Dolomit", "0", "0.005", "0.010", "0.015",
            "0.025", "0.050","0.1", "0.2", "0.3", "0.5", "0.8", "1.0", "1.2"]
        if (numericRows.includes(selectedParam.text))
            type = "number";
        else{
            type = "dict";
            dict = [...new Set(data.map(item => item[selectedParam.value]))]
        }

        filters.push({param:selectedParam.value, text:selectedParam.text, operator:null, value:null, type:type, dict:dict})
        this.setState({capillaryFilters:filters})

    };
    getResults (display) {
        let graphData=[];
        let data = JSON.parse(JSON.stringify(this.state.capillaryBaseData));
        let filters = JSON.parse(JSON.stringify(this.state.capillaryFilters));
        let filteredArray = JSON.parse(JSON.stringify(data));
        console.log(filteredArray)
        if (filters.length>0)
            for (let i = 0 ; i <filters.length;i++){
                console.log(filters[i])
                filteredArray = filteredArray.filter(function (el) {
                    if (filters[i].value === null)
                        return true;
                    if (filters[i].type === "string"){
                        if (el[filters[i].param] === null || el[filters[i].param] === undefined)
                            return false;
                        else
                            return el[filters[i].param].includes(filters[i].value);

                    }
                    else if(filters[i].type === "dict"){
                        return filters[i].value.includes(el[filters[i].param])
                    } else{
                        if (filters[i].operator === "EQUAL"){
                            return el[filters[i].param] === filters[i].value;
                        }else if (filters[i].operator === "LESS"){
                            return el[filters[i].param] < filters[i].value;
                        }else{
                            return el[filters[i].param] > Number.parseFloat(filters[i].value);
                        }
                    }

                });
                console.log(filteredArray)
            }
        console.log(filteredArray)
        for (let i=0; i<filteredArray.length; i++){
            let trace = {
                y:[0,0.005,0.010,0.015,0.025,0.050,0.1,0.2,0.3,0.5,0.8,1,1.2],
                x:[filteredArray[i].data_0,filteredArray[i].data_0_005,filteredArray[i].data_0_010,filteredArray[i].data_0_015,filteredArray[i].data_0_025,filteredArray[i].data_0_050,filteredArray[i].data_0_1,filteredArray[i].data_0_2,filteredArray[i].data_0_3,filteredArray[i].data_0_5,filteredArray[i].data_0_8,filteredArray[i].data_1_0,filteredArray[i].data_1_2],
                mode: 'lines+markers',
                name: filteredArray[i].well_number,
                customdata:filteredArray[i].wells_id,
                line: {shape: 'spline'},
                type: 'scatter'
            }
            graphData.push(trace)
        }
        this.setState({capillary:true,capillaryButtonText:"Change to Kern",capillaryData:filteredArray,capillaryGraphData:graphData})

    }
    onDictionaryChanged (e,val,index) {
        let filters = JSON.parse(JSON.stringify(this.state.capillaryFilters));
        filters[index].value = val.value;
        let data = JSON.parse(JSON.stringify(this.state.capillaryBaseData));
        let filteredArray = JSON.parse(JSON.stringify(data));
        console.log(filteredArray)
        if (filters.length>1)
            for (let i = 0 ; i <filters.length-1;i++){
                console.log(filters[i])
                filteredArray = filteredArray.filter(function (el) {
                    if (filters[i].value === null)
                        return true;
                    else if(filters[i].type === "dict"){
                        return filters[i].value.includes(el[filters[i].param])
                    }

                });
                console.log(filteredArray)
            }
        for (let i = 0; i < filters.length; i++){
            if (filters[i].type === 'dict'){
                let type;
                let dict = null;
                type = "dict";
                dict = [...new Set(filteredArray.map(item => item[filters[i].param]))]
                filters[i].dict = dict
            }
        }
        this.setState({capillaryFilters:filters})

    };

    componentDidMount() {
        let add = ''
        axios.get(urlBackend+'/kern',{headers: { 'addition':add}})
            .then((res) => {
                console.log(res);
                let array = []
                for (let i = 0 ; i < res.data.length;i++){
                    array.push({key:res.data[i].name,text:res.data[i].name,value:res.data[i].id})
                }
                this.setState({arraytype:array});
            })
            .catch((error) => {
                console.log(error)
            });
    }
    render() {
        //var authorized = Cookies.get("id") !== undefined;
        return(
            <div className="kern" style={{
                display:this.props.display,
            }}>
                <Button basic size={'mini'} onClick={this.props.close} icon><i className="close icon"/></Button>
                <br />
                {this.state.tabs.map(({name,status},index) =>{
                    //console.log(name,status)
                    return(
                        <Button style={{color:"white",backgroundColor:this.state.colors[status]}}
                                onClick={() => {
                                    //console.log(name,status)
                                    let tabs = this.state.tabs;
                                    for (let i = 0; i < tabs.length; i++)
                                        tabs[i].status = "inactive";
                                    tabs[index].status = "active"
                                    if (name !== "Capillary"){
                                        //console.log("Kern")
                                        this.setState({activeTab:name, tabs:tabs})
                                    }
                                    else{
                                        //console.log("Capillary")
                                        axios.post(urlBackend+'/postQuery', {query: {type:"capillary", select_type:"all"}})
                                            .then((response) =>{
                                                console.log(response)
                                                this.setState({activeTab:name, tabs:tabs,  capillaryBaseData:response.data})
                                            })
                                            .catch((error) => {console.log(error)})

                                    }
                                }}>{name}</Button>
                    )
                })}

                {(this.state.activeTab === "Kern")&&(<KernTab/>)}
                {(this.state.activeTab === "Capillary")&&(
                    <div>
                        {this.state.capillaryFilters.map(({param,text,operator,value,type,dict},index)=>{
                        return(
                            <>
                                <Label className={"label-filters"}>{text}</Label>
                                {(type==="dict")&&(
                                    <Dropdown
                                        className={"dropdown-dictionary"}
                                        //style={{transform: "translatey(-6px)"}}
                                        multiple
                                        options={dict.map(data => ({key: data, text:data, value: data}))}
                                        onChange={(e,val) => {
                                            this.onDictionaryChanged(e,val,index);
                                        }}
                                        //value={this.state.selected}
                                        selection
                                        placeholder={""} />
                                )}
                                {(type==="number")&&(<Dropdown
                                    compact
                                    options={this.state.capillaryOperators}
                                    onChange={(e,val) => {
                                        let filters = JSON.parse(JSON.stringify(this.state.capillaryFilters));
                                        filters[index].operator = val.value;
                                        this.setState({capillaryFilters:filters})
                                    }}
                                    //value={this.state.selected}
                                    selection
                                    placeholder={""} />)}
                                {(type!=="dict")&&(<Input onChange={(e,val)=>{
                                    let filters = JSON.parse(JSON.stringify(this.state.capillaryFilters));
                                    filters[index].value = val.value;
                                    this.setState({capillaryFilters:filters})
                                }}/>)}
                                <Button style={{backgroundColor:'red'}}
                                        onClick={() => {
                                            let filters = JSON.parse(JSON.stringify(this.state.capillaryFilters));
                                            filters.splice(index, 1)
                                            this.setState({capillaryFilters:filters})
                                            // -- ##
                                        }}>{"-"}</Button>
                                <br/>
                            </>
                        )
                        })}
                        <br/>
                        <Button style={{backgroundColor:'green'}}>{"+"}</Button>
                        <Dropdown
                            options={this.state.capillaryFilterParams}
                            onChange={(e,val) => {
                                let options = val.options.filter(function (el) {
                                    return el.value === val.value
                                })
                                console.log(options)
                                this.setState({capillarySelectedParam:{text:options[0].text,value:val.value}})
                                //console.log(e,val)
                            }}
                            //value={this.state.selected}
                            selection
                            placeholder={"Filter params"} />
                        <Button
                            onClick={() => {
                                this.addFilter(this.state.capillaryBaseData,this.state.capillarySelectedParam)
                            }}>{"Добавить"}</Button>
                        <br/>
                        <br/>
                        <Button style={{backgroundColor:'green'}}
                                onClick={() => {
                                    this.getResults(true)
                                }}>{"Submit"}</Button>
                        <br/>
                        <br/>
                        <br/>
                        {(this.state.capillaryGraphData !== null)&&(
                            <Plot
                                data={this.state.capillaryGraphData}
                                //layout={ this.state.layout}
                                divId={"plotlyCapillary"}

                                onClick={(data,e)=>{
                                    console.log(data,data.event.shiftKey,e)
                                    console.log(data.points[0].data.name)
                                    if (data.points[0].data.customdata !== undefined)
                                    {
                                        this.props.onCapillaryClick("Wells",data.points[0].data.customdata)
                                    }

                                }}
                            />
                        )}
                        {(this.state.capillaryData.length>0)&&(<CapillaryTable capillaryData={this.state.capillaryData}/>)}

                    </div>
                )}
            </div>
        )
    }
}
export default Kern;