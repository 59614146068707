import React from "react";
import Cookies from "js-cookie";
import axios from "axios";
import {Button, Checkbox, Icon, Input, Label} from "semantic-ui-react";
import imageName from "../Interface/blank.png";
import GroupPermissionForObject from "./GroupPermissionForObject";
var config = require('../config');
const hostBackend = config.HOSTBackend;
const portBackend = config.PORTBackend;
const protocolBackend = config.ProtocolBackend;
const prefixBackend = config.PrefixBackend;
var urlBackend;
if (portBackend === "" || portBackend === undefined){
    urlBackend=protocolBackend+'://'+hostBackend+prefixBackend;
}
else{
    urlBackend=protocolBackend+'://'+hostBackend+':'+portBackend+prefixBackend;
}


class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name:null,
            email:null,
            firstname:null,
            lastname:null,
            middlename:null,
            organization:null,
            gender:null,
            birthday:null,
            city:null,
            country:null,
            tel:null,
            oldpassword:null,
            newpassword:null,
            confirmpassword:null,
            avatar:"",
            mode:"none",
            objects:[],
            button1:"grey",
            button2:"grey",
            button3:"grey",
            button4:"grey",
            interation:null,
            update:true,
            active:false,
            groupPermDisplay:false,
            groupPermArray:[{}],
            groupPermArrayBasic:[{}],
            groupPermCssTop:0,
            objectID:null,
            layerID:null
        }
        this.onListUpdate = this.onListUpdate.bind(this);
        this.closePerm = this.closePerm.bind(this);
    }
    static getDerivedStateFromProps(props, current_state) {
        console.log(props.interation,current_state.interation);
        if (current_state.interation !== props.interation) {
            //console.log(current_state.items,current_state.renderitems)
            return {
                interation: props.interation,
                update:true
            }
        }
        return null
    }
    closePerm() {
        this.setState({groupPermDisplay:false,})
    };
    onListUpdate() {
        if (this.props.display === "block" ) {
            let searcharray = [];
            let profileQuary = {type:"profile_user_object",select_type:"select",select_params:{user_id:Cookies.get("id")}}
            axios.post(urlBackend+'/postQuery', {query: profileQuary})
                .then((res) => {
                    console.log(res)
                    searcharray = [];
                    for (let i = 0; i < res.data.length; i++) {
                        res.data[i].sourceLayer = 'public.' + res.data[i].sourcetable;
                        for (let j = 0; j < this.props.result.length; j++) {
                            if (this.props.result[j].source === res.data[i].sourceLayer) {
                                res.data[i].sourceid = this.props.result[j].id;
                                if (this.props.result[j].visibility === "visible")
                                    res.data[i].icon = "plus";
                                else
                                    res.data[i].icon = "minus";
                            }
                        }
                        res.data[i].coordinates = JSON.parse(res.data[i].geom).coordinates;
                        res.data[i].id = res.data[i].qc_id;
                        res.data[i].source = res.data[i].sourceid;
                        res.data[i].geometry = JSON.parse(res.data[i].geom);
                        res.data[i].type = res.data[i].geometry.type;

                        searcharray.push({
                            name: res.data[i].originalname,
                            id: res.data[i].qc_id,
                            source: res.data[i].sourceid,
                            sourceLayer: res.data[i].sourceLayer,
                            coordinates: res.data[i].coordinates,
                            feature: res.data[i],
                            icon: res.data[i].icon
                        });
                    }
                    //console.log(res,searcharray);
                    this.setState({objects: searcharray})
                }).catch((error) => {
                console.log(error)
            });
        }
    };
    componentDidUpdate() {
        console.log(this.props.display,this.state.update)
        if (this.props.display === "block" && this.state.update===true) {
            console.log("update");
            this.onListUpdate();
            this.setState({update:false})
        }
    }
    render() {
        var imageName = require('../Interface/blank.png');
        /**/
        return(
            <div className="profile" style={{display:this.props.display }} >
                <span>
                        {this.props.error}
                    <Button basic size={'mini'} onClick={this.props.close} icon><i className="close icon"/></Button>
                    <br/>
                        <Button color={this.state.button1} size={'mini'} onClick={()=>{this.setState({mode:"Personal",button1:"white",button2:"grey",button3:"grey",button4:"grey"})}} >{"Change personal info"}</Button>
                        <Button color={this.state.button2} size={'mini'} onClick={()=>{this.setState({mode:"Password",button1:"grey",button2:"white",button3:"grey",button4:"grey"})}} >{"Change password"}</Button>
                        <Button color={this.state.button3} size={'mini'} onClick={()=>{this.setState({mode:"Objects",button1:"grey",button2:"grey",button3:"white",button4:"grey"});this.onListUpdate();}} >{"My objects"}</Button>
                        <Button color={this.state.button4} size={'mini'} onClick={()=>{this.setState({mode:"Documents",button1:"grey",button2:"grey",button3:"white",button4:"white"});}} >{"Documents"}</Button>
                    <br/>
                    <div style={{display:"flex"}}>
                        <div>
                            <img src={imageName} height="150" width="150"/>
                        </div>
                        <div style={{display:"grid",gridTemplateRows:"max-content"}}>
                            <span >{"Name:"}<Label basic>{this.props.name}</Label></span>
                            <span >{"Email:"}<Label basic>{this.props.email}</Label></span>
                            <Checkbox
                                slider
                                size={'mini'}
                                label={"Only User Objects"}
                                className={'checkbox'}
                                checked={this.state.active}
                                onChange={ () => {
                                    this.setState({active:!this.state.active});
                                    this.props.lassostate(!this.state.active);
                                }}
                            />
                        </div>
                    </div>
                    <br/>

                    {(this.state.mode==="Personal") && (
                        <div>
                            <Label>{"Personal"}</Label><br/>
                            <div style={{display:"grid"}}>
                                <span >{"Last name:"}<Input defaultValue={this.state.lastname}  onChange={(e,val) =>{
                                    this.setState({lastname:val.value})
                                }}/></span>
                                <span >{"First Name:"}<Input defaultValue={this.state.firstname}  onChange={(e,val) =>{
                                    this.setState({firstname:val.value})
                                }}/></span>
                                <span >{"Middle name:"}<Input defaultValue={this.state.middlename}  onChange={(e,val) =>{
                                    this.setState({middlename:val.value})
                                }}/></span>
                            </div>
                            <Label>{"Contacts"}</Label><br/>
                            <div style={{display:"grid"}}>
                                <span>{"Email:"}<Input defaultValue={this.props.email}  onChange={(e,val) =>{
                                    this.setState({email:val.value})
                                }}/></span>
                                <span >{"Organization:"}<Input defaultValue={this.state.organization}  onChange={(e,val) =>{
                                    this.setState({organization:val.value})
                                }}/></span>
                            </div>
                            <br/>
                            <Button size={"mini"} onClick={() => {
                                console.log(this.state.name,this.state.email);
                                if (this.state.name === null || this.state.email === null)
                                    this.setState({name:this.props.name,email:this.props.email})
                                this.props.create(this.state.name,this.state.email)
                            }}>Change</Button>
                        </div>)}
                    {(this.state.mode==="Password") && (
                        <div><div style={{display:"grid"}}>
                                <span>{"Old Password:"}<Input defaultValue={null}  onChange={(e,val) =>{
                                    this.setState({oldpassword:val.value})
                                }}/></span>
                            <span>{"New Password:"}<Input defaultValue={null}  onChange={(e,val) =>{
                                this.setState({newpassword:val.value})
                            }}/></span>
                            <span>{"Confirm New Password:"}<Input defaultValue={null}  onChange={(e,val) =>{
                                this.setState({confirmpassword:val.value})
                            }}/></span>
                        </div>
                            <br/>
                            <Button size={"mini"} onClick={() => {
                                console.log(this.state.name,this.state.email);
                                if (this.state.name === null || this.state.email === null)
                                    this.setState({name:this.props.name,email:this.props.email})
                                this.props.create(this.state.name,this.state.email)
                            }}>Change</Button></div>
                    )}
                    {(this.state.mode==="Documents") && (
                        <div>
                            <div style={{display:"grid"}}>
                                <span>{"Правовая Информация: "}<a href={"https://mygeomap.ru/docs/cookies.pdf"} target="_blank">{"link"}</a></span>
                            </div>
                        </div>
                    )}
                    {(this.state.mode==="Objects") && (
                        <div style={{overflowY: 'scroll'}}>
                            <GroupPermissionForObject csstop={this.state.groupPermCssTop} arrayChange={(array)=>{this.setState({groupPermArray:array})}} permArrayBasic={this.state.groupPermArrayBasic} permArray={this.state.groupPermArray} layer_id={this.state.layerID} id={this.state.objectID} close={this.closePerm} display={this.state.groupPermDisplay} />
                            <br/>
                            {this.state.objects.map(({name,id,source,sourceLayer,coordinates,feature,icon}) => {
                                console.log(name,id,source,sourceLayer,coordinates,feature)
                                let displayPerm = false;
                                let color = 'red';
                                if (icon === "plus")
                                    color = 'teal';
                                return (
                                    <div key={id}>
                                        <Label color={color} as='a' basic size={'medium'} onClick={() => {
                                            let newlist = [];
                                            let arraydata = {content:[]};
                                            arraydata.content.push(name);
                                            arraydata.content.push(id);
                                            arraydata.content.push(source);
                                            arraydata.content.push(sourceLayer);
                                            arraydata.content.push(coordinates);
                                            arraydata.content.push(feature);
                                            arraydata.content.push(true);
                                            if (icon !== "minus")
                                                this.props.itemclick("",arraydata);
                                        }}>
                                            <Icon name={icon} /> {name + " " +source.replace(/[0-9]/g, '')}
                                        </Label>
                                        <Icon className={"i"+color} onClick={()=>{
                                            //routerview
                                            let proxy = {};
                                            proxy.features = [feature];
                                            proxy.point = {x:0,y:0};
                                            proxy.features[0].properties= {OriginalName:name};
                                            console.log(proxy)
                                            if (icon !== "minus")
                                                this.props.infoclick(proxy)
                                        }} name='info' />
                                        <Icon className={"i"+color} onClick={(e)=>{
                                            e.persist()
                                            console.log(id)
                                            let PermQuary = {type:"get_Groups_Permission",select_type:"get_Groups_Permission",select_params:{object_id:id,user_id:Cookies.get("id")}}
                                            axios.post(urlBackend+'/postQuery', {query: PermQuary})
                                                .then((res) => {
                                                    console.log(res.data,e.pageY,e)
                                                    this.setState({groupPermDisplay:true,groupPermCssTop:e.pageY-100,layerID:feature.sourcetable,objectID:id,groupPermArray:res.data[0].get_object_permission,groupPermArrayBasic:JSON.parse(JSON.stringify(res.data[0].get_object_permission))})
                                                })
                                                .catch((error)=>{console.log(error)})
                                            //this.setState({groupPermDisplay:true,objectID:id,groupPermArray:res.data})
                                        }} name='group' />

                                    </div>
                                )
                            })}

                        </div>
                    )}
                </span>
            </div>
        )
    }
}
export default Profile;