import React from "react";
import {Button, Dropdown, Icon, Input, Label} from "semantic-ui-react";
import axios from "axios";
import Cookies from "js-cookie";
import Slide from "@mui/material/Slide";
const config = require('../config');
const hostBackend = config.HOSTBackend;
const portBackend = config.PORTBackend;
const protocolBackend = config.ProtocolBackend;
const prefixBackend = config.PrefixBackend;
var urlBackend;
if (portBackend === "" || portBackend === undefined){
    urlBackend=protocolBackend+'://'+hostBackend+prefixBackend;
}
else{
    urlBackend=protocolBackend+'://'+hostBackend+':'+portBackend+prefixBackend;
}
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
class UpdatePublications extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editObjectID:null,
            display:"none",
            publicationType:"",
            publicationTags:[],
            url:"",
            mapType:"",
            tags:[],
            author:"",
            doi:"",
            year:null,
            publicationError:"",
            PublicationFiles:null,
            addDisplay:"none",
            publicationText:"Files to Upload: ",
            exist:false,
            open:false,
            fileName:"",
        }
    };
    updateForm() {
        //this.props.editTags
        //this.props.editType
        let tags_id = [];
        let type_id = null;
        for (let i = 0; i < this.props.tags.length; i++) {
            if (this.props.editTags !== null)
            for (let j = 0; j < this.props.editTags.length; j++) {
                if ( this.props.editTags[j] === this.props.tags[i].text ) {
                    tags_id.push(this.props.tags[i].value)
                }
            }
        }
        for (let i = 0; i < this.props.word.length; i++){
            if ( this.props.word[i].text === this.props.editType)
                type_id = this.props.word[i].value;
        }
        console.log(type_id, tags_id)
        this.setState({
            editObjectID:this.props.editID,
            publicationName:this.props.editName,
            author:this.props.editAuthor,
            doi:this.props.editDOI,
            year:this.props.editYear,
            url:this.props.editUrl,
            publicationType:type_id,
            publicationTags:tags_id
        })

    }
    componentDidUpdate(prevProps) {
        if(this.props.editID!== prevProps.editID) // Check if it's a new user, you can also use some unique property, like the ID  (this.props.user.id !== prevProps.user.id)
        {
            this.updateForm();
        }
    }
    render() {
        const { t } = this.props;

        //let infos = document.getElementById('infos')
        //console.log(infos.body.scrollTop)
        let display = "none";
        if ( this.props.display)
            display = "block";
        return(
            <div style={{display:display,position: 'absolute',backgroundColor:"#FFFFFF",border: "thick double #000000", right:"3px", top:this.props.editPositionTop+"px", zIndex:'99999999'}} >
                <Button basic size={'mini'} onClick={()=>this.props.close()} icon><i className="close icon"/></Button>
                <br/>
                {this.state.publicationError}
                <br/>
                <div>
                    <span >{"Имя публикации:"}<Input value={this.state.publicationName}  onChange={(e,val) =>{
                        this.setState({publicationName:val.value})
                    }}/></span>
                    <br/>
                    <span >{"Автор:"}<Input value={this.state.author}  onChange={(e,val) =>{
                        this.setState({author:val.value})
                    }}/></span>
                    <br/>
                    <span >{"Doi:"}<Input value={this.state.doi}  onChange={(e,val) =>{
                        this.setState({doi:val.value})
                    }}/></span>
                    <br/>
                    <span >{"Год Публикации:"}<Input value={this.state.year}  onChange={(e,val) =>{
                        this.setState({year:val.value})
                    }}/></span>
                    <br/>
                    <span >{"Ссылка:"}<Input value={this.state.url}  onChange={(e,val) =>{
                        this.setState({url:val.value})
                    }}/></span>
                    <br/>
                    <br/>
                    <span style={{display: "flex"}}>{"Теги:"}<Dropdown
                        options={this.props.tags}
                        value={this.state.publicationTags}
                        onChange={(e,val) => {
                            console.log(val)
                            this.setState({publicationTags:val.value})
                        }}
                        selection
                        style={{overflow:"visible",zIndex:"9"}}
                        search
                        multiple={true}
                        placeholder={"Tags"} /></span>
                    <br/>
                    <span >{"Тип публикации:"}<Dropdown
                        options={this.props.word}
                        value={this.state.publicationType}
                        onChange={(e,val) => {
                            console.log(this.props.word,val)
                            this.setState({publicationType:val.value})
                        }}
                        selection
                        placeholder={"Publications Type"} /></span>
                </div>
                <Button basic onClick={()=>{
                    if (this.state.publicationType !== "" && this.state.publicationName !== "" ){
                        const dataform = new FormData();
                        console.log(this.state.PublicationFiles);
                        if(this.state.PublicationFiles!==null && this.state.PublicationFiles!==undefined)
                            dataform.append('file',this.state.PublicationFiles[0]);
                        else
                            dataform.append('file',"none");
                        let doi ;
                        if (this.state.doi.includes("https://doi.org/"))
                            doi = this.state.doi;
                        else
                            doi = "https://doi.org/"+this.state.doi;
                        //console.log(dataform,this.state.selectedFile);
                        console.log(dataform)
                            let layer = this.props.layer;
                            let name = this.state.publicationName;
                            let type = this.state.publicationType
                            let url = this.state.url
                            let id = null;
                            let year = this.state.year
                            let author = this.state.author
                            axios.post(urlBackend+'/postQuery', {query:{
                                type:"update_publications",
                                    select_type:"update_publications",
                                    select_params:{name: name ,
                                        year: year,
                                        doi: doi ,
                                        url: url ,
                                        author: author ,
                                        type: type ,
                                        id: this.props.editID,}}})
                                .then((res) => {
                                    axios.post(urlBackend+'/postQuery', {query:{type:"delete_publications_tags",select_type:"delete", select_params:{id: res.data[0].f_publication_id}}})
                                        .then((res2) => {
                                            let bulkCreate = [];
                                            for (let i = 0; i < this.state.publicationTags.length;i++){
                                                bulkCreate.push({f_publications_id:res.data[0].f_publication_id,tag_id:this.state.publicationTags[i]})
                                            }
                                            axios.post(urlBackend+'/postQuery', {query:{type:"insert_publications_tags",select_type:"insert", select_params:{bulk:bulkCreate}}})
                                                .then((res3) => {
                                                    this.props.update(this.props.id,layer);
                                                    this.setState({publicationType:"",publicationTags:[],
                                                        url:"",
                                                        mapType:"",
                                                        tags:[],
                                                        author:"",
                                                        doi:"",
                                                        year:"",
                                                        publicationError:"",
                                                        publicationName:"",
                                                        PublicationFiles:null})
                                                    this.props.close()
                                                })
                                                .catch((err) => {console.log(err)});
                                        })
                                        .catch((err) => {console.log(err)});
                                })
                                .catch((error) => {console.log(error)})

                            //this.setState({url:urlBackend+'/displayimages?file='+res.data.filename});

                    }else{
                        this.setState({publicationError:"Fill required fields"})
                    }
                }}>{"Save"}</Button>
            </div>
        )}}
export default UpdatePublications;
