import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import React from "react";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
export const Help = {
    en: {
        translation: {
            "Помощь":"Help"
        }
    },
    ru: {
        translation: {
            "Помощь":"Помощь"
        }
    }
};


