var config = {};

config.authURL = "https://www.mygeomap.ru/auth/";
config.loginURL = "https://www.mygeomap.ru/login/";
config.checkURL = "https://www.mygeomap.ru/check-auth/";
config.resetURL = "https://www.mygeomap.ru/password_reset/";
config.regURL = "https://www.mygeomap.ru/register/";
config.helpURL = "https://www.mygeomap.ru/wiki/";
config.kern = "htts://93.180.61.58:4444/api/analysistype/";
config.kernapi = "http://93.180.61.58:4444/reports/parameters_table/";
config.reprojectApi ="https://www.mygeomap.ru/crs_converter/";
config.ProtocolMartin = "https";
config.ProtocolBackend = "https";
config.HOSTMartin = "mygeomap.ru";
config.HOSTBackend = "www.mygeomap.ru";
config.PORTBackend = "443";
config.PORTMartin = "";
config.StartPortBackend=9001;
config.PrefixMartin = "/martin/rpc";
config.PrefixBackend = "/server";
config.FormatMartin = ".pbf";
config.georef="https://www.mygeomap.ru/georef/georef";
config.georefReproject="https://www.mygeomap.ru/georef/imagereproj";
config.georefApi="https://www.mygeomap.ru/georef";
config.reservesApi="https://www.mygeomap.ru/reserves_api";
config.viewer3dApi="https://www.mygeomap.ru/3dvisualizer/";
module.exports = config;
