import React, {useEffect} from 'react';
import {Button, Checkbox, Icon, Input, Label} from 'semantic-ui-react'
import axios from "axios";
import config from "../config";
import LoadingScreen from "./LoadingScreen";
import {t} from "i18next";
import Plot from "react-plotly.js";
const reservesApi = config.reservesApi;
//import "../Styles/MyGeoMapGraticule.css"
Array.prototype.isNull = function (){
    return this.join().replace(/,/g,'').length === 0;
};
export function ReservesGraphPreview (props) {
    const [x,setX] = React.useState(0);
    const [y,setY] = React.useState(0);
    const [wait,setWait] = React.useState(false)
    const [layout,setLayout] = React.useState({})
    const [plot,setPlot] = React.useState({})
    const [trigger,setTrigger] = React.useState(false);
    useEffect(()=>{
        console.log(props.variable,props.name,props.distribution,props.display,props.position,props.params,Object.keys(props.params),Object.values(props.params),Object.values(props.params).isNull())
        console.log(Object.keys(props.params).length>0 , !Object.values(props.params).isNull(),(Object.keys(props.params).length>0 && !Object.values(props.params).isNull()))
        if (Object.keys(props.params).length>0 && !Object.values(props.params).isNull()){
            console.log("proccess")
            let newJsonFormat = {
                "name": "Model",
                "seed": null,
                "num_samples": 1000,
                "config": {
                    "region_name": {
                        "composition": "Composition",
                        "inputs": {},
                        "results": {
                            "result": {
                                "equation": props.variable,
                                "probability":1,
                            }
                        }
                    }
                }
            }
            newJsonFormat.config.region_name.inputs[props.name] = {
                "variable":props.variable,
                "distribution": {
                    "name": props.distribution,
                    "params": props.params
                }
            }
            setWait(true)
            axios.post(reservesApi+'/getGraphData',newJsonFormat,{ headers: { "Content-Type": "application/json" } })
                .then(result => {
                    console.log(result.data.region_name.result);
                    setWait(false)
                    let layout = {
                        height: 350,
                        width: 450,
                    };
                    let trace1 = {
                        x: result.data.region_name.result.values,
                        xaxis: "x",
                        yaxis: "y",
                        type: "histogram",
                        histnorm: "probability density",
                        opacity: 0.8,
                        name:t(props.variable),
                        marker: {
                            color: "green",
                        },
                    };
                    setLayout(layout)
                    setPlot(trace1)
                    console.log(trace1,layout)
                })
                .catch(e=>{console.log(e)})
        }
    },[props.display, props.position, props.params, props.distribution, props.name, props.variable])
    return(
        <>
            {(props.display)&&(<>
                <div className="reserves-preview" style={{top: props.position.y+20, left: props.position.x-320}} >
                    <LoadingScreen close={()=>setWait(false)} loading={wait} />
                    <Plot
                        style={{marginLeft:'-10px'}}
                        data={[plot]}
                        layout={ layout }
                    />
                </div>
            </>)}
        </>


    )
}