import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import Cookies from "js-cookie";

export default function LoadProfileOnStart(props) {
    const [loading,setLoading] = useState(false)
    let params = useParams();
    console.log(params)

    useEffect(() => {
        if (loading)
            return
        setLoading(true)
        if (Cookies.get("id") !== undefined)
            props.profilehandle()
        else
            props.handleAlert("error"," Log In to view profile")
    }, [loading, props]);



}