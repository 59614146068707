import React from "react";
import {Button, Dropdown, Icon, Input, Label} from "semantic-ui-react";
import axios from "axios";
import Cookies from "js-cookie";
import Dropzone from "react-dropzone";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Slide from "@mui/material/Slide";
import GroupPermissionForObject from "./GroupPermissionForObject";
const config = require('../config');
const hostBackend = config.HOSTBackend;
const portBackend = config.PORTBackend;
const protocolBackend = config.ProtocolBackend;
const prefixBackend = config.PrefixBackend;
var urlBackend;
if (portBackend === "" || portBackend === undefined){
    urlBackend=protocolBackend+'://'+hostBackend+prefixBackend;
}
else{
    urlBackend=protocolBackend+'://'+hostBackend+':'+portBackend+prefixBackend;
}
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
class AddLegends extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            display:"none",
            source:"",
            name:"",
            PermissionDisplay:"none",
            groupPermArray:[],
            groupPermArrayBasic:[],
            PermissionDisplayIcon:'plus',
            publicationError:"",
            LegendFiles:null,
            addDisplay:"none",
            LegendText:"Files to Upload: ",
            exist:false,
            open:false,
            fileName:"",
        }
    };
    render() {
        const { t } = this.props;
        // eslint-disable-next-line no-unused-vars
        let files;
        return(
            <div style={{display:this.props.display}} >
                <Button basic size={"mini"} onClick={()=>{
                    if (this.state.addDisplay === "none"){
                        this.setState({addDisplay:"block"})
                    }
                    else
                        this.setState({addDisplay:"none"})
                }}><Icon name='plus'  />{"Add new legend"}</Button>
                <div style={{display:this.state.addDisplay}}>
                    {this.state.publicationError}
                    <br/>
                    <div>
                        <span >{"Имя легенды:"}<Input value={this.state.name}  onChange={(e,val) =>{
                            this.setState({name:val.value})
                        }}/></span>
                        <br/>
                        <span >{"Источник:"}<Input value={this.state.source}  onChange={(e,val) =>{
                            this.setState({source:val.value})
                        }}/></span>
                    </div>
                    <Dropzone onDrop={acceptedFiles => {
                        files = acceptedFiles;
                        console.log(acceptedFiles[0].name)
                        if (acceptedFiles[0].name !== undefined)
                            this.setState({LegendFiles:acceptedFiles,LegendText:"Files to Upload: "+ acceptedFiles[0].name});
                        else
                            this.setState({LegendFiles:acceptedFiles,LegendText:"Files to Upload: "});
                    }}
                    >
                        {({getRootProps, getInputProps}) => (
                            <section>
                                <div style={{height:"35px",width:"300px",borderRadius: "15px",padding: "10px",border:"1px solid black"}} {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    Drag'n'drop files here, or click to select files.
                                </div>
                            </section>
                        )}

                    </Dropzone>
                    <Dialog
                        classes={{ root: 'MenuItem'}}
                        open={this.state.open}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={()=>{this.setState({open:false})}}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle id="alert-dialog-slide-title">{t("Similar publication already exists in the database, please use search to check")}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description">
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={()=>{this.setState({open:false})}} color="primary">
                                {t("Cancel uploading")}
                            </Button>
                            <Button onClick={()=> {
                                let name = this.state.fileName;
                                let type;
                                if (this.state.LegendFiles){
                                    if (this.state.LegendFiles[0])
                                        type=this.state.LegendFiles[0].type;
                                    else
                                        type=null;
                                }
                                else
                                    type=null;
                                let values = "'"+name+"' , NULL, NULL, "+Cookies.get("id")+", '"+type+"', (select universal_dict_id from universal_dict where dict_code = 'attachment_type' and name_ru='Легенда')"
                                axios.post(urlBackend+'/postQuery', {query:{type:"attachments_insert", select_type:"insert", select_params:{values:values}}})
                                    .then((res) => {
                                        console.log(res.data[0].id)
                                        axios.post(urlBackend+'/postQuery', {query:{type:"legends_insert", select_type:"insert", select_params:{id:res.data[0].id,name:this.state.name,source:this.state.source}}})
                                            .then((res) => {
                                                this.props.update();
                                            }).catch((error) => {
                                                console.log(error)
                                        });
                                    }).catch((error) => {
                                    console.log(error)
                                });
                            }} color="primary">
                                {t("Upload anyway")}
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {(this.state.LegendFiles !== undefined  && this.state.LegendFiles !== null)&&(<span><Label basic>{this.state.LegendText+" "}</Label><br/></span>)}
                    <Button basic onClick={()=>{
                        if (this.state.LegendName !== "" ){
                            const dataform = new FormData();
                            if(this.state.LegendFiles!==null && this.state.LegendFiles!==undefined)
                                dataform.append('file',this.state.LegendFiles[0]);
                            else
                                dataform.append('file',"none");
                            axios.post(urlBackend+'/uploadtext', dataform, {
                                // receive two    parameter endpoint url ,form data
                            }).then(res => { // then print response status
                                //console.log(res,this.state.id)
                                console.log(this.props,res)
                                let layer = this.props.layer;
                                let name = null;
                                let type = null;
                                if (res.data === ""){
                                    name = "";
                                    type = null;
                                }
                                else {
                                    name = res.data.filename;
                                    type = this.state.LegendFiles[0].type;
                                }
                                    let values = "'"+name+"' , NULL, NULL, "+Cookies.get("id")+", '"+type+"', (select universal_dict_id from universal_dict where dict_code = 'attachment_type' and name_ru='Легенда')";
                                    axios.post(urlBackend+'/postQuery', {query:{type:"attachments_insert", select_type:"insert", select_params:{values:values}}})
                                        .then((res) => {
                                            console.log(res)
                                            axios.post(urlBackend+'/postQuery', {query:{type:"legends_insert", select_type:"insert", select_params:{id:res.data[0].id,name:this.state.name,source:this.state.source}}})
                                                .then((res2) => {
                                                    this.props.update();
                                                    console.log(res2)
                                                }).catch((error) => {
                                                console.log(error)
                                            });

                                        }).catch((error) => {
                                        console.log(error)
                                    });
                            }).catch((error) => {
                                console.log(error)
                            });
                        }else{
                            this.setState({publicationError:"Fill required fields"})
                        }
                    }}>{"Save"}</Button>
                </div>
            </div>
        )}}
export default AddLegends;
